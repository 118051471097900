import React from "react";
import { Form, Drawer } from "antd";

const DrawerDetalheMobile = ({ visible, categoria, periodo, onClose }) => {
    return (
        <Drawer
            className="drawer-detalhe-curso"
            title="Detalhe"
            width={720}
            onClose={onClose}
            visible={visible}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
        >
            <div className="bloco-info">
                <Form className="form-categorias" layout="horizontal">
                    <div className="bloco">
                        <Form.Item label="Categoria">
                            {categoria}
                        </Form.Item>
                        <Form.Item label="Periodo">
                            {periodo}
                        </Form.Item>
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button className="botao-secundario" onClick={onClose}>
                    Voltar
                </button>
            </div>
        </Drawer>
    );
}

export default DrawerDetalheMobile;
