import React, { Component } from "react";
import noimage from "../../../images/noimage.png";
import moment from "moment";

class ObservacaoAluno extends Component {

    momntarAlunos = aula => {
        if (!aula.presencas)
            return "Sem registos...";

        const presencas = JSON.parse(aula.presencas);
        let registos = presencas.map(presenca => {
            var participante = this.props.participantes.find(x => x.id === presenca.id)

            if (participante)
                return <li className="item-observacoes">
                    <div className="info-participante">
                        <div className="container-img">
                            <img src={participante.foto ? participante.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">{participante.nome}</span>
                            <p className="numero">{participante.numero}</p>
                        </div>
                    </div>
                    <p>{presenca.observacoes ? presenca.observacoes : "Sem registo..."}</p>
                </li>
        })

        return registos;
    }

    render() {
        const {
            aula,
            key
        } = this.props;

        return (
            <div key={key} className="bloco-diario-observacoes">
                <div className="observacao-data-aula">
                    {aula.curso ?
                        <span>{aula.curso} - {aula.titulo}</span>
                        :
                        <span> {aula.titulo}</span>
                    }
                    <span>{moment(aula.dt_inicio).format("DD/MM/YYYY")} <i className="fas fa-circle separador-data-hora"></i> {aula.hr_inicio} - {aula.hr_termino}</span>
                </div>
                <ul className="lista-observacoes">
                    {this.momntarAlunos(aula)}
                </ul>
            </div>
        );
    }
}

export default ObservacaoAluno;