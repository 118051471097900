import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { notification, Modal, Rate, Button } from "antd";
import OpcaoAnotacoes from "./OpcaoAnotacoes";
import OpcaoModulos from "./OpcaoModulos";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Controlos extends Component {
    static contextType = GlobalContext;
    state = {
        aulaid: 0,
        drawerTranscricao: false,
        drawerForum: false,
        drawerAnotacoes: false,
        drawerModulos: false,
        drawerEnviarMsg: false,
        marcador: false,
        modalRating: false,
        ratingEnviado: false,
        rating: null,
        //ATIVIDADES
        modulos: [],
        loadingModulos: false,
        //MODAL INFORMACOES
        visibleModalInfos: false,
        //SETAS
        btnAnteriorLink: "",
        btnAnteriorModuloIndex: "",
        btnAnteriorTarefaId: "",
        btnAnteriorAlerta: "",
        btnProximoLink: "",
        btnProximoModuloIndex: "",
        btnProximoTarefaId: "",
        btnProximoAlerta: "",
        btnDisabledAnterior: false,
        btnDisabledProximo: false,
    };

    UNSAFE_componentWillMount() {
        //const match = matchPath(window.location.pathname, {
        //    path: "/:curso/:cursoid/:aula/:aulaid"
        //});

        //if (match && match.params.aulaid) {
        //    this.setState({
        //        aulaid: match.params.aulaid
        //    });
        //    this.montarAtividades()
        //}
        this.setState({
            aulaid: localStorage.getItem("codigo_aula")
        });
        this.montarAtividades();
    }

    UNSAFE_componentWillReceiveProps() {
        if (!this.props.sameUrl) {
            this.setState({
                drawerModulos: false
            });

            //const match = matchPath(window.location.pathname, {
            //    path: "/:curso/:cursoid/:aula/:aulaid"
            //});

            //if (match && match.params.aulaid) {
            //    this.setState({
            //        aulaid: match.params.aulaid
            //    });
            //    () => this.montarAtividades()
            //}
            this.setState({
                aulaid: localStorage.getItem("codigo_aula")
            });
            this.montarAtividades();
        }
    }

    montarAtividades = () => {
        if (this.context.lista_modulos.length === 0) {
            /*this.setState({ loading: true })*/
            this.listarModulos();
        }
        else
            this.setState({
                modulos: this.context.lista_modulos,
            }, () => this.montarSetas())
    }

    listarModulos = () => {
        this.setState({
            loadingModulos: true
        });

        axios({
            method: "get",
            url: "/api/gestao-player/gerir-listar-modulos",
            params: {
                cursoid: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.context.atualizarState({
                    lista_modulos: response.data
                });

                this.setState({
                    modulos: response.data,
                    loadingModulos: false
                }, () => this.montarSetas());
            })
            .catch(() => { });
    };

    montarSetas = () => {
        var tarefaId = parseInt(localStorage.getItem("codigo_aula"));
        var _modulos = this.state.modulos;

        for (let i = 0; i < _modulos.length; i++) {
            var tarefaIndex = _modulos[i].modulo_Aula.findIndex(x => x.id === tarefaId)
            if (tarefaIndex >= 0) {//CASO TRUE É TAREFA DE NIVEL 1
                this.validarTarefas(tarefaIndex, _modulos, i);
                break;
            }
            else {//CASO TRUE E TAREFA DE NIVEL 2
                for (let e = 0; e < _modulos[i].modulo_Aula.length; e++) {
                    tarefaIndex = _modulos[i].modulo_Aula[e].modulo_Aula_lvl2.findIndex(x => x.id === tarefaId);

                    if (tarefaIndex >= 0) {
                        this.validarSubTarefas(tarefaIndex, _modulos, i, e);

                        break;
                    }
                }

                if (tarefaIndex >= 0)
                    break;
            }
        }
    }

    validarTarefas = (tarefaIndex, _modulos, i) => {// i -> index do modulo
        this.validarTarefaAnteriorAtividadeAtiva(_modulos, i, tarefaIndex);
        this.validarProximaTarefaAtividadeAtiva(_modulos, i, tarefaIndex);
    }

    validarSubTarefas = (tarefaIndex, _modulos, i, e) => {// i -> index do modulo e -> index da tarefa pai
        this.validarSubTarefaAnteriorAtiva(_modulos, i, e, tarefaIndex);
        this.validarProximaSubTarefaAtiva(_modulos, i, e, tarefaIndex);
    }

    validarProximoModulo = (_modulos, i) => {
        for (let t = i + 1; t < _modulos.length; t++) {
            if (_modulos[t].modulo_Aula.length) {
                var tipoRecurso = _modulos[t].modulo_Aula[0].tipo_Recurso.id;
                if (tipoRecurso !== 13) {
                    this.montarLinkProximo(_modulos[t].modulo_Aula[0], t)
                }
                else {
                    this.validarProximaTarefaAtividadeAtiva(_modulos, t, 0);
                }
                break;
            }
        }
    }

    validarModuloAnterior = (_modulos, i) => {
        for (let t = i - 1; t >= 0; t--) {
            this.validarTarefaAnteriorAtividadeAtiva(_modulos, t, _modulos[t].modulo_Aula.length);
            break;
        }
    }

    validarProximaTarefaAtividadeAtiva = (_modulos, i, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let s = 0; s < _modulos[i].modulo_Aula[tarefaIndex].modulo_Aula_lvl2.length; s++) {
            let tipoRecurso = _modulos[i].modulo_Aula[tarefaIndex].modulo_Aula_lvl2[s].tipo_Recurso.id;
            if (tipoRecurso !== 13) {
                this.montarLinkProximo(_modulos[i].modulo_Aula[tarefaIndex].modulo_Aula_lvl2[s], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada) {
            for (let m = tarefaIndex + 1; m < _modulos[i].modulo_Aula.length; m++) {
                var tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
                if (tipoRecurso !== 13) {
                    this.montarLinkProximo(_modulos[i].modulo_Aula[m], i)
                    tarefaEncontrada = true;
                    break;
                }
                else {
                    for (let s = 0; s < _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length; s++) {
                        tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                        if (tipoRecurso !== 13) {
                            this.montarLinkProximo(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                            tarefaEncontrada = true;
                            break;
                        }
                    }
                }

                if (tarefaEncontrada)
                    break;
            }
        }

        if (!tarefaEncontrada) {
            this.validarProximoModulo(_modulos, i);
        }
    }

    validarTarefaAnteriorAtividadeAtiva = (_modulos, i, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let m = tarefaIndex - 1; m >= 0; m--) {
            let tipoRecurso = "";
            for (let s = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length - 1; s >= 0; s++) {
                tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                if (tipoRecurso !== 13) {
                    this.montarLinkAnterior(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                    tarefaEncontrada = true;
                    break;
                }
            }

            if (tarefaEncontrada)
                break;

            tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
            if (tipoRecurso !== 13) {
                this.montarLinkAnterior(_modulos[i].modulo_Aula[m], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada)
            this.validarModuloAnterior(_modulos, i);
    }

    validarProximaSubTarefaAtiva = (_modulos, i, e, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let s = tarefaIndex + 1; s < _modulos[i].modulo_Aula[e].modulo_Aula_lvl2.length; s++) {
            let tipoRecurso = _modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s].tipo_Recurso.id;
            if (tipoRecurso !== 13) {
                this.montarLinkProximo(_modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada) {
            for (let m = e + 1; m < _modulos[i].modulo_Aula.length; m++) {
                var tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
                if (tipoRecurso !== 13) {
                    this.montarLinkProximo(_modulos[i].modulo_Aula[m], i)
                    tarefaEncontrada = true;
                    break;
                }
                else {
                    for (let s = 0; s < _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length; s++) {
                        tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                        if (tipoRecurso !== 13) {
                            this.montarLinkProximo(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                            tarefaEncontrada = true;
                            break;
                        }
                    }
                }
            }
        }

        if (!tarefaEncontrada) {
            this.validarProximoModulo(_modulos, i);
        }
    }

    validarSubTarefaAnteriorAtiva = (_modulos, i, e, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let s = tarefaIndex - 1; s >= 0; s--) {
            let tipoRecurso = _modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s].tipo_Recurso.id;
            if (tipoRecurso !== 13) {
                this.montarLinkAnterior(_modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada) {
            var tipoRecurso = _modulos[i].modulo_Aula[e].tipo_Recurso.id;
            if (tipoRecurso !== 13) {
                this.montarLinkAnterior(_modulos[i].modulo_Aula[e], i)
                tarefaEncontrada = true;
            }
        }

        if (!tarefaEncontrada) {
            for (let m = e - 1; m >= 0; m--) {
                var tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
                if (tipoRecurso !== 13) {
                    this.montarLinkAnterior(_modulos[i].modulo_Aula[m], i)
                    tarefaEncontrada = true;
                    break;
                }
                else {
                    for (let s = 0; s < _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length; s++) {
                        tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                        if (tipoRecurso !== 13) {
                            this.montarLinkAnterior(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                            tarefaEncontrada = true;
                            break;
                        }
                    }

                    if (tarefaEncontrada)
                        break;
                }
            }
        }

        if (!tarefaEncontrada) {
            this.validarModuloAnterior(_modulos, i);
        }
    }

    montarLinkAnterior = (tarefa, moduloIndex) => {
        let link = `/gerir-curso/${this.props.match.params.curso}/${tarefa.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}-${Math.floor(100000 + Math.random() * 900000)}${tarefa.tipo_Recurso.url}`

        this.setState({
            btnAnteriorLink: link,
            btnAnteriorModuloIndex: moduloIndex,
            btnAnteriorTarefaId: tarefa.id
        })
    }

    montarLinkProximo = (tarefa, moduloIndex) => {
        let link = `/gerir-curso/${this.props.match.params.curso}/${tarefa.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}-${Math.floor(100000 + Math.random() * 900000)}${tarefa.tipo_Recurso.url}`

        this.setState({
            btnProximoLink: link,
            btnProximoModuloIndex: moduloIndex,
            btnProximoTarefaId: tarefa.id
        })
    }

    //FECHAR MÓDULOS
    fecharModulos = () => {
        this.setState({ drawerModulos: false });
    };

    //FECHAR ANOTAQÇÕES
    fecharAnotacoes = () => {
        this.setState({ drawerAnotacoes: false });
    };

    //SUBMETER RATING
    enviarRating = () => {
        axios({
            method: "post",
            url: "/api/gestao-player/total-avaliacoes",
            params: {
                aulaid: this.state.aulaid
            }
        })
            .then(response => {
                this.setState({
                    rating: response.data.rating,
                    modalRating: false
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível submeter o rating!");
            });
    };

    handleChangeRating = value => {
        this.setState({ rating: value });
    };

    render() {
        return (
            <>
                <div className="bloco-controlos bloco-controlos-player">
                    {(!this.context.chave_entidade && this.context.role === "UTILIZADOR") || this.context.role !== "UTILIZADOR" ? (
                        <>
                            <div className="controlos">
                                <div className="container-btn">
                                    <Link to={this.props.backUrl} title="Voltar">
                                        <i className="fas fa-angle-double-left"></i>
                                    </Link>
                                </div>
                                {!this.props.semModulos && (
                                    <div className="container-btn">
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.setState({ drawerModulos: true });
                                            }}
                                            title="Módulos"
                                        >
                                            <i className="fas fa-list-ul" />
                                        </Link>
                                    </div>
                                )}
                                {this.props.temRating ? (
                                    <div className={"container-btn" + `${this.state.ratingEnviado ? " ativo" : ""}`}>
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.setState({
                                                    modalRating: true
                                                });
                                            }}
                                            title="Avaliação"
                                        >
                                            <i className="fas fa-star" />
                                        </Link>
                                    </div>
                                ) : null}
                                {this.props.temAnotacoes ? (
                                    <div className="container-btn">
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                this.setState({
                                                    drawerAnotacoes: true,
                                                    nao_nova_anotacao: true
                                                });
                                            }}
                                            title="Anotações"
                                        >
                                            <i className="fas fa-sticky-note" />
                                        </Link>
                                    </div>
                                ) : null}
                                <div className="container-btn">
                                    <Link to="#" onClick={this.context.onClickFullscreen} title={this.context.fullscreen ? "Sair de ecrã inteiro" : "Ecrã inteiro"}>
                                        {this.context.fullscreen ? <i className="fas fa-compress" /> : <i className="fas fa-expand" />}
                                    </Link>
                                </div>
                                <div className="container-btn">
                                    <Link to="#" title="Informações" onClick={() => this.setState({ visibleModalInfos: true })}>
                                        <i className="fas fa-info"></i>
                                    </Link>
                                </div>
                                <div className={`container-btn ${this.state.btnAnteriorLink === "" ? "container-btn-disable" : ""}`}>
                                    <Link
                                        to={this.state.btnAnteriorLink}
                                        title="Anterior"
                                        disabled={this.state.btnAnteriorLink === ""}
                                        onClick={() => {
                                            localStorage.setItem("modulo_aberto", this.state.btnAnteriorModuloIndex);
                                            localStorage.setItem("codigo_aula", this.state.btnAnteriorTarefaId);
                                        }}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </Link>
                                </div>
                                <div className={`container-btn ${this.state.btnProximoLink === "" ? "container-btn-disable" : ""}`}>
                                    <Link
                                        to={this.state.btnProximoLink}
                                        title="Próxima"
                                        disabled={this.state.btnProximoLink === ""}
                                        onClick={() => {
                                            localStorage.setItem("modulo_aberto", this.state.btnProximoModuloIndex);
                                            localStorage.setItem("codigo_aula", this.state.btnProximoTarefaId);
                                        }}
                                    >
                                        <i className="fas fa-chevron-right"></i>
                                    </Link>
                                </div>
                            </div>
                            <OpcaoModulos modulos={this.state.modulos} loading={this.state.loadingModulos} drawerModulos={this.state.drawerModulos} fecharModulos={this.fecharModulos} />
                            <OpcaoAnotacoes drawerAnotacoes={this.state.drawerAnotacoes} fecharAnotacoes={this.fecharAnotacoes} />
                            <Modal title="Submeter Rating" visible={this.state.modalRating} onOk={this.enviarRating} onCancel={() => this.setState({ modalRating: false })}>
                                <div style={{ textAlign: "center", marginBottom: 20 }}>
                                    <Rate value={this.state.rating} onChange={this.handleChangeRating} />
                                </div>
                            </Modal>
                        </>
                    ) : (
                        <div className="controlos">
                            <div className="container-btn">
                                <Link to={this.props.backUrl} >
                                    <i className="fas fa-angle-double-left" title="Voltar" />
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
                <Modal
                    visible={this.state.visibleModalInfos}
                    onCancel={() => this.setState({ visibleModalInfos: false })}
                    footer={[
                        <Button key="primary" onClick={() => this.setState({ visibleModalInfos: false })}>
                            Voltar
                        </Button>
                    ]}
                >
                    <div className="modal-player-informacoes">
                        <div className="bloco-info-tarefa">
                            <span className="icon-tarefa">
                                <img src={this.props.icon} />
                            </span>
                            <span className="titulo-data-tarefa">
                                <span className="titulo-tarefa">{this.props.nome}</span>
                                <span className="data-tarefa">{this.props.dataHora}</span>
                                {this.props.questoes ? <span className="numero-questoes">{this.props.questoes} {this.props.questoes > 1 ? "Questões" : "Questão"}</span> : null}
                            </span>
                        </div>
                        <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: this.props.objetivos }}></p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default Controlos = withRouter(Controlos);
