import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Dropdown, Menu } from "antd";
import Header from "../../User/Header";
import noimage from "../../images/noimage.png";
import axios from "axios";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import DrawerInscricao from "./_Aux/DrawerInscricao";
import moment from "moment";
import { GlobalContext } from "../../GlobalState";

class GestaoCursosInscricoes extends Component {
    static contextType = GlobalContext;
    state = {
        //TABELA
        loading_table: true,
        alunos: [],
        ativo: true,
        inscritos: false,
        //PAGINATION
        //pagination: {
        //    pageSize: 10,
        //    current: 1,
        //    total: 0
        //},
        //INFO PRINCIPAL
        cursoid: 0,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //FILTRO PROCURAR
        pesquisa: "",
        //DRAWER INSCRICAO
        inscricaoId: 0,
        anexo: "",
        nr_aluno: "",
        nome: "",
        email: "",
        contacto: "",
        descricao: "",
        habilitacao: "",
        paginaPessoal: "",
        dt_nascimento: "",
        naturalidade: "",
        sexo: "",
        estado_civil: "",
        cc: "",
        dt_ccvalidade: "",
        nif: "",
        cep: "",
        localidade: "",
        morada: "",
        motivo: "",
        responsavel_1: "",
        responsavel_2: "",
        inscricao_visible: false,
        nomeID: "",
        ccID: "",
        dt_ccvalidadeID: "",
        ficheiro_inscricao: "",
        ficheiro_inscricao_nome: "",
        dt_ficheiro_inscricao: ""
    };

    UNSAFE_componentWillMount() {
        //this.total();
        this.listar();
    }

    componentDidUpdate() {
        if (this.context.role === "UTILIZADOR")
            this.setState({
                redirect: true,
                redirectLink: "/user"
            });
    }

    //LIMPA A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: ""
            },
            () => {
                this.listar();
            }
        );
    };

    //CHAMADA APÓS INTERASSÃO COM A CAIXA DE PROCURA
    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value
                },
                () => {
                    //this.total();
                    this.listar();
                }
            );
        }
    };

    //LISTA TODOS OS ALUNOS DA ENTIDADE
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/formador-cursos-inscricoes/listar",
                    params: {
                        cursoid: localStorage.getItem("codigo_curso"),
                        pesquisa: this.state.pesquisa
                    }
                })
                    .then(response => {
                        var alunos = [];

                        response.data.map((aluno, index) => {
                            alunos.push({
                                key: aluno.inscricaoId,
                                nome: (
                                    <>
                                        <div className="imagem-curso">
                                            <img src={aluno.foto ? aluno.foto : noimage} />
                                        </div>
                                        <div className="nome-colaborador">
                                            <span className="titulo-curso">{aluno.nome_completo}</span>
                                        </div>
                                    </>
                                ),
                                email: aluno.email,
                                nr_aluno: <div className="info-numero-estado">
                                    <span className="info-numero">{aluno.nr_aluno}</span>
                                    {aluno.pendente ?
                                        <span className="info-estado">Registo Pendente</span>
                                        : null}
                                </div>,
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(aluno.inscricaoId)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });
                        });

                        this.setState({
                            alunos,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    // MONTA O MENU DE OPÇÕES POR CADA CURSO/LINHA
    montarMenu = inscricaoId => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.consultar(inscricaoId)}>
                        Detalhe
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    consultar = inscricaoId => {
        axios({
            method: "get",
            url: "/api/formador-cursos-inscricoes/consultar-inscricao",
            params: {
                inscricaoId
            }
        })
            .then(response => {
                this.setState({
                    anexo: response.data.anexo,
                    nr_aluno: response.data.nr_aluno,
                    nome: response.data.nome,
                    email: response.data.email,
                    contacto: response.data.contacto ? response.data.contacto : "",
                    descricao: response.data.descricao ? response.data.descricao : "",
                    habilitacao: response.data.habilitacao ? response.data.habilitacao : "",
                    paginaPessoal: response.data.paginaPessoal ? response.data.paginaPessoal : "",
                    dt_nascimento: response.data.dt_nascimento ? moment(response.data.dt_nascimento).format("DD/MM/YYYY") : null,
                    naturalidade: response.data.naturalidade ? response.data.naturalidade : "",
                    sexo: response.data.sexo ? response.data.sexo : undefined,
                    estado_civil: response.data.estado_civil ? response.data.estado_civil : undefined,
                    cc: response.data.cc ? response.data.cc : "",
                    dt_ccvalidade: response.data.dt_ccvalidade ? moment(response.data.dt_ccvalidade).format("DD/MM/YYYY") : null,
                    nif: response.data.nif ? response.data.nif : "",
                    cep: response.data.cep ? response.data.cep : "",
                    localidade: response.data.localidade ? response.data.localidade : "",
                    morada: response.data.morada ? response.data.morada : "",
                    pais: response.data.pais ? response.data.pais : "",
                    motivo: response.data.motivo ? response.data.motivo : "",
                    responsavel_1: response.data.responsavel_1 ? response.data.responsavel_1 : "",
                    responsavel_2: response.data.responsavel_2 ? response.data.responsavel_2 : "",
                    inscricao_visible: true,
                    nomeID: response.data.nomeCompletoID,
                    ccID: response.data.docIdentificacaoID,
                    dt_ccvalidadeID: moment(response.data.identificacaoValidadeID).format("DD/MM/YYYY"),
                    ficheiro_inscricao: response.data.ficheiro_inscricao,
                    ficheiro_inscricao_nome: response.data.ficheiro_inscricao_nome,
                    dt_ficheiro_inscricao: response.data.dt_ficheiro_inscricao ? moment(response.data.dt_ficheiro_inscricao).format("DD/MM/YYYY") : null
                });
            })
            .catch(error => {});
    };

    fecharDrawerInscricao = () => {
        this.setState({
            inscricao_visible: false
        });
        this.listar();
    };

    render() {
        const {
            //TABELA
            loading_table,
            alunos,
            //PAGINATION
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER INSCRICAO
            inscricaoId,
            anexo,
            nr_aluno,
            nome,
            email,
            contacto,
            descricao,
            habilitacao,
            paginaPessoal,
            dt_nascimento,
            naturalidade,
            sexo,
            estado_civil,
            cc,
            dt_ccvalidade,
            nif,
            cep,
            localidade,
            morada,
            pais,
            motivo,
            responsavel_1,
            responsavel_2,
            inscricao_visible,
            nomeID,
            ccID,
            dt_ccvalidadeID,
            ficheiro_inscricao,
            ficheiro_inscricao_nome,
            dt_ficheiro_inscricao
        } = this.state;

        const columns = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-nome"
            },
            {
                title: "Código",
                dataIndex: "nr_aluno"
            },
            {
                title: "E-mail",
                dataIndex: "email"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-opcoes"
            }
        ];

        const columnsMobile = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-nome"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-opcoes"
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Inscrições" />
                <div className="container container-body">
                    <div className="bloco-cursos bloco-cursos-inscricoes">
                        <div className="bloco-tabela">
                            <div className="bloco-flex" style={{ marginBottom: 20 }}>
                                {localStorage.getItem("nome_curso_pai") ? (
                                    <div>
                                        <h3>
                                            {localStorage.getItem("cod_curso_pai")} - {localStorage.getItem("nome_curso_pai")}
                                        </h3>
                                        <span>
                                            {localStorage.getItem("cod_subcurso")} - {localStorage.getItem("nome_subcurso")}
                                        </span>
                                    </div>
                                ) : (
                                    <h3 className="tabela_titulo">
                                        {localStorage.getItem("cod_curso")} - {localStorage.getItem("nome_curso")}
                                    </h3>
                                )}
                                <Link to="/formador-lista-cursos" className="botao-principal">
                                    Voltar
                                </Link>
                            </div>
                            <div className="filtros" style={{ justifyContent: "space-between" }}>
                                <div className="bloco-esquerda">
                                    <BotaoProcurar
                                        placeholder="Coloque o nome a pesquisar..."
                                        tem_placeholder
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </div>
                            <Table
                                id="tabela-inscricoes"
                                className="tabela-inscricoes-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={alunos}
                                pagination={false}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                id="tabela-inscricoes"
                                className="tabela-inscricoes-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsMobile}
                                dataSource={alunos}
                                pagination={false}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                        </div>
                    </div>
                </div>
                <DrawerInscricao
                    visible={inscricao_visible}
                    onClose={() => this.setState({ inscricao_visible: false })}
                    inscricaoId={inscricaoId}
                    anexo={anexo}
                    nome={nome}
                    nr_aluno={nr_aluno}
                    email={email}
                    contacto={contacto}
                    descricao={descricao}
                    habilitacao={habilitacao}
                    paginaPessoal={paginaPessoal}
                    dt_nascimento={dt_nascimento}
                    naturalidade={naturalidade}
                    sexo={sexo}
                    estado_civil={estado_civil}
                    cc={cc}
                    dt_ccvalidade={dt_ccvalidade}
                    nif={nif}
                    cep={cep}
                    localidade={localidade}
                    morada={morada}
                    pais={pais}
                    motivo={motivo}
                    responsavel_1={responsavel_1}
                    responsavel_2={responsavel_2}
                    nomeID={nomeID}
                    ccID={ccID}
                    dt_ccvalidadeID={dt_ccvalidadeID}
                    ficheiro_inscricao={ficheiro_inscricao}
                    ficheiro_inscricao_nome={ficheiro_inscricao_nome}
                    dt_ficheiro_inscricao={dt_ficheiro_inscricao}
                    fecharDrawerInscricao={this.fecharDrawerInscricao}
                />
            </>
        );
    }
}

export default GestaoCursosInscricoes;
