import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { notification, Form, Icon } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../GlobalState";
import LazyLoad from "react-lazy-load";
import Oops from "../Geral/Oops.jsx";

class Sumario extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        modulo: "",
        moduloIndex: 0,
        aulaid: 0,
        aula: "",
        aulaIndex: 0,
        fullscreen: false,
        nome: "",
        objetivos: "",
        dt_inicio: null,
        hora: null,
        descricao: "",
        current: "",
        updateModulos: false,
        dataHora: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        sameUrl: true,
        //NAO DISPONIVEL
        nao_disponivel: false,
        //LOADING
        loading: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/gestao-player/conteudo3",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    dt_inicio: response.data.dt_inicio ? moment(response.data.dt_inicio).format("DD/MM/YYYY") : "",
                    hora: response.data.hora,
                    descricao: response.data.recurso,
                    dataHora: moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"),
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.data === "SEM_PERMISSAO")
                    this.setState({
                        redirect: true,
                        redirectLink: "/user"
                    });
                else if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    nao_disponivel: true
                })
            });
    };

    render() {
        const {
            curso,
            nome,
            objetivos,
            dt_inicio,
            hora,
            descricao,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //NAO DISPONIVEL
            nao_disponivel,
            //LOADING
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
            <div className="questionario">
                <div className="player-wrapper">
                    <LazyLoad offsetVertical={200}>
                        <img src={require("../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                    </LazyLoad>
                    {!nao_disponivel ?
                        <>
                            <div className="container">
                                <div className="bloco-presencial">
                                    <div className="bloco-info sessao-presencial bloco-player-template">
                                        <div className="bloco-info-tarefa">
                                            <span className="icon-tarefa">
                                                <img src={require("../images/icons/sumario.png")} />
                                            </span>
                                            <span className="titulo-tarefa">{nome}</span>
                                        </div>
                                        <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                        <Form className="form-aula" layout="horizontal" style={{ margin: 0 }}>
                                            <div className="info">
                                                <Form.Item>
                                                    <span className="desc">
                                                        <Icon type="calendar" /> {dt_inicio}
                                                    </span>
                                                </Form.Item>
                                                {hora && (
                                                    <Form.Item>
                                                        <span className="desc">
                                                            <Icon type="clock-circle" /> {hora}
                                                        </span>
                                                    </Form.Item>
                                                    )}
                                                    {descricao ?
                                                        <Form.Item>
                                                            <span className="desc" dangerouslySetInnerHTML={{ __html: descricao.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>
                                                        </Form.Item> : null}
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            <Controlos
                                backUrl={`/gerir-curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                sameUrl={sameUrl}
                                temAnotacoes
                                nome={nome}
                                objetivos={objetivos}
                                    icon={require("../images/icons/sumario.png")}
                                    dataHora={dataHora}
                            />
                        </>
                        :
                        <>
                            <div className="container">
                                <div className="bloco-nao-disponivel">
                                    <div className="bloco-info nao-disponivel">
                                        <Oops tem_texto texto={"Conte�do n�o dispon�vel"} />
                                    </div>
                                </div>
                            </div>
                            <ControlosSimples />
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default Sumario;
