import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import PaginaEntidade from "./Main";
import PaginaConsultar from "./Consultar/Main";
import PaginaConsultarNotasCreche from "./Consultar/NotasCreche";
import PaginaConsultarNotasPre from "./Consultar/NotasPre";
import PaginaConsultarNotasRegular from "./Consultar/NotasRegular";
import PaginaEntidadeConsultar from "./Consultar";
import ConsultarResultados from "./ConsultarResultados";
import PaginaEntidadeLogin from "./Login";
import PaginaEntidadeLogin2 from "./Login2";
import PaginaEntidadeAlterarSenha from "./AlterarSenha";
import PaginaEntidadeRecuperarPassword from "./RecuperarPassword";
import PaginaEntidadeRecuperarPassword2 from "./RecuperarPassword2";
import PaginaEntidadeSolicitarConta from "./SolicitarConta";
import PaginaEntidadeCriarConta from "./CriarConta";
import PaginaEntidadeRegistarConta from "./RegistarConta";
import PaginaRegistoPasso2 from "./RegistoPasso2";
import PaginaRegistoConclusao from "./RegistoConclusao";
import PaginaEntidadeContacto from "./Contacto";
import PaginaEntidadeInformacoes from "./Informacoes/Lista";
import PaginaEntidadeUnsubscribe from "./Unsubscribe";
import PaginaEntidadeVideoConfInscricao from "./VideoConfInscricao";
import PaginaEntidadeVideoConfCodigo from "./VideoConfCodigo";
import PaginaEntidadeVideoConfComCodigo from "./VideoConfComCodigo";
import PaginaEntidadeVideoConfAguardar from "./VideoConfAguardar";
import PaginaEntidadeJitsiMeetingGeral from "./JitsiMeetingGeral";
import PaginaVideoSimples from "./VideoSimples";
import PaginaEntidadeCurso from "./Curso/CursoLayout";
import PaginaEntidadePlayer from "./Player/PlayerLayout";
import PaginaChatConfirmacao from "./Chat/Confirmacao";
import PaginaChatCodigo from "./Chat/Codigo";
import PaginaChatInativo from "./Chat/Inativo";
import PaginaPaginas from "./Paginas/Pagina";
import PaginaChat from "./Chat/Main";
import Navbar from "../Geral/Navbar";
import Footer from "../Geral/Footer";
import axios from "axios";

class Layout extends Component {
    static contextType = GlobalContext;
    state = {
        redessociais: [],
        fundoBranco: false,
        semIniciarSessao: false,
        entidade: "",
        //REDIRECT
        redirect: false
    };

    componentDidMount() {
        if (localStorage.getItem("entidadeId")) {
            if (!localStorage.getItem("entidade_url")) {
                localStorage.setItem("entidade_url", this.props.match.params.entidade)
                this.carregarEntidade(localStorage.getItem("entidadeId"));
                this.entidadeRS(localStorage.getItem("entidadeId"));
            }
            else if (localStorage.getItem("entidade_url") !== this.props.match.params.entidade) {
                localStorage.setItem("entidade_url", this.props.match.params.entidade)
                this.carregarEntidadeID(this.props.match.params.entidade);
            }
            else {
                this.carregarEntidade(localStorage.getItem("entidadeId"));
                this.entidadeRS(localStorage.getItem("entidadeId"));
            }
        } else {
            localStorage.setItem("entidade_url", this.props.match.params.entidade)
            this.carregarEntidadeID(this.props.match.params.entidade);
        }

        this.setState({
            semIniciarSessao: this.props.location.pathname.split("/").pop() === "utilizador-registar-passo-2"
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                semIniciarSessao: this.props.location.pathname.split("/").pop() === "utilizador-registar-passo-2"
            });
        }
    }

    carregarEntidadeID = nome_abrev => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/carregar-entidadeid",
            params: {
                nome_abrev
            }
        })
            .then(response => {
                localStorage.setItem("entidadeId", response.data);
                this.carregarEntidade(response.data);
                this.entidadeRS(response.data);
            })
            .catch(error => { })
    }

    //LISTA DAS TIPOS
    carregarEntidade = entidadeId => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/carregar-entidade",
            params: {
                entidadeId,
                entidadeUrl: this.props.match.params.entidade
            }
        })
            .then(response => {
                if (response.data) {
                    this.setState({
                        entidade: {
                            logo_entidade: response.data.logo,
                            nome_entidade: response.data.nome,
                            site_entidade: "/" + this.props.match.params.entidade,
                            login_entidade: response.data.login2 ? "login-escola" : "login",
                            imagem_propria: response.data.imagem_propria,
                            paginas: response.data.paginas
                        }
                    });
                    if (response.data.imagem_propria)
                        this.context.atualizarState({
                            capa: response.data.capa,
                            capavideo: response.data.capavideo,
                            favicon_entidade: response.data.logo,
                            //title_entidade: response.data.nome_abrev ? response.data.nome_abrev : response.data.nome,
                            title_entidade: response.data.nome,
                            login2_entidade: response.data.login2,
                            autoconta_entidade: response.data.autoconta,
                            valorMensal_entidade: response.data.valorMensal,
                            moeda_entidade: response.data.moeda,
                            monetizacao_entidade: response.data.monetizacao_entidade,
                            vendaCursos: response.data.vendaCursos,
                            valorMensal: response.data.valorMensal,
                            subscricao_entidade: response.data.subscricao_entidade,
                            escolas_entidade: response.data.escolas_entidade,
                            login_entidade: response.data.login2 ? "login-escola" : "login",
                            diario_entidade: response.data.diario,
                        });
                    else
                        this.context.atualizarState({
                            capa: response.data.capa,
                            capavideo: response.data.capavideo,
                            login2_entidade: response.data.login2,
                            autoconta_entidade: response.data.autoconta,
                            valorMensal_entidade: response.data.valorMensal,
                            moeda_entidade: response.data.moeda,
                            monetizacao_entidade: response.data.monetizacao_entidade,
                            vendaCursos: response.data.vendaCursos,
                            valorMensal: response.data.valorMensal,
                            subscricao_entidade: response.data.subscricao_entidade,
                            escolas_entidade: response.data.escolas_entidade,
                            login_entidade: response.data.login2 ? "login-escola" : "login"
                        });
                } else
                    this.setState({
                        redirect: true
                    });
            })
            .catch(() => {});
    };

    entidadeRS = entidadeId => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/entidade-rs-publico",
            params: {
                entidadeId,
                entidadeUrl: this.props.match.params.entidade
            }
        })
            .then(response => {
                localStorage.setItem("whatsapp", response.data[7])
                this.setState({ redessociais: response.data });
            })
            .catch(() => { });
    };

    render() {
        const { redessociais, fundoBranco, semIniciarSessao, entidade } = this.state;

        const opcoes = (
            <ul className="links_uteis" style={{ textAlign: "center" }}>
                <li>
                    <Link to={`/${this.props.match.params.entidade}/suporte-contacto`}>Contato</Link>
                </li>
            </ul>
        );

        if (this.state.redirect) return <Redirect to="/" />;

        return (
            <>
                <Navbar semIniciarSessao={semIniciarSessao} semPesquisa entidade={entidade} />
                <Switch>
                    <Route path="/:entidade/curso/:curso/:cursoid/tarefa/:aula" component={PaginaEntidadePlayer} />
                    <Route path="/:entidade/curso/:curso/:cursoid" component={PaginaEntidadeCurso} />
                    <Route path="/:entidade/consultar-resultados" component={ConsultarResultados} />
                    <Route path="/:entidade/consultar/notas-creche/:utilizadorid" component={PaginaConsultarNotasCreche} />
                    <Route path="/:entidade/consultar/notas-pre/:utilizadorid" component={PaginaConsultarNotasPre} />
                    <Route path="/:entidade/consultar/notas-regular/:utilizadorid" component={PaginaConsultarNotasRegular} />
                    <Route path="/:entidade/consultar/:utilizadorid" component={PaginaConsultar} />
                    <Route path="/:entidade/consultar" component={PaginaEntidadeConsultar} />
                    <Route path="/:entidade/login" component={PaginaEntidadeLogin} />
                    <Route path="/:entidade/login-escola" component={PaginaEntidadeLogin2} />
                    <Route path="/:entidade/alteracao-senha" component={PaginaEntidadeAlterarSenha} />
                    <Route path="/:entidade/recuperar-senha" component={PaginaEntidadeRecuperarPassword} />
                    <Route path="/:entidade/recuperar-senha-escola" component={PaginaEntidadeRecuperarPassword2} />
                    <Route path="/:entidade/solicitar-conta" component={PaginaEntidadeSolicitarConta} />
                    <Route path="/:entidade/criar-conta" component={PaginaEntidadeCriarConta} />
                    <Route path="/:entidade/registar-conta" component={PaginaEntidadeRegistarConta} />
                    <Route path="/:entidade/utilizador-registar-passo-2" component={PaginaRegistoPasso2} />
                    <Route path="/:entidade/utilizador-registo-conclusao" component={PaginaRegistoConclusao} />
                    <Route path="/:entidade/suporte-contacto" component={PaginaEntidadeContacto} />
                    <Route path="/:entidade/informacoes" component={PaginaEntidadeInformacoes} />
                    <Route path="/:entidade/cancelar-subscricao" component={PaginaEntidadeUnsubscribe} />
                    <Route path="/:entidade/vi/:token" component={PaginaEntidadeVideoConfInscricao} />{/*/:entidade/videoconferencia-inscricao/:token*/}
                    <Route path="/:entidade/vsc/:token" component={PaginaEntidadeVideoConfCodigo} />{/*/:entidade/videoconferencia-codigo/:token*/}
                    <Route path="/:entidade/vcc/:token" component={PaginaEntidadeVideoConfComCodigo} />{/*/:entidade/videoconferencia-com-codigo/:token*/}
                    <Route path="/:entidade/videoconferencia-aguardar/:token" component={PaginaEntidadeVideoConfAguardar} />
                    <Route path="/:entidade/videoconferencia/:vconfid/player-videoconferencia" component={PaginaEntidadeJitsiMeetingGeral} />
                    <Route path="/:entidade/videoconferencia/:vconfid/player-video-meets" component={PaginaVideoSimples} />
                    <Route path="/:entidade/chat-confirmacao" component={PaginaChatConfirmacao} />
                    <Route path="/:entidade/chat-codigo" component={PaginaChatCodigo} />
                    <Route path="/:entidade/chat-inativo" component={PaginaChatInativo} />
                    <Route path="/:entidade/chat" component={PaginaChat} />
                    <Route path="/:entidade/:nome/:pagina" component={PaginaPaginas} />
                    <Route path="/:entidade" component={PaginaEntidade} />
                </Switch>
                <Footer redessociais={redessociais} opcoes={this.props.match.url.path !== "/:entidade/curso/:curso/:cursoid" && this.props.match.url.split("/").pop() !== "curso-visao-geral" && this.props.match.url.split("/").pop() !== "curso-modulos" ? opcoes : null} fundoBranco={fundoBranco} semFiltro />
            </>
        );
    }
}

export default Layout;
