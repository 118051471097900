import React, { Component } from "react";
import { Icon } from "antd";
import moment from "moment";
import noimage from "../../images/noimage.png";
import { GlobalContext } from "../../GlobalState";

class MensagemInicial extends Component {
    static contextType = GlobalContext;
    verificarData = data => {
        var diff = moment().diff(data, 'days')
        return diff > 0;
    }

    destinatario = () => {
        switch (this.props.id_tipo_destinatario) {
            case 1:
                return "Stepforma";
                break;
            case 2:
                return this.context.nome_entidade;
                break;
            case 3:
                return "Todos os Alunos";
                break;
            case 4:
                return "Todos os Gestores";
                break;
            case 5:
                return "Todos os Professores";
                break;
            default:
                return "Todos os Respons�veis";
                break;
        }
    }

    render() {
        return (
            <div className="mensagem">
                <div className="info">
                    <div className="lado-esquerdo" style={{ display: "block" }}>
                        <div className="bloco-de">
                            <label>De</label>
                            {this.props.nome ?
                                <div className="bloco-info-user">
                                    <div className="container-img">
                                        <img src={noimage} />
                                    </div>
                                    <div className="dados">
                                        <div className="nome">
                                            {this.props.nome}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="bloco-info-user">
                                    <div className="container-img">
                                        <img src={this.props.mensagem.utilizador.foto ? this.props.mensagem.utilizador.foto : noimage} />
                                    </div>
                                    <div className="dados">
                                        <div className="nome">
                                            {this.props.mensagem.utilizador.nome_completo}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {this.props.id_tipo_destinatario &&
                            <div className="bloco-para">
                                <label>Para</label>
                                <div className="nome">
                                    {this.destinatario()}
                                </div>
                            </div>}
                    </div>
                    {this.verificarData(this.props.mensagem.dt_criado) ?
                        <div className="bloco-data-hora">
                            <div className="data">{moment(this.props.mensagem.dt_criado).format("DD/MM/YYYY")}</div>
                            <div className="hora">{moment(this.props.mensagem.dt_criado).format("HH:mm")}</div>
                        </div>
                        :
                        <div className="data">{moment.duration(-(moment.duration(moment(new Date()).diff(this.props.mensagem.dt_criado)).asMinutes() - moment.duration(moment(new Date()).diff(this.props.mensagem.dt_atual)).asMinutes()), "minutes").humanize(true)}</div>
                    }
                </div>
                <div
                    className="texto"
                    dangerouslySetInnerHTML={{ __html: this.props.mensagem.mensagem }}
                ></div>
                {this.props.mensagem.anexo ? (
                    <div className="anexos">
                        <a href={this.props.mensagem.anexo} download>
                            <div className="anexo">
                                <Icon type="download" />
                                <div className="desc">Anexo</div>
                            </div>
                        </a>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default MensagemInicial;
