import React, { Component } from "react";
import ConteudoResumo from "./ConteudoResumo";
import Controlos from "../_Aux/Controlos";
import { GlobalContext } from "../../GlobalState";
import LazyLoad from "react-lazy-load";

class Resumo extends Component {
    static contextType = GlobalContext;

    render() {
        return (
            <div className="questionario-resumo">
                <div className="player-wrapper">
                    <LazyLoad offsetVertical={200}>
                        <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                    </LazyLoad>
                    <ConteudoResumo />
                    <Controlos
                        backUrl={`/gerir-curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                        sameUrl={true}
                        temAnotacoes
                    />
                </div>
            </div>
        );
    }
}

export default Resumo;
