import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Badge, Collapse } from "antd";
import logo from "../images/logos/stepforma-01.png";
import noimage from "../images/noimage.png";
import axios from "axios";
import { GlobalContext } from "../GlobalState";

const { Panel } = Collapse;
class Navbar extends Component {
    static contextType = GlobalContext;

    state = {
        entidade_imagem_propria: false,
        entidade_logo: "",
        entidade_nome: "StepForma",
        //MOBILE
        mobile: false,
        //LOADING
        iconLoading: false
    };

    componentDidMount() {
        this.carregaDadosEntidade();
    }

    componentDidUpdate() {
        if (this.context.atualizarEntidade) {
            this.carregaDadosEntidade();
            this.context.atualizarState({ atualizarEntidade: false });
        }
    }

    carregaDadosEntidade = () => {
        axios({
            method: "get",
            url: "/api/consultar-resultados/dados-entidade",
            params: {
                entidadeId: localStorage.getItem("codigo_entidade")
            }
        })
            .then(response => {
                this.setState({
                    entidade_imagem_propria: response.data.imagem_propria,
                    entidade_logo: response.data.logo,
                    entidade_nome: response.data.nome,
                });

                if (response.data.imagem_propria)
                    this.context.atualizarState({
                        favicon_entidade: response.data.logo,
                        title_entidade: response.data.nome
                    });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            entidade_imagem_propria,
            entidade_logo,
            entidade_nome,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="navbar-consultar">
                <div className="container container-v2">
                    <Link to="/user" className="brand" title={entidade_nome}>
                        <img src={entidade_imagem_propria ? (entidade_logo ? entidade_logo : noimage) : logo} alt="Logo" className="logo" />
                    </Link>
                    <div className="links">
                        <ul className="nav-list nav-list-direita">
                            <li className="nav-item nav-item-icon">
                                <Badge
                                    count={this.context.totalConversasPorLer}
                                    showZero={false}
                                    style={{ backgroundColor: "#f79071" }}
                                >
                                    <Link to="#" style={{ fontSize: 30, color: "rgba(0, 0, 0, 0.6)" }}>
                                        <i className="fas fa-comment-dots"></i>
                                    </Link>
                                </Badge>
                            </li>
                            <li className="nav-item" style={{ visibility: "hidden" }}>
                                
                            </li>
                        </ul>
                    </div>
                    <div className="burger" onClick={() => this.setState({ mobile: !this.state.mobile })}>
                        <i className="fas fa-bars"></i>
                    </div>
                </div>
            </div>
        );
    }
}

export default Navbar;
