import React from "react";
import MsgSecundaria from "./_Aux/MsgSecundaria";
import LazyLoad from "react-lazy-load";

const Pagina404 = () => {
    localStorage.clear();

    return (
        <div className="pagina-erro">
            <div className="hero">
                <LazyLoad offsetVertical={200}>
                    <img src={require("../images/wave_hero_user.svg")} alt="" />
                </LazyLoad>
                <div className="container" style={{ marginTop: 80 }}>
                    <div className="pagina-erro-header">
                        <div className="container-img">
                            <img src={require("../images/notfound.svg")} alt=""/>
                        </div>
                    </div>
                    <div className="pagina-erro-content">
                        <MsgSecundaria texto="Ocorreu um erro!" style={{ fontSize: 38, color: "white" }} />
                        <MsgSecundaria texto="A página não foi encontrada ou não existe." />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pagina404;
