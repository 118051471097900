import React, { Component } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { Select } from "antd";
import { GlobalContext } from "../GlobalState";

const Option = Select.Option;

class TabelaMobile extends Component {
    static contextType = GlobalContext;

    state = {
        filteredInfo: null,
        sortedInfo: null
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter
        });
    };

    montarLink = (text, record) => {
        switch (this.context.role) {
            case "ADMIN":
                return (
                    <Link
                        to={
                            "/administracao-suporte-detalhe/" +
                            record.id +
                            "/" +
                            record.assunto.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()
                        }
                    >
                        {text}
                    </Link>
                );
            case "ENTIDADE":
                return (
                    <Link
                        to={
                            "/gestao-suporte-detalhe/" +
                            record.id +
                            "/" +
                            record.assunto.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()
                        }
                    >
                        {text}
                    </Link>
                );
            default:
                return (
                    <Link
                        to={
                            "/user-suporte-detalhe/" +
                            record.id +
                            "/" +
                            record.assunto.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()
                        }
                    >
                        {text}
                    </Link>
                );
        }
    };

    render() {
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: "Assunto",
                dataIndex: "codigo",
                key: "codigo",
                render: (text, record) => this.montarLink(text, record)
            },
            {
                title: "",
                dataIndex: "controlos",
                key: "controlos",
                render: (text, record) => this.montarLink(text, record)
            }
        ];

        return (
            <>
                <Table
                    columns={columns}
                    dataSource={this.props.tickets}
                    pagination={this.props.pagination}
                    loading={this.props.loading}
                    onChange={this.props.handleTableChange}
                    locale={{ emptyText: "Não existem registos!" }}
                    className="tabela-tickets"
                />
                {this.props.tickets.length > 0 ? (
                    <div className="num_elementos">
                        Mostrar{" "}
                        <Select defaultValue="10" onChange={this.props.handleChangeSelect}>
                            <Option value="10">10</Option>
                            <Option value="25">25</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                        </Select>
                    </div>
                ) : null}
            </>
        );
    }
}

export default TabelaMobile;
