import React, { Component } from "react";
import { Icon, notification } from "antd";

class ExcelTabelaNotasCreche extends Component {
    render() {
        const {
            periodos,
            avaliacoes
        } = this.props;

        const montarTitulosAvaliacoes = () => {
            return (<>
                {avaliacoes.map((avaliacao, index) => (
                    <td key={index} style={{ borderTop: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000', minWidth: 20 }} align="center" valign="middle"><b><font color="#000000">{avaliacao}</font></b></td>
                ))}
            </>);
        }

        const montarNotas = (nota) => {
            return (<>
                {avaliacoes.map((avaliacao, index) => (
                    <td key={index} className="celula-tabela-notas-pre" style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="left" valign="bottom"><div>{nota === avaliacao ? <Icon type="check-square" /> : <Icon type="border" />}</div></td>
                ))}
            </>);
        }

        return (
            <div>
                <table className="tabela-notas-pre" cellSpacing={0} border={0} style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={2} height={40} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CÓDIGO</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CAMPOS DE EXPERIÊNCIAS</font></b></td>
                            {periodos.map(periodo => (
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '2px double #000000' }} colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                            ))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><b><font color="#000000">CORPO, GESTOS E MOVIMENTOS</font></b></td>
                            {periodos.map(() => (montarTitulosAvaliacoes()))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={44} align="center" valign="middle"><b><font color="#000000">(EI02CG01)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Apropriar-se de gestos e movimentos de sua cultura no cuidado de si e nos jogos e brincadeiras.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[0].avaliacoes[0].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">((EI02CG02))</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Deslocar seu corpo no espaço, orientando-se por noções como em frente, atrás, no alto, embaixo, dentro, fora etc., ao se envolver em brincadeiras e atividades de diferentes naturezas.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[0].avaliacoes[1].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02CG03)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Explorar formas de deslocamento no espaço (pular, saltar, dançar), combinando movimentos e seguindo orientações.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[0].avaliacoes[2].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02CG04)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Demonstrar progressiva independência no cuidado do seu corpo.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[0].avaliacoes[3].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02CG05)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Desenvolver progressivamente as habilidades manuais, adquirindo controle para desenhar, pintar, rasgar, folhear, entre outros.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[0].avaliacoes[4].nota))}
                        </tr>
                        <tr>
                            <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={2} height={40} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CÓDIGO</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CAMPOS DE EXPERIÊNCIAS</font></b></td>
                            {periodos.map(periodo => (
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '2px double #000000' }} colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                            ))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><b><font color="#000000">ESCUTA, FALA PENSAMENTO E IMAGINAÇÃO</font></b></td>
                            {periodos.map(() => (montarTitulosAvaliacoes()))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={44} align="center" valign="middle"><b><font color="#000000">(EI02EF01)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Dialogar com crianças e adultos, expressando seus desejos, necessidades, sentimentos e opiniões.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[0].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EF02)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Identificar e criar diferentes sons e reconhecer rimas e aliterações em cantigas de roda e textos poéticos.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[1].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EF03)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Demonstrar interesse e atenção ao ouvir a leitura de histórias e outros textos, diferenciando escrita de ilustrações, e acompanhando, com orientação do adulto-leitor, a direção da leitura (de cima para baixo, da esquerda para a direita).</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[2].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EF04)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Formular e responder perguntas sobre fatos da história narrada, identificando cenários, personagens e principais acontecimentos.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[3].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EF05)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Relatar experiências e fatos acontecidos, histórias ouvidas, filmes ou peças teatrais assistidos etc.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[4].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EF06)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Criar e contar histórias oralmente, com base em imagens ou temas sugeridos.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[5].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EF07)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Manusear diferentes portadores textuais, demonstrando reconhecer seus usos sociais.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[6].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={44} align="center" valign="middle"><b><font color="#000000">(EI02EF08)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Manipular textos e participar de situações de escuta para ampliar seu contato com diferentes gêneros textuais (parlendas, histórias de aventura, tirinhas, cartazes de sala, cardápios, notícias etc.).</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[7].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EF09)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Manusear diferentes instrumentos e suportes de escrita para desenhar, traçar letras e outros sinais gráficos.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[8].nota))}
                        </tr>
                        <tr>
                            <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={2} height={40} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CÓDIGO</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CAMPOS DE EXPERIÊNCIAS</font></b></td>
                            {periodos.map(periodo => (
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '2px double #000000' }} colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                            ))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><b><font color="#000000">TRAÇOS, SONS, CORES E FORMAS</font></b></td>
                            {periodos.map(() => (montarTitulosAvaliacoes()))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02TS01)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Criar sons com materiais, objetos e instrumentos musicais, para acompanhar diversos ritmos de música.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[2].avaliacoes[0].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02TS02)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Utilizar materiais variados com possibilidades de manipulação (argila, massa de modelar), explorando cores, texturas, superfícies, planos, formas e volumes ao criar objetos tridimensionais.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[2].avaliacoes[1].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02TS03)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Utilizar diferentes fontes sonoras disponíveis no ambiente em brincadeiras cantadas, canções, músicas e melodias.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[2].avaliacoes[2].nota))}
                        </tr>
                        <tr>
                            <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={2} height={40} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CÓDIGO</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CAMPOS DE EXPERIÊNCIAS</font></b></td>
                            {periodos.map(periodo => (
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '2px double #000000' }} colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                            ))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><b><font color="#000000">O EU, O OUTRO E O NÓS</font></b></td>
                            {periodos.map(() => (montarTitulosAvaliacoes()))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EO01)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Demonstrar atitudes de cuidado e solidariedade na interação com crianças e adultos.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[0].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EO02)</font></b></td>
                            <td align="left" valign="middle"><p color="#000000">Demonstrar imagem positiva de si e confiança em sua capacidade para enfrentar dificuldades e desafios.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[1].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EO03)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Compartilhar os objetos e os espaços com crianças da mesma faixa etária e adultos.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[2].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={27} align="center" valign="middle"><b><font color="#000000">(EI02EO04)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Comunicar-se com os colegas e os adultos, buscando compreendê-los e fazendo-se compreender.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[3].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EO05)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Perceber que as pessoas têm características físicas diferentes, respeitando essas diferenças.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[4].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={27} align="center" valign="middle"><b><font color="#000000">(EI02EO06)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Respeitar regras básicas de convívio social nas interações e brincadeiras.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[5].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02EO07)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Resolver conflitos nas interações e brincadeiras, com a orientação de um adulto.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[6].nota))}
                        </tr>
                        <tr>
                            <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={2} height={40} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CÓDIGO</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CAMPOS DE EXPERIÊNCIAS</font></b></td>
                            {periodos.map(periodo => (
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '2px double #000000' }} colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                            ))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><b><font color="#000000">ESPAÇOS,TEMPOS, QUANTIDADES, RELAÇÕES E TRANSFORMAÇÕES</font></b></td>
                            {periodos.map(() => (montarTitulosAvaliacoes()))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02ET01)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Explorar e descrever semelhanças e diferenças entre as características e propriedades dos objetos (textura, massa, tamanho).</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[0].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02ET02)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Observar, relatar e descrever incidentes do cotidiano e fenômenos naturais (luz solar, vento, chuva etc.).</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[1].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02ET03)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Compartilhar, com outras crianças, situações de cuidado de plantas e animais nos espaços da instituição e fora dela.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[2].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02ET04)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Identificar relações espaciais (dentro e fora, em cima, embaixo, acima, abaixo, entre e do lado) e temporais (antes, durante e depois).</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[3].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={27} align="center" valign="middle"><b><font color="#000000">(EI02ET05)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Classificar objetos, considerando determinado atributo (tamanho, peso, cor, forma etc.).</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[4].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02ET06)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Utilizar conceitos básicos de tempo (agora, antes, durante, depois, ontem, hoje, amanhã, lento, rápido, depressa, devagar).</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[5].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI02ET07)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Contar oralmente objetos, pessoas, livros etc., em contextos diversos.</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[6].nota))}
                        </tr>
                        <tr>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={27} align="center" valign="middle"><b><font color="#000000">(EI02ET08)</font></b></td>
                            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Registrar com números a quantidade de crianças (meninas e meninos, presentes e ausentes) e a quantidade de objetos da mesma natureza (bonecas, bolas, livros etc.).</p></td>
                            {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[7].nota))}
                        </tr>
                        <tr>
                            <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        </tr>
                        <tr>
                            <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        </tr>
                        <tr>
                            <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ExcelTabelaNotasCreche;