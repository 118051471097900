import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Dropdown, Switch, Menu, Modal, Tooltip, Select, Collapse } from "antd";
import Header from "../../User/Header";
import noimage from "../../images/noimage.png";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";
import { GlobalContext } from "../../GlobalState";
import DrawerDiario from "./_Aux/DrawerDiario";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";

const { Panel } = Collapse;
const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Diario extends Component {
    static contextType = GlobalContext;
    state = {
        escolaridades: [],
        //FILTROS
        pesquisa: "",
        ativo: true,
        //DRAWER
        escolaridadeId: 0,
        visible: false,
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //PAGINATION
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        }
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.total();
    }

    componentDidUpdate() {
        if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
            this.setState({
                redirect: true,
                redirectLink: "/user"
            });
    }

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.total();
            }
        );
    };

    handleChangeTable = (pagination, filters, sorter) => {
        var _pagination = this.state.pagination;
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                this.listar();
            }
        );
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                    this.total();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                this.total();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA EDIÇÃO NA TABELA
    montarMenu = (escolaridade) => {
        return (
            <Menu>
                {/*{escolaridade.bncc ?*/}
                {/*    <Menu.Item>*/}
                {/*        <Link to="#" onClick={() => this.carregarDetalheBncc(escolaridade)}>*/}
                {/*            Editar*/}
                {/*        </Link>*/}
                {/*    </Menu.Item>*/}
                {/*    :*/}
                {/*    <Menu.Item>*/}
                {/*        <Link to="#" onClick={() => this.carregarDetalhe(escolaridade)}>*/}
                {/*            Editar*/}
                {/*        </Link>*/}
                {/*    </Menu.Item>*/}
                {/*}*/}
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ escolaridadeId: escolaridade.id, visible: true })}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.ativar(escolaridade.id, escolaridade.ativo)}>
                        {escolaridade.ativo ? "Inativar" : "Ativar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //CARREGA O TOTAL DE FASES ESCOLARES
    total = () => {
        axios({
            method: "get",
            url: "/api/gestao-diario/total",
            params: {
                pesquisa: this.state.pesquisa,
                ativo: this.state.ativo
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                },() => this.listar());
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //CARREGA TODAS AS FASES ESCOLARES DA ENTIDADE
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-diario/listar",
                    params: {
                        pesquisa: this.state.pesquisa,
                        ativo: this.state.ativo,
                        pageSize: this.state.pagination.pageSize,
                        page: this.state.pagination.current,
                    }
                })
                    .then(response => {
                        let escolaridades = [];

                        response.data.map((escolaridade, index) => {
                            let avaliacoes = [];
                            let avaliacao = "";

                            if (escolaridade.formula === 0)
                                avaliacoes = escolaridade.avaliacoes.map(x => {
                                    return x.nome;
                                });
                            else
                                avaliacao = escolaridade.formula === 1 ?
                                    <div>
                                        <span style={{ marginRight: 5 }}>Fórmula Média Simples</span>
                                        <Tooltip className="info-icon info-icon2" title="Avaliação sem peso no cálculo da Média Final">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </div>
                                    :
                                    <div>
                                        <span style={{ marginRight: 5 }}>Fórmula Média Peso</span>
                                        <Tooltip className="info-icon info-icon2" title="Avaliação com peso no cálculo da Média Final">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </div>

                            escolaridades.push({
                                key: index,
                                id: escolaridade.id,
                                escolaridade: `${escolaridade.nome}${escolaridade.bncc ? " (BNCC)" : ""}`,
                                avaliacoes: escolaridade.formula === 0 ? avaliacoes.join(", ") : avaliacao,
                                listaAvaliacoes: escolaridade.avaliacoes,
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(escolaridade)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });
                        });

                        this.setState({
                            escolaridades,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //ABRE O DRAWER COM OS DADOS DA EDIÇÃO
    carregarDetalhe = escolaridade => {
        axios({
            method: "get",
            url: "/api/gestao-diario/detalhe",
            params: {
                id: escolaridade.id,
            }
        })
            .then(response => {
                this.setState({
                    estado: "EDITAR",
                    escolaridade: response.data,
                    visible: true
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //ABRE O DRAWER COM OS DADOS DA EDIÇÃO
    carregarDetalheBncc = escolaridade => {
        axios({
            method: "get",
            url: "/api/gestao-diario/detalhe-bncc",
            params: {
                id: escolaridade.id,
            }
        })
            .then(response => {
                this.setState({
                    estado: "EDITAR",
                    escolaridade: response.data.fase,
                    escolaridadeBncc: response.data.bncc,
                    visible: true
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //ATIVA E INATIVA A EDIÇÃO
    ativar = (id, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar este nivel?" : "Pretende ativar esta edição?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-diario/ativar",
                    params: {
                        id
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Edição inativada!" : "Edição ativada!");
                        this.total();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a edição!" : "Não foi possível ativar a edição!"
                        );
                    });
            }
        });
    };

    atualizarRegistos = () => {
        this.total();
        this.setState({
            estado: "",
            visible: false,
        });
    }

    //ABRE O DRAWER VAZIO PARA CRIAR NOVO
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            escolaridadeId: 0,
            visible: true,
        });
    };

    //FECHA O DRAWER SEM GUARDAR A EDIÇÃO
    onClose = () => {
        this.setState({
            estado: "",
            visible: false
        });
    };

    render() {
        const {
            escolaridades,
            //DRAWER
            escolaridadeId,
            visible,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //PAGINATION
            pagination
        } = this.state;

        const columns = [
            {
                title: "Fase",
                dataIndex: "escolaridade"
            },
            {
                title: "Avaliações",
                dataIndex: "avaliacoes"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Fase Escolar" />
                <div className="container container-body">
                    <div className="bloco-categorias">
                        <div className="bloco-tabela">
                            <div className="filtros" style={{justifyContent: "space-between"}}>
                                <BotaoProcurar
                                    pesquisa={this.state.pesquisa}
                                    tem_placeholder
                                    placeholder="Procurar por Curso"
                                    HandleKeyPress={this.HandleKeyPress}
                                    resetCaixaProcura={this.resetCaixaProcura}
                                />
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <Table
                                id="tabela_categorias"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={escolaridades}
                                pagination={pagination}
                                onChange={this.handleChangeTable}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="opcoes-meus-cursos">
                    <Link to="#" className="btn-adicionar" title="Adicionar" onClick={this.showDrawer}>
                        +
                    </Link>
                </div>
                <DrawerDiario
                    atualizarRegistos={this.atualizarRegistos}
                    onClose={this.onClose}
                    visible={visible}
                    escolaridadeId={escolaridadeId}
                />
            </div>
        );
    }
}
const FormDiario = Form.create({ name: "form-diario" })(Diario);

export default FormDiario;
