import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../GlobalState";
import Header from "./Header";
import Curso from "./Curso";
import { Form, Select, Badge, Icon } from "antd";
import Oops from "../Geral/Oops.jsx";
import axios from "axios";
import Video from "./Video";
import BlocoNoticias from "./BlocoNoticias";
import WhatsAppButton from "../Geral/WhatsAppButton";
import RedesSociais from "../Geral/_Aux/RedesSociais";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        //FILTROS
        categoriaId: 0,
        // tipoId: 0,
        idiomaId: 0,
        tipos: [],
        //idiomas: [],
        categorias: [],
        temCursos: false,
        cursos: [],
        //REDIRECT
        redirect: false,
        //LOADING
        loading: false,
        //VIDEO
        autoPlay: true,
        muted: true,
        loop: true,
        //NOTICIAS
        noticias: [],
        temNoticias: true
    };

    componentDidMount() {
        if (localStorage.getItem("entidadeId")) {
            if (!localStorage.getItem("entidade_url")) {
                this.listarCategorias(localStorage.getItem("entidadeId"));
                this.listarCursos(localStorage.getItem("entidadeId"));
                this.listarNoticias(localStorage.getItem("entidadeId"));
            }
            else if (localStorage.getItem("entidade_url") !== this.props.match.params.entidade) {
                this.carregarEntidadeID(this.props.match.params.entidade);
            }
            else {
                this.listarCategorias(localStorage.getItem("entidadeId"));
                this.listarCursos(localStorage.getItem("entidadeId"));
                this.listarNoticias(localStorage.getItem("entidadeId"));
            }
        } else {
            this.carregarEntidadeID(this.props.match.params.entidade);
        }
    }

    carregarEntidadeID = nome_abrev => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/carregar-entidadeid",
            params: {
                nome_abrev
            }
        })
            .then(response => {
                if (response.data > 0) {
                    this.listarCategorias(response.data);
                    this.listarCursos(response.data);
                    this.listarNoticias(response.data);
                }
                else {
                    this.setState({
                        redirect: true
                    })
                }
            })
            .catch(error => {});
    };

    onChandeSelectIdiomas = idiomaId => {
        this.setState(
            {
                idiomaId
            },
            () => this.listarCursos(localStorage.getItem("entidadeId"))
        );
    };

    selecionarCategoria = categoriaId => {
        this.setState(
            {
                categoriaId
            },
            () => this.listarCursos(localStorage.getItem("entidadeId"))
        );
    };

    limparFiltro = () => {
        this.setState(
            {
                categoriaId: 0,
                titulo: "Novidades",
            },
            () => this.listarCursos()
        );
    };

    //LISTA DAS CATEGORIAS
    listarCategorias = entidadeId => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/lista-categorias",
            params: {
                entidadeId,
                entidadeUrl: this.props.match.params.entidade
            },
            //headers: {
            //    apiKey: "teste"
            //}
        })
            .then(response => {
                var temCursos = false;

                response.data.forEach(categoria => {
                    if (categoria.cursos > 0)
                        temCursos = true;
                });

                this.setState({
                    categorias: response.data,
                    temCursos
                });
            })
            .catch(error => { });
    };

    //CARREGA AS NOTICIAS
    listarNoticias = entidadeId => {
        this.setState({
            loading: true
        });
        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-informacoes",
            params: {
                entidadeId,
                entidadeUrl: this.props.match.params.entidade
            }
        })
            .then(response => {
                this.setState({
                    noticias: response.data,
                    temNoticias: response.data.length > 0,
                    loading: false
                });
            })
            .catch(error => {});
    };

    //CARREGA OS CURSOS
    listarCursos = entidadeId => {
        this.setState({
            loading: true
        });
        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-cursos",
            params: {
                entidadeId,
                entidadeUrl: this.props.match.params.entidade,
                categoriaId: this.state.categoriaId
            }
        })
            .then(response => {
                this.setState({
                    cursos: response.data,
                    loading: false
                });
            })
            .catch(error => {});
    };

    render() {
        var categorias = this.state.categorias.map(
            (categoria, index) =>
                categoria.cursos > 0 && (
                    <li key={categoria.id} className={categoria.id === this.state.categoriaId ? "ativa" : ""}>
                        <Link to="#" onClick={() => this.selecionarCategoria(categoria.id)}>
                            {categoria.nome}
                        </Link>
                        <Badge count={categoria.cursos} />
                    </li>
                )
        );

        var cursos = this.state.cursos.map((curso, index) => (
            <Curso
                key={index}
                curso={curso}
                link={`/${this.props.match.params.entidade}/curso/${curso.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${curso.idEnc}/curso-visao-geral`}
            />
        ));

        if (this.state.redirect) return <Redirect to="/pagina-nao-encontrada" />;

        return (
            <div className="pagina-entidade">
                {localStorage.getItem("whatsapp") ?
                    <RedesSociais
                        whatsapp
                        numero={localStorage.getItem("whatsapp")}
                        mensagem="Eu tenho interesse em aderir &agrave; Stepforma"
                        title="Canal Whatsapp"
                    />
                    : null}
                <div className="container pagina-entidade-home">
                    {this.context.capa &&
                        <Header imagem={this.context.capa} />}
                    <div className="video-e-texto">
                        {this.context.capavideo && this.state.temNoticias ? (
                            <div className="video-margin">
                                <Video capavideo={this.context.capavideo} />
                            </div>
                        ) : this.context.capavideo && !this.state.temNoticias ? (
                            <Video capavideo={this.context.capavideo} />
                        ) : null}
                        {this.state.temNoticias && <BlocoNoticias noticias={this.state.noticias} entidade={this.props.match.params.entidade} />}
                    </div>
                    <div className="content-cursos" style={cursos.length <= 0 ? { gridTemplateColumns: "1fr" } : {}}>
                        {this.state.temCursos && (
                            <div className="filtros-cursos" style={cursos.length <= 0 ? { display: "none" } : {}}>
                                <div className="listagem-categorias">
                                    {this.state.categorias.length > 0 && !categorias.every(v => v === false) && (
                                        <>
                                            <h3>
                                                Categorias
                                                {this.state.categoriaId > 0 &&
                                                    <Link to="#" className="limpar-filtro-categorias" onClick={this.limparFiltro}><Icon type="close-circle" /></Link>}
                                            </h3>
                                            <ul>{categorias}</ul>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        {this.state.loading ?
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                            :
                            cursos.length > 0 &&
                                <div className="cursos listagem-cursos">{cursos}</div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default Main;
