import React from "react";
import BotaoDefinicoes from "../../Geral/_Aux/BotaoDefinicoes";
import noimage from "../../images/noimage.png";
import moment from "moment";

function montarRole(role, filtro) {
    let tag = "";

    switch (filtro) {
        case "1":
            tag = <span className="tag">Gestor</span>
            break;
        case "2":
            tag = <span className="tag">Professor</span>;
            break;
        case "3":
            tag = null;
            break;
        case "4":
            tag = <span className="tag">Responsável</span>
            break;
        default:
            tag = role !== "UTILIZADOR" ? <span className="tag">{role === "ENTIDADE" ? "Gestor" : "Professor"}</span> : null
            break;
    }

    return tag;
}

const Colaborador = ({ primeiro, colaborador, tem_definicoes, menu, menuMobile, filtro, paddingLeft, tag, organismos }) => {
    const montarDiasAusente = (data) => {
        const totalDias = moment().diff(moment(data, "DD/MM/YYYY"), 'days');
        if (totalDias > 0)
            return <div className="bloco-total-dias-ausente"><span className={`total-dias-ausente ${totalDias > 7 ? "ausente-acima" : "ausente-normal"}`} title="Número de dias ausente">{totalDias} {totalDias > 1 ? "dias" : "dia"}</span></div>
    }

    return (
        <>
            <div className="colaborador colaborador-desktop" style={paddingLeft}>
                <div className="info-participante">
                    <div className="container-img">
                        <img src={colaborador.foto ? colaborador.foto : noimage} />
                    </div>
                    <div className="info">
                        <span className="nome">
                            {colaborador.nome_completo}
                        </span>
                        {(colaborador.organismo && organismos) && <span className="tag-organismo" title="Organismo">{colaborador.organismo}</span>}
                        <div className="bottom">
                            <span className="email">{colaborador.email} ({colaborador.nr_aluno})
                                {!filtro ? (colaborador.role !== "UTILIZADOR" ? <span className="tag">{colaborador.role === "ENTIDADE" ? "Gestor" : "Professor"}</span> : null) : (tag ? <span className="tag">{tag}</span> : null)} </span>
                        </div>
                    </div>
                </div>
                <div className="entidadeUser">
                    {colaborador.emp_nome &&
                        <>
                            <div className="container-img">
                                <img src={colaborador.emp_logo ? colaborador.emp_logo : noimage} alt="Foto" />
                            </div>
                            <div className="head">
                                <span className="nome">
                                    {colaborador.emp_nome}
                                </span>
                            </div>
                        </>}
                </div>
                <div className="ultimoLoginUser">
                    {colaborador.ultimoLogin &&
                        <>
                        {primeiro && <label>Último login</label>}
                        <div>
                            <span className="ultimo-acesso">{colaborador.ultimoLogin}</span>
                            {montarDiasAusente(colaborador.ultimoLogin)}
                        </div>
                        </>}
                </div>
                {tem_definicoes ? <BotaoDefinicoes menu={menu} /> : null}
            </div>
            <div className="colaborador colaborador-mobile" style={paddingLeft}>
                <div className="info-participante">
                    <div className="container-img">
                        <img src={colaborador.foto ? colaborador.foto : noimage} />
                    </div>
                    <div className="info">
                        <span className="nome">
                            {colaborador.nome_completo}
                        </span>
                        {(colaborador.organismo && organismos) && <span className="tag-organismo" title="Organismo">{colaborador.organismo}</span>}
                        <div className="bottom">
                            <span className="email">{colaborador.email} ({colaborador.nr_aluno})
                                {!filtro ? (colaborador.role !== "UTILIZADOR" ? <span className="tag">{colaborador.role === "ENTIDADE" ? "Gestor" : "Professor"}</span> : null) : (tag ? <span className="tag">{tag}</span> : null)} </span>
                        </div>
                    </div>
                </div>
                {tem_definicoes ? <BotaoDefinicoes menu={menuMobile} /> : null}
            </div>
        </>
    );
};

export default Colaborador;
