import React, {useState, useEffect} from "react";
import { Table } from 'antd';
import ReactDragListView from "react-drag-listview";
import moment from 'moment';
import axios from "axios";
import Controlos from "./Controlos/Controlos";

const Relatorio = ({ menuOptionSelected }) => {
    const [search, setSearch] = useState("");
    const [filterSchool, setFilterSchool] = useState(undefined);
    const [filterEdition, setFilterEdition] = useState(undefined);
    const [filterPhase, setFilterPhase] = useState(undefined);
    const [filterState, setFilterState] = useState(undefined);
    const [activeFilters, setActiveFilters] = useState([]);
    const [cols, setCols] = useState([]);
    const [data, setData] = useState([]);
    const [dataBackUp, setDataBackUp] = useState([]);
    const [sorter, setSorter] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const columns = [...cols];
            const item = columns.splice(fromIndex, 1)[0];
            columns.splice(toIndex, 0, item);
            setCols(columns)
        },
        nodeSelector: "th"
    };

    const loadList = () => {
        if (search || filterSchool || filterEdition || filterPhase || filterState) {
            setLoading(true);
            axios({
                method: "get",
                url: "/api/relatorios/lista-cursos-escolas",
                params: {
                    search,
                    columns: JSON.stringify(cols.map(col => col.dataIndex)),
                    filterSchool,
                    filterEdition,
                    filterPhase,
                    filterState
                }
            })
                .then(response => {
                    setData(response.data);
                    setDataBackUp(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });

                    setLoading(false);
                });
        }
        else {
            setData([]);
        }
    }

    useEffect(() => {
        setFilterSchool(undefined);
        setFilterEdition(undefined);
        setFilterPhase(undefined);
        setCols([
            {
                title: "Edição",
                dataIndex: "edition",
                sorter: (a, b) => a.edition.localeCompare(b.edition),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Ano",
                dataIndex: "year",
                sorter: (a, b) => a.year.localeCompare(b.year),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Turma",
                dataIndex: "classLetter",
                sorter: (a, b) => a.classLetter.localeCompare(b.classLetter),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Código",
                dataIndex: "code",
                sorter: (a, b) => a.code.localeCompare(b.code),
                sortDirections: ['descend', 'ascend'],
            }
            ,
            {
                title: "Nome",
                dataIndex: "name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortDirections: ['descend', 'ascend'],
            }
        ])

        setData([]);

    }, [menuOptionSelected]);

    useEffect(() => {
        loadList();

    }, [activeFilters]);

    //const HandleKeyPress = (event) => {
    //    var pesquisa = event.target.value;
    //    if (event.key === "Enter") {
    //        var data = dataBackUp.filter(x =>
    //            cols.find(x => x.dataIndex === "edition") && x.edition.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "year") && x.year.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "classLetter") && x.classLetter.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "code") && x.code.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "name") && x.name.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "category") && x.category.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "start") && x.start.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "end") && x.end.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "registers") && x.registers.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "activities") && x.activities.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "tasks") && x.tasks.toLowerCase().includes(pesquisa.toLowerCase())
    //        )
    //        setData(data)
    //    }
    //}

    const HandleKeyPress = (event) => {
        if (event.key === "Enter") {
            setActiveFilters([...activeFilters, "SEARCH"])
            setSearch(event.target.value);
        }
    }

    const resetSearch = () => {
        setActiveFilters(activeFilters.filter(x => x !== "SEARCH"))
        setSearch("");
    }

    const handleChangeSchool = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "SCHOOL"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "SCHOOL"))
        }
        setFilterSchool(filter);
    }

    const handleChangeEdition = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "EDITION"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "EDITION"))
        }
        setFilterEdition(filter);
    }

    const handleChangePhase = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "PHASE"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "PHASE"))
        }
        setFilterPhase(filter);
    }

    const handleChangeState = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "STATE"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "STATE"))
        }
        setFilterState(filter);
    }

    return (
        <>
            <div className="report-info">
                <Controlos
                    HandleKeyPress={(event) => HandleKeyPress(event)}
                    resetSearch={resetSearch}
                    filterSchool={filterSchool}
                    setFilterSchool={(filter) => handleChangeSchool(filter)}
                    filterEdition={filterEdition}
                    setFilterEdition={(filter) => handleChangeEdition(filter)}
                    filterPhase={filterPhase}
                    setFilterPhase={(filter) => handleChangePhase(filter)}
                    filterState={filterState}
                    setFilterState={(filter) => handleChangeState(filter)}
                    setCols={(columns) => setCols(columns)}
                    sorter={sorter}
                    menuOptionSelected={menuOptionSelected}
                    disabledButtons={!search && !filterSchool && !filterEdition && !filterPhase && !filterState}
                    columnsPositions={cols.map(col => col.dataIndex)}
                    cols={cols}
                    data={data}
                    pageTitle={menuOptionSelected?.menuItemName}
                />
                <div className="page-container">
                    <div className="page-header">
                        <h1 className="page-title">Relatório - {menuOptionSelected?.menuItemName}</h1>
                        <h3 className="page-counters">
                            <strong>Contar:</strong>
                            <span>{data.length}</span>
                        </h3>
                    </div>
                    <div className="page-body">
                        <ReactDragListView.DragColumn {...dragProps}>
                            <Table
                                columns={cols}
                                pagination={false}
                                dataSource={data}
                                onChange={(pagination, filters, sorter) => setSorter(sorter.order ? { order: sorter.order, key: sorter.columnKey } : undefined) }
                            />
                        </ReactDragListView.DragColumn>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Relatorio;