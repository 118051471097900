import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Icon } from "antd";
import Header from "../User/Header";
import noimage from "../images/noimage.png";
//import DeclaracaoBeloMonte from "./Declaracoes/BeloMonte/Declaracoes";
//import DeclaracaoMaravilha from "./Declaracoes/Maravilha/Declaracoes";
import Declaracoes from "./Declaracoes/Declaracoes";

const Declaracao = (props) => {
    const [user, setUser] = useState({});

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("sis_utilizador")))
    }, []);

    return (
        <div className="bloco-emitir-declaracao">
            <Header titulo="Declaração de conclusão" />
            <div className="container container-body">
                <div className="bloco-controlos-declaracao">
                    <div className="info-participante">
                        <div className="container-img">
                            <img src={user?.foto ? user?.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">{user?.nome}</span>
                            <p className="numero">{user?.numero}</p>
                        </div>
                    </div>
                    <div className="notas-pre-controlos">
                        <Link to="/gestao-utilizadores-todos" className="botao-icon-voltar botao-maior" title="Voltar">
                            <Icon type="arrow-left" />
                        </Link>
                        <Link to="#" className="botao-icon-print botao-maior" title="Imprimir">
                            <Icon type="printer" />
                        </Link>
                        <Link to="#" className="botao-icon-pdf botao-maior" title="Descarregar">
                            <Icon type="file-pdf" />
                        </Link>
                    </div>
                </div>
                <div className="bloco-declaracao">
                    <Declaracoes declaracao={props.match.params.id}/>
                </div>
            </div>
        </div>
    );
};

export default Declaracao;
