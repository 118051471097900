import React, { Component } from "react";
import {
    Drawer,
    Form
} from "antd";
import noimage from "../../../images/noimage.png";
import GridAnexos from "../../_Aux/GridAnexos";

class DrawerTrabalho extends Component {
    render() {
        return (
            <Drawer
                className="drawer-from-trabalho"
                title={`Trabalho ${this.props.nome}`}
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
            >
                <div className="bloco-info">
                    <Form className="form-aula" layout="horizontal">
                        <Form.Item label="Participantes">
                            <div className="bloco-participantes">
                                {this.props.participantes.map(participante => (
                                    <div className="content" key={participante.id}>
                                        <div className="container-img">
                                            <img className="" src={participante.foto ? participante.foto : noimage} />
                                        </div>
                                        <div className="container-text">
                                            <span className="nome">{participante.nome_completo}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Form.Item>
                        <Form.Item label="Observações">
                            <p>{this.props.observacoes}</p>
                        </Form.Item>
                        <Form.Item label="Anexos">
                            <GridAnexos dataSource={this.props.ficheiros} />
                        </Form.Item>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                </div>
            </Drawer>
        );
    }
}

export default DrawerTrabalho;
