import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { notification } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import axios from "axios";
import moment from "moment";
import Vimeo from "@vimeo/player";
import { GlobalContext } from "../GlobalState";
import Oops from "../Geral/Oops.jsx";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Video extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        modulo: "",
        moduloIndex: 0,
        aulaid: 0,
        aula: "",
        nome: "",
        objetivos: "",
        aulaIndex: 0,
        fullscreen: false,
        vimeo: "",
        current: "",
        updateModulos: false,
        dataHora: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        sameUrl: true,
        player: "",
        //NAO DISPONIVEL
        nao_disponivel: false,
        //LOADING
        loading: true,
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: localStorage.getItem("codigo_aula")
        }, () => this.CarregarConteudo());
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState(
                {
                    sameUrl: false,
                    aulaid: localStorage.getItem("codigo_aula")
                },
                () => {
                    this.CarregarConteudo();
                    this.setState({
                        sameUrl: true
                    })
                }
            );
        }
    }

    //CARREGA A AULA
    CarregarConteudo = () => {
        axios({
            method: "get",
            url: "/api/gestao-player/conteudo1",
            params: {
                aulaid: this.state.aulaid
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    vimeo: "https://player.vimeo.com/video/" + response.data.recurso,
                    dataHora: moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"),
                    loading: false
                }, () => {
                        var iframe = document.querySelector("iframe");
                        var player = new Vimeo(iframe);

                        this.setState({
                            player
                        })

                        player.on("seeked", data => {
                            this.setState({ current: data.seconds });
                        });
                });
            })
            .catch(error => {
                if (error.response.data === "SEM_PERMISSAO")
                    this.setState({
                        redirect: true,
                        redirectLink: "/user"
                    });
                else if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    nao_disponivel: true
                })
            });
    };

    render() {
        const {
            curso,
            nome,
            objetivos,
            vimeo,
            current,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //NAO DISPONIVEL
            nao_disponivel,
            //LOADING
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                {this.state.loading ?
                    <div className="loading-data">
                        <div className="loading" />
                    </div>
                    :
                    <div className="player">
                        <div className="player-wrapper">
                            {!nao_disponivel ?
                                <>
                                    {sameUrl &&
                                        <iframe src={vimeo} id="player"></iframe>}
                                    <Controlos
                                        backUrl={`/gerir-curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                        sameUrl={sameUrl}
                                        temAnotacoes
                                        nome={nome}
                                        objetivos={objetivos}
                                        icon={require("../images/icons/video.png")}
                                        dataHora={dataHora}
                                    />
                                </>
                                :
                                <>
                                    <div className="container">
                                        <div className="bloco-nao-disponivel">
                                            <div className="bloco-info nao-disponivel">
                                                <Oops tem_texto texto={"Conteúdo não disponível"} />
                                            </div>
                                        </div>
                                    </div>
                                    <ControlosSimples />
                                </>
                            }
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default Video;
