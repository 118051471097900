import React, { Component } from "react";
import Indice from "./_Aux/IndiceVersoes";
import Artigo from "./_Aux/Artigo";
import Header from "./Header";
import axios from "axios";
import { Form, Input, Checkbox } from "antd";

class Home extends Component {
    state = {
        verMais: false,
        visivelTeste: false
    };

    UNSAFE_componentWillMount() {

    }

    carregar = () => {
        this.setState({
            verMais: true
        }, () => {
                setTimeout(() => {
                    this.setState({
                        verMais: false,
                        visivelTeste: true
                    })
                }, 1000);
        });
        
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        return (
            <>
                <div className="informacoes">
                    <div className="container container-body">
                        <div className="bloco-informacoes">
                            <div className="informacao informacao-verde">
                                <div className="container-img">
                                    <img src={require("../images/vantagens/1.svg")} />
                                </div>
                                <div className="bloco-texto">
                                    <span className="titulo">Novidade XPTO 1</span>
                                    <span className="data">01 Julho 2021</span>
                                    <div className="texto">
                                        <p>O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o salto para a tipografia electrónica, mantendo-se essencialmente inalterada. Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset, que continham passagens com Lorem Ipsum, e mais recentemente com os programas de publicação como o Aldus PageMaker que incluem versões do Lorem Ipsum.</p>
                                    </div>
                                    <a href="#" className="link">Clique aqui</a>
                                </div>
                            </div>
                            <div class="separador-vb"></div>
                            <div className="informacao informacao-branco">
                                <div className="bloco-texto">
                                    <span className="titulo">Novidade XPTO 1</span>
                                    <span className="data">01 Julho 2021</span>
                                    <div className="texto">
                                        <p>O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o salto para a tipografia electrónica, mantendo-se essencialmente inalterada. Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset, que continham passagens com Lorem Ipsum, e mais recentemente com os programas de publicação como o Aldus PageMaker que incluem versões do Lorem Ipsum.</p>
                                    </div>
                                    <a href="#" className="link">Clique aqui</a>
                                </div>
                                <div className="container-img">
                                    <img src={require("../images/vantagens/1.svg")} />
                                </div>
                            </div>
                            <div class="separador-bv"></div>
                            <div className="informacao informacao-verde">
                                <div className="bloco-texto">
                                    <span className="titulo">Novidade XPTO 1</span>
                                    <span className="data">01 Julho 2021</span>
                                    <div className="texto">
                                        <p>O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o salto para a tipografia electrónica, mantendo-se essencialmente inalterada. Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset, que continham passagens com Lorem Ipsum, e mais recentemente com os programas de publicação como o Aldus PageMaker que incluem versões do Lorem Ipsum.</p>
                                    </div>
                                    <a href="#" className="link">Clique aqui</a>
                                </div>
                                <div className="container-img">
                                    <iframe src="https://player.vimeo.com/video/513317186" id="player" data-ready="true"></iframe>
                                </div>
                            </div>
                            {this.state.visivelTeste ?
                                <>
                                    <div class="separador-vb"></div>
                                    <div className="informacao informacao-branco">
                                        <div className="container-img">
                                            <img src={require("../images/vantagens/1.svg")} />
                                        </div>
                                        <div className="bloco-texto">
                                            <span className="titulo">Novidade XPTO 1</span>
                                            <span className="data">01 Julho 2021</span>
                                            <div className="texto">
                                                <p>O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o salto para a tipografia electrónica, mantendo-se essencialmente inalterada. Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset, que continham passagens com Lorem Ipsum, e mais recentemente com os programas de publicação como o Aldus PageMaker que incluem versões do Lorem Ipsum.</p>
                                            </div>
                                            <a href="#" className="link">Clique aqui</a>
                                        </div>
                                    </div>
                                    <div class="separador-bv"></div>
                                    <div className="informacao informacao-verde">
                                        <div className="bloco-texto">
                                            <span className="titulo">Novidade XPTO 1</span>
                                            <span className="data">01 Julho 2021</span>
                                            <div className="texto">
                                                <p>O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o salto para a tipografia electrónica, mantendo-se essencialmente inalterada. Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset, que continham passagens com Lorem Ipsum, e mais recentemente com os programas de publicação como o Aldus PageMaker que incluem versões do Lorem Ipsum.</p>
                                            </div>
                                            <a href="#" className="link">Clique aqui</a>
                                        </div>
                                        <div className="container-img">
                                            <img src={require("../images/vantagens/1.svg")} />
                                        </div>
                                    </div>
                                    <div class="separador-vb"></div>
                                    <div className="informacao informacao-branco">
                                        <div className="bloco-texto">
                                            <span className="titulo">Novidade XPTO 1</span>
                                            <span className="data">01 Julho 2021</span>
                                            <div className="texto">
                                                <p>O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o salto para a tipografia electrónica, mantendo-se essencialmente inalterada. Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset, que continham passagens com Lorem Ipsum, e mais recentemente com os programas de publicação como o Aldus PageMaker que incluem versões do Lorem Ipsum.</p>
                                            </div>
                                            <a href="#" className="link">Clique aqui</a>
                                        </div>
                                        <div className="container-img">
                                            <iframe src="https://player.vimeo.com/video/513317186" id="player" data-ready="true"></iframe>
                                        </div>
                                    </div>
                                    <div class="separador-bv"></div>
                                    <div className="bloco-ver-mais bloco-ver-mais-verde">
                                        <button className="botao-principal" onClick={this.carregar}>{this.state.verMais ? "A carregar..." : "Ver mais"}</button>
                                    </div>
                                </>
                                : 
                                <>
                                    <div class="separador-vb"></div>
                                    <div className="bloco-ver-mais bloco-ver-mais-branco">
                                        <button className="botao-principal" onClick={this.carregar}>{this.state.verMais ? "A carregar..." : "Ver mais"}</button>
                                    </div>
                                </>
                            }
                            <div className="informacao-formulario">
                                <div className="bloco-formulario">
                                    <h2>Entre em contato connosco</h2>
                                    <h3>Coloque as suas questões ou comentários</h3>
                                    <Form className="formulario-contacto">
                                        <Form.Item>
                                            {getFieldDecorator("nome", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(<Input name="nome" placeholder="Nome*" />)}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator("email", {
                                                rules: [
                                                    {
                                                        type: "email",
                                                        message: "Introduza um endereço de e-mail válido"
                                                    },
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(<Input name="email" placeholder="Email*" />)}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator("assunto", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(<Input name="assunto" placeholder="Assunto*" />)}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator("mensagem", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ]
                                            })(<TextArea name="mensagem" placeholder="Mensagem*" style={{ height: "180px" }} resize />)}
                                        </Form.Item>
                                        <Form.Item>
                                            <Checkbox>Eu concordo em receber novidades da Stepforma.</Checkbox>
                                        </Form.Item>
                                        <Form.Item>
                                            <Checkbox>Eu concordo com a <span className="info-destaque">política de privacidade</span> da Stepforma.</Checkbox>
                                        </Form.Item>
                                        <div className="bloco-botao-enviar">
                                            <button className="botao-principal">
                                                Enviar
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const FormHome = Form.create({ name: "Home" })(Home);

export default FormHome;
