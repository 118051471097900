import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../User/Header";
import { Form, notification } from "antd";
import axios from "axios";
import DadosGerais from "./DadosGerais";
import Personalizacao from "./Personalizacao";
import Paginas from "./Paginas";
import Links from "./Links";
import GestorConta from "./GestorConta";
import Informacoes from "./Informacoes";
import Documentacao from "./Documentacao";
import Configuracoes from "./Configuracoes";
import Escolas from "./Escolas";
import { GlobalContext } from "../../GlobalState";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //GRID
        filtroAtivo: "Dados Gerais",
        escolasCarregadas: false
    };

    componentDidUpdate() {
        if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
            this.setState({
                redirect: true,
                redirectLink: "/user"
            });
    }

    ativarFiltro = (filtroAtivo) => {
        this.setState({ filtroAtivo });
    }

    render() {
        const {
            //REDIRECT
            redirect,
            redirectLink,
            filtroAtivo
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const opcoesMenu = () => {
            var opcoes = ["Dados Gerais", "Personalização", "Documentação", "Unidades Organizacionais", "Notícias", "Página Home", "Páginas", "Links", "Configurações"];

            if (!this.context.escolas_entidade)
                opcoes = opcoes.filter(x => x !== "Unidades Organizacionais")

            if (!this.context.visivel_entidade)
                opcoes = opcoes.filter(x => x !== "Página Pública" && x !== "Página Pública 2" && x !== "Página Pública 3")

            return opcoes;
        }

        return (
            <>
                <Header titulo="Entidade" />
                <div className="administracao-empresa">
                    <div className="container container-body">
                        <div className="bloco-grid">
                            <div className="filtros">
                                <ul>
                                    {opcoesMenu().map((item, index) => (
                                        <li
                                            key={index}
                                            className={filtroAtivo === item ? "ativo" : ""}
                                            onClick={() => this.ativarFiltro(item)}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="bloco-cursos-estatisticas">
                                {filtroAtivo === "Dados Gerais" && <DadosGerais />}
                                {filtroAtivo === "Personalização" && <Configuracoes />}
                                {filtroAtivo === "Documentação" && <Documentacao />}
                                {filtroAtivo === "Unidades Organizacionais" && <Escolas />}
                                {filtroAtivo === "Notícias" && <Informacoes />}
                                {filtroAtivo === "Página Home" && <Personalizacao />}
                                {filtroAtivo === "Páginas" && <Paginas />}
                                {filtroAtivo === "Links" && <Links />}
                                {filtroAtivo === "Configurações" && <GestorConta />}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const FormMain = Form.create({ name: "form-minha-conta" })(Main);

export default FormMain;
