import React, { useState, useEffect, useRef } from "react";
import { Icon } from "antd";

const TabelaDetalhe = ({ periods, avaluationTypes, courseDiary, pageBreak, header, footer, lastRecord }) => {

    const buildAvaluations = () => {
        return avaluationTypes.map((avaluation, index) => (
            <td align="center" valign="middle"><b><font color="#000000">{avaluation}</font></b></td>
        ))
    }

    const buildGrade = (grade) => {
        return (avaluationTypes.map((avaluation, index) => {
            return <td className={`celula-tabela-notas-pre ${(index === (avaluation.length - 1) ? "td-border-right" : "")}`} align="left" valign="bottom" ><div>{grade === avaluation ? <Icon type="check-square" /> : <Icon type="border" />}</div></td>
        }));
    }

    const buildTests = (group, groupIndex) => {
        const tests = group.tests.map((test, index) => {
            return <tr className="tabela-body">
                {courseDiary === "CRECHE" || courseDiary === "PRE" ?
                    <td className="td-border-right" align="center" valign="middle"><p color="#000000">{test.code}</p></td> : null}
                <td className="td-border-right" align="left" valign="middle"><p color="#000000">{test.title}</p></td>
                {periods.map((period, periodIndex) => {
                    const _group = period.groups[groupIndex];

                    let grade = "";
                    if (_group !== null) {
                        const _test = _group.tests[index];
                        if (_test !== null)
                            grade = _test.grade;
                    }

                    return buildGrade(grade);
                })}
            </tr>
        });

        return tests;
    }

    const buildGroups = (periods) => {
        if (periods.length) {
            var groups = periods[0].groups;

            return groups.map((group, index) => (
                <table className="tabela-notas-pre tabela-notas-regular" cellSpacing={0} border={0} style={{ width: "100%" }}>
                    <tbody>
                        <tr className="tabela-head">
                            {courseDiary === "CRECHE" || courseDiary === "PRE" ?
                                <td align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF"></font></b></td> : null}
                            <td align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">{group.title}</font></b></td>
                            {periods.map(period => (
                                <td colSpan={avaluationTypes.length} align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">{period.title}</font></b></td>
                            ))}
                        </tr>
                        <tr>
                            {courseDiary === "CRECHE" || courseDiary === "PRE" ?
                                <td align="center" valign="middle"><b><font color="#000000">Código</font></b></td> : null}
                            <td align="center" valign="middle"><b><font color="#000000">{courseDiary === "CRECHE" || courseDiary === "PRE" ? "Campos de experiências" : "Conceituais - Procedimentais e Atitudinais"}</font></b></td>
                            {periods.map(() => (buildAvaluations()))}
                        </tr>
                        {buildTests(group, index)}
                    </tbody>
                </table>
            ))
        }
        else
            return "Não existem períodos registados neste curso"
    }

    return (
        <>
            {header ?
                <table cellspacing="0" border="0" style={{ width: "100%" }} className="table-header">
                    <tr>
                        <td style={{ borderTop: "1px solid #000000", borderBottom: "1px solid #000000" }} height="20" align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">ESCOLA :</font></b></td>
                        <td style={{ borderTop: "1px solid #000000", borderBottom: "1px solid #000000" }} colspan="9" align="center" valign="middle"><b><font color="#000000">##ENTIDADE##</font></b></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                    </tr>
                    <tr>
                        <td style={{ borderBottom: "1px solid #000000" }} height="20" align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">ETAPA:</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} colspan="5" align="center" valign="middle"><b><font color="#000000">EDUCA&Ccedil;&Atilde;O INFANTIL  - ##TIPO##</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">TURMA:</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} align="center" valign="middle"><b><font color="#000000">##TURMA##</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">ANO:</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} align="center" valign="middle" sdval="2020" sdnum="1033;"><b><font color="#000000">##EDICAO##</font></b></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                    </tr>
                    <tr>
                        <td style={{ borderBottom: "1px solid #000000" }} height="20" align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">ESTUDANTE:</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} align="center" valign="middle"><b><font color="#000000">##ALUNO##</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">N&ordm;</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} align="center" valign="middle" bgcolor="#FFFFFF" sdval="2" sdnum="1033;"><b><font color="#000000">2</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">TURNO:</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} align="center" valign="middle" bgcolor="#FFFFFF"><b><font color="#000000"></font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">PROFESSOR:</font></b></td>
                        <td style={{ borderBottom: "1px solid #000000" }} colspan="3" align="center" valign="middle"><b><font color="#000000"><br /></font></b></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                    </tr>
                    <tr>
                        <td height="20" align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                        <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                    </tr>
                </table> : null}
            <table cellspacing="0" border="0" style={{ width: "100%" }} className={pageBreak ? "break-after-page" : ""}>
                {buildGroups(periods)}
            </table>
            {footer && lastRecord ?
                <div style={{ marginTop: 40 }}>
                    <p><b>Data:</b> ___/___/______</p>
                    <p><b>Professor/a:</b> ____________________________________________________</p>
                    <p><b>Coordenador/a</b> ____________________________________________________</p>
                </div> : null}
        </>
    )
}

export default TabelaDetalhe;