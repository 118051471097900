import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Modal, notification, Icon, Button } from "antd";
import Header from "../User/Header";
import DrawerAdicionar from "./_Aux/DrawerAdicionar";
import axios from "axios";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { GlobalContext } from "../GlobalState";
import CarouselGestor from "../Geral/CarouselGestor";
import "react-big-calendar/lib/css/react-big-calendar.css";
const localizer = momentLocalizer(moment)

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};
class Main extends Component {
    static contextType = GlobalContext;

    state = {
        dataHoje: new Date(),
        vista: "week",
        inicio: moment().startOf('week').format("YYYY/MM/DD"),
        termino: moment().endOf('week').format("YYYY/MM/DD"),
        eventos: [],
        //LOADING
        iconLoading: false,
        //DRAWER ADICIONAR
        visible: false,
        //MODAL DETALHE
        modalEventoVisible: false,
        eventoId: "",
        titulo: "",
        mesmoDia: false,
        data: "",
        horas: "",
        descricao: "",
        autor: false,
        cursoId: "",
        moduloIndex: "",
        tarefaId: "",
        stepmeet: "",
        tipo: "",
        link: "",
        botaoAtivo: false,
        fechada: false,
        registarUrl: "",
        aluno: false,
        tipoBotao: {},
        externa: false,
        convite: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL BROWSERS INFO
        visibleBrowsers: false,
        tituloCarousel: "Informação sobre como deve sair da Stepmeet",
        meetID: 0,
        mensagemId: 1,
        visivelMensagem1: false,
        visivelMensagem2: false,
        naoMostrarMensagem: false,
    }

    UNSAFE_componentWillMount() {
        this.listar();
    }

    listar = () => {
        this.setState(
            {
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/eventos/listar",
                    params: {
                        inicio: this.state.inicio,
                        termino: this.state.termino
                    }
                })
                    .then(response => {
                        const eventos = response.data.map(evento => {
                            return ({
                                id: evento.id,
                                title: evento.titulo,
                                start: new Date(evento.inicio),
                                end: new Date(evento.termino),
                                allDay: evento.todo_dia,
                                cor: evento.cor,
                                descricao: evento.descricao,
                                autor: evento.autor,
                                id_tarefa: evento.id_tarefa,
                                id_stepmeet: evento.id_stepmeet,
                                id_stepmeet_geral: evento.id_stepmeet_geral,
                                tarefa: evento.tarefa,
                                meet: evento.meet,
                            })
                        });

                        this.setState({
                            eventos,
                            iconLoading: false
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        this.setState({
                            iconLoading: false
                        })
                    });
            }
        );
    }

    listarOndeExistemEventos = (toolbar, tipo) => {
        this.setState(
            {
                iconLoading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/eventos/listar-onde-existem-eventos",
                    params: {
                        inicio: this.state.inicio,
                        termino: this.state.termino,
                        vista: toolbar.view,
                        tipo
                    }
                })
                    .then(response => {
                        if (response.data === "ANTERIOR" || response.data === "PROXIMO") {
                            openNotificationWithIcon("warning", "Atenção", response.data === "ANTERIOR" ? "Não existem anteriores" : "Não existem próximos eventos");
                            this.setState({
                                iconLoading: false
                            })
                        }
                        else {
                            let count = response.data;

                            if (tipo === "ANTERIOR") {
                                if (toolbar.view === "month") {
                                    toolbar.date.setMonth(toolbar.date.getMonth() - (count * 1));
                                    toolbar.onNavigate('prev');
                                    //let mDate = this.state.dataHoje;
                                    //mDate.setMonth(mDate.getMonth() - (count * 1))
                                    this.setState({
                                        dataHoje: toolbar.date
                                    })
                                }
                                else if (toolbar.view === "week") {
                                    //let mDate = toolbar.date;
                                    let mDate = this.state.dataHoje;
                                    let newDate = new Date(
                                        mDate.getFullYear(),
                                        mDate.getMonth(),
                                        mDate.getDate() - (count * 7));
                                    toolbar.onNavigate('prev', newDate);
                                    this.setState({
                                        dataHoje: newDate
                                    })
                                }
                                else {
                                    //let mDate = toolbar.date;
                                    let mDate = this.state.dataHoje;
                                    let newDate = new Date(
                                        mDate.getFullYear(),
                                        mDate.getMonth(),
                                        mDate.getDate() - count);
                                    toolbar.onNavigate('prev', newDate);
                                    this.setState({
                                        dataHoje: newDate
                                    })
                                }
                            }
                            else {
                                if (toolbar.view === "month") {
                                    toolbar.date.setMonth(toolbar.date.getMonth() + (count * 1));
                                    toolbar.onNavigate('next');
                                    //let mDate = this.state.dataHoje;
                                    //mDate.setMonth(mDate.getMonth() + (count * 1))
                                    this.setState({
                                        dataHoje: toolbar.date
                                    })
                                }
                                else if (toolbar.view === "week") {
                                    //let mDate = toolbar.date;
                                    let mDate = this.state.dataHoje;
                                    let newDate = new Date(
                                        mDate.getFullYear(),
                                        mDate.getMonth(),
                                        mDate.getDate() + (count * 7));
                                    toolbar.onNavigate('prev', newDate);
                                    this.setState({
                                        dataHoje: newDate
                                    })
                                }
                                else {
                                    //let mDate = toolbar.date;
                                    try {
                                        let mDate = this.state.dataHoje;
                                        let newDate = new Date(
                                            mDate.getFullYear(),
                                            mDate.getMonth(),
                                            mDate.getDate() + count);
                                        toolbar.onNavigate('prev', newDate);
                                        this.setState({
                                            dataHoje: newDate
                                        })
                                    }
                                    catch (error) {

                                    }
                                }
                            }
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        this.setState({
                            iconLoading: false
                        })
                    });
            }
        );
    }

    //onView = (vista) => {
    //    this.setState({
    //        vista
    //    }, () => {
    //        this.listar();
    //    })
    //}

    onRangeChange = (range) => {
        if (range.length) {
            this.setState({
                inicio: moment(range[0]).format("YYYY/MM/DD"),
                termino: moment(range.pop()).format("YYYY/MM/DD")
            }, () => this.listar())
        }
        else {
            this.setState({
                inicio: moment(range.start).format("YYYY/MM/DD"),
                termino: moment(range.end).format("YYYY/MM/DD")
            }, () => this.listar())
        }
    }

    onDrillDown = (range) => {
        this.setState({
            inicio: moment(range).format("YYYY/MM/DD"),
            termino: moment(range).format("YYYY/MM/DD"),
            vista: "day",
            dataHoje: range
        })
    }

    onSelectEvent = (event) => {
        let data = "";
        let mesmoDia = moment(event.start).isSame(event.end, 'day');
        if (mesmoDia) {
            data = `${moment(event.start).format("dddd, DD [de] MMMM")}`
        }
        else {
            data = `${moment(event.start).format("dddd, DD [de] MMMM")} - ${moment(event.end).format("dddd, DD [de] MMMM")}`
        }

        if (event.id_tarefa || event.id_stepmeet || event.id_stepmeet_geral) {
            let cursoId = "";
            let moduloIndex = "";
            let tipo = "";
            let link = "";
            let botaoAtivo = false;
            let registarUrl = "";
            let aluno = false;
            let tipoBotao = {};
            if (event.id_tarefa) { //CASO EVENTO ASSOCIADO A UMA TAREFA
                botaoAtivo = event.tarefa.botaoAtivo;
                registarUrl = this.context.role === "ENTIDADE" || event.tarefa.instrutor ? "/api/gerir-videoconferencia/add-participant-entry" : "/api/videoconferencia/add-participant-entry";
                tipo = "TAREFA";
                aluno = this.context.role === "ENTIDADE" || event.tarefa.instrutor;
                cursoId = event.tarefa.cursoId;
                moduloIndex = event.tarefa.moduloIndex;

                if (event.tarefa.tipo === 6)
                    link = `/${this.context.role === "ENTIDADE" || event.tarefa.instrutor ? "gerir-curso" : "curso"}/${event.tarefa.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${event.tarefa.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/player-avaliacao`;
                if (event.tarefa.tipo === 8)
                    link = `/${this.context.role === "ENTIDADE" || event.tarefa.instrutor ? "gerir-curso" : "curso"}/${event.tarefa.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${event.tarefa.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/player-trabalho`;
                if (event.tarefa.tipo === 10)
                    link = `/${this.context.role === "ENTIDADE" || event.tarefa.instrutor ? "gerir-curso" : "curso"}/${event.tarefa.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${event.tarefa.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/player-sumario`;
                if (event.tarefa.tipo === 11)
                    link = `/${this.context.role === "ENTIDADE" || event.tarefa.instrutor ? "gerir-curso" : "curso"}/${event.tarefa.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${event.tarefa.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/player-sessao-presencial`;
            }

            if (event.id_stepmeet) {//CASO EVENTO ASSOCIADO A UMA STEPMEET NUM CURSO
                botaoAtivo = event.meet.botaoAtivo;
                registarUrl = this.context.role === "ENTIDADE" || event.meet.instrutor ? "/api/gerir-videoconferencia/add-participant-entry" : "/api/videoconferencia/add-participant-entry";
                tipo = "STEPMEET";
                aluno = this.context.role === "ENTIDADE" || event.meet.instrutor;
                link = !event.meet.externa ? `/${this.context.role === "ENTIDADE" || event.meet.instrutor ? "gerir-curso" : "curso"}/${event.meet.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/vconf/${event.meet.id}/player-videoconferencia` : "";
                tipoBotao = {
                    tipo: this.context.role === "ENTIDADE" || event.meet.instrutor ? 1 : 2,
                    meetID: event.meet.id,
                    gravarSessao: false,
                    connected: false,
                    visivelMensagem1: false,
                    visivelMensagem2: false
                }
            }

            if (event.id_stepmeet_geral) {//CASO EVENTO ASSOCIADO A UMA STEPMEET GERAL OU WEBINAR
                botaoAtivo = event.meet.botaoAtivo;
                registarUrl = "/api/gestao-videoconferencia/add-participant-entry";
                tipo = "GERAL";
                link = event.meet.streaming ?
                    `/gestao-webinar/${event.meet.id}/player-webinar`
                    :
                    `/gestao-videoconferencia/${event.meet.id}/player-videoconferencia`;
                tipoBotao = {
                    tipo: event.meet.streaming ? 4 : 3,
                    meetID: event.meet.id,
                    gravarSessao: false,
                    connected: false,
                    visivelMensagem1: false,
                    visivelMensagem2: false,
                }
            }

            this.setState({
                modalEventoVisible: true,
                eventoId: event.id,
                titulo: event.title,
                data,
                horas: event.allDay ? "Todo dia" : `${moment(event.start).format("HH:mm")} - ${moment(event.end).format("HH:mm")}`,
                descricao: event.descricao,
                mesmoDia,
                autor: event.autor,
                cursoId,
                moduloIndex,
                tarefaId: event.id_tarefa,
                stepmeet: event.meet,
                tipo,
                link,
                botaoAtivo: !botaoAtivo,
                fechada: event.meet.fechada,
                registarUrl,
                aluno,
                tipoBotao,
                externa: event.meet?.externa,
                convite: event.meet?.convite,
            })
        }
        else {
            this.setState({
                modalEventoVisible: true,
                eventoId: event.id,
                titulo: event.title,
                data,
                horas: event.allDay ? "Todo dia" : `${moment(event.start).format("HH:mm")} - ${moment(event.end).format("HH:mm")}`,
                descricao: event.descricao,
                mesmoDia,
                autor: event.autor,
                tipo: ""
            })
        }
    }

    excluirEvento = () => {
        confirm({
            title: "Pretende excluir este evento?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/eventos/excluir",
                    params: {
                        eventoId: this.state.eventoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Evento excluido!");
                        const eventos = this.state.eventos.filter(x => x.id !== this.state.eventoId);
                        this.setState({
                            eventos,
                            modalEventoVisible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir este evento!");
                    });
            }
        });
    }

    excluirEventoParaUser = () => {
        confirm({
            title: "Pretende sair do evento?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/eventos/excluir-para-user",
                    params: {
                        eventoId: this.state.eventoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Confirmada a saida do Evento!");
                        const eventos = this.state.eventos.filter(x => x.id !== this.state.eventoId);
                        this.setState({
                            eventos,
                            modalEventoVisible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível sair do evento!");
                    });
            }
        });
    }

    editarEvento = () => {
        this.setState({
            modalEventoVisible: false,
            visible: true
        })
    }

    iniciar = (meet) => {
        if (this.state.tipo === "STEPMEET") {
            if (this.context.role === "ENTIDADE" || meet.instrutor)
                this.iniciarStepmeet(meet.id, meet.gravarSessao, meet.connected, meet.mensagens.mensagem1, meet.mensagens.mensagem2)
            else
                this.iniciarStepmeetAluno(meet.id, meet.gravarSessao, meet.mensagens.mensagem1, meet.participanteNaMeet)
        }
        else {
            if (meet.streaming)
                this.iniciarWebinar(meet.id, meet.gravarSessao, meet.connected, meet.mensagens.mensagem1, meet.mensagens.mensagem2);
            else
                this.iniciarGeral(meet.id, meet.gravarSessao, meet.connected, meet.mensagens.mensagem1, meet.mensagens.mensagem2);
        }
    }

    //*************************ALUNOS***************************
    iniciarStepmeetAluno = (id, gravarSessao, Mensagem1, ParticipanteNaMeet) => {
        if (!ParticipanteNaMeet)
            if (gravarSessao) {
                confirm({
                    title: "Esta sessão está a ser gravada!",
                    okText: "Continuar",
                    okType: "Cancelar",
                    onOk: () => {
                        if (Mensagem1)
                            this.setState({
                                meetID: id,
                                redirectLink: this.state.link
                            }, () => this.registarEntrada());
                        else
                            this.setState({
                                visibleBrowsers: true,
                                meetID: id,
                                redirectLink: this.state.link
                            });
                    }
                });
            }
            else {
                if (Mensagem1)
                    this.setState({
                        meetID: id,
                        redirectLink: this.state.link
                    }, () => this.registarEntrada());
                else
                    this.setState({
                        visibleBrowsers: true,
                        meetID: id,
                        redirectLink: this.state.link
                    });
            }
        else
            openNotificationWithIcon("warning", "Atenção", "Já está a participar numa Stepmeet. Para participar nesta, deve reentrar na Stepmeet onde já está a participar e clicar no botão SAIR para poder encerrar a sua participação nessa mesma sessão.")
    };
    //*************************ALUNOS***************************

    //*************************GESTAO/INSTRUTOR***************************
    iniciarStepmeet = (id, gravarSessao, connected, visivelMensagem1, visivelMensagem2) => {
        if (gravarSessao && connected) {
            confirm({
                title: "Esta sessão está a ser gravada!",
                okText: "Continuar",
                okType: "Cancelar",
                onOk: () => {
                    if (visivelMensagem1 && visivelMensagem2)
                        this.setState({
                            meetID: id,
                            redirectLink: this.state.link
                        }, () => this.registarEntrada());
                    else
                        this.setState({
                            visibleBrowsers: true,
                            meetID: id,
                            redirectLink: this.state.link
                        });
                }
            });
        }
        else {
            if (visivelMensagem1 && visivelMensagem2)
                this.setState({
                    meetID: id,
                    redirectLink: this.state.link
                }, () => this.registarEntrada());
            else
                this.setState({
                    visibleBrowsers: true,
                    meetID: id,
                    redirectLink: this.state.link
                });
        }
    };
    //*************************GESTAO/INSTRUTOR***************************

    //*************************GERAL***************************
    iniciarGeral = (id, gravarSessao, connected, visivelMensagem1, visivelMensagem2) => {
        if (gravarSessao && connected) {
            confirm({
                title: "Esta sessão está a ser gravada!",
                okText: "Continuar",
                okType: "Cancelar",
                onOk: () => {
                    localStorage.setItem("player_back_url", this.props.location.pathname)
                    if (visivelMensagem1 && visivelMensagem2) {
                        this.setState({
                            meetID: id,
                            redirectLink: this.state.link
                        }, () => this.registarEntrada());
                    }
                    else {
                        this.setState({
                            visibleBrowsers: true,
                            meetID: id,
                            redirectLink: this.state.link
                        });
                    }
                }
            });
        }
        else {
            localStorage.setItem("player_back_url", this.props.location.pathname)
            if (visivelMensagem1 && visivelMensagem2) {
                this.setState({
                    meetID: id,
                    redirectLink: this.state.link
                }, () => this.registarEntrada());
            }
            else {
                this.setState({
                    visibleBrowsers: true,
                    meetID: id,
                    redirectLink: this.state.link
                });
            }
        }
    };
    //*************************GERAL***************************

    //*************************WEBINAR***************************
    iniciarWebinar = (id, visivelMensagem1, visivelMensagem2) => {
        localStorage.setItem("player_back_url", this.props.location.pathname)
        if (visivelMensagem1 && visivelMensagem2) {
            this.setState({
                meetID: id,
                redirectLink: this.state.link
            }, () => this.registarEntrada());
        }
        else {
            this.setState({
                visibleBrowsers: true,
                meetID: id,
                redirectLink: this.state.link
            });
        }
    };
    //*************************WEBINAR***************************

    registarEntrada = () => {
        axios({
            method: "post",
            url: this.state.registarUrl,
            params: {
                meetingID: this.state.meetID,
                TempId: "",
                tipoEvento: 0
            }
        })
            .then(response => {
                if (this.state.aluno)
                    localStorage.setItem("participanteNaMeet", true)

                this.setState({
                    redirect: true
                })
            })
            .catch(() => { });
    }

    confirmarEntrada = () => {
        if (this.state.naoMostrarMensagem)
            this.marcarNaoMostrar();

        this.registarEntrada();
    }

    marcarNaoMostrar = () => {
        axios({
            method: "post",
            url: "/api/utilizadores-informacoes/marcar-não-mostrar",
            params: {
                //cursoId: this.state.cursoid,
                mensagemId: this.state.mensagemId,
            }
        })
    }

    alterarTituloCarousel = index => {
        if (index === 0)
            this.setState({
                mensagemId: index + 1,
                tituloCarousel: "Informação sobre como deve sair da Stepmeet"
            })
        else
            this.setState({
                mensagemId: index + 1,
                tituloCarousel: "Informação sobre como deve acessar a esta Stepmeet"
            })
    }

    render() {
        const {
            dataHoje,
            vista,
            eventos,
            //DRAWER ADICIONAR
            visible,
            //MODAL DETALHE
            //MODAL DETALHE
            modalEventoVisible,
            eventoId,
            titulo,
            mesmoDia,
            data,
            horas,
            descricao,
            autor,
            cursoId,
            moduloIndex,
            tarefaId,
            stepmeet,
            tipo,
            link,
            botaoAtivo,
            fechada,
            externa,
            convite,
            //REDIRECT
            redirect,
            redirectLink,
            //MODAL BROWSERS INFO
            visibleBrowsers,
            tituloCarousel,
            visivelMensagem1,
            visivelMensagem2,
            naoMostrarMensagem,
            //LOADING
            iconLoading,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const eventStyleGetter = (event, start, end, isSelected) => {
            const style = {
                backgroundColor: event.cor,
                border: 'none',
                //opacity: 0.8,
                //color: 'black',
                border: '1px solid',
                //display: 'block'
            };
            return {
                style: style
            };
        }

        const toolbar = (toolbar) => {
            const eventosAnteriores = () => {
                this.listarOndeExistemEventos(toolbar, "ANTERIOR");
            }

            const proximosEventos = () => {
                this.listarOndeExistemEventos(toolbar, "PROXIMO");
            }

            const anterior = () => {
                if (toolbar.view === "month") {
                    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
                    toolbar.onNavigate('prev');
                    //let mDate = this.state.dataHoje;
                    //mDate.setMonth(mDate.getMonth())
                    this.setState({
                        dataHoje: toolbar.date
                    })
                }
                else if (toolbar.view === "week") {
                    //let mDate = toolbar.date;
                    let mDate = this.state.dataHoje;
                    let newDate = new Date(
                        mDate.getFullYear(),
                        mDate.getMonth(),
                        mDate.getDate() - 7);
                    toolbar.onNavigate('prev', newDate);
                    this.setState({
                        dataHoje: newDate
                    })
                }
                else {
                    //let mDate = toolbar.date;
                    let mDate = this.state.dataHoje;
                    let newDate = new Date(
                        mDate.getFullYear(),
                        mDate.getMonth(),
                        mDate.getDate() - 1);
                    toolbar.onNavigate('prev', newDate);
                    this.setState({
                        dataHoje: newDate
                    })
                }
            };

            const proximo = () => {
                if (toolbar.view === "month") {
                    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
                    toolbar.onNavigate('next');
                    //let mDate = this.state.dataHoje;
                    //mDate.setMonth(mDate.getMonth())
                    this.setState({
                        dataHoje: toolbar.date
                    })
                }
                else if (toolbar.view === "week") {
                    //let mDate = toolbar.date;
                    let mDate = this.state.dataHoje;
                    let newDate = new Date(
                        mDate.getFullYear(),
                        mDate.getMonth(),
                        mDate.getDate() + 7);
                    toolbar.onNavigate('prev', newDate);
                    this.setState({
                        dataHoje: newDate
                    })
                }
                else {
                    //let mDate = toolbar.date;
                    let mDate = this.state.dataHoje;
                    let newDate = new Date(
                        mDate.getFullYear(),
                        mDate.getMonth(),
                        mDate.getDate() + 1);
                    toolbar.onNavigate('prev', newDate);
                    this.setState({
                        dataHoje: newDate
                    })
                }
            };

            const vistaHoje = () => {
                toolbar.onNavigate('current', new Date());
                this.setState({
                    dataHoje: new Date()
                })
            };

            const labelData = () => {
                const date = moment(toolbar.date);
                if (toolbar.view === "month")
                    return (
                        <span>{date.format('MMMM YYYY')}</span>
                    );
                else if (toolbar.view === "week") {
                    return (
                        <span>{toolbar.label}</span>
                    );
                }
                else
                    return (
                        <span>{date.format('dddd, DD [de] MMMM YYYY')}</span>
                    );
            };

            const vistaMes = () => {
                toolbar.onView('month');
                this.setState({
                    vista: 'month'
                })
            }

            const vistaSemana = () => {
                toolbar.onView('week');
                this.setState({
                    vista: 'week'
                })
            }

            const vistaDia = () => {
                toolbar.onView('day');
                this.setState({
                    vista: 'day'
                })
            }

            const montarTitleDuplaSetaEsq = () => {
                if (vista === "month")
                    return "Mês anterior com eventos";
                else if (vista === "week")
                    return "Semana anterior com eventos";
                else
                    return "Dia anterior com eventos";
            }

            const montarTitleDuplaSetaDir = () => {
                if (vista === "month")
                    return "Próximo mês com eventos";
                else if (vista === "week")
                    return "Próxima semana com eventos";
                else
                    return "Próximo dia com eventos";
            }

            const montarTitleSetaEsq = () => {
                if (vista === "month")
                    return "Mês anterior";
                else if (vista === "week")
                    return "Semana anterior";
                else
                    return "Dia anterior";
            }

            const montarTitleSetaDir = () => {
                if (vista === "month")
                    return "Próximo mês";
                else if (vista === "week")
                    return "Próxima semana";
                else
                    return "Próximo dia";
            }

            return (
                <>
                    <div className='toolbar-container toolbar-container-desktop'>
                        <div className="toolbal-opcoes-esquerda">
                            <Button.Group>
                                <Button type="primary" className='botao-principal btn-adicionar' onClick={() => this.setState({ visible: true, eventoId: "" })}>
                                    Novo evento
                                </Button>
                                <Button className='btn-anterior' title={montarTitleDuplaSetaEsq()} onClick={eventosAnteriores}>
                                    <Icon type="double-left" />
                                </Button>
                                <Button className='btn-anterior' title={montarTitleSetaEsq()} onClick={anterior}>
                                    <Icon type="left" />
                                </Button>
                                <Button className='btn-hoje' onClick={vistaHoje}>Hoje</Button>
                                <Button className='btn-proximo' title={montarTitleSetaDir()} onClick={proximo}>
                                    <Icon type="right" />
                                </Button>
                                <Button className='btn-proximo' title={montarTitleDuplaSetaDir()} onClick={proximosEventos}>
                                    <Icon type="double-right" />
                                </Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-label-data">
                            <label className='label-data'>{labelData()}</label>
                        </div>
                        <div className="toolbal-opcoes-direita">
                            <Button.Group>
                                <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>Mês</Button>
                                <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>Semana</Button>
                                <Button className={`btn-dia ${vista === "day" ? " ativa" : ""}`} onClick={vistaDia}>Dia</Button>
                            </Button.Group>
                        </div>
                    </div>
                    <div className='toolbar-container toolbar-container-tablet'>
                        <div className="toolbal-opcoes-cima">
                            <Button.Group>
                                <Button type="primary" className='botao-principal btn-adicionar' onClick={() => this.setState({ visible: true, eventoId: "" })}>
                                    <Icon type="plus" />
                                </Button>
                                <Button className='btn-anterior' title={montarTitleDuplaSetaEsq()} onClick={eventosAnteriores}>
                                    <Icon type="double-left" />
                                </Button>
                                <Button className='btn-anterior' title={montarTitleSetaEsq()} onClick={anterior}>
                                    <Icon type="left" />
                                </Button>
                                <Button className='btn-hoje' onClick={vistaHoje}>Hoje</Button>
                                <Button className='btn-proximo' title={montarTitleSetaDir()} onClick={proximo}>
                                    <Icon type="right" />
                                </Button>
                                <Button className='btn-proximo' title={montarTitleDuplaSetaDir()} onClick={proximosEventos}>
                                    <Icon type="double-right" />
                                </Button>
                            </Button.Group>
                            <Button.Group>
                                <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>Mês</Button>
                                <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>Semana</Button>
                                <Button className={`btn-dia ${vista === "day" ? " ativa" : ""}`} onClick={vistaDia}>Dia</Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-opcoes-baixo">
                            <div className="toolbal-label-data">
                                <label className='label-data'>{labelData()}</label>
                            </div>
                        </div>
                    </div>
                    <div className='toolbar-container toolbar-container-mobile'>
                        <div className="toolbal-opcoes-esquerda">
                            <Button.Group>
                                <Button type="primary" className='botao-principal btn-adicionar' onClick={() => this.setState({ visible: true, eventoId: "" })}>
                                    <Icon type="plus" />
                                </Button>
                                <Button className='btn-anterior' onClick={eventosAnteriores}>
                                    <Icon type="double-left" />
                                </Button>
                                <Button className='btn-anterior' onClick={anterior}>
                                    <Icon type="left" />
                                </Button>
                                <Button className='btn-hoje' onClick={vistaHoje}>Hoje</Button>
                                <Button className='btn-proximo' onClick={proximo}>
                                    <Icon type="right" />
                                </Button>
                                <Button className='btn-proximo' onClick={proximosEventos}>
                                    <Icon type="double-right" />
                                </Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-opcoes-direita">
                            <Button.Group>
                                <Button className={`btn-mes ${vista === "month" ? " ativa" : ""}`} onClick={vistaMes}>Mês</Button>
                                <Button className={`btn-semana ${vista === "week" ? " ativa" : ""}`} onClick={vistaSemana}>Semana</Button>
                                <Button className={`btn-dia ${vista === "day" ? " ativa" : ""}`} onClick={vistaDia}>Dia</Button>
                            </Button.Group>
                        </div>
                        <div className="toolbal-label-data">
                            <label className='label-data'>{labelData()}</label>
                        </div>
                    </div>
                </>
            );
        };

        const montarLink = () => {
            switch (tipo) {
                case "TAREFA":
                    return (
                        <Link
                            className="botao-principal"
                            to={link}
                            onClick={() => {
                                localStorage.setItem("codigo_curso", cursoId);
                                localStorage.setItem("modulo_aberto", moduloIndex);
                                localStorage.setItem("codigo_aula", tarefaId);
                            }}
                            disabled={botaoAtivo}
                        >
                            Abrir Tarefa
                        </Link>
                    );
                case "STEPMEET":
                    return (
                        <>
                            {!botaoAtivo ?
                                <Link
                                    className="botao-principal"
                                    to="#"
                                    onClick={() => this.iniciar(stepmeet)}
                                >
                                    Iniciar
                                </Link>
                                :
                                <Link
                                    className="botao-principal"
                                    to={this.state.link.replace("player-videoconferencia", "player-videoconferencia-aguardar")}
                                >
                                    Iniciar
                                </Link>
                            }
                        </>
                    );
                default:
                    return (
                        <>
                            {!botaoAtivo ?
                                <Link
                                    className="botao-principal"
                                    to="#"
                                    onClick={() => this.iniciar(stepmeet)}
                                >
                                    Iniciar
                                </Link>
                                :
                                <Link
                                    className="botao-principal"
                                    to={this.state.link.replace("player-videoconferencia", "player-videoconferencia-aguardar")}
                                >
                                    Iniciar
                                </Link>
                            }
                        </>
                    );
            }
        }

        const hoje = new Date();

        return (
            <>
                <Header titulo="Calendário" />
                <div className="container container-body bloco-calendario">
                    <Calendar
                        localizer={localizer}
                        events={eventos}
                        scrollToTime={new Date(
                            hoje.getFullYear(),
                            hoje.getMonth(),
                            hoje.getDate(),
                            8
                        )}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 700 }}
                        defaultView={vista}
                        view={vista}
                        date={dataHoje}
                        views={["month", "week", "day"]}
                        //onView={this.onView}
                        components={{
                            toolbar: toolbar
                        }}
                        onSelectEvent={this.onSelectEvent}
                        onDrillDown={this.onDrillDown}
                        onRangeChange={this.onRangeChange}
                        eventPropGetter={(eventStyleGetter)}
                    />
                </div>  
                <DrawerAdicionar
                    eventoId={eventoId}
                    visible={visible}
                    onClose={() => this.setState({
                        visible: false
                    })}
                    adicionarEvento={evento => this.setState({ eventos: [...eventos, evento], visible: false })}
                    atualizarEvento={evento => this.setState({ eventos: [...(eventos.filter(x => x.id !== eventoId)), evento], visible: false })}
                />
                <Modal
                    title={
                        autor && tipo === "" ?
                            <span className="modal-controlos-evento">
                                <Link to="#" title="Editar" onClick={this.editarEvento}>
                                    <Icon type="edit" />
                                </Link>
                                <Link to="#" title="Excluir" onClick={this.excluirEvento}>
                                    <Icon type="delete" />
                                </Link>
                                <Link to="#">
                                    <Icon type="close" onClick={() => this.setState({ modalEventoVisible: false })} />
                                </Link>
                            </span>
                            :
                            tipo === "" ?
                                <span className="modal-controlos-evento nao-autor">
                                    <Link to="#" title="Excluir" onClick={this.excluirEventoParaUser}>
                                        <Icon type="delete" />
                                    </Link>
                                    <Link to="#">
                                        <Icon type="close" onClick={() => this.setState({ modalEventoVisible: false })} />
                                    </Link>
                                </span>
                                :
                                <span className="modal-controlos-evento evento-normal">
                                    <Link to="#">
                                        <Icon type="close" onClick={() => this.setState({ modalEventoVisible: false })} />
                                    </Link>
                                </span>
                    }
                    className="modal-info-evento"
                    visible={modalEventoVisible}
                    onOk={() => this.setState({ modalEventoVisible: false })}
                    onCancel={() => this.setState({ modalEventoVisible: false })}
                    closable={false}
                    footer={null}
                >
                    <div className="info-evento">
                        <span className="titulo-evento" title={titulo}>{titulo}</span>
                        <span className="data-evento">{data}<i className="fas fa-circle"></i>{horas}</span>
                    </div>
                    {descricao ?
                        <>
                            <p className="desc-evento">{descricao}</p>
                            {link && !fechada ?
                                <div className="link-evento-com-desc">
                                    {montarLink()}
                                </div>
                                : null}
                            {fechada ?
                                <div className="link-evento-com-desc">
                                    <Link
                                        className="botao-principal botao-disabled"
                                        to="#"
                                        disabled
                                    >
                                        Encerrada
                                </Link>
                                </div>
                                : null}
                        </>
                        :
                        <>
                            {link && !fechada ?
                                <div className="link-evento">
                                    {montarLink()}
                                </div> : null}
                            {fechada ?
                                <div className="link-evento">
                                    <Link
                                        className="botao-principal botao-disabled"
                                        to="#"
                                        disabled
                                    >
                                        Encerrada
                                    </Link>
                                </div>
                                : null}
                            {externa ?
                                <p className="desc-evento" dangerouslySetInnerHTML={{ __html: convite?.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p> : null}
                        </>
                        }
                </Modal>
                <CarouselGestor
                    tituloCarousel={tituloCarousel}
                    visibleBrowsers={visibleBrowsers}
                    confirmarEntrada={this.confirmarEntrada}
                    cancelarEntrada={() => this.setState({ visibleBrowsers: false })}
                    alterarTituloCarousel={this.alterarTituloCarousel}
                    selecionarNaoMostrar={naoMostrarMensagem => this.setState({ naoMostrarMensagem })}
                    visivelMensagem1={visivelMensagem1}
                    visivelMensagem2={visivelMensagem2}
                />
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar eventos...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default Main;
