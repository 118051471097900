import React from "react";
import BotaoDefinicoes from "../../Geral/_Aux/BotaoDefinicoes";

const Pendente = ({ colaborador, menu, tem_definicoes, dt_convite }) => {
    return (
        <>
            <div className="colaborador colaborador-pendente-desktop colaborador-pendente">
                <div className="info-participante">
                    <div className="info">
                        <span className="nome">
                            {colaborador.nome_completo}
                        </span>
                        {colaborador.organismo && <span className="tag-organismo" title="Organismo">{colaborador.organismo}</span>}
                        <div className="bottom">
                            <span className="email">{colaborador.email} {colaborador.nr_aluno && "(" + colaborador.nr_aluno + ")"}</span>
                        </div>
                    </div>
                </div>
                <div className="email-reenviado">
                    <label>Data envio</label>
                    <span>{dt_convite}</span>
                </div>
                {tem_definicoes ? <BotaoDefinicoes menu={menu} pendente /> : null}
            </div>
            <div className="colaborador colaborador-pendente-mobile colaborador-pendente">
                <div className="info-participante">
                    <div className="info">
                        <span className="nome">
                            {colaborador.nome_completo}
                        </span>
                        {colaborador.organismo && <span className="tag-organismo" title="Organismo">{colaborador.organismo}</span>}
                        <div className="bottom">
                            <span className="email">{colaborador.email} {colaborador.nr_aluno && "(" + colaborador.nr_aluno + ")"}</span>
                            <span className="email"><strong>Data envio:</strong> {dt_convite}</span>
                        </div>
                    </div>
                </div>
                {tem_definicoes ? <BotaoDefinicoes menu={menu} pendente /> : null}
            </div>
        </>
    );
};

export default Pendente;
