import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { notification } from "antd";
import ConteudoQuestionario from "./ConteudoQuestionario";
import ControlosSimples from "./ControlosSimples";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import LazyLoad from "react-lazy-load";
//import Oops from "../../Geral/Oops.jsx";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Questionario extends Component {
    static contextType = GlobalContext;
    state = {
        cursoId: 0,
        curso: "",
        fullscreen: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);

        this.setState({
            curso: this.props.match.params.curso,
            cursoId: localStorage.getItem("codigo_curso")
        });
    }

    render() {
        const {
            redirect,
            redirectLink,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="questionario">
                <div className="player-wrapper">
                    <LazyLoad offsetVertical={200}>
                        <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                    </LazyLoad>
                    <ConteudoQuestionario />
                    <ControlosSimples />
                </div>
            </div>
        );
    }
}

export default Questionario;
