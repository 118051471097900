import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Icon, Table } from "antd";
import axios from "axios";
import moment from "moment";

export class Sessoes extends Component {
    state = {
        nr_sessoes: "",
        tempo_medio_sessao: "",
        tempo_total_sessao: "",
        dias_login: "",
        //TABELA
        listaPeriodo: [],
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //COLUNAS
        columns: [],
        cartoes: [],
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
        this.listarPeriodo();
    }

    componentDidUpdate() {
        if (this.props.atualizar) {
            this.listar();
            this.listarPeriodo();
            this.props.paraAtualizar();
        }
    }

    //CARREGA ESTATISTICAS
    listar = () => {
        axios({
            method: "get",
            url: "/api/estatistica/aluno-sessoes",
            params: {
                dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                dtfim: this.props.dt_termino.format("YYYY/MM/DD")
            }
        })
            .then(response => {
                this.setState({
                    nr_sessoes: response.data.nr_sessoes,
                    dias_login: response.data.dias_login,
                    tempo_total_sessao: response.data.tempo_total_sessao,
                    tempo_medio_sessao: response.data.tempo_medio_sessao
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarPeriodo = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/estatistica/aluno-sessoes-det",
                    params: {
                        dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.props.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        var cartoes = [];

                        var columns = [{
                            title: "Período",
                            dataIndex: "periodoHorario",
                            key: "periodoHorario",
                            fixed: "left",
                            width: 120
                        }]
                        var dt_termino = this.props.dt_termino.format("YYYY/MM/DD");
                        var dateStart = moment(dt_termino).subtract(5, 'month');
                        var dateEnd = moment(dt_termino);

                        while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
                            columns.push({
                                title: dateStart.format('MMM').charAt(0).toUpperCase() + dateStart.format('MMM').slice(1) + (dateStart.format('MMM') === "jan" || dateStart.format('MMM') === "dez" ? "/" + dateStart.format('YY') : ""),
                                dataIndex: dateStart.format('MMMM').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                                key: dateStart.format('MMMM').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                                width: 120
                            })
                            cartoes.push({
                                titulo: dateStart.format('MMMM').charAt(0).toUpperCase() + dateStart.format('MMMM').slice(1) + (dateStart.format('MMM') === "jan" || dateStart.format('MMM') === "dez" ? "/" + dateStart.format('YY') : ""),
                                key: dateStart.format('MMMM').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            })
                            dateStart.add(1, 'month');
                        }

                        var listaPeriodo = [];

                        response.data.forEach((registo, index) => {
                            listaPeriodo.push({
                                key: index,
                                periodoHorario: registo.hora,
                                janeiro: 0,
                                fevereiro: 0,
                                marco: 0,
                                abril: 0,
                                maio: 0,
                                junho: 0,
                                julho: 0,
                                agosto: 0,
                                setembro: 0,
                                outubro: 0,
                                novembro: 0,
                                dezembro: 0
                            });

                            registo.meses.forEach(mes => {
                                if (mes.mes === "1") listaPeriodo[index].janeiro = mes.total;
                                if (mes.mes === "2") listaPeriodo[index].fevereiro = mes.total;
                                if (mes.mes === "3") listaPeriodo[index].marco = mes.total;
                                if (mes.mes === "4") listaPeriodo[index].abril = mes.total;
                                if (mes.mes === "5") listaPeriodo[index].maio = mes.total;
                                if (mes.mes === "6") listaPeriodo[index].junho = mes.total;
                                if (mes.mes === "7") listaPeriodo[index].julho = mes.total;
                                if (mes.mes === "8") listaPeriodo[index].agosto = mes.total;
                                if (mes.mes === "9") listaPeriodo[index].setembro = mes.total;
                                if (mes.mes === "10") listaPeriodo[index].outubro = mes.total;
                                if (mes.mes === "11") listaPeriodo[index].novembro = mes.total;
                                if (mes.mes === "12") listaPeriodo[index].dezembro = mes.total;
                            });
                        });

                        this.setState({
                            columns,
                            cartoes,
                            listaPeriodo,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    render() {
        const {
            nr_sessoes,
            dias_login,
            tempo_medio_sessao,
            tempo_total_sessao,
            //TABELA
            listaPeriodo,
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //COLUNAS
            columns,
            cartoes
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="dashboard">
                <div className="bloco">
                    <div className="grid">
                        <div className="item">
                            <Icon type="solution" />
                            <span className="desc">
                                {nr_sessoes}/{dias_login}
                            </span>
                            <span className="titulo">Sessões/Dias</span>
                        </div>
                        <div className="item">
                            <Icon type="history" />
                            <span className="desc">{tempo_total_sessao}</span>
                            <span className="titulo">Tempo total das sessões</span>
                        </div>
                        <div className="item">
                            <Icon type="history" />
                            <span className="desc">{tempo_medio_sessao}</span>
                            <span className="titulo">Tempo médio por sessão</span>
                        </div>
                    </div>
                    <div style={{ marginTop: 40 }}>
                        <Table
                            className="tabela-sessoes-desktop"
                            columns={columns}
                            dataSource={listaPeriodo}
                            pagination={false}
                            locale={{ emptyText: "Não existem registos!" }}
                            loading={{
                                spinning: loading_table,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                        />
                        <div className="lista-sessoes lista-sessoes-mobile">
                            {cartoes.map((mes, index) => (
                                <div key={index} className="cartao-sessao">
                                    <div className="bloco-titulo">
                                        {mes.titulo}
                                    </div>
                                    <div className="bloco-info">
                                        <ul>
                                            {listaPeriodo.map(periodo => (
                                                <li><span>{periodo.periodoHorario}:</span> <span>{periodo[mes.key]}</span></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sessoes;
