import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Table, Drawer, Dropdown, Form, Menu, Modal, Icon, notification } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../images/noimage.png";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export class StepmeetsGerais extends Component {
    state = {
        lista: [],
        listaMobile: [],
        //TABELA
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER DETALHE
        visibleDetalhe: false,
        detalheAgendada: "",
        detalheRealizada: "",
        detalheParticipantes: "",
        detalheServidor: "",
        detalheGravador: "",
        //MODAL PARTICIPANTES DUPLICADOS
        visibleParticipantes: false,
        listaAgendadas: false,
        loadingLista: false,
        participantes: []
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    componentDidUpdate() {
        if (this.props.atualizar) {
            this.listar();
            this.props.paraAtualizar();
        }
    }

    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-estatistica/stepmeets-gerais-list",
                    params: {
                        dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.props.dt_termino.format("YYYY/MM/DD"),
                        vistaListagem: this.props.vistaListagem
                    }
                })
                    .then(response => {
                        if (response.data === "SEM_REGISTO_ANTERIORES") {
                            openNotificationWithIcon("warning", "Atenção", "Não existem Stepmeets anteriores");
                            this.setState({
                                loading_table: false
                            });
                            return;
                        }

                        if (response.data === "SEM_REGISTO_PROXIMOS") {
                            openNotificationWithIcon("warning", "Atenção", "Não existem próximos Stepmeets");
                            this.setState({
                                loading_table: false
                            });
                            return;
                        }

                        var lista = [];
                        var listaMobile = [];
                        var listaMeets = response.data.lista;

                        listaMeets.map((meet, index) => {
                            let datas = "";
                            let participantes = "";

                            if (!meet.realizada) {
                                datas = <div className="bloco-meet-datas">
                                    <div className="info-data">
                                        <span className="data">{moment(meet.dataAgendada).format("ddd, DD MMM")}</span>
                                        <span className="hora">{meet.horaAgendada} ({meet.duracaoAgendada})</span>
                                    </div>
                                        /
                                        <div className="info-data" style={{ minWidth: 72 }}>
                                            ----
                                        </div>
                                </div>
                                participantes = <div className="bloco-meet-participantes">
                                    <span>
                                        <span>
                                            <span title="Participantes Previstos">{meet.totalParticipantesAgendada}</span>
                                            {meet.participantesDuplicadosAgendada ?
                                                <Link to="#" onClick={() => this.carregarDuplicadosMeetAgendada(meet.id)} title="Participantes em Stepmeets simultâneas">
                                                    <span className="participantes-duplicados"><i className="fas fa-users icon-tipo-partilha partilhado"></i></span>
                                                </Link>
                                                : null}
                                        </span>
                                        <span className="bloco-meet-separador">/</span>
                                        <span>
                                            ----
                                        </span>
                                    </span>
                                </div>;
                            }
                            else {
                                datas = <div className="bloco-meet-datas">
                                    <div className="info-data">
                                        <span className="data">{moment(meet.dataAgendada).format("ddd, DD MMM")}</span>
                                        <span className="hora">{meet.horaAgendada} ({meet.duracaoAgendada})</span>
                                    </div>
                                        /
                                        <div className="info-data">
                                        <span className="data">{moment(meet.dataPrimeiraEntrada).format("ddd, DD MMM")}</span>
                                        <span className="hora">{meet.primeiraEntrada} ({meet.duracaoReal})</span>
                                    </div>
                                </div>
                                participantes = <div className="bloco-meet-participantes">
                                    <span>
                                        <span>
                                            <span title="Participantes Previstos">{meet.totalParticipantesAgendada}</span>
                                            {meet.participantesDuplicadosAgendada ?
                                                <Link to="#" onClick={() => this.carregarDuplicadosMeetAgendada(meet.id)} title="Participantes em Stepmeets simultâneas">
                                                    <span className="participantes-duplicados"><i className="fas fa-users icon-tipo-partilha partilhado"></i></span>
                                                </Link>
                                                : null}
                                        </span>
                                        <span className="bloco-meet-separador">/</span>
                                        <span>
                                            <span title="Participantes Reais">{meet.totalParticipantesRealizada}</span>
                                            {meet.participantesDuplicadosRealizada ?
                                                <Link to="#" onClick={() => this.carregarDuplicadosMeetRealizada(meet.id)} title="Participantes em Stepmeets simultâneas">
                                                    <span className="participantes-duplicados"><i className="fas fa-users icon-tipo-partilha partilhado"></i></span>
                                                </Link>
                                                : null}
                                        </span>
                                    </span>
                                </div>;
                            }

                            lista.push({
                                key: index,
                                assunto: meet.assunto,
                                datas,
                                participantes: participantes,
                                gravador: <div style={{ textAlign: "center" }}>
                                    <span className="tag-servidor">({meet.servidor})</span>
                                    {meet.gravador || meet.tipo === "WEBINAR" ?
                                        meet.tipo === "WEBINAR" ?
                                            <div className="icon-stream">
                                                <span className="circular-label" title="Webinar">LIVE</span>
                                            </div>
                                            :
                                            <div className="icon-gravador">
                                                <span className="circular-label" title="Stepmeet com Gravação">REC</span>
                                            </div>
                                        : null}
                                </div>
                            });

                            listaMobile.push({
                                key: index,
                                assunto: meet.assunto,
                                opcoes: (
                                    <Dropdown
                                        overlay={this.montarMenu(meet)}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });
                        });

                        this.setState({
                            lista,
                            listaMobile,
                            loading_table: false
                        });

                        this.props.atualizarDatas(response.data.dtini, response.data.dtfim);
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    carregarDuplicadosMeetAgendada = meetingId => {
        this.setState({
            visibleParticipantes: true,
            listaAgendadas: true,
            loadingLista: true
        }, () => {
                axios({
                    method: "get",
                    url: "/api/gestao-estatistica/listar-participantes-duplicados-agendada",
                    params: {
                        meetingId
                    }
                })
                    .then(response => {
                        this.setState({
                            loadingLista: false,
                            participantes: response.data
                        })
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível carregar participantes duplicados!")
                    });
        })
    }

    carregarDuplicadosMeetRealizada = meetingId => {
        this.setState({
            visibleParticipantes: true,
            listaAgendadas: true,
            loadingLista: true
        }, () => {
            axios({
                method: "get",
                url: "/api/gestao-estatistica/listar-participantes-duplicados-realizada",
                params: {
                    meetingId,
                    dtini: this.props.dt_inicio.format("YYYY/MM/DD"),
                    dtfim: this.props.dt_termino.format("YYYY/MM/DD"),
                }
            })
                .then(response => {
                    this.setState({
                        loadingLista: false,
                        participantes: response.data
                    })
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar participantes duplicados!")
                });
        })
    }

    montarMenu = (meet) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalhe(meet)}>
                        Detalhe
                    </Link>
                </Menu.Item>
            </Menu>
        );
    }

    abrirDetalhe = (meet) => {
        const detalheAgendada = <div className="info-data">
            <span className="data">{moment(meet.dataAgendada).format("ddd, DD MMM")}</span>
            <span className="hora">{meet.horaAgendada} ({meet.duracaoAgendada})</span>
        </div>;
        const detalheRealizada = meet.realizada ? <div className="info-data">
            <span className="data">{moment(meet.dataAgendada).format("ddd, DD MMM")}</span>
            <span className="hora">{meet.horaAgendada} ({meet.duracaoAgendada})</span>
        </div> : "";
        const detalheParticipantes = meet.realizada ?
            <div className="bloco-meet-participantes">
                <span>
                    <span>
                        <span title="Participantes Previstos">{meet.totalParticipantesAgendada}</span>
                        {meet.participantesDuplicadosAgendada ?
                            <Link to="#" onClick={() => this.carregarDuplicadosMeetAgendada(meet.id)} title="Participantes em Stepmeets simultâneas">
                                <span className="participantes-duplicados"><i className="fas fa-users icon-tipo-partilha partilhado"></i></span>
                            </Link>
                            : null}
                    </span>
                    <span className="bloco-meet-separador">/</span>
                    <span>
                        <span title="Participantes Reais">{meet.totalParticipantesRealizada}</span>
                        {meet.participantesDuplicadosRealizada ?
                            <Link to="#" onClick={() => this.carregarDuplicadosMeetRealizada(meet.id)} title="Participantes em Stepmeets simultâneas">
                                <span className="participantes-duplicados"><i className="fas fa-users icon-tipo-partilha partilhado"></i></span>
                            </Link>
                            : null}
                    </span>
                </span>
            </div>
            //`${meet.totalParticipantesAgendada} / ${meet.totalParticipantesRealizada}`
            :
            <div className="bloco-meet-participantes">
                <span>
                    <span title="Participantes Previstos">{meet.totalParticipantesAgendada}</span>
                    {meet.participantesDuplicadosAgendada ?
                        <Link to="#" onClick={() => this.carregarDuplicadosMeetAgendada(meet.id)} title="Participantes em Stepmeets simultâneas">
                            <span className="participantes-duplicados"><i className="fas fa-users icon-tipo-partilha partilhado"></i></span>
                        </Link>
                        : null}
                </span>
            </div>;
            //meet.totalParticipantesAgendada;
        const detalheServidor = meet.servidor
        const detalheGravador = meet.gravador || meet.tipo === "WEBINAR" ?
            meet.tipo === "WEBINAR" ?
                <div className="icon-stream">
                    <span className="circular-label" title="Webinar">LIVE</span>
                </div>
                :
                <div className="icon-gravador">
                    <span className="circular-label" title="Stepmeet com Gravação">REC</span>
                </div>
            : "";

        this.setState({
            visibleDetalhe: true,
            detalheAgendada,
            detalheRealizada,
            detalheParticipantes,
            detalheServidor,
            detalheGravador
        })
    }

    render() {
        const {
            lista,
            listaMobile,
            //TABELA
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER DETALHE
            visibleDetalhe,
            detalheAgendada,
            detalheRealizada,
            detalheParticipantes,
            detalheServidor,
            detalheGravador,
            //MODAL PARTICIPANTES DUPLICADOS
            visibleParticipantes,
            listaAgendadas,
            loadingLista,
            participantes
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const columns = [
            {
                title: "Assunto",
                dataIndex: "assunto",
                key: "assunto"
            },
            {
                title: "Agendada/Realizada",
                dataIndex: "datas",
                key: "datas",
                className: "td-250",
            },
            {
                title: "Previstos/Reais",
                dataIndex: "participantes",
                key: "participantes"
            },
            {
                title: <span style={{ textAlign: "center" }}>Servidores</span>,
                dataIndex: "gravador",
                key: "gravador"
            }
        ];

        const columnsMobile = [
            {
                title: "Assunto",
                dataIndex: "assunto",
                key: "assunto"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50",
            }
        ];

        return (
            <>
                <Table
                    columns={columns}
                    className="tabela-estatisticas-desktop tabela-estatisticas-stepmeets"
                    dataSource={lista}
                    //dataSource={data}
                    pagination={false}
                    locale={{ emptyText: "Não existem registos!" }}
                    loading={{
                        spinning: loading_table,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                />
                <Table
                    columns={columnsMobile}
                    className="tabela-estatisticas-mobile tabela-estatisticas-stepmeets"
                    dataSource={listaMobile}
                    pagination={false}
                    locale={{ emptyText: "Não existem registos!" }}
                    loading={{
                        spinning: loading_table,
                        indicator: (
                            <div className="loading-data-table">
                                <div className="loading" />
                            </div>
                        )
                    }}
                />
                <Drawer
                    className="drawer-detalhe-stepmeets"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Agendada">
                                    {detalheAgendada}
                                </Form.Item>
                                {detalheRealizada ?
                                    <Form.Item label="Realizada">
                                        {detalheRealizada}
                                    </Form.Item>
                                    : null}
                                <Form.Item label="Participantes Previstos/Reais">
                                    {detalheParticipantes}
                                </Form.Item>
                                <Form.Item label="Servidores">
                                    {detalheServidor}
                                </Form.Item>
                                {detalheGravador ?
                                    <Form.Item label="Gravação">
                                        {detalheGravador}
                                    </Form.Item>
                                    : null}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Modal
                    title={`Alunos em Stepmeets ${listaAgendadas ? "agendadas" : "realizadas"} simultâneas`}
                    visible={visibleParticipantes}
                    onCancel={() => this.setState({ visibleParticipantes: false })}
                    maskClosable={false}
                    className="modal-lista-participantes"
                    footer={null}
                >
                    <div className="bloco-lista-participantes">
                        {loadingLista ? (
                            <div className="bloco-loading">
                                <p>
                                    <Icon className="icon-loading" type="loading" />
                                </p>
                                <p className="texto">A carregar...</p>
                            </div>
                        ) : (
                                <>
                                    {participantes.map((participante, index) => (
                                        <div key={index} className="info-participante">
                                            <div className="bloco-img">
                                                <img src={participante.foto ? participante.foto : noimage} />
                                            </div>
                                            <div className="info">
                                                <span className="nome">
                                                    {participante.nome}
                                                </span>
                                                <p className="numero">{participante.numero}
                                                    {participante.instrutor ?
                                                        <span className="tag">Professor</span>
                                                        : null}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                    </div>
                </Modal>
            </>
        );
    }
}

export default StepmeetsGerais;
