import React, { Component } from "react";
import { Button, Icon, notification, Modal } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class CardsNotas extends Component {
    state = {
        num: 1,
        total: 0,
        semPeriodos: false,
        //PERIODO
        periodo: "",
        periodos: [],
        avaliacoes: [],
        participantes: [],
        //FINAL
        finalVisivel: false,
        //LOADING
        iconLoading: false
    }

    componentDidMount() {
        this.total();
    }

    total = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/aulas/total-periodos",
                params: {
                    faseId: localStorage.getItem("faseEscolar")
                }
            })
                .then(response => {
                    this.setState({
                        total: response.data
                    }, () => {
                        if (response.data > 0) {
                            this.listar();
                        }
                        else {
                            this.setState({
                                semPeriodos: true,
                                iconLoading: false
                            })
                            openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                        }
                    })
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                });
        })
    }

    listar = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-notas",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                faseId: localStorage.getItem("faseEscolar"),
                periodoSelecionado: this.state.num
            }
        })
            .then(response => {
                var periodos = this.state.periodos;
                if (!periodos.find(x => x.id === response.data.periodo.id)) {
                    periodos.push(response.data.periodo)
                }

                this.setState({
                    periodo: response.data.periodo,
                    periodos,
                    participantes: response.data.participantes,
                    avaliacoes: response.data.periodo.avaliacoes,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    carregarNota = (indexAvaliacao, utilizadorId) => {
        var avaliacoes = this.state.avaliacoes;
        if (avaliacoes[indexAvaliacao].participantes)
            var participante = avaliacoes[indexAvaliacao].participantes.find(x => x.id_utilizador === utilizadorId)
            if (participante)
                return participante.nota;

        return "-";
    }

    calcularTotalPeriodo = (utilizadorId) => {
        var total = 0;
        var avaliacoes = this.state.avaliacoes.filter(x => x.recuperacao === false);
        avaliacoes.forEach(avaliacao => {
            var subAvaliacoes = this.state.avaliacoes.filter(x => x.idPai === avaliacao.id);
            if (subAvaliacoes.length === 0) {
                var participante = avaliacao.participantes.find(x => x.id_utilizador === utilizadorId)
                if (participante)
                    total += parseFloat(participante.nota)
            }
        });

        var recuperacoes = this.state.avaliacoes.filter(x => x.recuperacao === true);
        var totalRecuperacoes = 0;
        recuperacoes.forEach(recuperacao => {
            var subAvaliacoes = this.state.avaliacoes.filter(x => x.idPai === recuperacao.id);
            if (subAvaliacoes.length === 0) {
                var participante = recuperacao.participantes.find(x => x.id_utilizador === utilizadorId)
                if (participante)
                    totalRecuperacoes += parseFloat(participante.nota)
            }
        });

        if (total < totalRecuperacoes)
            total = totalRecuperacoes

        return parseFloat(total.toFixed(2));
    }

    calcularTotalPeriodoFinal = (periodo, utilizadorId) => {
        var total = 0;
        var avaliacoes = periodo.avaliacoes.filter(x => x.recuperacao === false);
        avaliacoes.forEach(avaliacao => {
            var subAvaliacoes = periodo.avaliacoes.filter(x => x.idPai === avaliacao.id);
            if (subAvaliacoes.length === 0) {
                var participante = avaliacao.participantes.find(x => x.id_utilizador === utilizadorId)
                if (participante)
                    total += parseFloat(participante.nota)
            }
        });

        var recuperacoes = periodo.avaliacoes.filter(x => x.recuperacao === true);
        var totalRecuperacoes = 0;
        recuperacoes.forEach(recuperacao => {
            var subAvaliacoes = periodo.avaliacoes.filter(x => x.idPai === recuperacao.id);
            if (subAvaliacoes.length === 0) {
                var participante = recuperacao.participantes.find(x => x.id_utilizador === utilizadorId)
                if (participante)
                    totalRecuperacoes += parseFloat(participante.nota)
            }
        });

        if (total < totalRecuperacoes)
            total = totalRecuperacoes

        return parseFloat(total.toFixed(2));
    }

    anterior = () => {
        if (!this.state.finalVisivel)
            this.setState({
                num: this.state.num - 1,
                iconLoading: true
            }, () => this.listar())
        else
            this.setState({
                finalVisivel: false
            })
    }

    proximo = () => {
        if (this.state.num < this.state.total)
            this.setState({
                num: this.state.num + 1,
                iconLoading: true
            }, () => this.listar())
        else
            this.setState({
                finalVisivel: true
            })
    }

    calcularNotaGeral = (notas) => {
        let total = 0;
        notas.forEach(_nota => {
            total += parseFloat(_nota.nota)
            //total += (_nota.peso / 100) * _nota.nota
        })

        return parseFloat(total.toFixed(2));
    }

    calcularMediaAnual = (notas) => {
        let total = 0;
        notas.forEach(_nota => {
            total += parseFloat(_nota.nota)
        })

        return parseFloat((total / notas.length).toFixed(2));
    }

    montarFinal = (periodos, participante) => {
        let listaPeriodos = [];
        let notas = [];
        let aprovado = false;

        periodos.map((periodo, indexPeriodo) => {
            const nota = this.calcularTotalPeriodoFinal(periodo, participante.id);
            notas.push({
                nota,
                peso: periodo.peso
            })
            listaPeriodos.push(<div key={indexPeriodo} className="nota">
                <span className="titulo">{periodo.titulo}
                    {/*({periodo.peso} %)*/}
                </span>
                <span className="texto">{nota}</span>
            </div>);
        })

        const totalGeral = this.calcularNotaGeral(notas);
        const mediaAnual = this.calcularMediaAnual(notas);
        let mediaFinal = 0;
        let pontuacaoFinal = 0;
        if (this.props.formula === 1) {
            mediaFinal = participante.provaFinal ? ((parseFloat(participante.provaFinal) + mediaAnual) / 2) : mediaAnual;
            aprovado = mediaFinal >= 6;
        }
        else {
            mediaFinal = participante.provaFinal ? (((parseFloat(participante.provaFinal) * 3) + (mediaAnual * 4)) / 7) : mediaAnual;

            if (this.props.ano === "9º" && !this.props.eja || this.props.ano === "10º" && this.props.eja) {
                pontuacaoFinal = participante.segundaChamada ? ((mediaFinal + parseFloat(participante.segundaChamada)) / 2) : mediaFinal;
                aprovado = pontuacaoFinal >= 6;
            }
            else
                aprovado = mediaFinal >= 6;
        }

        const body = <>
            <div className="body">
                <div className="lista-notas">
                    {listaPeriodos.map(periodo => periodo)}
                </div>
                <div className="notas-resultado-final">
                    <div className="nota">
                        <span className="titulo">TOTAL GERAL</span>
                        <span className="texto">{totalGeral}</span>
                    </div>
                    <div className="nota">
                        <span className="titulo">MÉDIA ANUAL</span>
                        <span className="texto">{mediaAnual}</span>
                    </div>
                    <div className="nota">
                        <span className="titulo">PROVA FINAL</span>
                        <span className="texto">{participante.provaFinal}</span>
                    </div>
                    <div className="nota">
                        <span className="titulo">MÉDIA FINAL</span>
                        <span className="texto">{mediaFinal.toFixed(2)}</span>
                    </div>
                    {this.props.ano === "9º" && !this.props.eja || this.props.ano === "10º" && this.props.eja ?
                        <>
                            <div className="nota">
                                <span className="titulo">2ª CHAMADA</span>
                                <span className="texto">{participante.segundaChamada}</span>
                            </div>
                            <div className="nota">
                                <span className="titulo">PONTUAÇÃO FINAL</span>
                                <span className="texto">{pontuacaoFinal.toFixed(2)}</span>
                            </div>
                        </>
                        :
                        null}
                </div>
            </div>
            <div className="footer">
                {aprovado ?
                    <span className="notas-aprovado">Aprovado</span>
                    :
                    <span className="notas-reprovado">Reprovado</span>
                }
            </div>
        </>;

        return body;
    }

    montarAvaliacoes = (avaliacoes, utilizadorId) => {
        let html = avaliacoes.map((avaliacao, index) => {
            var subAvaliacoes = this.state.avaliacoes.filter(x => x.idPai === avaliacao.id)
            return (
                <>
                    <div key={index} className="nota">
                        <span className="titulo">{avaliacao.titulo}</span>
                        {subAvaliacoes.length === 0 &&
                            <span className="texto"><strong>{avaliacao.participantes.find(x => x.id_utilizador === utilizadorId)?.nota}</strong></span>}
                    </div>
                    {this.montarAvaliacoes(this.state.avaliacoes.filter(x => x.idPai === avaliacao.id), utilizadorId)}
                </>
            );
        })
        let estruturaHtml = avaliacoes.length ? <div className="lista-notas"> {html}</div> : null;
        return estruturaHtml;
    }

    render() {
        const {
            num,
            semPeriodos,
            //PERIODO
            periodo,
            periodos,
            participantes,
            //FINAL
            finalVisivel,
            //LOADING
            iconLoading
        } = this.state;

        return (
            <>
                <div>
                    <div className="bloco-controlos-frequencia">
                        {!semPeriodos ?
                            <div>
                                <Button className='botao-periodo botao-periodo-anterior' disabled={num === 1} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={this.anterior}>
                                    <Icon type="left" />
                                </Button>
                                <span>{!finalVisivel ? periodo.titulo : "Geral"}</span>
                                <Button className='botao-periodo botao-periodo-proximo' disabled={finalVisivel} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={this.proximo}>
                                    <Icon type="right" />
                                </Button>
                            </div>
                            : null}
                    </div>
                    <div className="bloco-cards">
                        {participantes.map((participante, index) => (
                            <div key={index} className="card">
                                <div className="header">
                                    <span className="titulo">{(('0' + (index + 1)).slice(-2))} - {participante.nome.split(" ").length > 1 ? `${participante.nome.split(" ")[0]} ${participante.nome.split(" ")[participante.nome.split(" ").length - 1]}` : participante.nome}</span>
                                </div>
                                {!finalVisivel ?
                                    <>
                                        <div className="body">
                                            {this.montarAvaliacoes(this.state.avaliacoes.filter(x => x.idPai === periodo.id), participante.id)}
                                            <div className="notas-resultado-final">
                                                <div className="nota">
                                                    <span className="titulo">NOTA BIMESTRAL</span>
                                                    <span className="texto">{this.calcularTotalPeriodo(participante.id)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer">
                                            {this.calcularTotalPeriodo(participante.id) >= 6 ?
                                                <span className="notas-aprovado">Aprovado</span>
                                                :
                                                <span className="notas-reprovado">Reprovado</span>}
                                        </div>
                                    </>
                                :
                                    this.montarFinal(periodos, participante)
                                }
                            </div>
                        ))}
                    </div>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default CardsNotas;