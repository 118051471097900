import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Switch, Table, Menu, Dropdown, Modal, notification } from "antd";
import DrawerInformacao from "./_Aux/DrawerInformacao";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class PaginaInformacoes extends Component {
    state = {
        informacoes: [],
        informacoesMobile: [],
        ativo: true,
        infoId: "",
        ordem: 1,
        //LOADING
        loading_table: false,
        //DRAWER
        visible: false
    };

    UNSAFE_componentWillMount() {
        this.listar();
    }

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA CATEGORIA NA TABELA
    montarMenu = (infoId, ativo) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.editar(infoId)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(infoId)}>
                        Excluir
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.ativar(infoId, ativo)}>
                        {ativo ? "Inativar" : "Ativar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    adicionar = () => {
        this.setState({
            infoId: "",
            visible: true
        })
    }

    editar = infoId => {
        this.setState({
            infoId,
            visible: true
        })
    }

    excluir = infoId => {
        confirm({
            title: "Pretende excluir este artigo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/empresa/excluir-pagina-artigo",
                    params: {
                        infoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Artigo excluido!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir este registo!");
                    });
            }
        });
    };

    ativar = (infoId, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar este artigo?" : "Pretende ativar este artigo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/empresa/ativar-pagina-artigo",
                    params: {
                        infoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Artigo inativado!" : "Artigo ativado!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar o artigo!" : "Não foi possível ativar o artigo!"
                        );
                    });
            }
        });
    };

    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/empresa/listar-pagina-artigos",
                    params: {
                        paginaId: this.props.paginaId,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var informacoes = [];
                        var informacoesMobile = [];

                        response.data.map((info, index) => {
                            informacoes.push({
                                key: index,
                                id: info.id,
                                info: info.titulo,
                                ordem: info.ordem,
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(info.id, info.ativo)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });
                            informacoesMobile.push({
                                key: index,
                                id: info.id,
                                info: <div className="bloco-pagina">
                                    <div className="info-pagina">
                                        <span className="titulo-pagina">{info.titulo}</span>
                                        <span className="ordem-pagina"><strong>Ordem:</strong> {info.ordem}</span>
                                    </div>
                                </div>,
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(info.id, info.ativo)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });
                        });

                        this.setState({
                            informacoes,
                            informacoesMobile,
                            ordem: (response.data.length + 1),
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    atualizarRegisto = () => {
        this.setState({
            visible: false
        })
        this.listar();
    }

    render() {
        const {
            informacoes,
            informacoesMobile,
            infoId,
            ordem,
            //LOADING
            loading_table,
            //DRAWER
            visible
        } = this.state;

        const columns = [
            {
                title: "Artigo",
                dataIndex: "info"
            },
            {
                title: "Ordem",
                dataIndex: "ordem",
                width: 100
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const columnsMobile = [
            {
                title: "Artigo",
                dataIndex: "info"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        return (
            <div className="bloco-minha-conta">
                <div className="bloco-gestor-paginas">
                    <h3 className="titulo-separador" style={{ marginBottom: 20 }}>Artigos</h3>
                    <div className="controlos controlos-pagina-informacoes">
                        <div>
                            <button className="botao-principal" onClick={this.adicionar}>
                                Adicionar
                            </button>
                            <button className="botao-secundario" style={{ marginLeft: 10 }} onClick={this.props.voltar}>
                                Voltar
                            </button>
                        </div>
                        <Switch
                            checkedChildren="Ativos"
                            unCheckedChildren="Inativos"
                            defaultChecked
                            onChange={this.handleChangeSwitch}
                        />
                    </div>
                    <div className="bloco-tabela">
                        <Table
                            id="tabela_categorias"
                            className="tabela-paginas-desktop"
                            loading={{
                                spinning: loading_table,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                            columns={columns}
                            dataSource={informacoes}
                            pagination={false}
                            locale={{ emptyText: "Não existem registos!" }}
                        />
                        <Table
                            id="tabela_categorias"
                            className="tabela-paginas-mobile"
                            loading={{
                                spinning: loading_table,
                                indicator: (
                                    <div className="loading-data-table">
                                        <div className="loading" />
                                    </div>
                                )
                            }}
                            columns={columnsMobile}
                            dataSource={informacoesMobile}
                            pagination={false}
                            locale={{ emptyText: "Não existem registos!" }}
                        />
                    </div>
                </div>
                <DrawerInformacao
                    infoId={infoId}
                    ordem={ordem}
                    paginaId={this.props.paginaId}
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    atualizarRegisto={this.atualizarRegisto}
                />
            </div>
        );
    }
}

export default PaginaInformacoes;
