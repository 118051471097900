import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, notification, Dropdown, Switch, Menu, Modal, Select, Drawer, Form } from "antd";
import Header from "../../User/Header";
import noimage from "../../images/noimage.png";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";
import { GlobalContext } from "../../GlobalState";
import DrawerCompetencia from "./_Aux/DrawerCompetencia";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Competencias extends Component {
    static contextType = GlobalContext;
    state = {
        competencias: [],
        competenciasMobile: [],
        ativo: true,
        //DRAWER
        competenciaId: 0,
        visible: false,
        iconLoading: false,
        //FILTROS
        pesquisa: "",
        categoria: undefined,
        categorias: [],
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER PROXIMAS
        visibleDetalhe: false,
        detalheCategoria: "",
        detalheTipos: "",
        detalheGerais: "",
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
        this.listarCategorias();
    }

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    componentDidUpdate() {
        if (this.context.role === "UTILIZADOR")
            this.setState({
                redirect: true,
                redirectLink: "/user"
            });
    }

    //MONTA AS OPÇÕES DE MENU POR CADA COMPETENCIA NA TABELA
    montarMenu = (competenciaId, ativo) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ competenciaId, visible: true })}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(competenciaId)}>
                        Excluir
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.ativar(competenciaId, ativo)}>
                        {ativo ? "Inativar" : "Ativar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuMobile = (competenciaId, ativo, categorias, tipos, gerais) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleDetalhe: true, detalheCategoria: categorias, detalheTipos: tipos, detalheGerais: gerais })}>
                        Detalhe
                        </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ competenciaId, visible: true })}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(competenciaId)}>
                        Excluir
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.ativar(competenciaId, ativo)}>
                        {ativo ? "Inativar" : "Ativar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    monterCoresBCNN = gerais => {
        let _gerais = gerais.map((geral, index) => {
            switch (geral.split(".")[0]) {
                case "1":
                    return <span key={index} className="bcnn-competencia competencia-1">{geral}</span>
                    break;
                case "2":
                    return <span key={index} className="bcnn-competencia competencia-2">{geral}</span>
                    break;
                case "3":
                    return <span key={index} className="bcnn-competencia competencia-3">{geral}</span>
                    break;
                case "4":
                    return <span key={index} className="bcnn-competencia competencia-4">{geral}</span>
                    break;
                case "5":
                    return <span key={index} className="bcnn-competencia competencia-5">{geral}</span>
                    break;
                case "6":
                    return <span key={index} className="bcnn-competencia competencia-6">{geral}</span>
                    break;
                case "7":
                    return <span key={index} className="bcnn-competencia competencia-7">{geral}</span>
                    break;
                case "8":
                    return <span key={index} className="bcnn-competencia competencia-8">{geral}</span>
                    break;
                case "9":
                    return <span key={index} className="bcnn-competencia competencia-9">{geral}</span>
                    break;
                case "10":
                    return <span key={index} className="bcnn-competencia competencia-10">{geral}</span>
                    break;
            }
        })

        return _gerais;
    }

    //CARREGA TODAS AS COMPETENCIAS DA ENTIDADE PARA A TABELA
    listar = () => {
        
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-competencias",
                    params: {
                        pesquisa: this.state.pesquisa,
                        categoria: this.state.categoria,
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var competencias = [];
                        var competenciasMobile = [];

                        response.data.map((competencia, index) => {
                            competencias.push({
                                key: index,
                                id: competencia.id,
                                categoria: (
                                    <div className="bloco-categoria">
                                        <div className="icon-categoria">
                                            <img src={competencia.icon ? competencia.icon : noimage} />
                                        </div>
                                        <div className="nome-categoria">
                                            <span className="titulo-curso">{competencia.categoria}</span>
                                        </div>
                                    </div>
                                ),
                                nome: competencia.nome,
                                tipos: JSON.parse(competencia.tipos).join(", "),
                                gerais: this.monterCoresBCNN(JSON.parse(competencia.competencias_gerais)),
                                descricao: competencia.descricao ? (
                                    <LinesEllipsis text={competencia.descricao} maxLine="1" ellipsis="..." trimRight basedOn="letters" />
                                ) : null,
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(competencia.id, competencia.ativo)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });

                            var categorias = (
                                <div className="bloco-categoria">
                                    <div className="icon-categoria">
                                        <img src={competencia.icon ? competencia.icon : noimage} />
                                    </div>
                                    <div className="nome-categoria">
                                        <span className="titulo-curso">{competencia.categoria}</span>
                                    </div>
                                </div>
                            );

                            var tipos = JSON.parse(competencia.tipos).join(", ");

                            var gerais = this.monterCoresBCNN(JSON.parse(competencia.competencias_gerais));

                            competenciasMobile.push({
                                key: index,
                                id: competencia.id,
                                nome: competencia.nome,
                                opcoes: (
                                    <Dropdown overlay={this.montarMenuMobile(competencia.id, competencia.ativo, categorias, tipos, gerais)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });
                        });

                        this.setState({
                            competencias,
                            competenciasMobile,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //CARREGA A LISTA DE CATEGORIAS EXISTENTES NA ENTIDADE
    listarCategorias = () => {
        axios({
            method: "get",
            url: "/api/gestao-competencias/lista-categorias"
        })
            .then(response => {
                this.setState({
                    categorias: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //EXCLUI A COMPETENCIA
    excluir = competenciaId => {
        confirm({
            title: "Pretende excluir esta competência?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-competencias/excluir",
                    params: {
                        competenciaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Competência excluida!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir este registo!");
                    });
            }
        });
    };

    //ATIVA E INATIVA A COMPETENCIA
    ativar = (competenciaId, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar esta competência?" : "Pretende ativar esta competência?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-competencias/ativar",
                    params: {
                        competenciaId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Competência inativada!" : "Competência ativada!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a competência!" : "Não foi possível ativar a competência!"
                        );
                    });
            }
        });
    };

    //PROCURA ENTIDADES
    HandleKeyPress = e => {
        var pesquisa = e.target.value;
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa
                },
                () => {
                    this.listar();
                }
            );
        }
    };

    //LIMPA A PESQUISA APOR LIMPAR A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: ""
            },
            () => {
                this.listar();
            }
        );
    };

    render() {
        const {
            competencias,
            competenciasMobile,
            competenciaId,
            visible,
            //FILTROS
            categoria,
            categorias,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER PROXIMAS
            visibleDetalhe,
            detalheCategoria,
            detalheTipos,
            detalheGerais
        } = this.state;

        const columns = [
            {
                title: "Categoria",
                dataIndex: "categoria"
            },
            {
                title: "Nome",
                dataIndex: "nome"
            },
            {
                title: "Tipos",
                dataIndex: "tipos"
            },
            {
                title: "Gerais",
                dataIndex: "gerais"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        const columnsMobile = [
            {
                title: "Nome",
                dataIndex: "nome"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Competências" />
                <div className="container container-body">
                    <div className="bloco-competencias">
                        <div className="bloco-tabela">
                            <div className="filtros" style={{ justifyContent: "space-between" }}>
                                <div className="bloco-esquerda">
                                    <BotaoProcurar
                                        tem_placeholder
                                        placeholder="Procurar por Nome"
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                    />
                                    <Select
                                        value={categoria}
                                        className="filtro-categorias"
                                        onChange={categoria => this.setState({ categoria }, () => this.listar())}
                                        placeholder="Selecionar Categoria"
                                        disabled={!categorias.length}
                                        allowClear={true}
                                    >
                                        {categorias.map(categoria => (
                                            <Option value={categoria.id} key={categoria.id}>{categoria.nome}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <Table
                                id="tabela_competencias"
                                className="tabela-competencias-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={competencias}
                                pagination={false}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                id="tabela_competencias"
                                className="tabela-competencias-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsMobile}
                                dataSource={competenciasMobile}
                                pagination={false}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="opcoes-meus-cursos">
                    <Link to="#" className="btn-adicionar" title="Adicionar" onClick={() => this.setState({ competenciaId: 0, visible: true })}>
                        +
                    </Link>
                </div>
                <DrawerCompetencia
                    competenciaId={competenciaId}
                    visible={visible}
                    categorias={categorias}
                    onClose={() => this.setState({ visible: false })}
                    atualizar={() => { this.setState({ visible: false }); this.listar(); }}
                />
                <Drawer
                    className="drawer-detalhe-competencias"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Categoria">
                                    {detalheCategoria}
                                </Form.Item>
                                <Form.Item label="Tipos">
                                    {detalheTipos}
                                </Form.Item>
                                <Form.Item label="Gerais">
                                    {detalheGerais}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </div>
        );
    }
}
export default Competencias;
