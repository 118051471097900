import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Upload, Tooltip, notification, Menu, Dropdown, Icon, Drawer, Form, Input, Select, Radio, Modal } from "antd";
import DrawerHTML from "../../Cursos/_Aux/FormPaginaHTML";
import axios from "axios";
import moment from "moment";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Ficheiros extends Component {
    state = {
        documentos: [],
        //DRAWER
        visible: false,
        documentoId: 0,
        nome: "",
        tipo: "UPLOAD",
        fileList: [],
        link: "",
        texto: "",
        //LOADING
        iconLoading: false,
        //DRAWER HTML
        visibleHTML: false
    };

    UNSAFE_componentWillMount() {
        this.listarFicheiros();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    //handleChangeEditor = texto => {
    //    this.setState({
    //        texto
    //    });
    //};

    handlerChangeRadio = event => {
        this.setState({
            [event.target.name]: event.target.value,
            fileList: [],
            link: "",
            texto: ""
        });
    };

    listarFicheiros = () => {
        axios({
            method: "get",
            url: "/api/player-trabalho/listar-fichs",
            params: {
                aulaId: localStorage.getItem("codigo_aula")
            }
        })
            .then(response => {
                var documentos = [];

                response.data.map(documento => {
                    documentos.push({
                        id: documento.id,
                        tipo:
                            !documento.link && !documento.editor
                                ? documento.ficheiro_nome.split(".").pop()
                                : documento.link
                                    ? "LINK"
                                    : "EDITOR",
                        nome: documento.ficheiro_nome,
                        data: documento.dt_alterado
                            ? moment(documento.dt_alterado).format("DD/MM/YYYY")
                            : moment(documento.dt_criado).format("DD/MM/YYYY"),
                        ficheiro: documento.ficheiro,
                        link: documento.link,
                        editor: documento.editor
                    });
                });

                this.setState({
                    documentos
                });
            })
            .catch(error => { });
    };

    //ADICIONAR FICHEIROS
    adicionarFicheiros = () => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            documentoId: 0,
            nome: "",
            tipo: "UPLOAD",
            fileList: [],
            link: "",
            texto: "",
            privado: true
        });
    };

    guardarFicheiro = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                if (this.state.tipo === "UPLOAD") {
                    item.append("ficheiro_nome", this.state.nome + "." + this.state.fileList[0].name.split(".").pop());
                    item.append("ficheiro", this.state.fileList[0]);
                } else if (this.state.tipo === "LINK") {
                    item.append("ficheiro_nome", this.state.nome);
                    item.append("ficheiro", this.state.link);
                    item.append("link", true);
                } else {
                    item.append("ficheiro_nome", this.state.nome);
                    item.append("ficheiro", this.state.texto);
                    item.append("editor", true);
                }

                axios({
                    method: "post",
                    url: "/api/player-trabalho/adicionar-ficheiro",
                    data: item,
                    params: {
                        aulaid: localStorage.getItem("codigo_aula"),
                        participantes: JSON.stringify(this.props.participantes),
                        tipo: this.props.tipo
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Registo adicionado!");
                        this.listarFicheiros();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    alterarFicheiro = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.documentoId);
                if (this.state.tipo === "UPLOAD") {
                    item.append("ficheiro_nome", this.state.nome + "." + this.state.fileList[0].name.split(".").pop());
                    item.append("ficheiro", this.state.fileList[0]);
                } else if (this.state.tipo === "LINK") {
                    item.append("ficheiro_nome", this.state.nome);
                    item.append("ficheiro", this.state.link);
                    item.append("link", true);
                } else {
                    item.append("ficheiro_nome", this.state.nome);
                    item.append("ficheiro", this.state.texto);
                    item.append("editor", true);
                }

                axios({
                    method: "put",
                    url: "/api/player-trabalho/alterar-ficheiro",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Ficheiro alterado!");
                        this.listarFicheiros();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    alterar = documento => {
        this.props.form.resetFields();
        if (!documento.link && !documento.editor) {
            this.setState({
                documentoId: documento.id,
                nome: documento.nome.replace("." + documento.nome.split(".").pop(), ""),
                tipo: "UPLOAD",
                fileList: [
                    {
                        uid: "-1",
                        name: documento.nome,
                        status: "done",
                        url: documento.link
                    }
                ],
                privado: documento.privado,
                visible: true
            });
        } else if (documento.link) {
            this.setState({
                documentoId: documento.id,
                nome: documento.nome,
                tipo: "LINK",
                link: documento.ficheiro,
                privado: documento.privado,
                visible: true
            });
        } else {
            this.setState({
                documentoId: documento.id,
                nome: documento.nome,
                tipo: "EDITOR",
                texto: documento.ficheiro,
                privado: documento.privado,
                visible: true
            });
        }
    };

    excluir = documentoId => {
        confirm({
            title: "Pretende excluir este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/player-trabalho/excluir-ficheiro",
                    params: {
                        documentoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Registo excluido!");
                        var documentos = this.state.documentos.filter(x => x.id !== documentoId);
                        this.setState({
                            documentos
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir este registo!");
                    });
            }
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = formato => {
        var formatos = [
            "zip",
            "rar",
            "7z",
            "docx",
            "doc",
            "pdf",
            "odf",
            "txt",
            "rtf",
            "xlsx",
            "xls",
            "csv",
            "ods",
            "pptx",
            "ppt",
            "odp",
            "bmp",
            "gif",
            "png",
            "jpeg",
            "jpg"
        ];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    validar = (rule, value, callback) => {
        if (value.file)
            if (!this.validarFormatoFicheiro(value.file.name.split(".").pop().toLowerCase()) && value.file.status !== "removed")
                callback("Ficheiro com formato inválido");
            else if (value.file.status === "removed") callback("Campo obrigatório");

        callback();
    };

    //DRAWER
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    onCloseDrawerHTML = () => {
        this.setState(
            {
                texto: localStorage.getItem("editor_conteudo") ? localStorage.getItem("editor_conteudo") : "",
                visibleHTML: false
            },
            () => localStorage.removeItem("editor_conteudo")
        );
    };

    downloadFicheiro = (documentoId) => {
        axios({
            method: "post",
            url: "/api/player-trabalho/download-ficheiro",
            params: {
                documentoId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const Dragger = Upload.Dragger;
        const {
            documentos,
            //DRAWER
            documentoId,
            nome,
            tipo,
            fileList,
            link,
            texto,
            //LOADING
            iconLoading,
            //DRAWER HTML
            visibleHTML
        } = this.state;

        const opcoes = documento => {
            if (this.props.disponivel)
                return (
                    <Menu>
                        {!documento.link && !documento.editor && (
                            <Menu.Item>
                                <Link to="#" onClick={() => this.downloadFicheiro(documento.id)}>
                                    Descarregar
                                </Link>
                            </Menu.Item>
                        )}
                        {documento.link && (
                            <Menu.Item>
                                <a href={documento.ficheiro} target="_blank" rel="noopener noreferrer">
                                    Acessar
                            </a>
                            </Menu.Item>
                        )}
                        {documento.editor && (
                            <Menu.Item>
                                <Link
                                    to={`/curso/${this.props.match.params.curso
                                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                        .toLowerCase()}/${documento.nome
                                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                            .toLowerCase()}/player-trabalho-ficheiro`}
                                    onClick={() => localStorage.setItem("codigo_documento", documento.id)}
                                >
                                    Acessar
                            </Link>
                            </Menu.Item>
                        )}
                        <Menu.Item>
                            <Link to="#" onClick={() => this.alterar(documento)}>
                                Editar
                        </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to="#" onClick={() => this.excluir(documento.id)}>
                                Excluir
                        </Link>
                        </Menu.Item>
                    </Menu>
                );
            else
                return (
                    <Menu>
                        {!documento.link && !documento.editor && (
                            <Menu.Item>
                                <Link to="#" onClick={() => this.downloadFicheiro(documento.id)}>
                                    Descarregar
                                </Link>
                            </Menu.Item>
                        )}
                        {documento.link && (
                            <Menu.Item>
                                <a href={documento.ficheiro} target="_blank" rel="noopener noreferrer">
                                    Acessar
                            </a>
                            </Menu.Item>
                        )}
                        {documento.editor && (
                            <Menu.Item>
                                <Link
                                    to={`/curso/${this.props.match.params.curso
                                        .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                        .toLowerCase()}/${documento.nome
                                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                            .toLowerCase()}/player-trabalho-ficheiro`}
                                    onClick={() => localStorage.setItem("codigo_documento", documento.id)}
                                >
                                    Acessar
                            </Link>
                            </Menu.Item>
                        )}
                    </Menu>
                );
        };

        const props = {
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("ficheiro");
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);

                    return {
                        fileList: newFileList
                    };
                });
            },
            defaultFileList: this.state.fileList,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }));

                return false;
            },
            fileList
        };

        const montar_tipo = tipo => {
            if (tipo === "UPLOAD") {
                return (
                    <Form.Item label="Ficheiro">
                        <div className="anexos">
                            {getFieldDecorator("ficheiro", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    },
                                    {
                                        validator: this.validar
                                    }
                                ],
                                initialValue: fileList
                            })(
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <i className="fas fa-upload" />
                                    </p>
                                    <p className="ant-upload-text">
                                        Adicionar anexo{" "}
                                        <Tooltip title="Formatos válidos: .zip, .rar, .7z, .docx, .doc, .pdf, .odf, .txt, .rtf, .xlsx, .xls, .csv, .ods, .pptx, .ppt, .odp, .bmp, .gif, .png, .jpeg, .jpg">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </p>
                                    <p className="ant-upload-hint">Limite de 25MB por upload</p>
                                </Dragger>
                            )}
                        </div>
                    </Form.Item>
                );
            } else if (tipo === "LINK") {
                return (
                    <Form.Item label="Link">
                        {getFieldDecorator("link", {
                            rules: [
                                {
                                    required: true,
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: link
                        })(<Input name="link" onChange={this.handleChange} />)}
                    </Form.Item>
                );
            } else {
                return (
                    <Form.Item>
                        {getFieldDecorator("texto", {
                            rules: [
                                {
                                    required: this.state.texto === "",
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: this.state.texto
                        })(
                            <>
                                <h3 className="titulo-separador">Editor Web</h3>
                                {this.state.texto ? (
                                    <Link
                                        to="#"
                                        className="link-principal"
                                        style={{ paddingLeft: 0 }}
                                        onClick={() => this.setState({ visibleHTML: true })}
                                    >
                                        Editar
                                    </Link>
                                ) : (
                                        <Link
                                            to="#"
                                            className="link-principal"
                                            style={{ paddingLeft: 0 }}
                                            onClick={() => this.setState({ visibleHTML: true })}
                                        >
                                            Adicionar
                                        </Link>
                                    )}
                            </>
                        )}
                    </Form.Item>
                );
            }
        };

        const montar_icon = tipo => {
            if (tipo === "LINK") {
                return (
                    <div className="imagem-container" title="Link">
                        <img
                            src={require("../../images/icons/link.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "EDITOR") {
                return (
                    <div className="imagem-container" title="Html">
                        <img
                            src={require("../../images/icons/html.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "pdf") {
                return (
                    <div className="imagem-container" title="Pdf">
                        <img
                            src={require("../../images/icons/pdf.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "zip" || tipo === "rar" || tipo === "7z") {
                return (
                    <div className="imagem-container" title="Zip">
                        <img
                            src={require("../../images/icons/compress.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "docx" || tipo === "doc" || tipo === "odf") {
                return (
                    <div className="imagem-container" title="Word">
                        <img
                            src={require("../../images/icons/word.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "txt" || tipo === "rtf") {
                return (
                    <div className="imagem-container" title="Texto">
                        <img
                            src={require("../../images/icons/text.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "xlsx" || tipo === "xls" || tipo === "csv" || tipo === "ods") {
                return (
                    <div className="imagem-container" title="Excel">
                        <img
                            src={require("../../images/icons/excel.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else if (tipo === "pptx" || tipo === "ppt" || tipo === "odp") {
                return (
                    <div className="imagem-container" title="Power Point">
                        <img
                            src={require("../../images/icons/powerpoint.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            } else {
                return (
                    <div className="imagem-container" title="Imagem" style={{ width: 30, height: 30 }}>
                        <img
                            src={require("../../images/icons/images.png")}
                            style={{ width: "100%", objectFit: "contain", borderRadius: "50%" }}
                        />
                    </div>
                );
            }
        };

        return (
            <>
                <div className="curso-ficheiros">
                    {this.props.disponivel &&
                        <div className="controlos">
                            <button className="botao-principal" onClick={this.adicionarFicheiros}>
                                Novo
                        </button>
                        </div>}
                    <div className="box-ficheiros box-ficheiros-desktop">
                        <div className="header">
                            <span className="desc"></span>
                            <span className="desc">Nome</span>
                            <span className="desc">Modificado</span>
                            <span className="desc"></span>
                            <span className="desc"></span>
                            <span className="desc"></span>
                            <span className="desc"></span>
                        </div>
                        {documentos.length > 0 ? (
                            documentos.map((documento, index) => (
                                <div key={index} className="content">
                                    <span className="desc">{montar_icon(documento.tipo)}</span>
                                    <span className="desc">{documento.nome}</span>
                                    <span className="desc">{documento.data}</span>
                                    <span className="desc"></span>
                                    <span className="desc"></span>
                                    <span className="desc box-ficheiros-opcoes">
                                        <Dropdown overlay={opcoes(documento)} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    </span>
                                </div>
                            ))
                        ) : (
                                <div className="sem-registos">Não existem registos!</div>
                            )}
                    </div>
                    <div className="box-ficheiros box-ficheiros-mobile">
                        <div className="header">
                            <span className="desc">Nome</span>
                            <span className="desc"></span>
                        </div>
                        {documentos.length > 0 ? (
                            documentos.map((documento, index) => (
                                <div key={index} className="content">
                                    <span className="desc">
                                        <div className="box-ficheiro">
                                            {montar_icon(documento.tipo)}
                                            <div className="info-ficheiro">
                                                <span className="nome-ficheiro">
                                                    {documento.nome}
                                                </span>
                                                <div className="data-ficheiro">
                                                    {documento.data}
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    <span className="desc box-ficheiros-opcoes">
                                        <Dropdown overlay={opcoes(documento)} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    </span>
                                </div>
                            ))
                        ) : (
                                <div className="sem-registos">Não existem registos!</div>
                            )}
                    </div>
                </div>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias"
                    title={documentoId > 0 ? "Editar" : "Adicionar"}
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" onChange={this.handleChange} />)}
                            </Form.Item>
                            {documentoId === 0 && (
                                <Form.Item className="form-item-checkbox">
                                    <Radio.Group name="tipo" onChange={this.handlerChangeRadio} value={tipo}>
                                        <Radio value="UPLOAD">Upload ficheiro</Radio>
                                        <Radio value="LINK">Link</Radio>
                                        <Radio value="EDITOR">Editor</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )}
                            {montar_tipo(tipo)}
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {documentoId > 0 ? (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.alterarFicheiro}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                                <button className="botao-principal" disabled={iconLoading} onClick={this.guardarFicheiro}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                                </button>
                            )}
                    </div>
                </Drawer>
                <DrawerHTML visible={visibleHTML} recurso={texto} onClose={this.onCloseDrawerHTML} />
            </>
        );
    }
}

const FicheirosForm = Form.create({ name: "form-ficheiro" })(Ficheiros);

export default withRouter(FicheirosForm);
