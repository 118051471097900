import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Form, Select, notification, Tooltip, Icon, Upload, Progress, Modal } from "antd";
import axios from "axios";
import { Upload as UploadVimeo } from "tus-js-client"
import { GlobalContext } from "../../../GlobalState"

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514"

const headerPost = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessToken}`,
    "Content-Type": "application/json"
}

class FormUploadVideo extends Component {
    static contextType = GlobalContext
    state = {
        video: [],
        //LISTA
        videos: [],
        //PROGRESS
        upload_progress: 0,
        //LOADING
        loading: false,
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
    };

    criarVideoVimeo = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true })

                const file = this.state.video[0]
                const fileName = file.name
                const fileSize = file.size.toString()

                axios({
                    method: "post",
                    url: `https://api.vimeo.com/me/videos`,
                    transformRequest: [function (data, headers) {
                        delete headers.common['apikey'];

                        return JSON.stringify(data);
                    }],
                    headers: headerPost,
                    data: {
                        upload: {
                            status: "in_progress",
                            approach: "tus",
                            size: fileSize
                        },
                        name: this.props.meetingName,
                        privacy: { view: "disable" }
                    }
                }).then(response => {
                    this.uploadVimeo(file, response)
                })
            }
        })
    }

    uploadVimeo = (file, response) => {
        const upload = new UploadVimeo(file, {
            endPoint: "https://api.vimeo.com/me/videos",
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            headers: {},
            onError: error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1)

                this.setState({
                    upload_progress: percentage
                })
            },
            onSuccess: () => {
                var videoId = response.data.link.split("/").pop()
                this.moverParaPasta(videoId)
                this.adicionarDominioEPreset(videoId)
                this.criarVideo(videoId)
            }
        })

        upload.start()
    }

    moverParaPasta = videoId => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/me/projects/${this.context.albumId_entidade}/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    adicionarDominioEPreset = videoId => {
        axios({
            method: "get",
            url: "/api/curso/carregar-vimeo-configs",
        }).then(response => {
            this.adicionarPreset(videoId, response.data.presetId);
            this.adicionarDominio(videoId, response.data.dominio);
        });
    };

    adicionarPreset = (videoId, presetId) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/presets/${presetId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    adicionarDominio = (videoId, dominio) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/privacy/domains/${dominio}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    criarVideo = videoId => {
        var item = new FormData()
        item.append("videoConfId", this.props.videoConfId)
        item.append("video", videoId)

        axios({
            method: "post",
            url: "/api/gestao-videoconferencia/adicionar-video",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Novo video adicionado!")

                this.setState({ iconLoading: false })
                this.props.form.resetFields()
                //this.props.atualizarRealizadas(response.data, this.props.meetingName, this.props.videoConfId);
                this.props.atualizarListagemVisivel();
                this.props.onClose();
            })
            .catch(error => {
                this.setState({ iconLoading: false })
                openNotificationWithIcon("error", "Erro", "Não foi possível criar tarefa!")
                this.removerVideoVimeoErro(videoId);
            })
    }

    carregarVideos = () => {
        this.setState({
            loading: true
        })
        axios({
            method: "get",
            url: "/api/gestao-videoconferencia/carregar-videos",
            params: {
                videoConfId: this.props.videoConfId
            }
        })
            .then(response => {
                this.setState({
                    videos: response.data.length ? response.data : [],
                    loading: false
                })
            })
            .catch(() => {
                this.setState({ loading: false })
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar os videos!")
            })
    }

    removerVideoVimeoErro = videoId => {
        axios({
            method: "delete",
            url: `https://api.vimeo.com/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    validar = (rule, value, callback) => {
        if (value.file)
            if (!this.validarFormatos(value.file.name.split(".").pop().toLowerCase(), ["mp4", "mov", "wmv", "avi", "flv"]) || value.file.size > 5368709120)
                callback("Campo obrigatório")

        callback()
    }

    validarFormatos = (formato, formatos_aceites) => {
        var valido = false

        for (var i = 0; i < formatos_aceites.length; i++) {
            if (formato === formatos_aceites[i]) valido = true
        }

        return valido
    }

    downloadVideo = sessaoVimeoID => {
        axios({
            method: "get",
            url: `https://api.vimeo.com/me/videos/${sessaoVimeoID}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        }).then(response => {
            response.data.download.forEach(download => {
                if (response.data.download) {
                    var element = document.createElement('a');

                    if (response.data.download.find(x => x.quality === "source"))
                        element.setAttribute('href', response.data.download.find(x => x.quality === "source").link);
                    else if (response.data.download.find(x => x.quality === "hd"))
                        element.setAttribute('href', response.data.download.find(x => x.quality === "hd").link);
                    else
                        element.setAttribute('href', response.data.download.find(x => x.quality === "sd").link);

                    element.style.display = 'none';

                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                }
            })
        })
    }

    excluirVideo = (video) => {
        confirm({
            title: "Pretende excluir este video?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-videoconferencia/excluir-video",
                    params: {
                        video,
                        videoConfId: this.props.videoConfId
                    }
                })
                    .then(response => {
                        this.setState({
                            videos: response.data ? response.data : []
                        }, () => this.excluirVideoVimeo(video))
                        this.props.atualizarListagemVisivel();
                    })
                    .catch(() => {
                        this.setState({ iconLoading: false })
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o video!")
                    })
            }
        });
    }

    excluirVideoVimeo = (videoId) => {
        axios({
            method: "delete",
            url: `https://api.vimeo.com/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    iniciarVideo = id => {
        this.setState({
            redirect: true,
            redirectLink: `/gestao-videoconferencia/${id}/player-video`
        });
    };

    afterVisibleChange = aberto => {
        this.props.form.resetFields();
        this.setState({
            video: [],
            //LISTA
            videos: [],
            //PROGRESS
            upload_progress: 0,
            //LOADING
            loading: false,
            iconLoading: false,
        }, () => this.carregarVideos())
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const Dragger = Upload.Dragger;

        const {
            video,
            //LISTA
            videos,
            //LOADING
            loading,
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const propsVideo = {
            accept: ".mp4, .mov, .wmv, .avi, .flv",
            multiple: false,
            onRemove: file => {
                this.props.form.resetFields("video")
                this.setState({
                    video: []
                })
            },
            defaultFileList: this.state.video,
            beforeUpload: file => {
                if (!this.validarFormatos(file.name.split(".").pop().toLowerCase(), ["mp4", "mov", "wmv", "avi", "flv"])) {
                    openNotificationWithIcon("error", "Erro", "Ficheiro com formato inválido")
                    return false;
                }

                if (file.size > 5368709120) {
                    openNotificationWithIcon("error", "Erro", "Limite de 5 GB por upload")
                    return false;
                }

                this.setState(state => ({
                    video: [file]
                }))

                return false
            },
            video
        }

        return (
            <>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias"
                    title="Carregar video"
                    width={720}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <div className="bloco">
                            <Form className="form-categorias" layout="horizontal">
                                <Form.Item label="Video (MP4, MOV, WMV, AVI, FLV)">
                                    {getFieldDecorator("video", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            },
                                            {
                                                validator: this.validar
                                            }
                                        ],
                                        initialValue: this.state.video
                                    })(
                                        <Dragger {...propsVideo} fileList={this.state.video}>
                                            <p className="ant-upload-drag-icon">
                                                <i className="fas fa-upload" />
                                            </p>
                                            <p className="ant-upload-text">
                                                Adicionar anexo{" "}
                                                <Tooltip title="Formatos válidos: .mp4, .mov, .wmv, .avi, .flv">
                                                    <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </p>
                                            <p className="ant-upload-hint">Limite de 5GB por upload</p>
                                        </Dragger>
                                    )}
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador">Videos</h3>
                            {loading ?
                                <div className="loading-data">
                                <div className="loading" />
                            </div>
                            :
                            <div className="lista-videos">
                                {!videos.length ? <span>Sem registos</span> : null}
                                {videos.map((video, index) => (
                                    <div key={index} className="item-video">
                                        <span className="video-titulo">Video {index + 1}</span>
                                        <div className="video-controlos">
                                            <Link to="#" className="botao-icon-camera" title="Descarregar" onClick={() => this.iniciarVideo(video)}>
                                                <Icon type="video-camera" />
                                            </Link>
                                            <Link to="#" className="botao-icon-download" title="Descarregar" onClick={() => this.downloadVideo(video)}>
                                                <Icon type="download" />
                                            </Link>
                                            <Link to="#" className="botao-icon-excluir" title="Excluir" onClick={() => this.excluirVideo(video)}>
                                                <Icon type="delete" />
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            }
                        </div>
                    </div>
                    <div className="ant-drawer-footer">
                        <button
                            className="botao-secundario"
                            onClick={this.props.onClose}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                    </button>
                        <button className="botao-principal" disabled={iconLoading} onClick={this.criarVideoVimeo}>
                            {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                    </div>
                </Drawer>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Progress type="circle" percent={Number(this.state.upload_progress)} />
                        </p>
                        <p className="texto">A enviar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}
const FormFormUploadVideo = Form.create({ name: "form-upload-video" })(FormUploadVideo);

export default FormFormUploadVideo;
