import React, { createContext, Component } from "react";
import { notification } from "antd";
import screenfull from "screenfull";
import moment from "moment";
import axios from "axios";

export const GlobalContext = createContext();

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class GlobalState extends Component {
    state = {
        temNavbar: true,
        //COMANDOS
        tem_comandos: false,
        formador_curso: false,
        //AUTENTICACAO
        is_authenticated: true,
        is_authenticated_nav: false,
        role: "",
        //PLAYERS
        modulo: "",
        moduloIndex: 0,
        aula: "",
        aulaIndex: 0,
        updateModulos: false,
        fullscreen: false,
        modulosOk: false,
        forumOk: false,
        anotacoesOk: false,
        concluidoOk: false,
        //NAVBAR
        totalConversasPorLer: 0,
        totalEventosHoje: 0,
        atualizarDadosGerias: false,
        atualizarEntidade: false,
        atualizarEntidadeFooter: false,
        //DADOS UTILIZADOR
        id_utilizador: "",
        foto_utilizador: "",
        avatar_utilizador: "",
        nome_utilizador: "",
        sexo_utilizador: "",
        nr_utilizador: "",
        idrole_utilizador: "",
        dt_adesao_utilizador: "",
        //ENTIDADE
        logo_entidade: "",
        nome_entidade: "",
        url_entidade: "",
        site_entidade: "",
        favicon_entidade: "",
        title_entidade: "",
        formacao_entidade: false,
        projeto_entidade: false,
        organizacional_entidade: false,
        responsaveis_entidade: false,
        escolas_entidade: false,
        visivel_entidade: false,
        albumId_entidade: "",
        chave_entidade: "",
        login2_entidade: false,
        autoconta_entidade: false,
        disciplinas_entidade: false,
        gamificacao_entidade: false,
        conteudos_animados_entidade: false,
        stepmeet_gestao: false,
        conf_total_participantes: 0,
        conf_total_recorders: 0,
        monetizacao_entidade: false,
        moeda_entidade: false,
        vendaCursos: false,
        vendaCertificados: false,
        //HOME PUBLICA
        carregar_dados: false,
        //CURSO
        curso: "",
        carrega_header: false,
        carrega_visao: false,
        //FOOTER GERAL
        fundoBranco: false,
        footer_visivel: true,
        //MODULOS
        lista_modulos: [],
        //DISCIPLINAS
        lista_disciplinas: [],
        //CURSO
        subcursos: false,
        conteudos_visiveis: false,
        atividadesLivres: false,
        disciplinasVisiveis: false,
        //PESQUISA ENTIDADE NAVBAR
        search_entidade: false,
        //COPIAR/COLAR
        id_copia_tarefa: 0,
        //METALHA
        medalhasCurso: 0,
        medalhasTarefa: 0,
        //CARRINHO
        carrinho: [],
        //
        fechado: false,
        //ENTIDADE
        login_entidade: ""
    };

    UNSAFE_componentWillMount() {
        //this.isAuthenticated()
    }

    ////VERIFICA SE O UTILIZADOR ESTÁ AUTENTICADO
    //isAuthenticated = () => {
    //    axios({
    //        method: "get",
    //        url: "/api/autenticacao/is-authenticated"
    //    })
    //        .then(response => {
    //            this.setState({
    //                is_authenticated: response.data.autenticado,
    //                is_authenticated_nav: response.data.autenticado,
    //                role: response.data.role
    //            });
    //        })
    //        .catch(() => {
    //            this.log("Tentativa de validar se o utilizador esta autenticado", "/api/autenticacao/is-authenticated");
    //        });
    //};

    montarNavbar = temNavbar => {
        this.setState({
            temNavbar
        });
    };

    formatarPeriodo = periodo => {
        var um_hora = moment()
            .startOf("day")
            .seconds(60 * 60 * 1000);
        var format = moment().startOf("day").seconds(periodo);

        if (format > um_hora) return moment().startOf("day").seconds(periodo).format("HH:mm:ss");
        else return moment().startOf("day").seconds(periodo).format("mm:ss");
    };

    atualizarLogo = entidade_logo => {
        this.setState({
            entidade_logo
        });
    };

    carregaModuloAula = (modulo, moduloIndex, aula, aulaIndex) => {
        this.setState({
            modulo,
            moduloIndex,
            aula,
            aulaIndex
        });
    };

    //MARCA A AULA COMO CONCLUIDA PELO ALUNO
    concluirAula = aulaid => {
        if (!this.state.tem_comandos && !this.state.formador_curso)
            axios({
                method: "put",
                url: "/api/player/concluir",
                params: {
                    aulaid: aulaid
                }
            })
                .then(() => {
                    this.setState({
                        updateModulos: true,
                        modulosOk: false
                    });
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível concluir aula!");
                });
    };

    //MARCA A AULA COMO CONCLUIDA PELO ALUNO
    concluirAulaAudio = aulaid => {
        if (!this.state.tem_comandos && !this.state.formador_curso)
            axios({
                method: "put",
                url: "/api/player/concluir-audio",
                params: {
                    aulaid: aulaid
                }
            })
                .then(() => {
                    this.setState({
                        updateModulos: true,
                        modulosOk: false
                    });
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível concluir aula!");
                });
    };

    atualizarEventosHoje = () => {
        axios({
            method: "get",
            url: "/api/navbar/total-eventos-hoje",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.setState({
                    totalEventosHoje: response.data
                });
            })
            .catch(error => {

            });
    }

    stopUpdateModulos = () => {
        this.setState({
            updateModulos: false
        });
    };

    onClickFullscreen = () => {
        this.setState({ fullscreen: !this.state.fullscreen });
        screenfull.toggle();
    };

    addMarcador = () => {
        this.setState({
            updateModulos: true
        });
    };

    atualizarModulosEstado = modulosOk => {
        this.setState({
            modulosOk
        });
    };

    atualizarForumEstado = forumOk => {
        this.setState({
            forumOk
        });
    };

    atualizarAnotacoesEstado = anotacoesOk => {
        this.setState({
            anotacoesOk
        });
    };

    atualizarState = state => {
        this.setState(state);
    };

    atualizarListaModulos = estado => {
        var _lista_modulos = this.state.lista_modulos;

        _lista_modulos.forEach((modulo, moduloIndex) => {
            var index = modulo.modulo_Aula.findIndex(x => x.id === parseInt(localStorage.getItem("codigo_aula")));

            if (index === -1) {
                modulo.modulo_Aula.forEach((aula, aulaIndex) => {
                    index = aula.modulo_Aula_lvl2.findIndex(x => x.id === parseInt(localStorage.getItem("codigo_aula")));

                    if (index > -1) {
                        var utilizador_registo =
                            _lista_modulos[moduloIndex].modulo_Aula[aulaIndex].modulo_Aula_lvl2[index].modulo_Aulas_Utilizadores;
                        if (utilizador_registo) {
                            utilizador_registo.concluido = !estado;
                            _lista_modulos[moduloIndex].modulo_Aula[aulaIndex].modulo_Aula_lvl2[
                                index
                            ].modulo_Aulas_Utilizadores = utilizador_registo;
                        } else {
                            _lista_modulos[moduloIndex].modulo_Aula[aulaIndex].modulo_Aula_lvl2[index].modulo_Aulas_Utilizadores = {
                                concluido: !estado
                            };
                        }

                        if (estado) {
                            var utilizador_registo = _lista_modulos[moduloIndex].modulo_Aula[aulaIndex].modulo_Aulas_Utilizadores;
                            if (utilizador_registo) {
                                utilizador_registo.concluido = false;
                                _lista_modulos[moduloIndex].modulo_Aula[aulaIndex].modulo_Aulas_Utilizadores = utilizador_registo;
                            }
                        }
                    }
                });
            }
            else {
                var utilizador_registo = _lista_modulos[moduloIndex].modulo_Aula[index].modulo_Aulas_Utilizadores;
                if (utilizador_registo) {
                    utilizador_registo.concluido = !estado;
                    _lista_modulos[moduloIndex].modulo_Aula[index].modulo_Aulas_Utilizadores = utilizador_registo;
                } else {
                    _lista_modulos[moduloIndex].modulo_Aula[index].modulo_Aulas_Utilizadores = { concluido: true };
                }
            }
        });

        this.setState({
            lista_modulos: _lista_modulos
        });
    };

    render() {
        return (
            <GlobalContext.Provider
                value={{
                    ...this.state,
                    montarNavbar: this.montarNavbar,
                    formatarPeriodo: this.formatarPeriodo,
                    atualizarLogo: this.atualizarLogo,
                    //isAuthenticated: this.isAuthenticated,
                    carregaModuloAula: this.carregaModuloAula,
                    concluirAula: this.concluirAula,
                    concluirAulaAudio: this.concluirAulaAudio,
                    stopUpdateModulos: this.stopUpdateModulos,
                    onClickFullscreen: this.onClickFullscreen,
                    addMarcador: this.addMarcador,
                    atualizarModulosEstado: this.atualizarModulosEstado,
                    atualizarForumEstado: this.atualizarForumEstado,
                    atualizarAnotacoesEstado: this.atualizarAnotacoesEstado,
                    atualizarState: this.atualizarState,
                    atualizarListaModulos: this.atualizarListaModulos,
                    atualizarEventosHoje: this.atualizarEventosHoje
                    //,
                    // criarLog: this.criarLog
                    //,
                    //   log: this.log
                    //  logOperacao: this.logOperacao
                }}
            >
                {this.props.children}
            </GlobalContext.Provider>
        );
    }
}
export default GlobalState;
