import React, { Component } from "react";
import { Icon } from "antd";

class ExcelTabelaNotasRegular extends Component {
    render() {
        const {
            grupos,
            periodos,
            avaliacoes
        } = this.props;

        const montarTitulosAvaliacoes = () => {
            return (avaliacoes.map((avaliacao, index) => (
                <td align="center" valign="middle"><b><font color="#000000">{avaliacao}</font></b></td>
            )));
        }

        const montarNotas = (nota) => {
            return (avaliacoes.map((avaliacao, index) => (
                <td className={`celula-tabela-notas-pre ${(index === (avaliacoes.length - 1) ? "td-border-right" : "")}`} align="left" valign="bottom" ><div>{nota === avaliacao ? <Icon type="check-square" /> : <Icon type="border" />}</div></td>
            )));
        }

        const montarParametros = (grupo, grupoIndex) => {
            const parametros = grupo.avaliacoes.map((avaliacao, index) => {
                return <tr className="tabela-body">
                    <td className="td-border-right" align="left" valign="middle"><p color="#000000">{avaliacao.titulo}</p></td>
                    {periodos.map((periodo, periodoIndex) => {
                        const _grupo = periodo.grupos[grupoIndex];

                        let nota = "";
                        if (_grupo !== null) {
                            const _avaliacao = _grupo.avaliacoes[index];
                            if (_avaliacao !== null)
                                nota = _avaliacao.nota;
                        }

                        return montarNotas(nota);
                    })}
                </tr>
            });

            return parametros;
        }

        return (
            <>
                <div>
                    {grupos.map((grupo, index) => (
                        <table className="tabela-notas-pre tabela-notas-regular" cellSpacing={0} border={0} style={{ width: "100%" }}>
                            <tbody>
                                <tr className="tabela-head">
                                    <td align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">{grupo.titulo}</font></b></td>
                                    {periodos.map(periodo => (
                                        <td colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#16817A"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                                    ))}
                                </tr>
                                <tr>
                                    <td align="center" valign="middle"><b><font color="#000000">Conceituais - Procedimentais e Atitudinais</font></b></td>
                                    {periodos.map(periodo => (montarTitulosAvaliacoes()))}
                                </tr>
                                {montarParametros(grupo, index)}
                            </tbody>
                        </table>
                    ))}
                </div>
            </>
        );
    }
}

export default ExcelTabelaNotasRegular;