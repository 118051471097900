import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Menu, Table, Progress, Dropdown, Modal, notification, Form, Icon, Drawer, Collapse } from "antd";
import DrawerRelatrio from "../_Aux/DrawerRelatorio";
import TabelaFrequencia from "../_Aux/TabelaFrequencia";
import TabelaNotas from "../_Aux/TabelaNotas";
//import TabelaNotasPre from "../_Aux/TabelaNotasPre";
//import TabelaNotasCreche from "../_Aux/TabelaNotasCreche";
//import TabelaNotasRegular from "../_Aux/TabelaNotasRegular";
import TabelaNotasPre from "../../GerirCursos/Diario/_Aux/ExcelTabelaNotasPre";
import TabelaNotasCreche from "../../GerirCursos/Diario/_Aux/ExcelTabelaNotasCreche";
import TabelaNotasRegular from "../../GerirCursos/Diario/_Aux/ExcelTabelaNotasRegular";
import CardsFrequencia from "../_Aux/CardsFrequencia";
import CardsNotas from "../_Aux/CardsNotas";
//import CardsNotasPre from "../_Aux/CardsNotasPre";
//import CardsNotasCreche from "../_Aux/CardsNotasCreche";
//import CardsNotasRegular from "../_Aux/CardsNotasRegular";
import CardsNotasPre from "../../GerirCursos/Diario/_Aux/CardsNotasPre";
import CardsNotasCreche from "../../GerirCursos/Diario/_Aux/CardsNotasCreche";
import CardsNotasRegular from "../../GerirCursos/Diario/_Aux/CardsNotasRegular";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../GlobalState";
import noimage from "../../images/noimage.png";

const { Panel } = Collapse;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export class Main extends Component {
    static contextType = GlobalContext;
    state = {
        cursoId: 0,
        utilizadorId: "",
        fechado: false,
        questoes: [],
        utilizadores: [],
        frequencias: [],
        notas: [],
        avaliacoesCompetencias: [],
        avaliacoesCompetenciasMobile: [],
        tarefas: [],
        tarefasMobile: [],
        diarioAvaliacoes: [],
        outras_avaliacoes: [],
        outras_avaliacoesMobile: [],
        questionarios_satisfacao: [],
        questionarios_satisfacaoMobile: [],
        relatorios_progresso: [],
        relatorios_progressoMobile: [],
        estatisticas: "",
        //FILTRO
        pesquisa: "",
        dt_inicio: "",
        dt_fim: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LOADING
        loading_table: false,
        loading_table_frequencias: false,
        loading_table_notas: false,
        loading_table_competencias: false,
        loading_table_todas_avaliacoes: false,
        loading_table_outras: false,
        loading_table_questionarios_satisfacao: false,
        loading_table_lvl3: false,
        //TIPO RESULTADO
        tipo_resultado: "",
        temp_record: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //DRAWER RELATORIO
        relatorioId: 0,
        visibleDrawerRelatorio: false,
        //TEMP
        expanded: false,
        record: "",
        //DRAWER DETAHE
        visibleDetalhe: false,
        visibleDetalheRelatorio: false,
        detalheRegisto: "",
        detalheClassificacao: "",
        detalheProgresso: "",
        detalheDescarregar: "",
        detalheNotificacao: "",
        //FORMULA
        ano: "",
        eja: false,
        formula: 0
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.setState({
            cursoId: localStorage.getItem("codigo_curso"),
            utilizadorId: this.props.match.params.utilizadorid,
            fechado: localStorage.getItem("curso_fechado") === "true"
        }, () => {
            this.listar();
            this.carregarFormulaAno();
            if (localStorage.getItem("habilitar_competencias") === "true")
                this.listarAvaliacaoCompetencias()
            this.listarTodasAvaliacoes();
            this.listarOutrasAvaliacoes();
            this.listarRelatoriosProgresso()
            this.listarEstatisticas();
        });
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeDataInicio = date => {
        this.setState({ dt_inicio: date });
    };

    handleChangeDataFim = date => {
        this.setState({ dt_fim: date });
    };

    handleChangeData = date => {
        this.setState({ data_avaliacao: date });
    };

    //CARREGA TODOS OS ALUNOS E SUAS AVALIAÇÕES
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-resultados/listar-resultados-curso",
                    params: {
                        cursoId: this.state.cursoId,
                        utilizadorId: this.state.utilizadorId
                    }
                })
                    .then(response => {
                        this.setState({
                            utilizadores: response.data.utilizadores,
                            diario: response.data.diario,
                            tipoDiario: response.data.tipoDiario,
                            loading_table: false
                        }, () => {
                            if (this.state.diario && this.context.diario_entidade) {
                                this.listarFrequencia()
                                if (this.state.tipoDiario === "REGULAR")
                                    this.listarNotas()
                                else
                                    this.listarNotasCrechePre()
                            }
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    carregarFormulaAno = () => {
        axios({
            method: "get",
            url: "/api/consultar-resultados/carregar-formula-ano",
            params: {
                cursoId: this.state.cursoId,
            }
        })
            .then(response => {
                this.setState({
                    ano: response.data.ano,
                    eja: response.data.eja,
                    formula: response.data.formula
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a formula!");
            });
    }

    montarMenuTarefa = (tarefa) => {
        const tipo = tarefa.tipo_recurso;
        if (tipo === 6) {
            return <>
                <Link to="#" className="botao-icon-download" onClick={() => this.exportarAvaliacao(tarefa.id)} title="Descarregar">
                    <Icon type="download" />
                </Link>
            </>;
        }
        else if (tipo === 5) {
            return <>
                <Link to="#" className="botao-icon-download" onClick={() => this.exportarQuestionario(tarefa.id)} title="Descarregar">
                    <Icon type="download" />
                </Link>
            </>
        }
        else {
            return <>
                <Link to="#" className="botao-icon-download" onClick={() => this.exportarTrabalho(tarefa.id)} title="Descarregar">
                    <Icon type="download" />
                </Link>
            </>
        }
    }

    montarMenuTarefaMobile = (tarefa) => {
        const tipo = tarefa.tipo_recurso;

        if (tipo === 6) {
            return <Dropdown overlay={this.montarMenuAvaliacaoMobile(tarefa)} placement="bottomLeft">
                <Link to="#" className="btn-opcoes">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>;
        }
        else if (tipo === 5) {
            return <Dropdown overlay={this.montarMenuQuestionarioMobile(tarefa)} placement="bottomLeft">
                <Link to="#" className="btn-opcoes">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
        else {
            return <Dropdown overlay={this.montarMenuTrabalhoMobile(tarefa)} placement="bottomLeft">
                <Link to="#" className="btn-opcoes">
                    <i className="fas fa-cog" />
                </Link>
            </Dropdown>
        }
    }

    montarMenuAvaliacaoMobile = tarefa => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                {tarefa.permitir_exportar ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarAvaliacao(tarefa.id)}>
                            Exportar
                    </Link>
                    </Menu.Item>
                    : null}
            </Menu>
        );
    };

    montarMenuQuestionarioMobile = tarefa => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.exportarQuestionario(tarefa.id)}>
                        Exportar
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuTrabalhoMobile = tarefa => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheTarefa(tarefa)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                {tarefa.modulo_Aulas_Utilizadores?.dt_concluido ?
                    <Menu.Item>
                        <Link to="#" onClick={() => this.exportarTrabalho(tarefa.id)}>
                            Exportar
                    </Link>
                    </Menu.Item>
                    : null}
            </Menu>
        );
    };

    // MONTA O MENU DE OPÇÕES POR CADA UTILIZADOR
    montarMenuQuestionarioSatisfacao = questionario => {
        return (
            <Menu>
                <Menu.Item>
                    <Link
                        to={`/sistema-informacao-questionario/${localStorage.getItem("nome_curso_pai") ? localStorage.getItem("nome_subcurso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase() : localStorage.getItem("nome_curso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/player-questionario`}
                        onClick={() => {
                            localStorage.setItem("questionario_tipo", questionario.tipo)
                            localStorage.setItem("codigo_utilizador", questionario.formador_id)
                            localStorage.setItem("nome_utilizador", questionario.formador_nome)
                        }}
                    >
                        Realizar
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    montarMenuQuestionarioSatisfacaoMobile = questionario => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheQuestionarioSatisfacaoMobile(questionario)}>
                        Detalhe
                    </Link>
                </Menu.Item>
                {(this.state.fechado && !questionario.dt_questionario) ?
                    <Menu.Item>
                        <Link
                            to={`/sistema-informacao-questionario/${localStorage.getItem("nome_curso_pai") ? localStorage.getItem("nome_subcurso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase() : localStorage.getItem("nome_curso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/player-questionario`}
                            onClick={() => {
                                localStorage.setItem("questionario_tipo", questionario.tipo)
                                localStorage.setItem("codigo_utilizador", questionario.formador_id)
                                localStorage.setItem("nome_utilizador", questionario.formador_nome)
                            }}
                        >
                            Realizar
                        </Link>
                    </Menu.Item> : null}
            </Menu>
        );
    };

    abrirDetalheQuestionarioSatisfacaoMobile = questionario => {
        const detalheRegisto = questionario.dt_questionario ? moment(questionario.dt_questionario).format("DD/MM/YYYY") : "";

        this.setState({
            visibleDetalhe: true,
            detalheRegisto
        })
    }

    montarMenuProgresso = relatorioId => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ relatorioId, visibleDrawerRelatorio: true })}>
                        Acessar
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //CARREGA OS DADOS NA TABELA DE NIVEL 1
    carregaDados = lista => {
        var utilizadores = [];

        lista.map((utilizador, index) => {
            utilizadores.push({
                key: index,
                id: utilizador.id,
                nome: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="imagem-curso">
                            <img src={utilizador.foto ? utilizador.foto : noimage} />
                        </div>
                        <div className="nome-colaborador">
                            <span className="titulo-curso">{utilizador.nome_completo}</span>
                        </div>
                    </div>
                ),
                registo: utilizador.dt_criado ? moment(utilizador.dt_criado).format("DD/MM/YYYY") : "",
                classificacao: utilizador.classificacao,
                classificacao_observacoes: utilizador.classificacao_observacoes,
                medalha: utilizador.medalha,
                progresso: <div style={{ textAlign: "center" }}>
                    <Progress percent={Math.round((utilizador.tarefasConcluidas / utilizador.totalTarefas) * 100)} />
                    <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.65)" }}>{utilizador.tarefasConcluidas} / {utilizador.totalTarefas}</span>
                </div>,
                descarregar: utilizador.documento ? (
                    <Link to="#" className="botao-icon-download" title="Descarregar certificado" onClick={this.downloadFicheiro}>
                        <Icon type="download" />
                    </Link>
                ) : ("")
            });
        });
        return utilizadores;
    };

    carregaDadosMobile = lista => {
        var utilizadores = [];

        lista.map((utilizador, index) => {
            utilizadores.push({
                key: index,
                nome: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="imagem-curso">
                            <img src={utilizador.foto ? utilizador.foto : noimage} />
                        </div>
                        <div className="nome-colaborador">
                            <span className="titulo-curso">{utilizador.nome_completo}</span>
                        </div>
                    </div>
                ),
                descarregar: (
                    <>
                        <Link to="#" className="botao-icon-detalhe" onClick={() => this.abrirDetalhe(utilizador)} title="Detalhe">
                            <Icon type="eye" />
                        </Link>
                        {/*<Dropdown overlay={this.montarMenuUtilizador(utilizador)} placement="bottomLeft">
                        <Link to="#" className="btn-opcoes">
                            <i className="fas fa-cog" />
                        </Link>
                        </Dropdown>*/}
                    </>
                )
            });
        });
        return utilizadores;
    };

    abrirDetalhe = utilizador => {
        const detalheRegisto = utilizador.dt_criado ? moment(utilizador.dt_criado).format("DD/MM/YYYY") : "";
        const detalheClassificacao = <div className="bloco-render"><span className="span-text">{utilizador.classificacao}</span> {utilizador.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>;
        const detalheProgresso = <div style={{ textAlign: "center" }}>
            <Progress percent={Math.round((utilizador.tarefasConcluidas / utilizador.totalTarefas) * 100)} />
            <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.65)" }}>{utilizador.tarefasConcluidas} / {utilizador.totalTarefas}</span>
        </div>;
        const detalheDescarregar = utilizador.documento ? (<Link to="#" className="botao-icon-download" title="Descarregar certificado" onClick={this.downloadFicheiro}>
            <Icon type="download" />
        </Link>) : "";

        this.setState({
            visibleDetalhe: true,
            detalheRegisto,
            detalheClassificacao,
            detalheProgresso,
            detalheDescarregar
        })
    }

    abrirDetalheTarefa = tarefa => {
        const detalheRegisto = <div className="bloco-datas">
            {tarefa.tipo_recurso !== 5 && tarefa.tipo_recurso !== 14 && tarefa.tipo_recurso !== 15 ?
                <span className="data-1" title={`${tarefa.tipo_recurso === 6 ? `Data da avaliação${!tarefa.dt_inicio ? " não definida" : ""}` : ""}${tarefa.tipo_recurso === 8 ? "Data limite de entrega" : ""}`}>
                    {tarefa.tipo_recurso === 8 ?
                        `até ${moment(tarefa.dt_fim).format("DD/MM/YYYY")}`
                        :
                        tarefa.dt_inicio ? moment(tarefa.dt_inicio).format("DD/MM/YYYY") : "----"
                    }
                </span> : null}
            <span title={`${tarefa.tipo_recurso !== 8 ? "Data de realização" : ""}${tarefa.tipo_recurso === 8 ? "Data de entrega" : ""}`}>
                {tarefa.modulo_Aulas_Utilizadores?.dt_concluido
                    ? moment(tarefa.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                    : tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title={`Esta tarefa deveria ter sido concluida até ${moment(tarefa.dt_fim).format("DD/MM/YYYY")}`} /> : "----" : "----"}
            </span>
        </div>;
        const detalheClassificacao = <div className="bloco-render"><span className="span-text">{tarefa.modulo_Aulas_Utilizadores?.nota_final}</span> {tarefa.modulo_Aulas_Utilizadores?.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>;

        this.setState({
            visibleDetalhe: true,
            detalheRegisto,
            detalheClassificacao
        })
    }

    //LISTA RESULTADOS DE TODAS AS TABELAS DO ALUNO SELECIONADO
    listarResultadosLvl2 = (expanded, record) => {
        if (expanded) {
            this.listarCompetencias(record);
        }

        this.setState({
            expanded,
            record
        })
    };

    //LISTA TODAS AS FREQUÊNCIAS
    listarFrequencia = () => {
        this.setState({
            loading_table_frequencias: true
        }, () => {
            axios({
                method: "get",
                url: "/api/consultar-resultados/listar-frequencia",
                params: {
                    cursoId: this.state.cursoId,
                    utilizadorId: this.state.utilizadorId
                }
            })
                .then(response => {
                    this.setState({
                        frequencias: response.data,
                        loading_table_frequencias: false
                    });
                })
                .catch();
        });
    }

    //LISTA TODAS AS NOTAS CRECHE
    listarNotasCrechePre = () => {
        this.setState({
            loading_table_notas: true
        }, () => {
            axios({
                method: "get",
                url: "/api/consultar-resultados/listar-notas-pre",
                params: {
                    cursoId: this.state.cursoId,
                    utilizadorId: this.state.utilizadorId
                }
            })
                .then(response => {
                    this.setState({
                        notas: response.data.periodos,
                        diarioAvaliacoes: response.data.avaliacoes,
                        loading_table_notas: false
                    });
                })
                .catch();
        });
    }

    //LISTA TODAS AS NOTAS
    listarNotas = () => {
        this.setState({
            loading_table_notas: true
        }, () => {
            axios({
                method: "get",
                url: "/api/consultar-resultados/listar-notas",
                params: {
                    cursoId: this.state.cursoId,
                    utilizadorId: this.state.utilizadorId
                }
            })
                .then(response => {
                    this.setState({
                        notas: response.data,
                        loading_table_notas: false
                    });
                })
                .catch();
        });
    }

    //LISTA TODAS AS AVALIACOES PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarAvaliacaoCompetencias = () => {
        this.setState({
            loading_table_competencias: true
        });

        axios({
            method: "get",
            url: "/api/consultar-resultados/listar-avaliacoes-competencias",
            params: {
                cursoId: this.state.cursoId,
                utilizadorId: this.state.utilizadorId
            }
        })
            .then(response => {
                var avaliacoesCompetencias = [];
                var avaliacoesCompetenciasMobile = [];

                response.data.map((tarefa, index) => {
                    if (!tarefa.modulo_Aulas_Utilizadores) {
                        avaliacoesCompetencias.push({
                            key: index,
                            id: tarefa.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconCompetencia(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: <div className="bloco-datas">
                                {tarefa.tipo_recurso !== 5 && tarefa.tipo_recurso !== 14 && tarefa.tipo_recurso !== 15 ?
                                    <span className="data-1" title={`${tarefa.tipo_recurso === 6 ? `Data da avaliação${!tarefa.dt_inicio ? " não definida" : ""}` : ""}${tarefa.tipo_recurso === 8 ? "Data limite de entrega" : ""}`}>
                                    {tarefa.tipo_recurso === 8 ?
                                        `até ${moment(tarefa.dt_fim).format("DD/MM/YYYY")}`
                                        :
                                        tarefa.dt_inicio ? moment(tarefa.dt_inicio).format("DD/MM/YYYY") : "----"
                                    }
                                </span> : null}
                                <span title={`${tarefa.tipo_recurso !== 8 ? "Data de realização" : ""}${tarefa.tipo_recurso === 8 ? "Data de entrega" : ""}`}>
                                    {tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title={`Esta tarefa deveria ter sido concluida até ${moment(tarefa.dt_fim).format("DD/MM/YYYY")}`} /> : "----" : "----"}
                                </span>
                            </div>,
                        });

                        avaliacoesCompetenciasMobile.push({
                            key: index,
                            id: tarefa.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconCompetencia(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: this.montarMenuTarefaMobile(tarefa)
                        });
                    }
                    else {
                        avaliacoesCompetencias.push({
                            key: index,
                            id: tarefa.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconCompetencia(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            //data: tarefa.modulo_Aulas_Utilizadores.dt_nota_final
                            //    ? moment(tarefa.modulo_Aulas_Utilizadores.dt_nota_final).format("DD/MM/YYYY")
                            //    : "",
                            data: <div className="bloco-datas">
                                {tarefa.tipo_recurso !== 5 && tarefa.tipo_recurso !== 14 && tarefa.tipo_recurso !== 15 ?
                                    <span className="data-1" title={`${tarefa.tipo_recurso === 6 ? `Data da avaliação${!tarefa.dt_inicio ? " não definida" : ""}` : ""}${tarefa.tipo_recurso === 8 ? "Data limite de entrega" : ""}`}>
                                        {tarefa.tipo_recurso === 8 ?
                                            `até ${moment(tarefa.dt_fim).format("DD/MM/YYYY")}`
                                            :
                                            tarefa.dt_inicio ? moment(tarefa.dt_inicio).format("DD/MM/YYYY") : "----"
                                        }
                                    </span> : null}
                                <span title={`${tarefa.tipo_recurso !== 8 ? "Data de realização" : ""}${tarefa.tipo_recurso === 8 ? "Data de entrega" : ""}`}>
                                    {tarefa.modulo_Aulas_Utilizadores.dt_concluido
                                        ? moment(tarefa.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                        : tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title={`Esta tarefa deveria ter sido concluida até ${moment(tarefa.dt_fim).format("DD/MM/YYYY")}`} /> : "----" : "----"}
                                </span>
                            </div>,
                            classificacao: tarefa.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: tarefa.modulo_Aulas_Utilizadores.medalha,
                            opcoes: tarefa.permitir_exportar ?
                                this.montarMenuTarefa(tarefa)
                                : null
                        });

                        avaliacoesCompetenciasMobile.push({
                            key: index,
                            id: tarefa.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconCompetencia(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: this.montarMenuTarefaMobile(tarefa)
                        });
                    }
                });

                this.setState({
                    avaliacoesCompetencias,
                    avaliacoesCompetenciasMobile,
                    loading_table_competencias: false
                });
            })
            .catch(() => { });
    };

    monterCoresBNCC = gerais => {
        var _gerais = gerais.map((geral, index) => {
            switch (geral.split(".")[0]) {
                case "1":
                    return <span key={index} className="bcnn-competencia competencia-1">{geral}</span>
                    break;
                case "2":
                    return <span key={index} className="bcnn-competencia competencia-2">{geral}</span>
                    break;
                case "3":
                    return <span key={index} className="bcnn-competencia competencia-3">{geral}</span>
                    break;
                case "4":
                    return <span key={index} className="bcnn-competencia competencia-4">{geral}</span>
                    break;
                case "5":
                    return <span key={index} className="bcnn-competencia competencia-5">{geral}</span>
                    break;
                case "6":
                    return <span key={index} className="bcnn-competencia competencia-6">{geral}</span>
                    break;
                case "7":
                    return <span key={index} className="bcnn-competencia competencia-7">{geral}</span>
                    break;
                case "8":
                    return <span key={index} className="bcnn-competencia competencia-8">{geral}</span>
                    break;
                case "9":
                    return <span key={index} className="bcnn-competencia competencia-9">{geral}</span>
                    break;
                case "10":
                    return <span key={index} className="bcnn-competencia competencia-10">{geral}</span>
                    break;
            }
        })

        return _gerais;
    }

    //LISTA TODAS AS AVALIACOES PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarCompetencias = record => {
        this.setState({
            loading_table_lvl3: true
        });

        axios({
            method: "get",
            url: "/api/consultar-resultados/listar-competencias",
            params: {
                aulaid: record.id,
                utilizadorId: this.state.utilizadorId
            }
        })
            .then(response => {
                var avaliacoesCompetencias = this.state.avaliacoesCompetencias;
                var avaliacoesCompetenciasMobile = this.state.avaliacoesCompetenciasMobile;
                var competencias = [];

                response.data.competencias.map((competencia, index) => {
                    var tipos = JSON.parse(competencia.tipos)
                    var lista = this.monterCoresBNCC(JSON.parse(competencia.gerais));

                    competencias.push({
                        key: index,
                        id: competencia.id,
                        competencia: (<div className="compentencia">
                            <div className="info-compentencia">
                                <span className="titulo-competencia">{competencia.nome}</span>
                                <div className="lista-tipos-competencias">
                                    {tipos.map((tipo, index) => (
                                        <div key={index} className="tipo-competencia">
                                            <span className={"icon-competencia " + (this.montarIcon(tipo, competencia.id, response.data.avaliacao))}></span>
                                            <span className="tipo">{tipo}</span>
                                        </div>
                                    ))}
                                </div>
                                {lista.length ?
                                    <div className="lista-tipos-competencias-gerais">
                                        <span className="titulo-competencias-gerais lista-competencias-gerais"><strong>(BNCC </strong> {lista.map(geral => (geral))}<strong>)</strong></span>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>)
                    });
                });


                avaliacoesCompetencias[record.key] = { ...avaliacoesCompetencias[record.key], competencias }
                avaliacoesCompetenciasMobile[record.key] = { ...avaliacoesCompetenciasMobile[record.key], competencias }
                this.setState({
                    avaliacoesCompetencias,
                    avaliacoesCompetenciasMobile,
                    loading_table_lvl3: false
                });
            })
            .catch(() => { });
    };

    montarIconCompetencia = (tipoId, tipoTrabalho) => {
        switch (tipoId) {
            case 5:
                return (<div className="imagem-curso" title="Questionário">
                    <img src={require("../../images/icons/questionario.png")} />
                </div>)
                break;
            case 6:
                return (<div className="imagem-curso" title="Avaliação">
                    <img src={require("../../images/icons/avaliacao.png")} />
                </div>)
                break;
            case 8:
                switch (tipoTrabalho) {
                    case "INDIVIDUAL":
                        return (<div className="imagem-curso" title="Trabalho Individual">
                            <img src={require("../../images/icons/trabalho_individual.png")} />
                        </div>)
                        break;
                    case "GRUPO":
                        return (<div className="imagem-curso" title="Trabalho de Grupo">
                            <img src={require("../../images/icons/trabalho_grupo.png")} />
                        </div>)
                        break;
                    default:
                        return (<div className="imagem-curso" title="Trabalho Colaborativo">
                            <img src={require("../../images/icons/trabalho_colaborativo.png")} />
                        </div>)
                        break;
                }
                break;
            case 14:
                return (<div className="imagem-curso" title="Questionario Progresso">
                    <img src={require("../../images/icons/progresso.png")} />
                </div>)
                break;
            case 15:
                return (<div className="imagem-curso" title="Questionário Dirigido">
                    <img src={require("../../images/icons/competencia.png")} />
                </div>)
                break;
        }
    }

    montarIcon = (tipo, competenciaId, avaliacao) => {
        var valor = "";
        if (avaliacao)
            if (avaliacao.tipos)
                if (avaliacao.tipos.length) {
                    var tipos = JSON.parse(avaliacao.tipos)
                    var _competencia = tipos.find(x => x.competenciaId === competenciaId)
                    if (_competencia) {
                        var _tipo = _competencia.tipos.find(x => x.tipo === tipo)
                        if (_tipo)
                            valor = _tipo.valor;
                    }
                }

        if (valor === "Competência satisfatória")
            return "icon-satisfatoria"
        else if (valor === "Competência não satisfatória")
            return "icon-nao-satisfatoria"
        else if (valor === "Competência parcialmente satisfatória")
            return "icon-parcialmente-satisfatoria"
        else
            return "icon-sem-avaliacao"

    }

    montarIconTarefa = (tipo, tipoTrabalho) => {
        if (tipo === 6) {
            return <div className="imagem-curso" title="Avaliação">
                <img src={require("../../images/icons/avaliacao.png")} />
            </div>
        }
        else if (tipo === 5) {
            return <div className="imagem-curso" title="Questionário">
                <img src={require("../../images/icons/questionario.png")} />
            </div>
        }
        else if (tipo === 8) {
            if (tipoTrabalho === "INDIVIDUAL")
                return <div className="imagem-curso" title="Trabalho Individual">
                    <img src={require("../../images/icons/trabalho_individual.png")} />
                </div>
            else if (tipoTrabalho === "GRUPO")
                return <div className="imagem-curso" title="Trabalho de Grupo">
                    <img src={require("../../images/icons/trabalho_grupo.png")} />
                </div>
            else
                return <div className="imagem-curso" title="Trabalho Colaborativo">
                    <img src={require("../../images/icons/trabalho_colaborativo.png")} />
                </div>
        }
        else {
            return <div className="imagem-curso" title="Questionário de Progresso">
                <img src={require("../../images/icons/progresso.png")} />
            </div>
        }
    }

    listarTodasAvaliacoes = () => {
        this.setState({
            loading_table_avaliacoes: true
        });

        axios({
            method: "get",
            url: "/api/consultar-resultados/listar-todas-avaliacoes",
            params: {
                cursoId: this.state.cursoId,
                utilizadorId: this.state.utilizadorId
            }
        })
            .then(response => {
                var tarefas = [];
                var tarefasMobile = [];

                response.data.map((tarefa, index) => {
                    if (!tarefa.modulo_Aulas_Utilizadores) {
                        tarefas.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: <div className="bloco-datas">
                                {tarefa.tipo_recurso !== 5 && tarefa.tipo_recurso !== 14 && tarefa.tipo_recurso !== 15 ?
                                    <span className="data-1" title={`${tarefa.tipo_recurso === 6 ? `Data da avaliação${!tarefa.dt_inicio ? " não definida" : ""}` : ""}${tarefa.tipo_recurso === 8 ? "Data limite de entrega" : ""}`}>
                                        {tarefa.tipo_recurso === 8 ?
                                            `até ${moment(tarefa.dt_fim).format("DD/MM/YYYY")}`
                                            :
                                            tarefa.dt_inicio ? moment(tarefa.dt_inicio).format("DD/MM/YYYY") : "----"
                                        }
                                    </span> : null}
                                <span title={`${tarefa.tipo_recurso !== 8 ? "Data de realização" : ""}${tarefa.tipo_recurso === 8 ? "Data de entrega" : ""}`}>
                                    {tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title={`Esta tarefa deveria ter sido concluida até ${moment(tarefa.dt_fim).format("DD/MM/YYYY")}`} /> : "----" : "----"}
                                </span>
                            </div>,
                        });
                        tarefasMobile.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: this.montarMenuTarefaMobile(tarefa)
                        });
                    }
                    else {
                        tarefas.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: <div className="bloco-datas">
                                {tarefa.tipo_recurso !== 5 && tarefa.tipo_recurso !== 14 && tarefa.tipo_recurso !== 15 ?
                                    <span className="data-1" title={`${tarefa.tipo_recurso === 6 ? `Data da avaliação${!tarefa.dt_inicio ? " não definida" : ""}` : ""}${tarefa.tipo_recurso === 8 ? "Data limite de entrega" : ""}`}>
                                        {tarefa.tipo_recurso === 8 ?
                                            `até ${moment(tarefa.dt_fim).format("DD/MM/YYYY")}`
                                            :
                                            tarefa.dt_inicio ? moment(tarefa.dt_inicio).format("DD/MM/YYYY") : "----"
                                        }
                                    </span> : null}
                                <span title={`${tarefa.tipo_recurso !== 8 ? "Data de realização" : ""}${tarefa.tipo_recurso === 8 ? "Data de entrega" : ""}`}>
                                    {tarefa.modulo_Aulas_Utilizadores.dt_concluido
                                        ? moment(tarefa.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                        : tarefa.tipo_recurso === 8 ? moment().isAfter(moment(tarefa.dt_fim)) ? <Icon type="warning" className="atrasado" title={`Esta tarefa deveria ter sido concluida até ${moment(tarefa.dt_fim).format("DD/MM/YYYY")}`} /> : "----" : "----"}
                                </span>
                            </div>,
                            classificacao: tarefa.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: tarefa.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: tarefa.modulo_Aulas_Utilizadores.medalha,
                            opcoes: ((tarefa.tipo_recurso === 6 && tarefa.permitir_exportar) || tarefa.tipo_recurso === 5 || (tarefa.tipo_recurso === 8 && tarefa.modulo_Aulas_Utilizadores.dt_concluido) ?
                                this.montarMenuTarefa(tarefa)
                                : null)
                        });
                        tarefasMobile.push({
                            key: index,
                            id: tarefa.id,
                            nome: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTarefa(tarefa.tipo_recurso, tarefa.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{tarefa.nome}</span>
                                        <p className="atividade">{tarefa.atividade}</p>
                                    </div>
                                </div>
                            ),
                            opcoes: this.montarMenuTarefaMobile(tarefa)
                        });
                    }
                });

                this.setState({
                    tarefas,
                    tarefasMobile,
                    loading_table_avaliacoes: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarAvaliacoes = () => {
        this.setState({
            loading_table_avaliacoes: true
        });

        axios({
            method: "get",
            url: "/api/consultar-resultados/listar-avaliacoes",
            params: {
                cursoId: this.state.cursoId,
                utilizadorId: this.state.utilizadorId
            }
        })
            .then(response => {
                var avaliacoes = [];

                response.data.map((avaliacao, index) => {
                    if (!avaliacao.modulo_Aulas_Utilizadores) {
                        avaliacoes.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Avaliação">
                                        <img src={require("../../images/icons/avaliacao.png")} />
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                        });
                    } else {
                        avaliacoes.push({
                            key: index,
                            id: avaliacao.id,
                            avaliacao: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Avaliação">
                                        <img src={require("../../images/icons/avaliacao.png")} />
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{avaliacao.nome}</span>
                                        <p className="atividade">{avaliacao.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: avaliacao.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(avaliacao.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : "",
                            classificacao: avaliacao.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: avaliacao.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: avaliacao.modulo_Aulas_Utilizadores.medalha,
                            opcoes: (avaliacao.permitir_exportar ?
                                <>
                                    <Link to="#" className="botao-icon-download" onClick={() => this.exportarAvaliacao(avaliacao.id)} title="Descarregar">
                                        <Icon type="download" />
                                    </Link>
                                    {/*<Dropdown overlay={this.montarMenuAvaliacao(avaliacao.id)} placement="bottomLeft">
                                        <Link to="#" className="btn-opcoes">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>*/}
                                </>
                                : null)
                        });
                    }
                });

                this.setState({
                    avaliacoes,
                    loading_table_avaliacoes: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //LISTA TODAS OS QUESTIONARIOS PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarQuestionarios = () => {
        this.setState({
            loading_table_questionarios: true
        });

        axios({
            method: "get",
            url: "/api/consultar-resultados/listar-questionarios",
            params: {
                cursoId: this.state.cursoId,
                utilizadorId: this.state.utilizadorId
            }
        })
            .then(response => {
                var questionarios = [];

                response.data.map((questionario, index) => {
                    if (!questionario.modulo_Aulas_Utilizadores) {
                        questionarios.push({
                            key: index,
                            id: questionario.id,
                            questionario: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Questionário">
                                        {questionario.tipo_recurso === 5 ?
                                            <img src={require("../../images/icons/questionario.png")} />
                                            :
                                            <img src={require("../../images/icons/progresso.png")} />
                                        }
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{questionario.nome}</span>
                                        <p className="atividade">{questionario.atividade}</p>
                                    </div>
                                </div>
                            ),
                        });
                    } else {
                        questionarios.push({
                            key: index,
                            id: questionario.id,
                            questionario: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    <div className="imagem-curso" title="Questionário">
                                        {questionario.tipo_recurso === 5 ?
                                            <img src={require("../../images/icons/questionario.png")} />
                                            :
                                            <img src={require("../../images/icons/progresso.png")} />
                                        }
                                    </div>
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{questionario.nome}</span>
                                        <p className="atividade">{questionario.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: questionario.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(questionario.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : "",
                            classificacao: questionario.tipo_recurso === 5 ? questionario.modulo_Aulas_Utilizadores.nota_final : questionario.modulo_Aulas_Utilizadores.dt_concluido ? "Realizado com sucesso" : "Realizado com respostas erradas",
                            classificacao_observacoes: questionario.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: questionario.modulo_Aulas_Utilizadores.medalha,
                            opcoes: questionario.tipo_recurso === 5 && (
                                <>
                                    <Link to="#" className="botao-icon-download" onClick={() => this.exportarQuestionario(questionario.id)} title="Descarregar">
                                        <Icon type="download" />
                                     </Link>
                                    {/*<Dropdown overlay={this.montarMenuQuestionario(questionario.id)} placement="bottomLeft">
                                    <Link to="#" className="btn-opcoes">
                                        <i className="fas fa-cog" />
                                    </Link>
                                    </Dropdown>*/}
                                    </>
                            )
                        });
                    }
                });

                this.setState({
                    questionarios,
                    loading_table_questionarios: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    montarIconTrabalho = tipo => {
        if (tipo === "INDIVIDUAL")
            return <div className="imagem-curso" title="Trabalho Individual">
                <img src={require("../../images/icons/trabalho_individual.png")} />
            </div>
        else if (tipo === "GRUPO")
            return <div className="imagem-curso" title="Trabalho de Grupo">
                <img src={require("../../images/icons/trabalho_grupo.png")} />
            </div>
        else
            return <div className="imagem-curso" title="Trabalho Colaborativo">
                <img src={require("../../images/icons/trabalho_colaborativo.png")} />
            </div>
    }

    //LISTA TODAS OS TRABALHOS PARA O CURSO EM QUESTAO COM E SEM CLASSIFICAÇÕES DO ALUNO SELECIONADO
    listarTrabalhos = () => {
        this.setState({
            loading_table_trabalhos: true
        });

        axios({
            method: "get",
            url: "/api/consultar-resultados/listar-trabalhos",
            params: {
                cursoId: this.state.cursoId,
                utilizadorId: this.state.utilizadorId
            }
        })
            .then(response => {
                var trabalhos = [];

                response.data.map((trabalho, index) => {
                    if (!trabalho.modulo_Aulas_Utilizadores) {
                        trabalhos.push({
                            key: index,
                            id: trabalho.id,
                            trabalho: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTrabalho(trabalho.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{trabalho.nome}</span>
                                        <p className="atividade">{trabalho.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: moment().isAfter(moment(trabalho.dt_fim)) ? <Icon type="warning" className="atrasado" title={`Esta tarefa deveria ter sido concluida até ${moment(trabalho.dt_fim).format("DD/MM/YYYY")}`} /> : "",
                        });
                    } else {
                        trabalhos.push({
                            key: index,
                            id: trabalho.id,
                            trabalho: (
                                <div style={{ display: "flex", alignItems: "center", height: 40 }}>
                                    {this.montarIconTrabalho(trabalho.tipo_trabalho)}
                                    <div className="nome-colaborador">
                                        <span className="titulo-curso" style={{ marginTop: 15 }}>{trabalho.nome}</span>
                                        <p className="atividade">{trabalho.atividade}</p>
                                    </div>
                                </div>
                            ),
                            data: trabalho.modulo_Aulas_Utilizadores.dt_concluido
                                ? moment(trabalho.modulo_Aulas_Utilizadores.dt_concluido).format("DD/MM/YYYY")
                                : moment().isAfter(moment(trabalho.dt_fim)) ? <Icon type="warning" className="atrasado" title={`Esta tarefa deveria ter sido concluida até ${moment(trabalho.dt_fim).format("DD/MM/YYYY")}`} /> : "",
                            classificacao: trabalho.modulo_Aulas_Utilizadores.nota_final,
                            classificacao_observacoes: trabalho.modulo_Aulas_Utilizadores.nota_final_notas,
                            medalha: trabalho.modulo_Aulas_Utilizadores.medalha,
                            opcoes: trabalho.modulo_Aulas_Utilizadores.dt_concluido && (
                                <>
                                    <Link to="#" className="botao-icon-download" onClick={() => this.exportarTrabalho(trabalho.id)} title="Descarregar">
                                        <Icon type="download" />
                                    </Link>
                                    {/*<Dropdown overlay={this.montarMenuTrabalho(trabalho.id)} placement="bottomLeft">
                                    <Link to="#" className="btn-opcoes">
                                        <i className="fas fa-cog" />
                                    </Link>
                                    </Dropdown>*/}
                                </>
                            )
                        });
                    }
                });

                this.setState({
                    trabalhos,
                    loading_table_trabalhos: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //LISTA TODAS AS OUTRAS AVALIACOES PARA O CURSO EM QUESTAO DO ALUNO SELECIONADO
    listarOutrasAvaliacoes = () => {
        this.setState({
            loading_table_outras: true
        });

        axios({
            method: "get",
            url: "/api/consultar-resultados/listar-outras-avaliacoes",
            params: {
                cursoId: this.state.cursoId,
                utilizadorId: this.state.utilizadorId
            }
        })
            .then(response => {
                var outras_avaliacoes = [];
                var outras_avaliacoesMobile = [];

                response.data.map((avaliacao, index) => {
                    outras_avaliacoes.push({
                        key: index,
                        id: avaliacao.id,
                        nome: avaliacao.nome,
                        data: avaliacao.data ? moment(avaliacao.data).format("DD/MM/YYYY") : "",
                        classificacao: avaliacao.classificacao,
                        observacoes: avaliacao.classificacao_notas,
                        medalha: avaliacao.medalha,
                    });

                    outras_avaliacoesMobile.push({
                        key: index,
                        id: avaliacao.id,
                        nome: avaliacao.nome,
                        opcoes: <>
                            <Link to="#" className="botao-icon-detalhe" onClick={() => this.abrirDetalheOutraAvaliacao(avaliacao)}>
                                <Icon type="eye" />
                            </Link>
                            {/*<Dropdown overlay={this.montarMenuOutrasAvaliacoes(avaliacao)} placement="bottomLeft">
                                <Link to="#" className="btn-opcoes">
                                    <i className="fas fa-cog" />
                                </Link>
                            </Dropdown>*/}
                        </>
                    });
                });

                this.setState({
                    outras_avaliacoes,
                    outras_avaliacoesMobile,
                    loading_table_outras: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    montarMenuOutrasAvaliacoes = tarefa => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheOutraAvaliacao(tarefa)}>
                        Detalhe
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    abrirDetalheOutraAvaliacao = avaliacao => {
        const detalheRegisto = avaliacao.data ? moment(avaliacao.data).format("DD/MM/YYYY") : "";
        const detalheClassificacao = <div className="bloco-render"><span className="span-text">{avaliacao.classificacao}</span> {avaliacao.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>;

        this.setState({
            visibleDetalhe: true,
            detalheRegisto,
            detalheClassificacao
        })
    }

    //LISTA TODAS AS OUTRAS AVALIACOES PARA O CURSO EM QUESTAO DO ALUNO SELECIONADO
    listarRelatoriosProgresso = () => {
        this.setState({
            loading_table_relatorios: true
        });

        axios({
            method: "get",
            url: "/api/consultar-resultados/listar-relatorios-progresso",
            params: {
                cursoId: this.state.cursoId,
                utilizadorId: this.state.utilizadorId
            }
        })
            .then(response => {
                var relatorios_progresso = [];
                var relatorios_progressoMobile = [];

                response.data.map((relatorio, index) => {
                    relatorios_progresso.push({
                        key: index,
                        id: relatorio.id,
                        nome: (<div style={{ display: "flex", alignItems: "center" }}>
                            <div className="imagem-curso" title="Relatório de Progresso">
                                <img src={require("../../images/icons/relatorio.png")} />
                            </div>
                            <div className="nome-colaborador">
                                <span className="titulo-curso" style={{ marginTop: 0 }}>{relatorio.nome}</span>
                            </div>
                        </div>),
                        data: moment(relatorio.data).format("DD/MM/YYYY"),
                        //notificacao: relatorio.responsavel_notificado && (<Link href="#" className="icon-notificacao" title="Responsável notificado" style={{ cursor: "default" }}>
                        //    <Icon type="mail" />
                        //</Link>),
                        opcoes: <>
                            <Link to="#" className="botao-icon-aceder" onClick={() => this.setState({ relatorioId: relatorio.id, visibleDrawerRelatorio: true })} title="Acessar">
                                <Icon type="login" />
                            </Link>
                        </>
                    });

                    relatorios_progressoMobile.push({
                        key: index,
                        id: relatorio.id,
                        nome: (<div style={{ display: "flex", alignItems: "center" }}>
                            <div className="imagem-curso" title="Relatório de Progresso">
                                <img src={require("../../images/icons/relatorio.png")} />
                            </div>
                            <div className="nome-colaborador">
                                <span className="titulo-curso" style={{ marginTop: 0 }}>{relatorio.nome}</span>
                            </div>
                        </div>),
                        opcoes: <>
                            <Link to="#" onClick={() => this.abrirDetalheRelatoriosProgresso(relatorio)}>
                                <Icon type="eye" />
                            </Link>
                        </>
                    });
                });

                this.setState({
                    relatorios_progresso,
                    relatorios_progressoMobile,
                    loading_table_relatorios: false
                });
            })
            .catch(() => { });
    };

    montarMenuRelatoriosProgresso = relatorio => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.abrirDetalheRelatoriosProgresso(relatorio)}>
                        Detalhe
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    abrirDetalheRelatoriosProgresso = relatorio => {
        const detalheRegisto = moment(relatorio.data).format("DD/MM/YYYY");
        const detalheNotificacao = relatorio.responsavel_notificado ? "Sim" : "Não";

        this.setState({
            visibleDetalheRelatorio: true,
            detalheRegisto,
            detalheNotificacao
        })
    }

    listarEstatisticas = () => {
        //this.setState({
        //    loading_table_outras: true
        //})

        axios({
            method: "get",
            url: "/api/consultar-estatistica/curso-aluno",
            params: {
                cursoId: this.state.cursoId,
                utilizadorId: this.state.utilizadorId
            }
        })
            .then(response => {
                var estatisticas = {
                    tarefas_visitadas: response.data.tarefas_visitadas,
                    tempos_tarefas: response.data.tempos_tarefas,
                    conteudos_concluidos: response.data.conteudos_concluidos,
                    doc_fichs: response.data.doc_fichs,
                    interacoes_anotacoes: response.data.interacoes_anotacoes,
                    mensagens_enviadas: response.data.mensagens_enviadas,
                    interacoes_forum: response.data.interacoes_forum,
                    //ratingsaulas: response.data.ratingsaulas,
                    ratingscursos: response.data.ratingscursos,
                    avaliacoescursos: response.data.avaliacoescursos,
                    avaliacoesaulas: response.data.avaliacoesaulas,
                    medalhacurso: response.data.medalhacurso,
                    medalhastarefas: response.data.medalhastarefas,
                    assiduidade: response.data.assiduidade,
                    presencasStepmeets: response.data.presencasStepmeets
                };

                this.setState({
                    estatisticas
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    exportarAvaliacao = aulaid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-exportar/avaliacao",
                    params: {
                        moduloaulaId: aulaid,
                        utilizadorId: this.state.utilizadorId,
                        entidadeId: localStorage.getItem("codigo_entidade")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        );
    };

    exportarQuestionario = aulaid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-exportar/questionario",
                    params: {
                        moduloaulaId: aulaid,
                        utilizadorId: this.state.utilizadorId,
                        entidadeId: localStorage.getItem("codigo_entidade")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        );
    };

    exportarTrabalho = aulaid => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-exportar/trabalho",
                    params: {
                        moduloaulaId: aulaid,
                        utilizadorId: this.state.utilizadorId,
                        entidadeId: localStorage.getItem("codigo_entidade")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        );
    };

    exportarresumoCSV = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-exportar/resumo-curso-csv",
                    params: {
                        cursoid: this.state.cursoId,
                        utilizadorId: this.state.utilizadorId,
                        entidadeId: localStorage.getItem("codigo_entidade")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        );
    };

    exportarEstatisticasCSV = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-exportar/curso-aluno",
                    params: {
                        cursoid: this.state.cursoId,
                        utilizadorId: this.state.utilizadorId,
                        entidadeId: localStorage.getItem("codigo_entidade")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        );
    };

    exportarEstatisticasDET = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-exportar/curso-aluno-det",
                    params: {
                        cursoid: this.state.cursoId,
                        utilizadorId: this.state.utilizadorId,
                        entidadeId: localStorage.getItem("codigo_entidade")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!")
                        this.setState({
                            visibleExportar: false
                        })
                    })
            }
        );
    };

    montarMenuExportar = () => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to={`/curso/${localStorage.getItem("nome_curso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-ranking`}>
                        Ranking
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    downloadFicheiro = () => {
        axios({
            method: "post",
            url: "/api/consultar-resultados/download-ficheiro",
            params: {
                cursoId: this.state.cursoId,
                utilizadorId: this.state.utilizadorId
            }
        })
            .then(response => {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute(
                    'download',
                    response.data.nome,
                );

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
                else
                    openNotificationWithIcon("error", "Erro", error.response.data);

                this.setState({ iconLoading: false });
            });
    }

    render() {
        const {
            utilizadorId,
            utilizadores,
            frequencias,
            notas,
            avaliacoesCompetencias,
            avaliacoesCompetenciasMobile,
            tarefas,
            tarefasMobile,
            diarioAvaliacoes,
            //avaliacoes,
            //questionarios,
            //trabalhos,
            outras_avaliacoes,
            outras_avaliacoesMobile,
            relatorios_progresso,
            relatorios_progressoMobile,
            estatisticas,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            loading_table,
            loading_table_frequencias,
            loading_table_notas,
            loading_table_lvl3,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //DRAWER RELATORIO
            relatorioId,
            visibleDrawerRelatorio,
            //DRAWER DETAHE
            visibleDetalhe,
            visibleDetalheRelatorio,
            detalheRegisto,
            detalheClassificacao,
            detalheProgresso,
            detalheDescarregar,
            detalheNotificacao,
            //FORMULA
            ano,
            eja,
            formula
        } = this.state;

        //COLUNAS DA TABELA DE NIVEL 1
        const columns = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-500"
            },
            {
                title: "Datas",
                dataIndex: "registo",
                key: "registo",
                className: "td-200"
            },
            {
                title: "Classificação",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-200",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}</span> {record.medalha && <i className="fas fa-award icon-medalha-curso" title="Medalha de Curso"></i>}</div>,
            },
            {
                title: "Progresso",
                dataIndex: "progresso",
                key: "progresso",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "descarregar",
                className: "td-50"
            },
            //{
            //    title: "",
            //    dataIndex: "opcoes",
            //    className: "td-50"
            //}
        ];

        const columnsMobile = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-500"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        //COLUNAS DA TABELA DE NIVEL 2 AVALICOES
        const columnslvl2Competencias = [
            {
                title: "Avaliação por Competências",
                dataIndex: "avaliacao",
                key: "avaliacao",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-205",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}</span> {record.medalha && <i className="fas fa-award icon-medalha-aula" title="Medalha de Tarefa"></i>}</div>,
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-250",
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ]

        const columnslvl2CompetenciasMobile = [
            {
                title: "Avaliação por Competências",
                dataIndex: "avaliacao",
                key: "avaliacao",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ]

        const columnslvl3Competencias = [
            {
                title: "Competências",
                dataIndex: "competencia",
                key: "competencia"
            }
        ]

        //COLUNAS DA TABELA DE NIVEL 2 AVALICOES
        const columnslvl2TodasAvaliacoes = [
            {
                title: "Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-500"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-205",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}</span> {record.medalha && <i className="fas fa-award icon-medalha-aula" title="Medalha de Tarefa"></i>}</div>,
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-250",
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnslvl2TodasAvaliacoesMobile = [
            {
                title: "Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        //COLUNAS DA TABELA DE NIVEL 2 OUTRAS AVALIAÇÕES
        const columnslvl2OutrasAvaliacoes = [
            {
                title: "Outras Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-500"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "classificacao",
                key: "classificacao",
                className: "td-205",
                render: (text, record) => <div className="bloco-render"><span className="span-text">{text}</span> {record.medalha && <i className="fas fa-award icon-medalha-aula" title="Medalha de Tarefa"></i>}</div>,
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-250"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ];

        const columnslvl2OutrasAvaliacoesMobile = [
            {
                title: "Outras Avaliações",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ];

        //COLUNAS DA TABELA DE NIVEL 2 PROGRESSOS
        const columnslvl2Progressos = [
            {
                title: "Relatórios de Progresso",
                dataIndex: "nome",
                key: "nome",
                className: "td-500"
            },
            {
                title: "",
                dataIndex: "data",
                key: "data",
                className: "td-200"
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-205",
            },
            {
                title: "",
                dataIndex: "",
                key: "",
                className: "td-200",
            },
            {
                title: "",
                dataIndex: "notificacao",
                key: "notificacao",
                className: "td-50"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ];

        const columnslvl2ProgressosMobile = [
            {
                title: "Relatórios de Progresso",
                dataIndex: "nome",
                key: "nome",
                className: "td-450"
            },
            {
                title: "",
                dataIndex: "opcoes",
                key: "opcoes",
                className: "td-50"
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <div className="container container-body">
                    <div className="curso-resultados bloco-formadores bloco-resultados-meus-cursos">
                        <div className="bloco tabelas-utilizador">
                            {(localStorage.getItem("habilitar_gamificacao") === "true" && this.context.gamificacao_entidade) ?
                                <div className="filtro-pesquisa-resultados">
                                    <div
                                        className="bloco-opcoes"
                                        style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", fontSize: 16 }}
                                    >
                                        <Link
                                            to={`/consultar/${localStorage.getItem("nome_curso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-ranking/${utilizadorId}`}
                                            className="botao-icon-ranking"
                                            title="Ranking"
                                        >
                                            <Icon type="star" />
                                        </Link>
                                    </div>
                                </div>
                                : null}
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-desktop" //TABELA LISTA UTILIZADOR (DESKTOP)
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)" }}
                                columns={columns}
                                dataSource={this.carregaDados(utilizadores)}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                className="tabela-avaliacoes-finais tabela-resultados-mobile" //TABELA LISTA UTILIZADOR (MOBILE)
                                style={{ background: "white", boxShadow: "0 0 5px rgba(178, 178, 178, 0.35)", marginTop: 40 }}
                                columns={columnsMobile}
                                dataSource={this.carregaDadosMobile(utilizadores)}
                                pagination={false}
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Collapse className="collapse-table-diario" defaultActiveKey={[]}>
                                {this.state.diario && this.context.diario_entidade ?
                                    <Panel className="panel-tabela-diario" header="Frequência" key="1">
                                        {loading_table_frequencias ?
                                            <div className="loading-data" style={{ minHeight: 100 }}>
                                                <div className="loading" />
                                            </div>
                                            :
                                            <>
                                                <div className="tabela-resultados-desktop">
                                                    <TabelaFrequencia
                                                        utilizadorId={utilizadores[0].id}
                                                        periodos={frequencias}
                                                    />
                                                </div>
                                                <div className="tabela-resultados-mobile">
                                                    <CardsFrequencia
                                                        utilizadorId={utilizadores[0].id}
                                                        periodos={frequencias}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </Panel>
                                    : null}
                                {this.state.diario && this.context.diario_entidade ?
                                    <Panel className="panel-tabela-diario" header={this.state.tipoDiario === "REGULAR" ? "Notas" : "Conceito"} key="2">
                                        {loading_table_notas ?
                                            <div className="loading-data" style={{ minHeight: 100 }}>
                                                <div className="loading" />
                                            </div>
                                            :
                                            <>
                                                {this.state.tipoDiario === "PRE" ?
                                                    <>
                                                        <div className="tabela-resultados-desktop">
                                                            <TabelaNotasPre
                                                                periodos={notas}
                                                                avaliacoes={diarioAvaliacoes}
                                                            />
                                                        </div>
                                                        <div className="tabela-resultados-mobile">
                                                            <CardsNotasPre
                                                                periodos={notas}
                                                                avaliacoes={diarioAvaliacoes}
                                                            />
                                                        </div>
                                                    </>
                                                    : null}
                                                {this.state.tipoDiario === "CRECHE" ?
                                                    <>
                                                        <div className="tabela-resultados-desktop">
                                                            <TabelaNotasCreche
                                                                periodos={notas}
                                                                avaliacoes={diarioAvaliacoes}
                                                            />
                                                        </div>
                                                        <div className="tabela-resultados-mobile">
                                                            <CardsNotasCreche
                                                                periodos={notas}
                                                                avaliacoes={diarioAvaliacoes}
                                                            />
                                                        </div>
                                                    </>
                                                    : null}
                                                {this.state.tipoDiario === "REGULAR" ?
                                                    <>
                                                        <div className="tabela-resultados-desktop">
                                                            <TabelaNotas
                                                                periodos={notas?.periodos}
                                                                ano={ano}
                                                                eja={eja}
                                                                formula={formula}
                                                                total={notas?.total}
                                                                provaFinal={notas?.provaFinal}
                                                                segundaChamada={notas?.segundaChamada}
                                                            />
                                                        </div>
                                                        <div className="tabela-resultados-mobile">
                                                            <CardsNotas
                                                                periodos={notas?.periodos}
                                                                ano={ano}
                                                                eja={eja}
                                                                formula={formula}
                                                                total={notas?.total}
                                                                provaFinal={notas?.provaFinal}
                                                                segundaChamada={notas?.segundaChamada}
                                                            />
                                                        </div>
                                                    </>
                                                    : null}
                                                {this.state.tipoDiario === "REGULAR1E2" || this.state.tipoDiario === "REGULAR4" ?
                                                    <>
                                                        <div className="tabela-resultados-desktop">
                                                            <TabelaNotasRegular
                                                                periodos={notas}
                                                                avaliacoes={diarioAvaliacoes}
                                                                grupos={notas[0]?.grupos}
                                                            />
                                                        </div>
                                                        <div className="tabela-resultados-mobile">
                                                            <CardsNotasRegular
                                                                periodos={notas}
                                                                avaliacoes={diarioAvaliacoes}
                                                                grupos={notas[0]?.grupos}
                                                            />
                                                        </div>
                                                    </>
                                                    : null}
                                            </>
                                        }

                                    </Panel>
                                    : null}
                                {localStorage.getItem("habilitar_competencias") === "true" &&  //TABELA LISTA TODAS AS AVALIAÇÕES POR COMPETENCIA
                                    <Panel className="panel-tabela-diario sem-padding-top" header="Avaliação por Competências" key="3">
                                    <Table
                                        className="tabela_lvl2 tabela-competencias-desktop"
                                        columns={columnslvl2Competencias}
                                        dataSource={avaliacoesCompetencias}
                                        pagination={false}
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                        onExpand={this.listarResultadosLvl2}
                                        expandedRowRender={record => (
                                            <div className="tabela-lvl2">
                                                <div className="bloco-avaliacoes">
                                                    <Table
                                                        id="tabela-avaliacoes-finais_lvl2"
                                                        className="tabela_lvl2"
                                                        columns={columnslvl3Competencias}
                                                        dataSource={avaliacoesCompetencias[record.key].competencias}
                                                        pagination={false}
                                                        locale={{ emptyText: "Não existem registos!" }}
                                                        loading={{
                                                            spinning: loading_table_lvl3,
                                                            indicator: (
                                                                <div className="loading-data-table">
                                                                    <div className="loading" />
                                                                </div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />
                                    <Table
                                        className="tabela_lvl2 tabela-competencias-mobile"
                                        columns={columnslvl2CompetenciasMobile}
                                        dataSource={avaliacoesCompetenciasMobile}
                                        pagination={false}
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                        onExpand={this.listarResultadosLvl2}
                                        expandedRowRender={record => (
                                            <div className="tabela-lvl2">
                                                <div className="bloco-avaliacoes">
                                                    <Table
                                                        id="tabela-avaliacoes-finais_lvl2"
                                                        className="tabela_lvl2"
                                                        columns={columnslvl3Competencias}
                                                        dataSource={avaliacoesCompetenciasMobile[record.key].competencias}
                                                        pagination={false}
                                                        locale={{ emptyText: "Não existem registos!" }}
                                                        loading={{
                                                            spinning: loading_table_lvl3,
                                                            indicator: (
                                                                <div className="loading-data-table">
                                                                    <div className="loading" />
                                                                </div>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />
                                    </Panel>
                                }
                                <Panel className="panel-tabela-diario sem-padding-top" header="Avaliações" key="4">
                                    <Table
                                        className="tabela-avaliacoes-finais tabela-resultados-desktop" //TABELA LISTA TODAS AS AVALIAÇÕES (DESKTOP)
                                        columns={columnslvl2TodasAvaliacoes}
                                        dataSource={tarefas}
                                        pagination={false}
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                    />
                                    <Table
                                        className="tabela-avaliacoes-finais tabela-resultados-mobile" //TABELA LISTA TODAS AS AVALIAÇÕES (MOBILE)
                                        columns={columnslvl2TodasAvaliacoesMobile}
                                        dataSource={tarefasMobile}
                                        pagination={false}
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                    />
                                </Panel>
                                <Panel className="panel-tabela-diario sem-padding-top" header="Outras Avaliações" key="5">
                                    <Table
                                        className="tabela-avaliacoes-finais tabela-resultados-desktop"  //TABELA LISTA TODAS AS OUTRAS AVALIAÇÕES (DESKTOP)
                                        columns={columnslvl2OutrasAvaliacoes}
                                        dataSource={outras_avaliacoes}
                                        pagination={false}
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                    />
                                    <Table
                                        className="tabela-avaliacoes-finais tabela-resultados-mobile"  //TABELA LISTA TODAS AS OUTRAS AVALIAÇÕES (MOBILE)
                                        columns={columnslvl2OutrasAvaliacoesMobile}
                                        dataSource={outras_avaliacoesMobile}
                                        pagination={false}
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                    />
                                </Panel>
                                <Panel className="panel-tabela-diario sem-padding-top" header="Relatórios de Progresso" key="7">
                                    <Table
                                        className="tabela-avaliacoes-finais tabela-resultados-desktop" //TABELA LISTA TODAS OS RELATORIOS DE PROGRESSO (DESKTOP)
                                        columns={columnslvl2Progressos}
                                        dataSource={relatorios_progresso}
                                        pagination={false}
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                    />
                                    <Table
                                        className="tabela-avaliacoes-finais tabela-resultados-mobile" //TABELA LISTA TODAS OS RELATORIOS DE PROGRESSO (MOBILE)
                                        columns={columnslvl2ProgressosMobile}
                                        dataSource={relatorios_progressoMobile}
                                        pagination={false}
                                        loading={{
                                            spinning: loading_table,
                                            indicator: (
                                                <div className="loading-data-table">
                                                    <div className="loading" />
                                                </div>
                                            )
                                        }}
                                        locale={{ emptyText: "Não existem registos!" }}
                                    />
                                </Panel>
                                <Panel className="panel-tabela-diario sem-padding-top" header="Estatísticas" key="8">
                                    <div className="dashboard" style={{ padding: 0, boxShadow: "none", marginTop: 20 }}>
                                        <div className="bloco" style={{ padding: 0, boxShadow: "none" }}>
                                            <div className="grid">
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas visitadas no curso", "")}>
                                                    <Icon type="desktop" />
                                                    <span className="desc">{estatisticas ? estatisticas.tarefas_visitadas : 0}</span>
                                                    <span className="titulo">Tarefas visitadas</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Tempo de consulta nas tarefas visitadas no curso", "")}>
                                                    <Icon type="history" />
                                                    <span className="desc">{estatisticas ? estatisticas.tempos_tarefas : 0}</span>
                                                    <span className="titulo">Tempo nas tarefas</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Tarefas concluídas no curso", "")}>
                                                    <Icon type="check" />
                                                    <span className="desc">{estatisticas ? estatisticas.conteudos_concluidos : 0}</span>
                                                    <span className="titulo">Tarefas concluídas</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Ficheiros adicionados na drive", "")}>
                                                    <Icon type="file-add" />
                                                    <span className="desc">{estatisticas ? estatisticas.doc_fichs : 0}</span>
                                                    <span className="titulo">Ficheiros</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Anotações pessoais adicionadas", "")}>
                                                    <Icon type="file" />
                                                    <span className="desc">{estatisticas ? estatisticas.interacoes_anotacoes : 0}</span>
                                                    <span className="titulo">Anotações</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Mensagens enviadas no grupo do curso", "")}>
                                                    <Icon type="message" />
                                                    <span className="desc">{estatisticas ? estatisticas.mensagens_enviadas : 0}</span>
                                                    <span className="titulo">Mensagens enviadas</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Questões submetidas em fóruns", "")}>
                                                    <Icon type="form" />
                                                    <span className="desc">{estatisticas ? estatisticas.interacoes_forum : 0}</span>
                                                    <span className="titulo">Interações fórum</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Classificações obtidas nas tarefas do curso (quando aplicável)", "")}>
                                                    <Icon type="check-circle" />
                                                    <span className="desc">{estatisticas ? estatisticas.avaliacoesaulas : 0}</span>
                                                    <span className="titulo">Classificações/média<br />(tarefas)</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Rating registado no curso (após a sua conclusão)", "")}>
                                                    <Icon type="number" />
                                                    <span className="desc">{estatisticas ? estatisticas.ratingscursos : 0}</span>
                                                    <span className="titulo">Rating (curso)</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Classificação obtida no curso (após a sua conclusão)", "")}>
                                                    <Icon type="check-circle" />
                                                    <span className="desc">{estatisticas ? estatisticas.avaliacoescursos : 0}</span>
                                                    <span className="titulo">Classificação (curso)</span>
                                                </div>
                                                <div className="item icon-medalha" onClick={() => openNotificationWithIcon("info", "Medalhas recebida ao curso", "")}>
                                                    <i className="fas fa-award icon-curso" style={{ width: 40 }}></i>
                                                    <span className="desc">{estatisticas ? estatisticas.medalhacurso : 0}</span>
                                                    <span className="titulo">Medalha no curso</span>
                                                </div>
                                                <div className="item icon-medalha" onClick={() => openNotificationWithIcon("info", "Medalhas recebidas em tarefas", "")}>
                                                    <i className="fas fa-award icon-aula" style={{ width: 40 }}></i>
                                                    <span className="desc">{estatisticas ? estatisticas.medalhastarefas : 0}</span>
                                                    <span className="titulo">Medalhas em Tarefas</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Assiduidade em sessões presencais com registo", "")}>
                                                    <Icon type="user" />
                                                    <span className="desc">{estatisticas ? estatisticas.assiduidade : 0}</span>
                                                    <span className="titulo">Presenças/Total<br />(frequência)</span>
                                                </div>
                                                <div className="item" onClick={() => openNotificationWithIcon("info", "Presenças em stepmeets realizadas", "")}>
                                                    <Icon type="video-camera" />
                                                    <span className="desc">{estatisticas ? estatisticas.presencasStepmeets : 0}</span>
                                                    <span className="titulo">Presenças/Total<br />(Stepmeets)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="botoes-exportar">
                                            <Link to="#" onClick={this.exportarEstatisticasCSV}>
                                                <Icon type="export" />
                                            Exportar resumo para csv
                                        </Link>
                                            <Link to="#" onClick={this.exportarEstatisticasDET}>
                                                <Icon type="export" />
                                            Exportar detalhe para csv
                                        </Link>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                </div>
                <DrawerRelatrio
                    relatorioId={relatorioId}
                    utilizadorId={utilizadorId}
                    onClose={() => this.setState({ visibleDrawerRelatorio: false })}
                    visibleDrawerRelatorio={visibleDrawerRelatorio}
                />
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                                <>
                                    <p>
                                        <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                    </p>
                                    <p className="texto">Dados exportados com sucesso!</p>
                                    <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                                </>
                            )}
                    </div>
                </Modal>
                <Drawer
                    className="drawer-detalhe-curso"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalhe: false })}
                    visible={visibleDetalhe}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Data">
                                    {detalheRegisto}
                                </Form.Item>
                                {detalheClassificacao &&
                                    <Form.Item label="Classificação">
                                        {detalheClassificacao}
                                    </Form.Item>}
                                <Form.Item label="Progresso">
                                    {detalheProgresso}
                                </Form.Item>
                                {detalheDescarregar &&
                                    <Form.Item label="Certificado">
                                        {detalheDescarregar}
                                    </Form.Item>}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalhe: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Drawer
                    className="drawer-detalhe-curso"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleDetalheRelatorio: false })}
                    visible={visibleDetalheRelatorio}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Data">
                                    {detalheRegisto}
                                </Form.Item>
                                {detalheNotificacao &&
                                    <Form.Item label="Responsável notificado">
                                        {detalheNotificacao}
                                    </Form.Item>}
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleDetalheRelatorio: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormMain = Form.create({ name: "form-cursos-resultados" })(Main);

export default withRouter(FormMain);
