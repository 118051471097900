import React, { useContext, useEffect } from "react";
import MsgSecundaria from "./_Aux/MsgSecundaria";
import { GlobalContext } from "../GlobalState";
import LazyLoad from "react-lazy-load";

const PaginaErro = () => {
    const context = useContext(GlobalContext);

    useEffect(() => {
        context.isAuthenticated();
    }, []);

    return (
        <>
            <div className="pagina-erro">
                <div className="hero">
                    {/* <Navbar /> */}
                    <LazyLoad offsetVertical={200}>
                        <img src={require("../images/wave_hero_user.svg")} />
                    </LazyLoad>
                    <div className="container" style={{ marginTop: 80 }}>
                        <div className="pagina-erro-header">
                            <div className="container-img">
                                <img src={require("../images/erro.svg")} />
                            </div>
                        </div>
                        <div className="pagina-erro-content">
                            <MsgSecundaria texto="Ocorreu um erro!" style={{ fontSize: 38, color: "white" }} />
                            <MsgSecundaria texto="Tente novamente e caso o erro persista entre em contacto com o suporte." />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaginaErro;
