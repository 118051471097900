import React, { Component } from "react";
import Controlos from "./_Aux/Controlos";
import axios from "axios";
import LazyLoad from "react-lazy-load";

class Pagina extends Component {
    state = {
        html: ""
    };

    componentDidMount() {
        this.CarregarConteudo();
    }

    //CARREGA CONTEUDO DA AULA
    CarregarConteudo = () => {
        axios({
            method: "get",
            url: "/api/player-trabalho/carregar-ficheiro",
            params: {
                ficheiroId: localStorage.getItem("codigo_documento")
            }
        })
            .then(response => {
                this.setState({
                    html: response.data
                });
            })
            .catch(() => { });
    };

    render() {
        return (
            <div>
                <div className="pagina-html">
                    <div className="player-wrapper">
                        <LazyLoad offsetVertical={200}>
                            <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                        </LazyLoad>
                        <div className="container">
                            <div className="bloco-html">
                                <div className="bloco-info" dangerouslySetInnerHTML={{ __html: this.state.html }}></div>
                            </div>
                        </div>
                        <Controlos />
                    </div>
                </div>
            </div>
        );
    }
}

export default Pagina;
