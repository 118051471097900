import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Tooltip, Icon, notification } from "antd";
import Navbar from "../User/Navbar";
import Header from "../User/Header";
import axios from "axios";
import { GlobalContext } from "../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Alterarlogin extends Component {
    static contextType = GlobalContext;
    state = {
        old_login: "",
        new_login: "",
        iconLoading: false
    };

    componentDidMount() {
        this.registarOperacao();
        //if (!this.context.is_authenticated)
        //    this.setState({
        //        redirect: true,
        //        redirectLink: "/login"
        //    });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    compareToFirstlogin = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue("new_login")) {
            callback("Dados de login não coincidentes");
        } else {
            callback();
        }
    };

    validateToNextlogin = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(["confirm"], { force: true });
        }
        callback();
    };

    registarOperacao = () => {
        axios({
            method: "post",
            url: "api/minha-conta/registar-operacao",
            params: {
                pagina: "Página Alterar Login"
            }
        })
            .then(() => {

            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    alterarlogin = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                axios({
                    method: "post",
                    url: "/api/alterar-login/alterar",
                    params: {
                        oldlogin: this.state.old_login,
                        newlogin: this.state.new_login
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Login alterado!");
                        this.setState({ iconLoading: false });
                        this.props.form.resetFields();
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Alterar Login" />
                <div className="container container-body">
                    <div className="bloco-alterar-password">
                        <div className="bloco-info">
                            <Form className="alterar-password">
                                <Form.Item
                                    label={
                                        <span>
                                            Login atual
                                            <Tooltip
                                                className="info-icon"
                                                title={
                                                    <>
                                                        Caso não se lembre do login atual, use a opção <strong>Suporte</strong> para
                                                        solictar ajuda.
                                                    </>
                                                }
                                            >
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </span>
                                    }
                                >
                                    {getFieldDecorator("old_login", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                                whitespace: true
                                            }
                                        ]
                                    })(<Input name="old_login" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label={<span>Novo login</span>}>
                                    {getFieldDecorator("new_login", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                                whitespace: true
                                            },
                                            {
                                                validator: this.validateToNextlogin
                                            }
                                        ]
                                    })(<Input name="new_login" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label={<span>Confirmar login</span>}>
                                    {getFieldDecorator("confirmar-login", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                                whitespace: true
                                            },
                                            {
                                                validator: this.compareToFirstlogin
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                                <button
                                    className="botao-principal"
                                    disabled={iconLoading}
                                    onClick={e => {
                                        this.alterarlogin(e);
                                        // this.logOperacao("Guardar");
                                    }}
                                >
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Alterar
                                </button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const FormAlterarlogin = Form.create({ name: "form-alterar-login" })(Alterarlogin);

export default FormAlterarlogin;
