import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Input, InputNumber, Checkbox, Select, notification, DatePicker, TimePicker, Tooltip, Icon } from "antd";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../GlobalState"
import noimage from "../../images/noimage.png";
import DrawerPesquisar from "./DrawerPesquisar";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class NovoGrupo extends Component {
    static contextType = GlobalContext;
    state = {
        capa: "",
        preview: "",
        nome: "",
        //PARTICIANTES
        participantes: [],
        //DRAWER PESQUISAR PARTICIPATES,
        visibleDrawerPesqusiar: false,
        //LOADING
        iconLoading: false,
    };

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    criar = iniciar => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.participantes.length > 0) {
                this.setState({
                    iconLoading: true
                });

                let item = new FormData()
                item.append("participantes", JSON.stringify(this.state.participantes));
                item.append("capa", this.state.capa);
                item.append("nome", this.state.nome);

                axios({
                    method: "post",
                    url: "/api/chat/adicionar",
                    data: item
                })
                    .then(response => {
                        if (response.data)
                            openNotificationWithIcon("success", "Sucesso", "Criada nova Conversa");

                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizarGrupos(response.data);
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
            else {
                if (this.state.participantes.length === 0)
                    openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Conversa");
            }
        })
    };

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.state.participantes.length > 0) {
                this.setState({
                    iconLoading: true
                });

                let item = new FormData()
                item.append("id", this.props.grupoId);
                item.append("participantes", JSON.stringify(this.state.participantes));
                item.append("capa", this.state.capa);
                item.append("nome", this.state.nome);

                axios({
                    method: "post",
                    url: "/api/chat/alterar",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Conversa alterada");

                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizarGrupos();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
            else {
                if (this.state.participantes.length === 0)
                    openNotificationWithIcon("error", "Erro", "Não existem participantes para esta Conversa");
            }
        });
    };

    carregarDetalhe = grupoId => {
        axios({
            method: "get",
            url: "/api/chat/detalhe",
            params: {
                grupoId
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data.participantes,
                    capa: response.data.capa,
                    preview: response.data.capa,
                    nome: response.data.nome,
                });
            })
            .catch(() => { });
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.grupoId) {
                this.carregarDetalhe(this.props.grupoId);
            }
            else {
                this.setState({
                    capa: "",
                    preview: "",
                    nome: "",
                    //PARTICIANTES
                    participantes: [],
                    //DRAWER PESQUISAR PARTICIPATES,
                    visibleDrawerPesqusiar: false
                })
            }
        }
    };

    //UPLOAD DA FOTO/IMAGEM DO CURSO
    uploadFoto = event => {
        if (event.target.files[0].size < 100 * 1024) {
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                this.setState({
                    capa: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
            }
            else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
            }
        }
        else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 100 KB para a imagem.");
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        let formatos = ["jpg", "jpeg", "png", "gif"];
        let valido = false;
        for (let i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //ABRE O FILE BROWSER
    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //REMOVE A FOTO
    removerFoto = () => {
        document.getElementById("input-foto").value = "";
        this.setState({
            capa: "",
            preview: ""
        });
    };

    render() {
        const { TextArea } = Input;
        const { getFieldDecorator } = this.props.form;
        const {
            capa,
            preview,
            nome,
            //PARTICIANTES
            participantes,
            //DRAWER PESQUISAR PARTICIPATES,
            visibleDrawerPesqusiar,
            //LOADING
            iconLoading,
        } = this.state;

        const { grupoId } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias"
                    title={grupoId ? "Alterar Grupo" : "Adicionar novo Grupo"}
                    width={720}
                    onClose={this.props.onClose}
                    visible={this.props.visibleNovoGrupo}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <h3 className="titulo-separador">
                                    Membros
                                </h3>
                                <Form.Item className="form-item-checkbox">
                                    <div className="lista-participantes">
                                        <label className="label-participantes">
                                            Incluidos os seguintes
                                            <button className="botao-principal" onClick={() => this.setState({ visibleDrawerPesqusiar: true })}>
                                                <Icon type="plus" />
                                            </button>
                                        </label>
                                        {participantes.length > 0 ?
                                            participantes.map((participante, index) => (
                                                <div key={index} className="participante">
                                                    <div className="info-participante">
                                                        <div className="container-img">
                                                            <img src={participante.foto ? participante.foto : noimage} />
                                                        </div>
                                                        <div className="info">
                                                            <span className="nome">
                                                                {participante.nome}
                                                            </span>
                                                            <p className="numero">{participante.numero}</p>
                                                        </div>
                                                    </div>
                                                    <div className="opcoes">
                                                        <button className="botao-excluir" onClick={() => this.setState({ participantes: [...this.state.participantes.filter(x => x.id !== participante.id)] })}>
                                                            <Icon type="delete" />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <span className="sem-registos">Sem registos</span>
                                        }
                                    </div>
                                </Form.Item>
                            </div>
                            {participantes.length > 1 &&
                                <>
                                <div className="bloco">
                                    <h3 className="titulo-separador">
                                        Grupo
                                    </h3>
                                    <Form.Item>
                                        <div className="bloco-container-img">
                                            <div className="container-img">
                                                <img src={preview ? preview : noimage} alt="Logo do curso" />
                                            </div>
                                            <input
                                                type="file"
                                                id="input-foto"
                                                accept="image/*"
                                                onChange={this.uploadFoto}
                                                style={{ display: "none" }}
                                            />
                                            {!preview ? (
                                                <>
                                                    <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                                        <span>Adicionar</span>
                                                    </Link>{" "}
                                                    <Tooltip
                                                        className="info-icon"
                                                        title={
                                                            <div>
                                                                <p>Tamanho recomendado: 200x200px</p>
                                                                <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                            </div>
                                                        }
                                                    >
                                                        <Icon type="question-circle-o" />
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                    <>
                                                        <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto} style={{ marginRight: 20 }}>
                                                            <span>Alterar</span>
                                                        </Link>
                                                        <Link to="#" className="link-principal-border" onClick={this.removerFoto}>
                                                            <span>Remover</span>
                                                        </Link>
                                                    </>
                                                )}
                                        </div>
                                    </Form.Item>
                                    </div>
                                    <div className="bloco">
                                        <Form.Item label="Nome do grupo">
                                            {getFieldDecorator("nome", {
                                                rules: [{
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }],
                                                initialValue: nome
                                            })(<Input name="nome" maxLength={40} onChange={this.handlerChange} />)}
                                        </Form.Item>
                                    </div>
                                </>}
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <Link
                            to="#"
                            className="botao-secundario"
                            onClick={this.props.onClose}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                                </Link>
                        {grupoId ? (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.alterar}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                    Guardar
                            </button>)
                            :
                            <button className="botao-principal" disabled={iconLoading} onClick={this.criar}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                                </button>}
                    </div>
                </Drawer>
                <DrawerPesquisar
                    onClose={() => this.setState({ visibleDrawerPesqusiar: false })}
                    participantes={participantes}
                    visibleDrawerPesqusiar={visibleDrawerPesqusiar}
                    selecionarParticipante={participante => this.setState({ participantes: [...participantes, participante] })}
                />
            </>
        );
    }
}
const FormNovoGrupo = Form.create({ name: "form-novo-grupo" })(NovoGrupo);

export default FormNovoGrupo;
