import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, Table, notification, Icon, Form, Input, Dropdown, Switch, Menu, Modal, Tooltip } from "antd";
import Header from "../User/Header";
import noimage from "../images/noimage.png";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";
import { GlobalContext } from "../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Edicoes extends Component {
    static contextType = GlobalContext;
    state = {
        edicoes: [],
        ativo: true,
        //DRAWER
        edicaoId: 0,
        nome: "",
        visible: false,
        iconLoading: false,
        //LOADING
        loading_table: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listar();
    }

    componentDidUpdate() {
        if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
            this.setState({
                redirect: true,
                redirectLink: "/user"
            });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //MONTA AS OPÇÕES DE MENU POR CADA EDIÇÃO NA TABELA
    montarMenu = (edicaoId, ativo) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.carregarEdicao(edicaoId)}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.excluir(edicaoId)}>
                        Excluir
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.ativar(edicaoId, ativo)}>
                        {ativo ? "Inativar" : "Ativar"}
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    //CARREGA TODAS AS EDIÇÕES DA ENTIDADE PARA A TABELA
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-edicoes/listar-edicoes",
                    params: {
                        ativo: this.state.ativo
                    }
                })
                    .then(response => {
                        var edicoes = [];

                        response.data.map((edicao, index) => {
                            edicoes.push({
                                key: index,
                                id: edicao.id,
                                edicao: edicao.nome,
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(edicao.id, edicao.ativo)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });
                        });

                        this.setState({
                            edicoes,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    //ABRE O DRAWER COM OS DADOS DA EDIÇÃO
    carregarEdicao = edicaoId => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-edicoes/carregar-edicao",
            params: {
                edicaoId
            }
        })
            .then(response => {
                this.setState({
                    edicaoId: response.data.id,
                    nome: response.data.nome,
                    ativo: response.data.ativo,
                    visible: true
                });
            })
            .catch(() => { });
    };

    //CRIA UMA NOVA EDIÇÃO
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome", this.state.nome);

                axios({
                    method: "post",
                    url: "/api/gestao-edicoes/adicionar-edicao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionada nova edição!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATUALIZA A EDIÇÕES
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.edicaoId);
                item.append("nome", this.state.nome);

                axios({
                    method: "put",
                    url: "/api/gestao-edicoes/alterar-edicao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Edição alterada!");
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //EXCLUI A EDIÇÃO
    excluir = edicaoId => {
        confirm({
            title: "Pretende excluir esta Edição?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-edicoes/excluir-edicao",
                    params: {
                        edicaoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Edição excluida!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não é possível excluir este registo!");
                    });
            }
        });
    };

    //ATIVA E INATIVA A EDIÇÃO
    ativar = (edicaoId, ativo) => {
        confirm({
            title: ativo ? "Pretende inativar esta edição?" : "Pretende ativar esta edição?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "put",
                    url: "/api/gestao-edicoes/ativar-edicao",
                    params: {
                        edicaoId
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", ativo ? "Edição inativada!" : "Edição ativada!");
                        this.listar();
                    })
                    .catch(error => {
                        openNotificationWithIcon(
                            "error",
                            "Erro",
                            ativo ? "Não foi possível inativar a edição!" : "Não foi possível ativar a edição!"
                        );
                    });
            }
        });
    };

    //ABRE O DRAWER VAZIO PARA CRIAR UMA NOVA EDIÇÃO
    showDrawer = () => {
        this.props.form.resetFields();
        this.setState({
            edicaoId: 0,
            nome: "",
            visible: true,
        });
    };

    //FECHA O DRAWER SEM GUARDAR A EDIÇÃO
    onClose = () => {
        this.setState({
            visible: false
        });
    };

    render() {
        const {
            edicaoId,
            nome,
            visible,
            iconLoading,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //LOADING
            loading_table,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const columns = [
            {
                title: "Edição",
                dataIndex: "edicao"
            },
            {
                title: "",
                dataIndex: "opcoes",
                width: 10
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Edições" />
                <div className="container container-body">
                    <div className="bloco-categorias">
                        <div className="bloco-tabela">
                            <div className="filtros">
                                <Switch
                                    checkedChildren="Ativos"
                                    unCheckedChildren="Inativos"
                                    defaultChecked
                                    onChange={this.handleChangeSwitch}
                                />
                            </div>
                            <Table
                                id="tabela_categorias"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={this.state.edicoes}
                                pagination={false}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="opcoes-meus-cursos">
                    <Link to="#" className="btn-adicionar" title="Adicionar" onClick={this.showDrawer}>
                        +
                    </Link>
                </div>
                <Drawer
                    className="drawer-add-cursos"
                    title={edicaoId ? "Alterar Edição" : "Adicionar nova Edição"}
                    width={720}
                    onClose={this.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" onChange={this.handleChange} />)}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {edicaoId ? (
                            <button className="botao-principal" onClick={this.alterar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                                <button className="botao-principal" onClick={this.criar} disabled={iconLoading} type="primary">
                                    {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                                </button>
                            )}
                    </div>
                </Drawer>
            </div>
        );
    }
}
const FormEdicoes = Form.create({ name: "form-edicoes" })(Edicoes);

export default FormEdicoes;
