import React, { Component } from "react";
import { Button } from "antd";
import axios from "axios";


class Testes extends Component {
    state = {
        link: "",
        iconLoading: false
    }

    testes = () => {
        this.setState({ iconLoading: true });
        axios({
            method: "post",
            url: "/api/anonimo-testes/finalizar"
        })
            .then(response => {
                this.setState({
                    link: response.data,
                    iconLoading: false
                });
            })
            .catch(error => {
                this.setState({ iconLoading: false });
            });
    }

    apagar = () => {
        axios({
            method: "put",
            url: "/api/anonimo-testes/apagar-alunos"
        })
            .then(response => {
                alert("OK");
            })
            .catch(error => {
                alert("NOK");
            });
    }

    enviar = () => {
        axios({
            method: "get",
            url: "/api/anonimo-testes/enviar"
        })
            .then(response => {
                console.log("OK");
            })
            .catch(error => {
                console.log("NOK");
            });
    }

    render() {
        const {
            link,
            iconLoading
        } = this.state;

        return (
            <div>
                <h3>Gerar PDF</h3>
                <Button type="primary" className="login-form-button" onClick={this.testes}>
                    Gerar
                                </Button>
                <a href={link} download>{iconLoading ? "A gerar..." : "Descarregar"}</a>
                <hr />
                <h3>Apagar Alunos</h3>
                <Button type="primary" className="login-form-button" onClick={this.apagar}>
                    Apagar
                </Button>
                <hr />
                <h3>Enviar</h3>
                <Button type="primary" className="login-form-button" onClick={this.enviar}>
                    Enviar
                </Button>
            </div>
        );
    }
}

export default Testes;
