import React, { Component } from "react";
import { Drawer, Form, Input, Tooltip, Icon, notification, Checkbox } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerAtribuirClassificacao extends Component {
    state = {
        iconLoading: false
    };

    UNSAFE_componentWillUpdate() {
        this.props.form.resetFields();
    }

    guardar = event => {
        event.preventDefault();

        //this.props.form.validateFieldsAndScroll((err, values) => {
        //    if (!err) {
        this.setState({ iconLoading: true });
        var item = new FormData();
        item.append("id_curso", this.props.cursoid);
        item.append("id_utilizador", this.props.utilizadorid);
        if (this.props.classificacao)
            item.append("classificacao", this.props.classificacao);
        if (this.props.observacoes)
            item.append("observacoes", this.props.observacoes);
        item.append("medalha", this.props.medalha);
        item.append("notificar", this.props.notificar);

        axios({
            method: "put",
            url: "/api/gestao-curso-resultados/atribuir-classificacao-final",
            data: item
        })
            .then(() => {
                this.setState({ iconLoading: false });
                this.props.onCloseAtribuirClassificacaoAtualizar();
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível guardar!");
                this.setState({ iconLoading: false });
            });
        //}
        //});
    };

    render() {
        const { TextArea } = Input;
        const { iconLoading } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                className="drawer-from-outra-avaliacao"
                title="Classificação"
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
            >
                <div className="bloco-info">
                    <Form className="form-modulo" layout="horizontal">
                        <Form.Item label="Classificação">
                            {/*{getFieldDecorator("atribuir_classificacao", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: this.props.classificacao
                            })(<Input name="atribuir_classificacao" onChange={this.props.handleChange} />)}*/}
                            <Input value={this.props.classificacao} name="atribuir_classificacao" onChange={this.props.handleChange} />
                        </Form.Item>
                        <Form.Item label="Observações">
                            <TextArea value={this.props.observacoes} name="atribuir_observacoes" onChange={this.props.handleChange} rows="3" style={{ height: "100%" }} />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox name="atribuir_medalha" checked={this.props.medalha} onChange={this.props.handlerChangeCheck}>
                                Atribuir medalha
                            </Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Checkbox name="atribuir_notificar" checked={this.props.notificar} onChange={this.props.handlerChangeCheck}>
                                Notificar por e-mail
                                <Tooltip className="info-icon" title="Notificar os participantes por e-mail">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </Checkbox>
                        </Form.Item>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.guardar}>
                        {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                    </button>
                </div>
            </Drawer>
        );
    }
}
const FormDrawerAtribuirClassificacao = Form.create({ name: "form-atribuir-classificacao" })(DrawerAtribuirClassificacao);

export default FormDrawerAtribuirClassificacao;
