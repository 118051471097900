import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Icon, notification } from "antd";
import axios from "axios";
import noimage from "../../images/noimage.png";
import { GlobalContext } from "../../GlobalState";
import Oops from "../../Geral/Oops.jsx";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class CategoriasFavoritas extends Component {
    static contextType = GlobalContext;
    state = {
        categorias: [],
        categorias_selecionadas: [],
        //LOADING
        iconLoading: false,
        loading: true,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.listarCategorias();
    }

    //LISTA AS CATEGORIAS
    listarCategorias = () => {
        axios({
            method: "get",
            url: "/api/minha-conta/listar-categorias"
        })
            .then(response => {
                var categorias = [];
                var categorias_selecionadas = [];

                response.data.map(categoria => {
                    if (categoria.selecionado)
                        categorias_selecionadas.push(categoria.id);

                    categorias.push({
                        id: categoria.id,
                        nome: categoria.nome,
                        ativo: categoria.selecionado,
                        estado: categoria.selecionado ? "botao-categoria-on" : "botao-categoria-off"
                    });
                });

                this.setState({
                    categorias: categorias,
                    categorias_selecionadas,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //GUARDA NUM ARRAY AS CATEGORIAS SELECIONADAS
    selecionarCategorias = (categoriaid, index) => {
        var categorias = this.state.categorias;
        categorias[index].ativo = !categorias[index].ativo;
        categorias[index].estado = categorias[index].ativo ? "botao-categoria-on" : "botao-categoria-off";

        if (categorias[index].ativo) {
            var categorias_selecionadas = this.state.categorias_selecionadas;

            categorias_selecionadas.push(categoriaid);

            this.setState({
                categorias_selecionadas,
                categorias
            });
        } else {
            let categorias_selecionadas = this.state.categorias_selecionadas;
            var indexOf = categorias_selecionadas.indexOf(categoriaid);
            categorias_selecionadas.splice(indexOf, 1);

            this.setState({
                categorias_selecionadas,
                categorias
            });
        }
    };

    //ALTUALIZA OS DADOS DO UTILIZADOR
    atualizarDados = event => {
        event.preventDefault();

        this.setState({ iconLoading: true });

        axios({
            method: "put",
            url: "/api/minha-conta/atualizar-categorias",
            params: {
                lista: JSON.stringify(this.state.categorias_selecionadas)
            }
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                this.setState({
                    iconLoading: false
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                this.setState({
                    iconLoading: false
                });
            });
    };

    render() {
        const {
            categorias,
            //LOADING
            iconLoading,
            loading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="bloco-minha-conta">
                <div className="categorias-favoritas">
                    <div className="bloco-info">
                        {categorias.length ?
                            <Form className="minha-conta">
                                <div className="bloco">
                                    <h3 className="titulo-separador">Selecione as categorias para as quais pretende receber Newsletters</h3>
                                    <Form.Item>
                                        <div className="bloco-categorias-minha-conta">
                                            <div className="categorias-minha-conta">
                                                {loading ? (
                                                    <div className="loading-data">
                                                        <div className="loading" />
                                                    </div>
                                                ) : (
                                                        categorias.map((categoria, index) => (
                                                            <div
                                                                className={"categoria " + categoria.estado}
                                                                key={categoria.id}
                                                                onClick={() => this.selecionarCategorias(categoria.id, index)}
                                                            >
                                                                <div className="cabecalho">
                                                                    <div className="container-img">
                                                                        <img src={categoria.icon ? categoria.icon : noimage} alt="imagem" />
                                                                    </div>
                                                                    <span className="titulo"> {categoria.nome}</span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )}
                                            </div>
                                        </div>
                                    </Form.Item>
                                    <button
                                        className="botao-principal"
                                        style={{ padding: "10px 30px", marginTop: 20 }}
                                        onClick={this.atualizarDados}
                                        disabled={iconLoading}
                                    >
                                        {iconLoading ? <Icon type="loading" /> : null}
                                    Guardar
                                    </button>
                                </div>
                            </Form>
                            :
                            <Oops />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default CategoriasFavoritas;
