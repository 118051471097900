import React from "react";
import MsgSecundaria from "./_Aux/MsgSecundaria";
import LazyLoad from "react-lazy-load";

const PaginaSucesso = () => {
    return (
        <>
            <div className="pagina-erro">
                <div className="hero">
                    {/* <Navbar /> */}
                    <LazyLoad offsetVertical={200}>
                        <img src={require("../images/wave_hero_user.svg")} />
                    </LazyLoad>
                    <div className="container" style={{ marginTop: 80 }}>
                        <div className="pagina-erro-header">
                            <div className="container-img">
                                <img src={require("../images/sucesso.svg")} />
                            </div>
                        </div>
                        <div className="pagina-erro-content">
                            <MsgSecundaria texto="O registro foi efetuado com sucesso!" style={{ fontSize: 38, color: "white" }} />
                            <MsgSecundaria texto="Queira confirmar o seu e-mail para definir a sua senha de acesso à plataforma." />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaginaSucesso;
