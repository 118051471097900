import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, notification, Icon } from "antd";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Links extends Component {
    static contextType = GlobalContext;

    state = {
        id: "",
        facebook: "",
        twitter: "",
        linkedin: "",
        instagram: "",
        email: "",
        site: "",
        whatsapp: "",
        //reclamacoes: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        this.carregarDados();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    carregarDados = () => {
        axios({
            method: "get",
            url: "/api/empresa/links"
        })
            .then(response => {
                this.setState({
                    id: response.data.id,
                    facebook: response.data.facebook ? response.data.facebook : "",
                    twitter: response.data.twitter ? response.data.twitter : "",
                    linkedin: response.data.linkedin ? response.data.linkedin : "",
                    instagram: response.data.instagram ? response.data.instagram : "",
                    email: response.data.email ? response.data.email : "",
                    site: response.data.site ? response.data.site : "",
                    whatsapp: response.data.whatsapp ? response.data.whatsapp : "",
                    //reclamacoes: response.data.reclamacoes ? response.data.reclamacoes : ""
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    atualizarDados = event => {
        event.preventDefault();

        this.setState({ iconLoading: true });

        var item = new FormData();
        item.append("facebook", this.state.facebook);
        item.append("twitter", this.state.twitter);
        item.append("linkedin", this.state.linkedin);
        item.append("instagram", this.state.instagram);
        item.append("email", this.state.email);
        item.append("site", this.state.site);
        item.append("whatsapp", this.state.whatsapp);
        //item.append("reclamacoes", this.state.reclamacoes);

        axios({
            method: "put",
            url: "/api/empresa/alterar-links",
            data: item
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                this.context.atualizarState({
                    atualizarEntidadeFooter: true,
                    whatsapp_entidade: this.state.whatsapp
                });
                this.setState({
                    iconLoading: false
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
            });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            facebook,
            twitter,
            linkedin,
            instagram,
            email,
            site,
            whatsapp,
            //reclamacoes,
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="bloco-redes-sociais">
                <Form className="personalizacao">
                    <div className="bloco">
                        <h3 className="titulo-separador">Endereços das contas sociais a constar no rodapé das páginas do Canal</h3>
                        <Form.Item
                            label={
                                <>
                                    <Icon type="facebook" />
                                Facebook
                            </>
                            }
                            className="redes-sociais-link"
                        >
                            <Input value={facebook} name="facebook" className="input-50" onChange={this.handleChange} />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    <Icon type="twitter" />
                                Twitter
                            </>
                            }
                            className="redes-sociais-link"
                        >
                            <Input value={twitter} name="twitter" className="input-50" onChange={this.handleChange} />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    <Icon type="linkedin" />
                                LinkedIn
                            </>
                            }
                            className="redes-sociais-link"
                        >
                            <Input value={linkedin} name="linkedin" className="input-50" onChange={this.handleChange} />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    <Icon type="instagram" />
                                Instagram
                            </>
                            }
                            className="redes-sociais-link"
                        >
                            <Input value={instagram} name="instagram" className="input-50" onChange={this.handleChange} />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    <i className="fab fa-whatsapp"></i>
                                WhatsApp
                            </>
                            }
                            className="redes-sociais-link"
                        >
                            <Input value={whatsapp} name="whatsapp" className="input-50" onChange={this.handleChange} />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    <Icon type="mail" />
                                E-mail
                            </>
                            }
                            className="redes-sociais-link"
                        >
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        type: "email",
                                        message: "Introduza um endereço de e-mail válido"
                                    }
                                ],
                                initialValue: email
                            })(<Input name="email" className="input-50" onChange={this.handleChange} />)}
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    <Icon type="global" />
                                Site
                            </>
                            }
                            className="redes-sociais-link"
                        >
                            <Input value={site} name="site" className="input-50" onChange={this.handleChange} />
                        </Form.Item>
                        {/*<Form.Item
                            label={
                                <>
                                    <Icon type="read" />
                                Livro de Reclamações
                            </>
                            }
                            className="redes-sociais-link"
                        >
                            <Input value={reclamacoes} name="reclamacoes" className="input-50" onChange={this.handleChange} />
                        </Form.Item>*/}
                        <button className="botao-principal" onClick={this.atualizarDados} disabled={iconLoading}>
                            {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                    </div>
                </Form>
            </div>
        );
    }
}

const FormLinks = Form.create({ name: "form-links" })(Links);

export default FormLinks;
