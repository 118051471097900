import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Select, Form, Checkbox, notification, Icon, Input } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../../../images/noimage.png";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ListagemAlunos extends Component {
    state = {
        //DRAWER PRESENÇAS
        dt_inicio: "",
        falta: false,
        presenca: false,
        presencas: [],
        observacoes: "",
        participantes: [],
        loadingParticipantes: false,
        //LOADING
        iconLoading: false
    };

    componentDidMount() {
        this.listar();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeParticipante = (event, participanteId) => {
        var presencas = this.state.presencas;
        var index = presencas.findIndex(x => x.id === participanteId)
        presencas[index].observacoes = event.target.value;

        this.setState({ presencas });
    };

    handlerChangeCheckboxPresencaColetiva = event => {
        this.props.form.resetFields();

        this.setState({
            presenca: event.target.checked,
            falta: false,
            presencas: this.state.participantes.map(participante => {
                //if (!moment(participante.dtCriado).isAfter(this.state.dt_inicio, 'days')) {
                    var registo = this.state.presencas.find(x => x.id === participante.id);
                    registo.estado = "P";
                    return registo;
                //}
                //else
                //    return this.state.presencas.find(x => x.id === participante.id)
            })
        });
    };

    handlerChangeCheckboxFaltaColetiva = event => {
        this.props.form.resetFields();
        this.setState({
            falta: event.target.checked,
            presenca: false,
            presencas: this.state.participantes.map(participante => {
                //if (!moment(participante.dtCriado).isAfter(this.state.dt_inicio, 'days')) {
                    var registo = this.state.presencas.find(x => x.id === participante.id);
                    registo.estado = "F";
                    return registo;
                //}
                //else
                //    return this.state.presencas.find(x => x.id === participante.id)
            })
        });
    };

    handlerChangeCheckboxPresencas = (event, participanteId) => {
        var presencas = this.state.presencas;
        var index = presencas.findIndex(x => x.id === participanteId)
        presencas[index].presente = event.target.checked;

        this.setState({
            falta: false,
            presenca: false,
            presencas
        });
    };

    handlerChangeSelect = (value, participanteId) => {
        var presencas = this.state.presencas;
        var index = presencas.findIndex(x => x.id === participanteId)
        presencas[index].estado = value;

        this.setState({
            falta: false,
            presenca: false,
            presencas
        });
    };

    listar = () => {
        this.setState(
            {
                loadingParticipantes: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/detalhe-assiduidade",
                    timeout: 60 * 10 * 1000,
                    params: {
                        aulaId: this.props.aulaId
                    }
                })
                    .then(response => {
                        if (!response.data.presenca && !response.data.falta && !response.data.presencas) {
                            let presencas = response.data.participantes.map(participante => {
                                return {
                                    id: participante.id,
                                    estado: undefined,
                                    observacoes: "",
                                    //bloqueado: moment(participante.dtCriado).isAfter(response.data.dt_inicio, 'days')
                                }
                            })

                            this.setState({
                                dt_inicio: response.data.dt_inicio,
                                presencas,
                                participantes: response.data.participantes,
                                loadingParticipantes: false
                            });
                        }
                        else {
                            let presencas = JSON.parse(response.data.presencas);
                            if (presencas.length === 0)
                                presencas = response.data.participantes.map(participante => {
                                    return {
                                        id: participante.id,
                                        estado: undefined,
                                        observacoes: "",
                                        //bloqueado: moment(participante.dtCriado).isAfter(response.data.dt_inicio, 'days')
                                    }
                                })
                            else
                                presencas = response.data.participantes.map(participante => {
                                    var registo = presencas.find(x => x.id === participante.id)
                                    if (registo) {
                                        if (registo.presente)
                                            registo.estado = registo.presente ? "P" : "F";
                                        //registo.bloqueado = moment(participante.dtCriado).isAfter(response.data.dt_inicio, 'days');
                                        return registo;
                                    }
                                    else
                                        return {
                                            id: participante.id,
                                            estado: undefined,
                                            observacoes: "",
                                            //bloqueado: moment(participante.dtCriado).isAfter(response.data.dt_inicio, 'days')
                                        }
                                })

                            this.setState({
                                dt_inicio: response.data.dt_inicio,
                                presenca: response.data.presenca,
                                falta: response.data.falta,
                                presencas,
                                observacoes: response.data.observacoes ? response.data.observacoes : "",
                                participantes: response.data.participantes,
                                loadingParticipantes: false
                            });
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    }

    guardarPresencas = () => {
        if (this.state.presenca || this.state.falta || this.state.presencas.filter(x => x.estado || x.observacoes).length) {
            this.setState({ iconLoading: true })

            var item = new FormData()
            item.append("id", this.props.aulaId)
            item.append("presenca", this.state.presenca)
            item.append("falta", this.state.falta)
            item.append("presencas", JSON.stringify(this.state.presencas))
            item.append("observacoes", this.state.observacoes)

            axios({
                method: "put",
                url: "/api/aulas/alterar-assiduidade",
                data: item,
                timeout: 60 * 30 * 1000
            })
                .then(() => {
                    openNotificationWithIcon("success", "Sucesso", "Assiduidade guardada!")
                    this.setState({ iconLoading: false })
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi guardar assiduidade!")
                    this.setState({ iconLoading: false })
                })
        }
        else {
            openNotificationWithIcon("info", "Informação", "Não existem registos a serem guardados")
        }
    }

    montarParticipante = (participanteId) => {
        var participante = this.state.participantes.find(x => x.id === participanteId);

        if (participante)
            return <div className="participante">
                <div className="info-participante">
                    <div className="container-img">
                        <img src={participante.foto ? participante.foto : noimage} />
                    </div>
                    <div className="info">
                        <span className="nome">{participante.nome}</span>
                        <p className="numero">{participante.numero}</p>
                    </div>
                </div>
            </div>
    }

    render() {
        const { TextArea } = Input;
        const {
            //DRAWER PRESENÇAS
            falta,
            presenca,
            presencas,
            observacoes,
            //LOADING
            iconLoading
        } = this.state;

        return (
            <>
                <div className="bloco-presencial">
                    <div className="bloco-frequencia-diario" style={{ boxShadow: "none" }}>
                        <div className="listagem-participantes" style={{ marginTop: 0 }}>
                            <Form className="form-presencas" layout="horizontal">
                                <div className="bloco">
                                    <Form.Item className="form-item-checkbox">
                                        <Checkbox name="presenca" checked={presenca} onChange={this.handlerChangeCheckboxPresencaColetiva}>
                                            Presença coletiva
                                                            </Checkbox>
                                        <Checkbox name="falta" checked={falta} onChange={this.handlerChangeCheckboxFaltaColetiva}>
                                            Falta coletiva
                                                            </Checkbox>
                                    </Form.Item>
                                </div>
                                <div className="bloco">
                                    <Form.Item>
                                        <div className="listagem-presencas">
                                            {presencas.map((presenca, index) => (
                                                <div key={presenca.id} className="item-presenca">
                                                    <div className="header">
                                                        {this.montarParticipante(presenca.id)}
                                                        <div className="opcao">
                                                            <Form.Item>
                                                                <Select value={presenca.estado} placeholder="Selecionar" allowClear={true} onChange={(e) => this.handlerChangeSelect(e, presenca.id)}>
                                                                    <Option value="P">
                                                                        Presença
                                                                    </Option>
                                                                    <Option value="F">
                                                                        Falta
                                                                    </Option>
                                                                    <Option value="FJ">
                                                                        Falta justificada
                                                                    </Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                    <div className="body">
                                                        <Form.Item label="Observações">
                                                            <TextArea
                                                                name="observacoes"
                                                                rows={4}
                                                                style={{ height: 100 }}
                                                                value={presenca.observacoes}
                                                                onChange={(e) => this.handleChangeParticipante(e, presenca.id)}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                )
                                            )}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label="Observações gerais">
                                        <TextArea
                                            name="observacoes"
                                            rows={4}
                                            style={{ height: 100 }}
                                            value={observacoes}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Link className="botao-principal" to="#" disabled={iconLoading} onClick={this.guardarPresencas}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                            Guardar
                                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const FormListagemAlunos = Form.create({ name: "form-listagem-alunos" })(ListagemAlunos);

export default FormListagemAlunos;
