import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Form, Checkbox, Tabs, Collapse, Icon, Modal, notification, Radio } from "antd";
import DrawerAdicionarAvaliacao from "./_Aux/DrawerAdicionarAvaliacao"
import { GeralContext } from "../ContextProvider";
import axios from "axios";
import moment from 'moment';
import noimage from "../../images/noimage.png";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const { TabPane } = Tabs;
const { Panel } = Collapse;

class NotasPre extends Component {
    static contextType = GeralContext;
    state = {
        periodoId: "",
        avaliacaoId: "",
        periodos: [],
        avaliacoes: [],
        participantes: [],
        //DRAWER
        visible: false,
        //LOADING
        iconLoading: false,
        //LOADING
        loading: true,
    }

    componentDidMount() {
        this.listar();
    }

    handleChange = (event, periodoIndex, indexGrupo, avaliacaoIndex, utilizadorId) => {
        const periodos = this.state.periodos;
        let participanteIndex = -1;
        if (periodos[periodoIndex].grupos[indexGrupo].avaliacoes[avaliacaoIndex].participantes)
            participanteIndex = periodos[periodoIndex].grupos[indexGrupo].avaliacoes[avaliacaoIndex].participantes.findIndex(x => x.id_utilizador === utilizadorId)

        if (participanteIndex >= 0) {
            periodos[periodoIndex].grupos[indexGrupo].avaliacoes[avaliacaoIndex].participantes[participanteIndex].nota = event.target.value;
        }
        else {
            const nota = {
                id_utilizador: utilizadorId,
                nota: event.target.value
            };

            periodos[periodoIndex].grupos[indexGrupo].avaliacoes[avaliacaoIndex].participantes = [...periodos[periodoIndex].grupos[indexGrupo].avaliacoes[avaliacaoIndex].participantes, nota]
        }

        this.setState({
            periodos
        })
    };

    handleChangeCheckbox = (event, periodoIndex, indexGrupo, avaliacaoIndex, utilizadorId) => {
        const periodos = this.state.periodos;
        let participanteIndex = -1;
        if (periodos[periodoIndex].grupos[indexGrupo].avaliacoes[avaliacaoIndex].participantes)
            participanteIndex = periodos[periodoIndex].grupos[indexGrupo].avaliacoes[avaliacaoIndex].participantes.findIndex(x => x.id_utilizador === utilizadorId)

        if (participanteIndex >= 0) {
            periodos[periodoIndex].grupos[indexGrupo].avaliacoes[avaliacaoIndex].participantes[participanteIndex].nota = event.target.checked;
        }
        else {
            const nota = {
                id_utilizador: utilizadorId,
                nota: event.target.value
            };

            periodos[periodoIndex].grupos[indexGrupo].avaliacoes[avaliacaoIndex].participantes = [...periodos[periodoIndex].grupos[indexGrupo].avaliacoes[avaliacaoIndex].participantes, nota]
        }

        this.setState({
            periodos
        })
    };

    listar = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-periodos-pre-creche",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                tipo: localStorage.getItem("tipoDiario")
            }
        })
            .then(response => {
                this.setState({
                    periodos: response.data.periodos,
                    avaliacoes: response.data.avaliacoes
                }, () => this.listarParticipantes())
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar períodos!");
            });
    }

    listarParticipantes = () => {
        axios({
            method: "get",
            url: "/api/aulas/participantes",
            timeout: 60 * 10 * 1000,
            params: {
                cursoid: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    carregarNota = (indexPeriodo, indexGrupo, indexAvaliacao, utilizadorId) => {
        var periodos = this.state.periodos;
        if (periodos[indexPeriodo].grupos[indexGrupo].avaliacoes[indexAvaliacao].participantes)
            var participante = periodos[indexPeriodo].grupos[indexGrupo].avaliacoes[indexAvaliacao].participantes.find(x => x.id_utilizador === utilizadorId)
            if (participante)
                return participante.nota;
    }

    guardar = (indexPeriodo, indexGrupo, indexAvaliacao) => {
        this.setState({ iconLoading: true })

        const periodos = this.state.periodos;
        const notas = periodos[indexPeriodo].grupos[indexGrupo].avaliacoes[indexAvaliacao].participantes

        var item = new FormData()
        item.append("avaliacaoId", periodos[indexPeriodo].grupos[indexGrupo].avaliacoes[indexAvaliacao].id)
        item.append("notas", JSON.stringify(notas))

        axios({
            method: "post",
            url: "/api/aulas/adicionar-notas-pre-creche",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Notas guardadas!")
                this.setState({ iconLoading: false })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi guardar notas!")
                this.setState({ iconLoading: false })
            })
    }

    render() {
        const {
            periodoId,
            avaliacaoId,
            periodos,
            avaliacoes,
            participantes,
            //DRAWER
            visible,
            //LOADING
            iconLoading,
            //LOADING
            loading,
        } = this.state;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        return (
            <>
                <div className="container container-body">
                    <div className="bloco-curso-diario-opcoes-end">
                        <Link to={`/gerir-curso/${this.props.match.params.curso}/curso-diario`} className="botao-icon-voltar" title="Voltar">
                            <Icon type="arrow-left" />
                        </Link>
                    </div>
                    {loading ? (
                        <div className="loading-data">
                            <div className="loading" />
                        </div>
                    ) : (
                            <>
                                {periodos.map((periodo, indexPeriodo) => (
                                    <div key={indexPeriodo} className="curso-diario bloco-notas">
                                        <div className="bloco-informacoes">
                                            <div className="info-aula">
                                                <span className="bloco-info-aula">
                                                    <span className="titulo-aula">{periodo.titulo}</span>
                                                    <span className="datas-aula">{moment(periodo.dt_inicio).format("DD/MM/YYYY")} - {moment(periodo.dt_fim).format("DD/MM/YYYY")}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="listagem-avaliacoes">
                                            <Collapse defaultActiveKey={[]} className="collapse-campos">
                                                {periodo.grupos.map((grupo, indexGrupo) => (
                                                    <Panel key={indexGrupo} header={<span className="collapse-panel-avaliacoes">
                                                        <span>{grupo.titulo}</span>
                                                    </span>}
                                                    >
                                                        <Collapse defaultActiveKey={[]} className="collapse-campos">
                                                            {grupo.avaliacoes.map((avaliacao, indexAvaliacao) => (
                                                                <Panel key={indexAvaliacao} header={<span className="collapse-panel-avaliacoes sub-avaliacao">
                                                                    <span>{avaliacao.titulo}</span>
                                                                </span>}
                                                                >
                                                                    <Form {...formItemLayout} className="form-categorias">
                                                                        <ul className="lista-participantes-notas">
                                                                            {participantes.map((participante, indexParticipante) => (
                                                                                <li key={indexParticipante} className="participante">
                                                                                    <div className="info-participante">
                                                                                        <div className="container-img">
                                                                                            <img src={participante.foto ? participante.foto : noimage} />
                                                                                        </div>
                                                                                        <div className="info">
                                                                                            <span className="nome">{participante.nome}</span>
                                                                                            <p className="numero">{participante.numero}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        {avaliacoes.length ?
                                                                                            <Form.Item label="Nota">
                                                                                                {avaliacoes.length > 1 ?
                                                                                                    <Radio.Group onChange={(event) => this.handleChange(event, indexPeriodo, indexGrupo, indexAvaliacao, participante.id)} value={this.carregarNota(indexPeriodo, indexGrupo, indexAvaliacao, participante.id)}>
                                                                                                        {avaliacoes.map((avaliacao, index) => (
                                                                                                            <Radio key={index} value={avaliacao.nome}>
                                                                                                                {avaliacao.nome}
                                                                                                            </Radio>
                                                                                                        ))}
                                                                                                    </Radio.Group>
                                                                                                :
                                                                                                <Checkbox checked={this.carregarNota(indexPeriodo, indexGrupo, indexAvaliacao, participante.id)} onChange={(event) => this.handleChangeCheckbox(event, indexPeriodo, indexGrupo, indexAvaliacao, participante.id)} style={{marginLeft: 10}}>
                                                                                                        {avaliacoes[0].nome}
                                                                                                    </Checkbox>
                                                                                                }
                                                                                            </Form.Item>
                                                                                            : null}
                                                                                    </div>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </Form>
                                                                    <Link className="botao-principal" to="#" disabled={iconLoading} onClick={() => this.guardar(indexPeriodo, indexGrupo, indexAvaliacao)}>
                                                                        {iconLoading ? <Icon type="loading" /> : null}
                                                Guardar
                                            </Link>
                                                                </Panel>
                                                            ))}
                                                        </Collapse>
                                                    </Panel>
                                                ))}
                                            </Collapse>
                                        </div>
                                    </div>
                                ))}
                            </>
                    )}
                </div>
                <DrawerAdicionarAvaliacao
                    periodoId={periodoId}
                    avaliacaoId={avaliacaoId}
                    visible={visible}
                    onClose={() => this.setState({ visible: false })}
                    avaliacaoAdicionada={(avaliacao) => this.avaliacaoAdicionada(avaliacao)}
                    avaliacaoAlterada={(avaliacao) => this.avaliacaoAlterada(avaliacao)}
                />
            </>
        );
    }
}

export default NotasPre;
