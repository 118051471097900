import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { notification, Modal, Rate, Button } from "antd";
import OpcaoAnotacoes from "./OpcaoAnotacoes";
import OpcaoModulos from "./OpcaoModulos";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Controlos extends Component {
    static contextType = GlobalContext;
    state = {
        aulaid: 0,
        drawerTranscricao: false,
        drawerForum: false,
        drawerAnotacoes: false,
        drawerModulos: false,
        marcador: false,
        anotacoes: false,
        modalRating: false,
        ratingEnviado: false,
        rating: null,
        //ATIVIDADES
        modulos: [],
        loadingModulos: false,
        //MODAL INFORMACOES
        visibleModalInfos: false,
        //SETAS
        btnAnteriorLink: "",
        btnAnteriorModuloIndex: "",
        btnAnteriorTarefaId: "",
        btnAnteriorAlerta: "",
        btnProximoLink: "",
        btnProximoModuloIndex: "",
        btnProximoTarefaId: "",
        btnProximoAlerta: "",
        btnDisabledAnterior: false,
        btnDisabledProximo: false,
    };

    UNSAFE_componentWillMount() {
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            aulaid: aulaId
        });
        this.devolveMarcador(aulaId);
        this.montarAtividades();
    }

    UNSAFE_componentWillReceiveProps() {
        if (!this.props.sameUrl) {
            this.setState({
                drawerModulos: false
            });
            var aulaId = localStorage.getItem("codigo_aula");

            this.setState({
                aulaid: aulaId
            });
            this.devolveMarcador(aulaId);
            this.montarAtividades();
        }
    }

    montarAtividades = () => {
        if (this.context.lista_modulos.length === 0) {
            /*this.setState({ loading: true })*/
            this.listarModulos();
        }
        else
            this.setState({
                modulos: this.context.lista_modulos,
            }, () => this.montarSetas())
    }

    listarModulos = () => {
        this.setState({
            loadingModulos: true
        });

        axios({
            method: "get",
            url: "/api/player/listar-modulos",
            params: {
                cursoid: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.context.atualizarState({
                    lista_modulos: response.data
                });

                this.setState({
                    modulos: response.data,
                    loadingModulos: false
                }, () => this.montarSetas());
            })
            .catch(() => { });
    };

    montarSetas = () => {
        var tarefaId = parseInt(localStorage.getItem("codigo_aula"));
        var _modulos = this.state.modulos;

        for (let i = 0; i < _modulos.length; i++) {
            var tarefaIndex = _modulos[i].modulo_Aula.findIndex(x => x.id === tarefaId)
            if (tarefaIndex >= 0) {//CASO TRUE É TAREFA DE NIVEL 1
                this.validarTarefas(tarefaIndex, _modulos, i);
                break;
            }
            else {//CASO TRUE E TAREFA DE NIVEL 2
                for (let e = 0; e < _modulos[i].modulo_Aula.length; e++) {
                    tarefaIndex = _modulos[i].modulo_Aula[e].modulo_Aula_lvl2.findIndex(x => x.id === tarefaId);

                    if (tarefaIndex >= 0) {
                        this.validarSubTarefas(tarefaIndex, _modulos, i, e);

                        break;
                    }
                }

                if (tarefaIndex >= 0)
                    break;
            }
        }
    }

    validarTarefas = (tarefaIndex, _modulos, i) => {// i -> index do modulo
        if (_modulos[i].estado === "ATIVO") {
            this.validarTarefaAnteriorAtividadeAtiva(_modulos, i, tarefaIndex);
            this.validarProximaTarefaAtividadeAtiva(_modulos, i, tarefaIndex);
        }
        else {
            this.validarTarefaAnteriorAtividadeFechada(_modulos, i, tarefaIndex);
            this.validarProximaTarefaAtividadeFechada(_modulos, i, tarefaIndex);
        }
    }

    validarSubTarefas = (tarefaIndex, _modulos, i, e) => {// i -> index do modulo e -> index da tarefa pai
        if (_modulos[i].estado === "ATIVO") {
            this.validarSubTarefaAnteriorAtiva(_modulos, i, e, tarefaIndex);
            this.validarProximaSubTarefaAtiva(_modulos, i, e, tarefaIndex);
        }
        else {
            this.validarSubTarefaAnteriorFechada(_modulos, i, e, tarefaIndex);
            this.validarProximaSubTarefaFechada(_modulos, i, e, tarefaIndex);
        }
    }

    validarProximoModulo = (_modulos, i) => {
        for (let t = i + 1; t < _modulos.length; t++) {
            if (!_modulos[t].precedencia || _modulos[t].precedencia && !_modulos[t].obrigatoria || _modulos[t].precedencia && _modulos[t].obrigatoria && localStorage.getItem("estado_atividade_" + _modulos[t].precendenciaId) === "true") {
                if (_modulos[t].modulo_Aula.length > 0 && _modulos[t].estado === "ATIVO") {
                    var tipoRecurso = _modulos[t].modulo_Aula[0].tipo_Recurso.id;
                    if (tipoRecurso !== 13) {
                        this.montarLinkProximo(_modulos[t].modulo_Aula[0], t)
                    }
                    else {
                        this.validarProximaTarefaAtividadeAtiva(_modulos, t, 0);
                    }
                    break;
                }
                else if (_modulos[t].modulo_Aula.length > 0 && _modulos[t].estado === "FECHADO") {
                    var tipoRecurso = _modulos[t].modulo_Aula[0].tipo_Recurso.id;
                    if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 13) {
                        this.montarLinkProximo(_modulos[t].modulo_Aula[0], t)
                    }
                    else {
                        this.validarProximaTarefaAtividadeFechada(_modulos, t, 0);
                    }
                    break;
                }
            }
        }
    }

    validarModuloAnterior = (_modulos, i) => {
        for (let t = i - 1; t >= 0; t--) {
            if (!_modulos[t].precedencia || _modulos[t].precedencia && !_modulos[t].obrigatoria || _modulos[t].precedencia && _modulos[t].obrigatoria && localStorage.getItem("estado_atividade_" + _modulos[t].precendenciaId) === "true") {
                if (_modulos[t].modulo_Aula.length > 0 && _modulos[t].estado === "ATIVO") {
                    this.validarTarefaAnteriorAtividadeAtiva(_modulos, t, _modulos[t].modulo_Aula.length);
                    break;
                }
                else if (_modulos[t].modulo_Aula.length > 0 && _modulos[t].estado === "FECHADO") {
                    this.validarTarefaAnteriorAtividadeFechada(_modulos, t, _modulos[t].modulo_Aula.length);
                    break;
                }
            }
        }
    }

    validarProximaTarefaAtividadeAtiva = (_modulos, i, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let s = 0; s < _modulos[i].modulo_Aula[tarefaIndex].modulo_Aula_lvl2.length; s++) {
            let tipoRecurso = _modulos[i].modulo_Aula[tarefaIndex].modulo_Aula_lvl2[s].tipo_Recurso.id;
            if (tipoRecurso !== 13) {
                this.montarLinkProximo(_modulos[i].modulo_Aula[tarefaIndex].modulo_Aula_lvl2[s], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada) {
            for (let m = tarefaIndex + 1; m < _modulos[i].modulo_Aula.length; m++) {
                var tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
                if (tipoRecurso !== 13) {
                    this.montarLinkProximo(_modulos[i].modulo_Aula[m], i)
                    tarefaEncontrada = true;
                    break;
                }
                else {
                    for (let s = 0; s < _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length; s++) {
                        tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                        if (tipoRecurso !== 13) {
                            this.montarLinkProximo(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                            tarefaEncontrada = true;
                            break;
                        }
                    }
                }

                if (tarefaEncontrada)
                    break;
            }
        }

        if (!tarefaEncontrada) {
            this.validarProximoModulo(_modulos, i);
        }
    }

    validarTarefaAnteriorAtividadeAtiva = (_modulos, i, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let m = tarefaIndex - 1; m >= 0; m--) {
            let tipoRecurso = "";
            for (let s = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length - 1; s >= 0; s++) {
                tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                if (tipoRecurso !== 13) {
                    this.montarLinkAnterior(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                    tarefaEncontrada = true;
                    break;
                }
            }

            if (tarefaEncontrada)
                break;

            tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
            if (tipoRecurso !== 13) {
                this.montarLinkAnterior(_modulos[i].modulo_Aula[m], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada)
            this.validarModuloAnterior(_modulos, i);
    }

    validarProximaTarefaAtividadeFechada = (_modulos, i, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let s = 0; s < _modulos[i].modulo_Aula[tarefaIndex].modulo_Aula_lvl2.length; s++) {
            let tipoRecurso = _modulos[i].modulo_Aula[tarefaIndex].modulo_Aula_lvl2[s].tipo_Recurso.id;
            if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 12 && tipoRecurso !== 13) {
                this.montarLinkProximo(_modulos[i].modulo_Aula[tarefaIndex].modulo_Aula_lvl2[s], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada) {
            for (let m = tarefaIndex + 1; m < _modulos[i].modulo_Aula.length; m++) {
                var tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
                if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 13) {
                    this.montarLinkProximo(_modulos[i].modulo_Aula[m], i)
                    tarefaEncontrada = true;
                    break;
                }
                else {
                    for (let s = 0; s < _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length; s++) {
                        tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                        if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 13) {
                            this.montarLinkProximo(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                            tarefaEncontrada = true;
                            break;
                        }
                    }
                }

                if (tarefaEncontrada)
                    break;
            }
        }

        if (!tarefaEncontrada) {
            this.validarProximoModulo(_modulos, i);
        }
    }

    validarTarefaAnteriorAtividadeFechada = (_modulos, i, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let m = tarefaIndex - 1; m >= 0; m--) {
            let tipoRecurso = "";
            for (let s = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length - 1; s >= 0; s++) {
                tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 13) {
                    this.montarLinkAnterior(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                    tarefaEncontrada = true;
                    break;
                }
            }

            if (tarefaEncontrada)
                break;

            tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
            if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 13) {
                this.montarLinkAnterior(_modulos[i].modulo_Aula[m], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada)
            this.validarModuloAnterior(_modulos, i);
    }

    validarProximaSubTarefaAtiva = (_modulos, i, e, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let s = tarefaIndex + 1; s < _modulos[i].modulo_Aula[e].modulo_Aula_lvl2.length; s++) {
            let tipoRecurso = _modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s].tipo_Recurso.id;
            if (tipoRecurso !== 13) {
                this.montarLinkProximo(_modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada) {
            for (let m = e + 1; m < _modulos[i].modulo_Aula.length; m++) {
                var tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
                if (tipoRecurso !== 13) {
                    this.montarLinkProximo(_modulos[i].modulo_Aula[m], i)
                    tarefaEncontrada = true;
                    break;
                }
                else {
                    for (let s = 0; s < _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length; s++) {
                        tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                        if (tipoRecurso !== 13) {
                            this.montarLinkProximo(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                            tarefaEncontrada = true;
                            break;
                        }
                    }
                }
            }
        }

        if (!tarefaEncontrada) {
            this.validarProximoModulo(_modulos, i);
        }
    }

    validarSubTarefaAnteriorAtiva = (_modulos, i, e, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let s = tarefaIndex - 1; s >= 0; s--) {
            let tipoRecurso = _modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s].tipo_Recurso.id;
            if (tipoRecurso !== 13) {
                this.montarLinkAnterior(_modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada) {
            var tipoRecurso = _modulos[i].modulo_Aula[e].tipo_Recurso.id;
            if (tipoRecurso !== 13) {
                this.montarLinkAnterior(_modulos[i].modulo_Aula[e], i)
                tarefaEncontrada = true;
            }
        }

        if (!tarefaEncontrada) {
            for (let m = e - 1; m >= 0; m--) {
                var tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
                if (tipoRecurso !== 13) {
                    this.montarLinkAnterior(_modulos[i].modulo_Aula[m], i)
                    tarefaEncontrada = true;
                    break;
                }
                else {
                    for (let s = 0; s < _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length; s++) {
                        tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                        if (tipoRecurso !== 13) {
                            this.montarLinkAnterior(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                            tarefaEncontrada = true;
                            break;
                        }
                    }

                    if (tarefaEncontrada)
                        break;
                }
            }
        }

        if (!tarefaEncontrada) {
            this.validarModuloAnterior(_modulos, i);
        }
    }

    validarProximaSubTarefaFechada = (_modulos, i, e, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let s = tarefaIndex + 1; s < _modulos[i].modulo_Aula[e].modulo_Aula_lvl2.length; s++) {
            let tipoRecurso = _modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s].tipo_Recurso.id;
            if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 12 && tipoRecurso !== 13) {
                this.montarLinkProximo(_modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada) {
            for (let m = e + 1; m < _modulos[i].modulo_Aula.length; m++) {
                var tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
                if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 12 && tipoRecurso !== 13) {
                    this.montarLinkProximo(_modulos[i].modulo_Aula[m], i)
                    tarefaEncontrada = true;
                    break;
                }
                else {
                    for (let s = 0; s < _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length; s++) {
                        tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                        if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 12 && tipoRecurso !== 13) {
                            this.montarLinkProximo(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                            tarefaEncontrada = true;
                            break;
                        }
                    }
                }
            }
        }

        if (!tarefaEncontrada) {
            this.validarProximoModulo(_modulos, i);
        }
    }

    validarSubTarefaAnteriorFechada = (_modulos, i, e, tarefaIndex) => {
        let tarefaEncontrada = false;
        for (let s = tarefaIndex - 1; s > 0; s--) {
            let tipoRecurso = _modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s].tipo_Recurso.id;
            if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 12 && tipoRecurso !== 13) {
                this.montarLinkAnterior(_modulos[i].modulo_Aula[e].modulo_Aula_lvl2[s], i)
                tarefaEncontrada = true;
                break;
            }
        }

        if (!tarefaEncontrada) {
            var tipoRecurso = _modulos[i].modulo_Aula[e].tipo_Recurso.id;
            if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 12 && tipoRecurso !== 13) {
                this.montarLinkAnterior(_modulos[i].modulo_Aula[e], i)
                tarefaEncontrada = true;
            }
        }

        if (!tarefaEncontrada) {
            for (let m = e - 1; m > 0; m--) {
                var tipoRecurso = _modulos[i].modulo_Aula[m].tipo_Recurso.id;
                if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 12 && tipoRecurso !== 13) {
                    this.montarLinkAnterior(_modulos[i].modulo_Aula[m], i)
                    tarefaEncontrada = true;
                    break;
                }
                else {
                    for (let s = 0; s < _modulos[i].modulo_Aula[m].modulo_Aula_lvl2.length; s++) {
                        tipoRecurso = _modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s].tipo_Recurso.id;
                        if (tipoRecurso !== 5 && tipoRecurso !== 6 && tipoRecurso !== 8 && tipoRecurso !== 12 && tipoRecurso !== 13) {
                            this.montarLinkAnterior(_modulos[i].modulo_Aula[m].modulo_Aula_lvl2[s], i)
                            tarefaEncontrada = true;
                            break;
                        }
                    }
                }
            }
        }

        if (!tarefaEncontrada) {
            this.validarModuloAnterior(_modulos, i);
        }
    }

    montarLinkAnterior = (tarefa, moduloIndex) => {
        let link = `/curso/${this.props.match.params.curso}/${tarefa.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}-${Math.floor(100000 + Math.random() * 900000)}${tarefa.tipo_Recurso.url}`

        this.setState({
            btnAnteriorLink: link,
            btnAnteriorModuloIndex: moduloIndex,
            btnAnteriorTarefaId: tarefa.id
        })
    }

    montarLinkProximo = (tarefa, moduloIndex) => {
        let link = `/curso/${this.props.match.params.curso}/${tarefa.nome.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}-${Math.floor(100000 + Math.random() * 900000)}${tarefa.tipo_Recurso.url}`

        this.setState({
            btnProximoLink: link,
            btnProximoModuloIndex: moduloIndex,
            btnProximoTarefaId: tarefa.id
        })
    }

    listarModulo = moduloid => {
        axios({
            method: "get",
            url: "/api/player/listar-modulo",
            params: {
                cursoid: localStorage.getItem("codigo_curso"),
                moduloid
            }
        })
            .then(response => {
                var moduloIndex = this.state.modulos.findIndex(x => x.id === moduloid);
                var _modulos = this.state.modulos;
                _modulos[moduloIndex] = response.data

                this.setState({
                    modulos: _modulos
                })
            })
            .catch(() => { });
    };

    concluirAula = (moduloid, aulaid) => {
        axios({
            method: "post",
            url: "/api/player/concluir-aula",
            params: {
                aulaid
            }
        })
            .then(() => {
                this.listarModulo(moduloid);
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível concluir o conteúdo!");
            });
    };

    //FECHAR MÓDULOS
    fecharModulos = () => {
        this.setState({ drawerModulos: false });
    };

    //FECHAR ANOTAQÇÕES
    fecharAnotacoes = () => {
        this.setState({ drawerAnotacoes: false });
    };

    //DEVOLVE SE EXISTE OU NÃO MARCADOR PARA A AULA ATIVA
    devolveMarcador = aulaid => {
        axios({
            method: "get",
            url: "/api/player/marcador",
            params: {
                aulaid
            }
        })
            .then(response => {
                this.setState({
                    marcador: response.data.marcador,
                    ratingEnviado: response.data.rating > 0,
                    rating: response.data.rating,
                    anotacoes: response.data.anotacoes
                });
            })
            .catch(() => { });
    };

    //ADICIONA UM MARCADO A AULA ATIVA
    addMarcador = () => {
        axios({
            method: "put",
            url: "/api/player/add-marcador",
            params: {
                aulaid: this.state.aulaid
            }
        })
            .then(() => {
                if (this.context.lista_modulos.length) {
                    var _lista_modulos = this.context.lista_modulos;
                    var aulaIndex = _lista_modulos[localStorage.getItem("modulo_aberto")].modulo_Aula.findIndex(x => x.id === this.state.aulaid);
                    if (aulaIndex > -1) {
                        var utilizador_registo = _lista_modulos[localStorage.getItem("modulo_aberto")].modulo_Aula[aulaIndex].modulo_Aulas_Utilizadores;

                        _lista_modulos[localStorage.getItem("modulo_aberto")].modulo_Aula[aulaIndex].modulo_Aulas_Utilizadores = { ...utilizador_registo, marcador: !this.state.marcador };
                    }
                    else {
                        _lista_modulos[localStorage.getItem("modulo_aberto")].modulo_Aula.forEach((aula, index) => {
                            var aulalvl2Index = aula.modulo_Aula_lvl2.findIndex(x => x.id === this.state.aulaid);
                            if (aulalvl2Index > -1) {
                                var utilizador_registo = _lista_modulos[localStorage.getItem("modulo_aberto")].modulo_Aula[index].modulo_Aula_lvl2[aulalvl2Index].modulo_Aulas_Utilizadores;

                                _lista_modulos[localStorage.getItem("modulo_aberto")].modulo_Aula[index].modulo_Aula_lvl2[aulalvl2Index].modulo_Aulas_Utilizadores = { ...utilizador_registo, marcador: !this.state.marcador };
                            }
                        })
                    }

                    this.context.atualizarState({ lista_modulos: _lista_modulos });
                }

                this.setState({
                    marcador: !this.state.marcador
                });
                //this.context.addMarcador();
            })
            .catch(() => {
                openNotificationWithIcon(
                    "error",
                    "Erro",
                    this.state.marcador ? "Não foi possível adicionar marcador!" : "Não foi possível remover marcador!"
                );
            });
    };

    //SUBMETER RATING
    enviarRating = () => {
        axios({
            method: "put",
            url: "/api/player/alterar-avaliacao",
            params: {
                aulaid: this.state.aulaid,
                qualificacao: this.state.rating
            }
        })
            .then(response => {
                this.setState({
                    ratingEnviado: true,
                    modalRating: false
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível submeter o rating!");
            });
    };

    handleChangeRating = value => {
        this.setState({ rating: value });
    };

    registarSaida = () => {
        axios({
            method: "post",
            url: "/api/logs/registar-saidaid",
            params: {
                id: localStorage.getItem("codigo_entrada")
            }
        });
    };

    render() {
        const {
            //SETAS
            btnDisabledAnterior,
            btnDisabledProximo,
        } = this.state;

        return (
            <div className="bloco-controlos bloco-controlos-player">
                <>
                    <div className="controlos" style={{ overflowY: "scroll" }}>
                        <div className="container-btn">
                            <Link to={this.props.backUrl} onClick={this.registarSaida} title="Voltar">
                                <i className="fas fa-angle-double-left"></i>
                            </Link>
                        </div>
                        {!this.props.semModulos && (
                            <div className="container-btn">
                                <Link
                                    to="#"
                                    onClick={() => {
                                        this.setState({ drawerModulos: true });
                                    }}
                                    title="Atividades"
                                >
                                    <i className="fas fa-list-ul" />
                                </Link>
                            </div>
                        )}
                        {this.props.temAnotacoes ? (
                            <div className={`container-btn ${(this.state.anotacoes ? "ativo" : "")}`}>
                                <Link
                                    to="#"
                                    onClick={() => {
                                        this.setState({
                                            drawerAnotacoes: true,
                                            nao_nova_anotacao: true
                                        });
                                    }}
                                    title="Anotações"
                                >
                                    <i className="fas fa-sticky-note" />
                                </Link>
                            </div>
                        ) : null}
                        {this.props.temMarcador ? (
                            <div className={"container-btn" + `${this.state.marcador ? " ativo" : ""}`}>
                                <Link to="#" title="Marcador" onClick={this.addMarcador}>
                                    <i className="fas fa-bookmark" />
                                </Link>
                            </div>
                        ) : null}
                        <div className="container-btn">
                            <Link
                                to="#"
                                onClick={this.context.onClickFullscreen}
                                title={this.context.fullscreen ? "Sair de ecrã inteiro" : "Ecrã inteiro"}
                            >
                                {this.context.fullscreen ? <i className="fas fa-compress" /> : <i className="fas fa-expand" />}
                            </Link>
                        </div>
                        <div className="container-btn">
                            <Link to="#" title="Informações" onClick={() => this.setState({ visibleModalInfos: true })}>
                                <i className="fas fa-info"></i>
                            </Link>
                        </div>
                        <div className={`container-btn ${this.state.btnAnteriorLink === "" ? "container-btn-disable" : ""}`}>
                            <Link
                                to={this.state.btnAnteriorLink}
                                title="Anterior"
                                disabled={this.state.btnAnteriorLink === ""}
                                onClick={() => {
                                    localStorage.setItem("modulo_aberto", this.state.btnAnteriorModuloIndex);
                                    localStorage.setItem("codigo_aula", this.state.btnAnteriorTarefaId);
                                }}
                            >
                                <i className="fas fa-chevron-left"></i>
                            </Link>
                        </div>
                        <div className={`container-btn ${this.state.btnProximoLink === "" ? "container-btn-disable" : ""}`}>
                            <Link
                                to={this.state.btnProximoLink}
                                title="Próxima"
                                disabled={this.state.btnProximoLink === ""}
                                onClick={() => {
                                    localStorage.setItem("modulo_aberto", this.state.btnProximoModuloIndex);
                                    localStorage.setItem("codigo_aula", this.state.btnProximoTarefaId);
                                }}
                            >
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                        </div>
                    </div>
                    <OpcaoModulos
                        modulos={this.state.modulos}
                        loading={this.state.loadingModulos}
                        concluirAula={this.concluirAula}
                        drawerModulos={this.state.drawerModulos}
                        fecharModulos={this.fecharModulos}
                    />
                    <OpcaoAnotacoes drawerAnotacoes={this.state.drawerAnotacoes} marcar={() => !this.state.anotacoes && this.setState({ anotacoes: true }) } fecharAnotacoes={this.fecharAnotacoes} />
                </>
                <Modal
                    title="Rating"
                    className={this.state.ratingEnviado ? "rating-submetido" : ""}
                    visible={this.state.modalRating}
                    onOk={this.enviarRating}
                    okText="Confirmar"
                    onCancel={() => this.setState({ modalRating: false })}
                    cancelText="Fechar"
                >
                    <div style={{ textAlign: "center", marginBottom: 20 }}>
                        <Rate value={this.state.rating} onChange={this.handleChangeRating} disabled={this.state.ratingEnviado} />
                    </div>
                </Modal>
                <Modal
                    visible={this.state.visibleModalInfos}
                    onCancel={() => this.setState({ visibleModalInfos: false })}
                    footer={[
                        <Button key="primary" onClick={() => this.setState({ visibleModalInfos: false })}>
                            Voltar
                        </Button>
                    ]}
                >
                    <div className="modal-player-informacoes">
                        <div className="bloco-info-tarefa">
                            <span className="icon-tarefa">
                                <img src={this.props.icon} />
                            </span>
                            <span className="titulo-data-tarefa">
                                <span className="titulo-tarefa">{this.props.nome}</span>
                                <span className="data-tarefa">{this.props.dataHora}</span>
                                {this.props.questoes ? <span className="numero-questoes">{this.props.questoes} {this.props.questoes > 1 ? "Questões" : "Questão"}</span> : null}
                            </span>
                        </div>
                        <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: this.props.objetivos }}></p>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Controlos = withRouter(Controlos);
