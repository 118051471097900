import React, { Component } from "react";
import { Form, Input, Button, notification, Icon } from "antd";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Header from "../../User/Header";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        iconLoading: false
    });
};

class FromAlterarSenha extends Component {
    static contextType = GlobalContext;
    state = {
        newPassword: "",
        redirect: false,
        redirectLink: "",
        iconLoading: false
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    getParameterByName = name => {
        const match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue("nova-password")) {
            callback("Senhas não coincidentes");
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(["confirm"], { force: true });
        }
        callback();
    };

    alterar = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                axios({
                    method: "post",
                    url: "/api/alterar-password/forcar-alterar",
                    params: {
                        newPassword: this.state.newPassword
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Senha alterada!");
                        this.setState({
                            redirect: true,
                            redirectLink: "/user",
                            iconLoading: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    render() {
        const { redirect, redirectLink, iconLoading } = this.state;
        const { getFieldDecorator } = this.props.form;
        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Alterar Senha" />
                <Form className="login">
                    <div className="bloco-alerta">
                        <span className="icon-alerta">
                            <Icon type="info-circle" />
                        </span>
                        <span className="mensagem-alerta">Para poder continuar a utilizar a stepforma em segurança necessita de alterar a sua senha.</span>
                    </div>
                    <div className="recuperar-password">
                        <Form.Item>
                            {getFieldDecorator("nova-password", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    },
                                    {
                                        pattern: "^(?=.*[A-Z])(?=.*[0-9])(?=.{6,})",
                                        message: <p>A senha deve conter pelo menos uma letra maiúscula, um número e um mínimo de 6 caracteres</p>
                                    },
                                    {
                                        validator: this.validateToNextPassword
                                    }
                                ]
                            })(<Input.Password type="password" placeholder="Nova senha" type="password" name="newPassword" onChange={this.handleChange} />)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("confirmar-password", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    },
                                    {
                                        validator: this.compareToFirstPassword
                                    }
                                ]
                            })(<Input.Password type="password" placeholder="Confirmar senha" type="password" />)}
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" className="login-form-button" disabled={iconLoading} onClick={this.alterar}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Alterar e Continuar
                        </Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}
const FormAlterarSenha = Form.create({ name: "form-alterar-senha" })(FromAlterarSenha);

export default FormAlterarSenha;
