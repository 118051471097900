import React, { Component } from "react";
import { Icon, notification } from "antd";

class ExcelTabelaNotasPre extends Component {
    render() {
        const {
            periodos,
            avaliacoes
        } = this.props;

        const montarTitulosAvaliacoes = () => {
            return (<>
                {avaliacoes.map((avaliacao, index) => (
                    <td key={index} style={{ borderTop: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000', minWidth: 20 }} align="center" valign="middle"><b><font color="#000000">{avaliacao}</font></b></td>
                ))}
            </>);
        }

        const montarNotas = (nota) => {
            return (<>
                {avaliacoes.map((avaliacao, index) => (
                    <td key={index} className="celula-tabela-notas-pre" style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="left" valign="bottom"><div>{nota === avaliacao ? <Icon type="check-square" /> : <Icon type="border" />}</div></td>
                ))}
            </>);
        }

        return (
            <>
                <div>
                    <table className="tabela-notas-pre" cellSpacing={0} border={0} style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={2} height={40} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CÓDIGO</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CAMPOS DE EXPERIÊNCIAS</font></b></td>
                                {periodos.map(periodo => (
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '2px double #000000' }} colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                                ))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><b><font color="#000000">CORPO, GESTOS E MOVIMENTOS</font></b></td>
                                {periodos.map(() => (montarTitulosAvaliacoes()))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={44} align="center" valign="middle"><b><font color="#000000">(EI03CG01) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Criar com o corpo formas diversificadas de expressão de sentimentos, sensações e emoções, tanto nas situações do cotidiano quanto em brincadeiras, dança, teatro, música.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[0].avaliacoes[0].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03CG02)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Demonstrar controle e adequação do uso de seu corpo em brincadeiras e jogos, escuta e reconto de histórias, atividades artísticas, entre outras possibilidades.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[0].avaliacoes[1].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03CG03)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Adotar hábitos de autocuidado relacionados a higiene, alimentação, conforto e aparência.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[0].avaliacoes[2].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03CG04)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Criar movimentos, gestos, olhares e mímicas em brincadeiras, jogos e atividades artísticas como dança, teatro e música.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[0].avaliacoes[3].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03CG05)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Coordenar suas habilidades manuais no atendimento adequado a seus interesses e necessidades em situações diversas.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[0].avaliacoes[4].nota))}
                            </tr>
                            <tr>
                                <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={2} height={40} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CÓDIGO</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CAMPOS DE EXPERIÊNCIAS</font></b></td>
                                {periodos.map(periodo => (
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '2px double #000000' }} colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                                ))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><b><font color="#000000">ESCUTA, FALA PENSAMENTO E IMAGINAÇÃO</font></b></td>
                                {periodos.map(() => (montarTitulosAvaliacoes()))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={44} align="center" valign="middle"><b><font color="#000000">(EI03EF01)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Expressar ideias, desejos e sentimentos sobre suas vivências, por meio da linguagem oral e escrita (escrita espontânea), de fotos, desenhos e outras formas de expressão.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[0].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EF02)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Inventar brincadeiras cantadas, poemas e canções, criando rimas, aliterações e ritmos.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[1].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EF03)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Escolher e folhear livros, procurando orientar-se por temas e ilustrações e tentando identificar palavras conhecidas.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[2].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EF04)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Recontar histórias ouvidas e planejar coletivamente roteiros de vídeos e de encenações, definindo os contextos, os personagens, a estrutura da história.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[3].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EF05)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Recontar histórias ouvidas para produção de reconto escrito, tendo o professor como escriba.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[4].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EF06)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Produzir suas próprias histórias orais e escritas (escrita espontânea), em situações com função social significativa.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[5].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EF07)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Levantar hipóteses sobre gêneros textuais veiculados em portadores conhecidos, recorrendo a estratégias de observação gráfica e/ou de leitura.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[6].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={44} align="center" valign="middle"><b><font color="#000000">(EI03EF08)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Selecionar livros e textos de gêneros conhecidos para a leitura de um adulto e/ou para sua própria leitura (partindo de seu repertório sobre esses textos, como a recuperação pela memória, pela leitura das ilustrações etc.).</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[7].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EF09)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Levantar hipóteses em relação à linguagem escrita, realizando registros de palavras e textos, por meio de escrita espontânea.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[1].avaliacoes[8].nota))}
                            </tr>
                            <tr>
                                <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={2} height={40} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CÓDIGO</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CAMPOS DE EXPERIÊNCIAS</font></b></td>
                                {periodos.map(periodo => (
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '2px double #000000' }} colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                                ))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><b><font color="#000000">TRAÇOS, SONS, CORES E FORMAS</font></b></td>
                                {periodos.map(() => (montarTitulosAvaliacoes()))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03TS01) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Utilizar sons produzidos por materiais, objetos e instrumentos musicais durante brincadeiras de faz de conta, encenações, criações musicais, festas.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[2].avaliacoes[0].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03TS02) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Expressar-se livremente por meio de desenho, pintura, colagem, dobradura e escultura, criando produções bidimensionais e tridimensionais.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[2].avaliacoes[1].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03TS03) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Reconhecer as qualidades do som (intensidade, duração, altura e timbre), utilizando-as em suas produções sonoras e ao ouvir músicas e sons.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[2].avaliacoes[2].nota))}
                            </tr>
                            <tr>
                                <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={2} height={40} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CÓDIGO</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CAMPOS DE EXPERIÊNCIAS</font></b></td>
                                {periodos.map(periodo => (
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '2px double #000000' }} colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                                ))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><b><font color="#000000">O EU, O OUTRO E O NÓS</font></b></td>
                                {periodos.map(() => (montarTitulosAvaliacoes()))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EO01)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Demonstrar empatia pelos outros, percebendo que as pessoas têm diferentes sentimentos, necessidades e maneiras de pensar e agir.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[0].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EO02)</font></b></td>
                                <td align="left" valign="middle"><p color="#000000">Agir de maneira independente, com confiança em suas capacidades, reconhecendo suas conquistas e limitações.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[1].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EO03)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Ampliar as relações interpessoais, desenvolvendo atitudes de participação e cooperação.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[2].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={27} align="center" valign="middle"><b><font color="#000000">(EI03EO04)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Comunicar suas ideias e sentimentos a pessoas e grupos diversos..</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[3].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EO05)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Demonstrar valorização das características de seu corpo e respeitar as características dos outros (crianças e adultos) com os quais convive.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[4].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={27} align="center" valign="middle"><b><font color="#000000">(EI03EO06)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Manifestar interesse e respeito por diferentes culturas e modos de vida.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[5].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03EO07)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Usar estratégias pautadas no respeito mútuo para lidar com conflitos nas interações com crianças e adultos.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[3].avaliacoes[6].nota))}
                            </tr>
                            <tr>
                                <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} rowSpan={2} height={40} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CÓDIGO</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">CAMPOS DE EXPERIÊNCIAS</font></b></td>
                                {periodos.map(periodo => (
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '2px double #000000' }} colSpan={avaliacoes.length} align="center" valign="middle" bgcolor="#008000"><b><font color="#FFFFFF">{periodo.titulo}</font></b></td>
                                ))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle"><b><font color="#000000">ESPAÇOS,TEMPOS, QUANTIDADES, RELAÇÕES E TRANSFORMAÇÕES</font></b></td>
                                {periodos.map(() => (montarTitulosAvaliacoes()))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03ET01) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Estabelecer relações de comparação entre objetos, observando suas propriedades.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[0].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03ET02) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Observar e descrever mudanças em diferentes materiais, resultantes de ações sobre eles, em experimentos envolvendo fenômenos naturais e artificiais.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[1].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03ET03) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Identificar e selecionar fontes de informações, para responder a questões sobre a natureza, seus fenômenos, sua conservação.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[2].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03ET04) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Registrar observações, manipulações e medidas, usando múltiplas linguagens (desenho, registro por números ou escrita espontânea), em diferentes suportes.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[3].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={27} align="center" valign="middle"><b><font color="#000000">(EI03ET05) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Classificar objetos e figuras de acordo com suas semelhanças e diferenças.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[4].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03ET06) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Relatar fatos importantes sobre seu nascimento e desenvolvimento, a história dos seus familiares e da sua comunidade.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[5].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={29} align="center" valign="middle"><b><font color="#000000">(EI03ET07) </font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Relacionar números às suas respectivas quantidades e identificar o antes, o depois e o entre em uma sequência.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[6].nota))}
                            </tr>
                            <tr>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={27} align="center" valign="middle"><b><font color="#000000">(EI03ET08)</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000' }} align="left" valign="middle"><p color="#000000">Expressar medidas (peso, altura etc.), construindo gráficos básicos.</p></td>
                                {periodos.map(periodo => montarNotas(periodo.grupos[4].avaliacoes[7].nota))}
                            </tr>
                            <tr>
                                <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            </tr>
                            <tr>
                                <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            </tr>
                            <tr>
                                <td height={20} align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                                <td align="left" valign="bottom"><font color="#000000"><br /></font></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default ExcelTabelaNotasPre;