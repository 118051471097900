import React, { Component } from "react";
import ConteudoQuestionario from "./ConteudoQuestionario";
import Controlos from "../_Aux/Controlos";
import ControlosSimples from "../_Aux/ControlosSimples";
import LazyLoad from "react-lazy-load";
import Oops from "../../Geral/Oops.jsx";

class Questionario extends Component {
    state = {
        nome: "",
        objetivos: "",
        questoes: 0,
        dataHora: "",
        //NAO DISPONIVEL
        nao_disponivel: false,
        sameUrl: true,
    };

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    marcarComoNaoDisponivel = () => {
        this.setState({
            nao_disponivel: true
        })
    }

    render() {
        const {
            nome,
            objetivos,
            questoes,
            dataHora,
            //NAO DISPONIVEL
            nao_disponivel,
            sameUrl
        } = this.state;

        return (
            <div className="questionario">
                <div className="player-wrapper">
                    <LazyLoad offsetVertical={200}>
                        <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                    </LazyLoad>
                    {!nao_disponivel ?
                        <>
                            <ConteudoQuestionario marcarComoNaoDisponivel={this.marcarComoNaoDisponivel} carregarInfo={(nome, objetivos, questoes, dataHora) => this.setState({ nome, objetivos, questoes, dataHora })} />
                            <Controlos
                                backUrl={`/gerir-curso/${this.props.match.params.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                sameUrl={sameUrl}
                                temAnotacoes
                                temInformacoes
                                nome={nome}
                                objetivos={objetivos}
                                questoes={questoes}
                                icon={require("../../images/icons/dirigido.png")}
                                dataHora={dataHora}
                            />
                        </>
                        :
                        <>
                            <div className="container">
                                <div className="bloco-nao-disponivel">
                                    <div className="bloco-info nao-disponivel">
                                        <Oops tem_texto texto={"Conteúdo não disponível"} />
                                    </div>
                                </div>
                            </div>
                            <ControlosSimples />
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default Questionario;
