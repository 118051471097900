import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Form, Input, Tabs, Collapse, Icon, Modal, notification } from "antd";
import DrawerAdicionarAvaliacao from "./_Aux/DrawerAdicionarAvaliacao"
import axios from "axios";
import moment from 'moment';
import noimage from "../../images/noimage.png";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const { TabPane } = Tabs;
const { Panel } = Collapse;

class Notas extends Component {
    state = {
        periodoId: "",
        avaliacaoId: "",
        ordem: 1,
        existeRecuperacao: false,
        periodos: [],
        participantes: [],
        participantesProvas: [],
        avalicoesNoPeriodo: 0,
        //DRAWER
        visible: false,
        //LOADING
        iconLoading: false,
        //LOADING
        loading: true,
        //VALIDATE
        formValida: true,
        //FORMULA
        ano: "",
        eja: false,
        formula: 0
    }

    componentDidMount() {
        this.listar();
        this.carregarFormulaAno();
    }

    handleChange = (event, periodoIndex, avaliacaoIndex, utilizadorId) => {
        const periodos = this.state.periodos;
        const participanteIndex = periodos[periodoIndex].avaliacoes[avaliacaoIndex].participantes.findIndex(x => x.id_utilizador === utilizadorId)

        if (participanteIndex >= 0) {
            periodos[periodoIndex].avaliacoes[avaliacaoIndex].participantes[participanteIndex].nota = event.target.value;
        }
        else {
            const nota = {
                id_utilizador: utilizadorId,
                nota: event.target.value
            };

            periodos[periodoIndex].avaliacoes[avaliacaoIndex].participantes = [...periodos[periodoIndex].avaliacoes[avaliacaoIndex].participantes, nota]
        }

        this.setState({
            periodos
        })
    };

    handleChangeProvaFinal = (event, index) => {
        const participantesProvas = this.state.participantesProvas;
        participantesProvas[index].provaFinal = event.target.value;

        this.setState({
            participantesProvas
        })
    };

    handleChangeSegundaChamada = (event, index) => {
        const participantesProvas = this.state.participantesProvas;
        participantesProvas[index].segundaChamada = event.target.value;

        this.setState({
            participantesProvas
        })
    };

    listar = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-periodos",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    periodos: response.data
                }, () => this.listarParticipantes())
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar períodos!");
            });
    }

    listarParticipantes = () => {
        axios({
            method: "get",
            url: "/api/aulas/participantes",
            timeout: 60 * 10 * 1000,
            params: {
                cursoid: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data,
                    participantesProvas: response.data,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    carregarFormulaAno = () => {
        axios({
            method: "get",
            url: "/api/aulas/carregar-formula-ano",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    ano: response.data.ano,
                    eja: response.data.eja,
                    formula: response.data.formula
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a formula!");
            });
    }

    avaliacaoAdicionada = (avaliacao) => {
        avaliacao.participantes = [];
        const periodos = this.state.periodos;
        const index = periodos.findIndex(x => x.id === this.state.periodoId)
        let avaliacoes = [...periodos[index].avaliacoes, avaliacao]
        avaliacoes.sort((a, b) => a.ordem > b.ordem && 1 || -1)
        periodos[index].avaliacoes = avaliacoes;

        this.setState({
            periodos,
            visible: false
        })
    }

    avaliacaoAlterada = (avaliacao) => {
        var periodos = this.state.periodos;
        var index = periodos.findIndex(x => x.id === this.state.periodoId)
        var avaliacoes = periodos[index].avaliacoes
        var avaliacaoIndex = avaliacoes.findIndex(x => x.id === this.state.avaliacaoId)
        avaliacoes.splice(avaliacaoIndex, 1)
        avaliacoes = [...avaliacoes, avaliacao];
        avaliacoes.sort((a, b) => a.ordem > b.ordem && 1 || -1)
        periodos[index].avaliacoes = avaliacoes;

        this.setState({
            periodos,
            visible: false
        })
    }

    carregarNota = (indexPeriodo, indexAvaliacao, utilizadorId) => {
        var periodos = this.state.periodos;
        if (periodos[indexPeriodo].avaliacoes[indexAvaliacao].participantes)
            var participante = periodos[indexPeriodo].avaliacoes[indexAvaliacao].participantes.find(x => x.id_utilizador === utilizadorId)
        if (participante)
            return participante.nota;
    }

    guardar = (indexPeriodo, indexAvaliacao) => {
        if (!this.state.formValida)
            return false;

        this.setState({ iconLoading: true })

        const periodos = this.state.periodos;
        const notas = periodos[indexPeriodo].avaliacoes[indexAvaliacao].participantes

        var item = new FormData()
        item.append("avaliacaoId", periodos[indexPeriodo].avaliacoes[indexAvaliacao].id)
        item.append("notas", JSON.stringify(notas))

        axios({
            method: "post",
            url: "/api/aulas/adicionar-notas",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Notas guardadas!")
                this.setState({ iconLoading: false })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi guardar notas!")
                this.setState({ iconLoading: false })
            })
    }

    guardarProvas = () => {
        if (!this.state.formValida)
            return false;

        this.setState({ iconLoading: true })

        var item = new FormData()
        item.append("cursoId", localStorage.getItem("codigo_curso"))
        item.append("participantesProvas", JSON.stringify(this.state.participantesProvas))

        axios({
            method: "post",
            url: "/api/aulas/adicionar-provas-finais",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Notas guardadas!")
                this.setState({ iconLoading: false })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi guardar notas!")
                this.setState({ iconLoading: false })
            })
    }

    excluir = (periodoIndex, avaliacaoId) => {
        confirm({
            title: "Pretende excluir esta avaliação?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/aulas/excluir-avaliacao",
                    params: {
                        avaliacaoId
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Avaliação excluida!");
                        var periodos = this.state.periodos;
                        var avaliacoes = periodos[periodoIndex].avaliacoes;
                        var index = avaliacoes.findIndex(x => x.id === avaliacaoId)
                        avaliacoes.splice(index, 1)
                        let _avaliacoes = avaliacoes.map((avaliacao, index) => {
                            avaliacao.ordem = index + 1;
                            return avaliacao;
                        })
                        periodos[periodoIndex].avaliacoes = _avaliacoes;

                        this.setState({
                            periodos
                        });

                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir esta avaliação!");
                    });
            }
        });
    }

    abrirDrawerAvaliacao = periodo => {
        //var _avaliacoes = periodo.avaliacoes.filter(x => !x.recuperacao)
        //if (_avaliacoes.length < 5)
        this.setState({
            periodoId: periodo.id,
            avaliacaoId: 0,
            visible: true,
            ordem: periodo.avaliacoes.length ? periodo.avaliacoes[periodo.avaliacoes.length - 1].ordem + 1 : 1,
            existeRecuperacao: periodo.avaliacoes.find(x => x.recuperacao === true),
            avalicoesNoPeriodo: periodo.avaliacoes
        })
        //else
        //    openNotificationWithIcon("warning", "Atenção", "Limite de 5 avaliações por periodo");
    }

    abrirDrawerAvaliacaoDetalhe = (periodo, avaliacaoId) => {
        this.setState({
            periodoId: periodo.id,
            avaliacaoId: avaliacaoId,
            visible: true,
            ordem: periodo.avaliacoes[periodo.avaliacoes.length - 1].ordem + 1,
            existeRecuperacao: periodo.avaliacoes.find(x => x.recuperacao === true && x.id !== avaliacaoId)
        })
    }

    validateDecimal = (rule, value, callback) => {
        var regexp = /^\d+\.\d{0,2}$/;

        if (!isNaN(value.replace(",", ".")) && regexp.test(value.replace(",", ".")) && !value.includes(".")) {
            callback("Substitua a ',' por '.'");
            this.setState({
                formValida: false
            })
        }
        else {
            callback();
            this.setState({
                formValida: true
            })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            periodoId,
            avaliacaoId,
            ordem,
            existeRecuperacao,
            periodos,
            participantes,
            participantesProvas,
            avalicoesNoPeriodo,
            //DRAWER
            visible,
            //LOADING
            iconLoading,
            //LOADING
            loading,
            //FORMULA
            ano,
            eja,
            formula
        } = this.state;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        return (
            <>
                <div className="container container-body bloco-diario-notas">
                    <div className="bloco-curso-diario-opcoes-end">
                        <Link to={`/gerir-curso/${this.props.match.params.curso}/curso-diario`} className="botao-icon-voltar" title="Voltar">
                            <Icon type="arrow-left" />
                        </Link>
                    </div>
                    {loading ? (
                        <div className="loading-data">
                            <div className="loading" />
                        </div>
                    ) : (
                        <>
                            {periodos.map((periodo, indexPeriodo) => (
                                <div key={indexPeriodo} className="curso-diario bloco-notas">
                                    <div className="bloco-informacoes">
                                        <div className="info-aula">
                                            <span className="bloco-info-aula">
                                                <span className="titulo-aula">{periodo.titulo}
                                                    {/*<span>({periodo.peso}%)</span>*/}
                                                </span>
                                                <span className="datas-aula">{moment(periodo.dt_inicio).format("DD/MM/YYYY")} - {moment(periodo.dt_fim).format("DD/MM/YYYY")}</span>
                                            </span>
                                            <Link className="botao-icon-adicionar" to="#" title="Adicionar" onClick={() => this.abrirDrawerAvaliacao(periodo)}>
                                                <Icon type="plus" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="listagem-avaliacoes">
                                        <Collapse defaultActiveKey={[]} className="collapse-campos">
                                            {periodo.avaliacoes.map((avaliacao, indexAvaliacao) => (
                                                <Panel key={indexAvaliacao} header={<span className="collapse-panel-avaliacoes">
                                                    <span>{avaliacao.titulo}
                                                        {/*{!avaliacao.recuperacao ?
                                                            <span>({avaliacao.peso}%)</span>
                                                            :
                                                            null
                                                        }*/}
                                                    </span>
                                                    <span className="bloco-opcoes">
                                                        <Link className="botao-icon-editar" to="#" title="Editar" onClick={(event) => { event.stopPropagation(); this.abrirDrawerAvaliacaoDetalhe(periodo, avaliacao.id); }}>
                                                            <Icon type="edit" />
                                                        </Link>
                                                        <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={(event) => { event.stopPropagation(); this.excluir(indexPeriodo, avaliacao.id) }}>
                                                            <Icon type="delete" />
                                                        </Link>
                                                    </span>
                                                </span>}
                                                >
                                                    <Form {...formItemLayout} className="form-categorias form-diario-notas-desktop">
                                                        <ul className="lista-participantes-notas">
                                                            {participantes.map((participante, indexParticipante) => (
                                                                <li key={indexParticipante} className="participante">
                                                                    <div className="info-participante">
                                                                        <div className="container-img">
                                                                            <img src={participante.foto ? participante.foto : noimage} />
                                                                        </div>
                                                                        <div className="info">
                                                                            <span className="nome">{participante.nome}</span>
                                                                            <p className="numero">{participante.numero}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Item label="Nota">
                                                                            {getFieldDecorator(`nota${indexPeriodo}${indexAvaliacao}${indexParticipante}`, {
                                                                                rules: [
                                                                                    {
                                                                                        validator: this.validateDecimal,
                                                                                    }
                                                                                ],
                                                                                initialValue: this.carregarNota(indexPeriodo, indexAvaliacao, participante.id)
                                                                            })(<Input name={`nota${indexPeriodo}${indexAvaliacao}${indexParticipante}`} onChange={(event) => this.handleChange(event, indexPeriodo, indexAvaliacao, participante.id)} />)}
                                                                        </Form.Item>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Form>
                                                    <Form className="form-categorias form-diario-notas-mobile">
                                                        <ul className="lista-participantes-notas">
                                                            {participantes.map((participante, indexParticipante) => (
                                                                <li key={indexParticipante} className="participante">
                                                                    <div className="info-participante">
                                                                        <div className="container-img">
                                                                            <img src={participante.foto ? participante.foto : noimage} />
                                                                        </div>
                                                                        <div className="info">
                                                                            <span className="nome">{participante.nome}</span>
                                                                            <p className="numero">{participante.numero}</p>
                                                                        </div>
                                                                    </div>
                                                                    <Form.Item label="Nota">
                                                                        {getFieldDecorator(`nota${indexPeriodo}${indexAvaliacao}${indexParticipante}`, {
                                                                            rules: [
                                                                                {
                                                                                    validator: this.validateDecimal,
                                                                                }
                                                                            ],
                                                                            initialValue: this.carregarNota(indexPeriodo, indexAvaliacao, participante.id)
                                                                        })(<Input name={`nota${indexPeriodo}${indexAvaliacao}${indexParticipante}`} onChange={(event) => this.handleChange(event, indexPeriodo, indexAvaliacao, participante.id)} />)}
                                                                    </Form.Item>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Form>
                                                    <Link className="botao-principal" to="#" disabled={iconLoading} onClick={() => this.guardar(indexPeriodo, indexAvaliacao)}>
                                                        {iconLoading ? <Icon type="loading" /> : null}
                                                        Guardar
                                                    </Link>
                                                </Panel>
                                            ))}
                                        </Collapse>
                                    </div>
                                </div>
                            ))}
                            <div className="curso-diario bloco-notas">
                                <div className="bloco-informacoes">
                                    <div className="info-aula">
                                        <span className="bloco-info-aula">
                                            <span className="titulo-aula">Prova Final</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="listagem-avaliacoes">
                                    <Collapse defaultActiveKey={[]} className="collapse-campos">
                                        <Panel header={<span className="collapse-panel-avaliacoes">
                                            Resultados
                                        </span>}
                                        >
                                            <Form {...formItemLayout} className="form-categorias">
                                                <ul className="lista-participantes-notas">
                                                    {participantesProvas.map((participante, index) => (
                                                        <li key={index} className="participante">
                                                            <div className="info-participante">
                                                                <div className="container-img">
                                                                    <img src={participante.foto ? participante.foto : noimage} />
                                                                </div>
                                                                <div className="info">
                                                                    <span className="nome">{participante.nome}</span>
                                                                    <p className="numero">{participante.numero}</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Form.Item label="Nota">
                                                                    {getFieldDecorator(`nota${index}`, {
                                                                        rules: [
                                                                            {
                                                                                validator: this.validateDecimal,
                                                                            }
                                                                        ],
                                                                        initialValue: participante.provaFinal
                                                                    })(<Input name={`nota${index}`} onChange={(event) => this.handleChangeProvaFinal(event, index)} />)}
                                                                </Form.Item>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Form>
                                            <Link className="botao-principal" to="#" disabled={iconLoading} onClick={this.guardarProvas}>
                                                {iconLoading ? <Icon type="loading" /> : null}
                                                Guardar
                                            </Link>
                                        </Panel>
                                    </Collapse>
                                </div>
                                </div>
                                {ano === "9º" && !eja || ano === "10º" && eja ?
                                    <div className="curso-diario bloco-notas">
                                        <div className="bloco-informacoes">
                                            <div className="info-aula">
                                                <span className="bloco-info-aula">
                                                    <span className="titulo-aula">Prova (2ª Chamada)</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="listagem-avaliacoes">
                                            <Collapse defaultActiveKey={[]} className="collapse-campos">
                                                <Panel header={<span className="collapse-panel-avaliacoes">
                                                    Resultados
                                                </span>}
                                                >
                                                    <Form {...formItemLayout} className="form-categorias">
                                                        <ul className="lista-participantes-notas">
                                                            {participantesProvas.map((participante, index) => (
                                                                <li key={index} className="participante">
                                                                    <div className="info-participante">
                                                                        <div className="container-img">
                                                                            <img src={participante.foto ? participante.foto : noimage} />
                                                                        </div>
                                                                        <div className="info">
                                                                            <span className="nome">{participante.nome}</span>
                                                                            <p className="numero">{participante.numero}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Item label="Nota">
                                                                            {getFieldDecorator(`segundaChamada${index}`, {
                                                                                rules: [
                                                                                    {
                                                                                        validator: this.validateDecimal,
                                                                                    }
                                                                                ],
                                                                                initialValue: participante.segundaChamada
                                                                            })(<Input name={`segundaChamada${index}`} onChange={(event) => this.handleChangeSegundaChamada(event, index)} />)}
                                                                        </Form.Item>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Form>
                                                    <Link className="botao-principal" to="#" disabled={iconLoading} onClick={this.guardarProvas}>
                                                        {iconLoading ? <Icon type="loading" /> : null}
                                                        Guardar
                                                    </Link>
                                                </Panel>
                                            </Collapse>
                                        </div>
                                    </div>
                                    : null}
                        </>
                    )}
                </div>
                <DrawerAdicionarAvaliacao
                    periodoId={periodoId}
                    avaliacaoId={avaliacaoId}
                    ordem={ordem}
                    existeRecuperacao={existeRecuperacao}
                    visible={visible}
                    avalicoesNoPeriodo={avalicoesNoPeriodo}
                    onClose={() => this.setState({ visible: false })}
                    avaliacaoAdicionada={(avaliacao) => this.avaliacaoAdicionada(avaliacao)}
                    avaliacaoAlterada={(avaliacao) => this.avaliacaoAlterada(avaliacao)}
                />
            </>
        );
    }
}

const FormNotas = Form.create({ name: "form-notas" })(Notas);

export default FormNotas;
