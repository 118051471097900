import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Checkbox, notification, Icon, Tooltip } from "antd";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Configuracoes extends Component {
    static contextType = GlobalContext;

    state = {
        //CONFIGURACOES
        imagem_propria: false,
        novidades: false,
        visivel: false,
        visivel_entidade: true,
        nome_abrev: "",
        //DADOS HOST
        host: "",
        port: "",
        ssl: false,
        username: "",
        password: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    UNSAFE_componentWillMount() {
        this.carregarDados();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeHost = event => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
                if (!this.state.host && !this.state.port && !this.state.username && !this.state.password) {
                    this.props.form.resetFields()
                }
        });
    };

    handleChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    carregarDados = () => {
        axios({
            method: "get",
            url: "/api/empresa/configs"
        })
            .then(response => {
                this.setState({
                    //CONFIGURACOES
                    imagem_propria: response.data.imagem_propria,
                    novidades: response.data.novidades,
                    visivel: response.data.visivel,
                    visivel_entidade: response.data.visivel_entidade,
                    nome_abrev: response.data.nome_abrev ? response.data.nome_abrev : "",
                    //DADOS HOST
                    host: response.data.host ? response.data.host : "",
                    port: response.data.port ? response.data.port : "",
                    ssl: response.data.ssl,
                    username: response.data.username ? response.data.username : "",
                    password: response.data.password ? response.data.password : "",
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    atualizarDados = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll(["nome_abrev"], (err, values) => {
            if (!err) {

                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("imagem_propria", this.state.imagem_propria);
                item.append("novidades", this.state.novidades);
                item.append("visivel_entidade", this.state.visivel_entidade);
                item.append("nome_abrev", this.state.nome_abrev);

                axios({
                    method: "put",
                    url: "/api/empresa/alterar-configuracoes",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        this.setState({
                            iconLoading: false
                        });

                        this.context.atualizarState({
                            atualizarEntidade: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        })
    };

    atualizarDadosHost = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll(["host", "port", "username", "password"],(err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("host", this.state.host);
                item.append("port", this.state.port);
                item.append("ssl", this.state.ssl);
                item.append("username", this.state.username);
                item.append("password", this.state.password);

                axios({
                    method: "put",
                    url: "/api/empresa/alterar-configuracoes-host",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Dados atualizados!");
                        this.setState({
                            iconLoading: false
                        });

                        this.context.atualizarState({
                            atualizarEntidade: true
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                    });
            }
        });
    };

    validarEstrutura = (rule, value, callback) => {
        var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (format.test(value))
            callback("Nome abreviado deve conter apenas letras e números");

        callback();
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            host,
            port,
            ssl,
            username,
            password,
            imagem_propria,
            visivel_entidade,
            nome_abrev,
            visivel,

            novidades,
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="bloco-redes-sociais bloco-personalizacao">
                <h3 className="titulo-separador" style={{ marginBottom: 20 }}>Personalização</h3>
                <Form className="personalizacao">
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="imagem_propria" checked={imagem_propria} onChange={this.handleChangeCheckbox}>
                            Usar o logo no topo da página do Canal
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 20 }}>
                        <Checkbox name="novidades" checked={novidades} onChange={this.handleChangeCheckbox}>
                            Destacar os cursos recentemente adicionados{" "}
                            <Tooltip className="info-icon" title="Mostrar a lista de novos cursos / novidades na página de entrada">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </Checkbox>
                    </Form.Item>
                    {/*{visivel ? (
                        <Form.Item>
                            <Checkbox name="visivel_entidade" checked={visivel_entidade} onChange={this.handleChangeCheckbox}>
                                Habilitar canal personalizado{" "}
                                <Tooltip className="info-icon" title="A página pública do Canal ficará visível na pesquisa da página da entrada da Stepforma e pode ser acedida através do link personalizado no campo seguinte 'Nome Abreviado'">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </Checkbox>
                        </Form.Item>
                    ) : null}*/}
                    {/*<Form.Item
                        label={
                            <span>
                                Nome Abreviado{" "}
                                <Tooltip className="info-icon" title="Nome utilizado no endereço da página pública - Não usar espaços">
                                    <Icon type="question-circle-o" />
                                </Tooltip>{" "}
                                <span>
                                    <i>{`https://${window.location.hostname}/${nome_abrev.replace(/\s+/g, '-').toLowerCase()}`}</i>
                                </span>
                            </span>
                        }
                    >
                        {getFieldDecorator("nome_abrev", {
                            rules: [
                                {
                                    required: visivel_entidade,
                                    message: "Campo obrigatório",
                                    whitespace: true
                                },
                                {
                                    validator: this.validarEstrutura
                                }
                            ],
                            initialValue: nome_abrev
                        })(<Input name="nome_abrev" disabled={!visivel_entidade} onChange={this.handleChange} />)}
                    </Form.Item>*/}
                    <button className="botao-principal" onClick={this.atualizarDados} disabled={iconLoading}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                </Form>
                <Form className="personalizacao" style={{ marginTop: 100 }}>
                    <h3 className="titulo-separador" style={{ marginBottom: 20 }}>Dados da conta de e-mail do Canal a usar nas notificações e nas newsletters</h3>
                    <Form.Item label="Host">
                        {getFieldDecorator("host", {
                            rules: [
                                {
                                    required: (host || port || username || password),
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: host
                        })(<Input name="host" className="input-50" onChange={this.handleChangeHost} />)}
                    </Form.Item>
                    <Form.Item label="Port">
                        {getFieldDecorator("port", {
                            rules: [
                                {
                                    required: (host || port || username || password),
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: port
                        })(<Input name="port" className="input-50" onChange={this.handleChangeHost} />)}
                    </Form.Item>
                    <Form.Item>
                        <Checkbox name="ssl" className="input-50" checked={ssl} onChange={this.handleChangeCheckbox}>
                            SSL/TLS
                        </Checkbox>
                    </Form.Item>
                    <Form.Item label="Conta / Email">
                        {getFieldDecorator("username", {
                            rules: [
                                {
                                    type: "email",
                                    message: "Introduza um endereço de e-mail válido"
                                },
                                {
                                    required: (host || port || username || password),
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: username
                        })(<Input name="username" className="input-50" onChange={this.handleChangeHost} />)}
                    </Form.Item>
                    <Form.Item label="Senha">
                        {getFieldDecorator("password", {
                            rules: [
                                {
                                    required: (host || port || username || password),
                                    message: "Campo obrigatório"
                                }
                            ],
                            initialValue: password
                        })(<Input name="password" className="input-50" type="password" onChange={this.handleChangeHost} />)}
                    </Form.Item>
                    <button className="botao-principal" onClick={this.atualizarDadosHost} disabled={iconLoading}>
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                </Form>
            </div>
        );
    }
}

const FormConfiguracoes = Form.create({ name: "form-configuracoes" })(Configuracoes);

export default FormConfiguracoes;
