import React, {useState, useEffect} from "react";
import { Table } from 'antd';
import ReactDragListView from "react-drag-listview";
import moment from 'moment';
import axios from "axios";
import Controlos from "./Controlos/Controlos";

const Relatorio = ({ menuOptionSelected }) => {
    const [search, setSearch] = useState("");
    const [filterSchool, setFilterSchool] = useState(undefined);
    const [filterEdition, setFilterEdition] = useState(undefined);
    const [filterCourse, setFilterCourse] = useState(undefined);
    const [filterPhase, setFilterPhase] = useState(undefined);
    const [filterRole, setFilterRole] = useState(undefined);
    const [filterState, setFilterState] = useState(undefined);
    const [activeFilters, setActiveFilters] = useState([]);
    const [cols, setCols] = useState([]);
    const [data, setData] = useState([]);
    const [dataBackUp, setDataBackUp] = useState([]);
    const [sorter, setSorter] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const columns = [...cols];
            const item = columns.splice(fromIndex, 1)[0];
            columns.splice(toIndex, 0, item);
            setCols(columns)
        },
        nodeSelector: "th"
    };

    const loadList = () => {
        if (search || filterSchool || filterEdition || filterCourse || filterPhase || filterRole || filterState) {
            setLoading(true);
            axios({
                method: "get",
                url: "/api/relatorios/lista-utilizadores",
                params: {
                    search,
                    columns: JSON.stringify(cols.map(col => col.dataIndex)),
                    filterSchool,
                    filterEdition,
                    filterCourse,
                    filterPhase,
                    filterRole,
                    filterState
                }
            })
                .then(response => {
                    //const buildDaysAway = (data) => {
                    //    const totalDias = moment().diff(moment(data, "YYYY-MM-DD"), 'days');
                    //    return totalDias ? totalDias : 0;
                    //}

                    //const buildAge = (birthday) => {
                    //    if (birthday)
                    //        return moment().diff(birthday, 'years') + "";
                    //    else
                    //        return ""
                    //}

                    //const buildRole = (role) => {
                    //    if (role === 1)
                    //        return "Gestor";
                    //    if (role === 2)
                    //        return "Instrutor";
                    //    else
                    //        return "Aluno";
                    //}

                    //var data = response.data.map((user, index) => {
                    //    return {
                    //        key: index,
                    //        number: user.number,
                    //        name: user.name,
                    //        lastAccess: user.lastAccess,
                    //        absence: buildDaysAway(user.lastAccess),
                    //        locale: user.locale ? user.locale : "",
                    //        age: buildAge(user.birthday),
                    //        school: user.school ? user.school : "",
                    //        gender: user.gender ? user.gender : "",
                    //        role: buildRole(user.role),
                    //        lastChange: moment(user.lastChange).format("YYYY-MM-DD")
                    //    }
                    //})

                    setData(response.data);
                    setDataBackUp(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });

                    setLoading(false);
                });
        }
        else {
            setData([]);
        }
    }

    useEffect(() => {
        setFilterSchool(undefined);
        setFilterEdition(undefined);
        setFilterCourse(undefined);
        setFilterPhase(undefined);
        setFilterRole(undefined);
        setFilterState(undefined);
        setCols([
            {
                title: "Número",
                dataIndex: "number",
                sorter: (a, b) => a.number.localeCompare(b.number),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Nome",
                dataIndex: "name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Último Acesso",
                dataIndex: "lastAccess",
                sorter: (a, b) => moment(a.lastAccess).unix() - moment(b.lastAccess).unix(),
                sortDirections: ['descend', 'ascend'],
            }
        ])

        setData([]);

    }, [menuOptionSelected]);

    useEffect(() => {
        loadList();

    }, [activeFilters]);

    //const HandleKeyPress = (event) => {
    //    var pesquisa = event.target.value;
    //    if (event.key === "Enter") {
    //        var data = dataBackUp.filter(x =>
    //            cols.find(x => x.dataIndex === "number") && x.number.toLowerCase().includes(pesquisa.toLowerCase())
    //            || cols.find(x => x.dataIndex === "name") && x.name.toLowerCase().includes(pesquisa.toLowerCase())
    //            || (cols.find(x => x.dataIndex === "lastAccess") && x.lastAccess) && x.lastAccess.toLowerCase().includes(pesquisa.toLowerCase())
    //            || (cols.find(x => x.dataIndex === "absence") && x.absence) && ((`${x.absence} dia`).includes(pesquisa.toLowerCase()) || (`${x.absence} dias`).includes(pesquisa.toLowerCase()))
    //            || (cols.find(x => x.dataIndex === "locale") && x.locale) && x.locale.toLowerCase().includes(pesquisa.toLowerCase())
    //            || (cols.find(x => x.dataIndex === "age") && x.age) && x.age.toLowerCase().includes(pesquisa.toLowerCase())
    //            || (cols.find(x => x.dataIndex === "school") && x.school) && x.school.toLowerCase().includes(pesquisa.toLowerCase())
    //            || (cols.find(x => x.dataIndex === "gender") && x.gender) && x.gender.toLowerCase().includes(pesquisa.toLowerCase())
    //            || (cols.find(x => x.dataIndex === "role") && x.role) && x.role.toLowerCase().includes(pesquisa.toLowerCase())
    //            || (cols.find(x => x.dataIndex === "lastChange") && x.lastChange) && x.lastChange.toLowerCase().includes(pesquisa.toLowerCase())
    //        )
    //        setData(data)
    //    }
    //}

    const HandleKeyPress = (event) => {
        if (event.key === "Enter") {
            setActiveFilters([...activeFilters, "SEARCH"])
            setSearch(event.target.value);
        }
    }

    const resetSearch = () => {
        setActiveFilters(activeFilters.filter(x => x !== "SEARCH"))
        setSearch("");
    }

    const handleChangeSchool = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "SCHOOL"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "SCHOOL"))
        }
        setFilterSchool(filter);
    }

    const handleChangeEdition = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "EDITION"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "EDITION"))
        }
        setFilterEdition(filter);
    }

    const handleChangeCourse = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "COURSE"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "COURSE"))
        }
        setFilterCourse(filter);
    }

    const handleChangePhase = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "PHASE"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "PHASE"))
        }
        setFilterPhase(filter);
    }

    const handleChangeRole = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "ROLE"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "ROLE"))
        }
        setFilterRole(filter);
    }

    const handleChangeState = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "STATE"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "STATE"))
        }
        setFilterState(filter);
    }

    return (
        <>
            <div className="report-info">
                <Controlos
                    menuItemKey={menuOptionSelected?.menuItemKey}
                    menuItemName={menuOptionSelected?.menuItemName}
                    HandleKeyPress={(event) => HandleKeyPress(event)}
                    resetSearch={resetSearch}
                    filterSchool={filterSchool}
                    setFilterSchool={(filter) => handleChangeSchool(filter)}
                    filterEdition={filterEdition}
                    setFilterEdition={(filter) => handleChangeEdition(filter)}
                    filterCourse={filterCourse}
                    setFilterCourse={(filter) => handleChangeCourse(filter)}
                    filterPhase={filterPhase}
                    setFilterPhase={(filter) => handleChangePhase(filter)}
                    filterRole={filterRole}
                    setFilterRole={(filter) => handleChangeRole(filter)}
                    filterState={filterState}
                    setFilterState={(filter) => handleChangeState(filter)}
                    setCols={(columns) => setCols(columns)}
                    sorter={sorter}
                    menuOptionSelected={menuOptionSelected}
                    disabledButtons={!search && !filterSchool && !filterEdition && !filterCourse && !filterPhase && !filterRole && !filterState}
                    columnsPositions={cols.map(col => col.dataIndex)}
                    cols={cols}
                    data={data}
                    pageTitle={menuOptionSelected?.menuItemName}
                />
                <div className="page-container">
                    <div className="page-header">
                        <h1 className="page-title">Relatório - Usuários {menuOptionSelected?.menuItemName}</h1>
                        <h3 className="page-counters">
                            <strong>Contar:</strong>
                            <span>{data.length}</span>
                        </h3>
                    </div>
                    <div className="page-body">
                        <ReactDragListView.DragColumn {...dragProps}>
                            <Table
                                columns={cols}
                                pagination={false}
                                dataSource={data}
                                onChange={(pagination, filters, sorter) => setSorter(sorter.order ? { order: sorter.order, key: sorter.columnKey } : undefined)}
                            />
                        </ReactDragListView.DragColumn>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Relatorio;