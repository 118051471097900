import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Oops from "../../Geral/Oops.jsx";
import screenfull from "screenfull";
import { Icon } from "antd";

class Pagina extends Component {
    static contextType = GlobalContext;
    constructor(props) {
        super(props);
        this.state = {
            entidade: "",
            curso: "",
            cursoId: "",
            aulaid: 0,
            html: "",
            fullscreen: false,
            //REDIRECT
            redirect: false,
            redirectLink: "",
            sameUrl: true,
            //LOADING
            loading: true
        };

        this.elementFullScreen = React.createRef();
    }

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            entidade: this.props.match.params.entidade,
            curso: this.props.match.params.curso,
            cursoId: this.props.match.params.cursoid,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    //CARREGA CONTEUDO DA AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/pagina-entidade-player/conteudo1",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    html: response.data.recurso,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    onClickFullscreenElement = () => {
        screenfull.toggle(this.elementFullScreen.current);
        this.setState({ fullscreen: !this.state.fullscreen });
    };

    render() {
        const {
            entidade,
            curso,
            cursoId,
            html,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //LOADING
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data">
                <div className="loading" />
            </div>
        ) : (
            <div>
                <div className="pagina-html">
                    <div className="player-wrapper">
                        <LazyLoad offsetVertical={200}>
                                <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                        </LazyLoad>
                            <div className="container" ref={this.elementFullScreen}>
                                <div className="bloco-html">
                                    <Icon
                                        type={this.state.fullscreen ? "fullscreen-exit" : "fullscreen"}
                                        onClick={this.onClickFullscreenElement}
                                        className="btn-fullscreen"
                                    />
                                    <div className="bloco-info" dangerouslySetInnerHTML={{ __html: html }}></div>
                                </div>
                            </div>
                            <Controlos
                                backUrl={`/${entidade}/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/${cursoId}/curso-modulos`}
                                sameUrl={sameUrl}
                            />
                    </div>
                </div>
            </div>
        );
    }
}

export default Pagina;
