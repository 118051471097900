import React from "react";

const Header = props => {
    return (
        <div
            className="header_user"
            style={{ background: "url(" + `${props.imagem}` + ")" }}
        >
        </div>
    );
};

export default Header;
