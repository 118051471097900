import React, { useState } from "react";
import { Menu } from 'antd';
import Relatorio from "./Relatorio/Relatorio";
import RelatorioEscolasTurmas from "./EscolasTurmas/Relatorio";
import RelatorioFrequenciaGeral from "./FrequenciaGeral/Relatorio";
import RelatorioFrequenciaComparativo from "./FrequenciaComparativo/Relatorio";
import RelatorioFrequenciaAluno from "./FrequenciaAluno/Relatorio";
import RelatorioNotas from "./Notas/Relatorio";
import RelatorioConceito from "./Conceito/Relatorio";
import RelatorioObservacoes from "./Observacoes/Relatorio";
import RelatorioObservacoesCurso from "./ObservacoesCurso/Relatorio";
import RelatorioPareceres from "./Pareceres/Relatorio";
import RelatorioPareceresCurso from "./PareceresCurso/Relatorio";

const { SubMenu } = Menu;

const Relatorios = () => {
    const [menuOptionSelected, setMenuOptionSelected] = useState(null)

    const handleClick = e => {
        setMenuOptionSelected({
            menuItemKey: e.keyPath[0],
            menuItemName: e.item.props.children, 
        })
    };

    const buildReport = () => {
        var key = menuOptionSelected.menuItemKey;

        if (key === "sub1-1" || key === "sub1-2" || key === "sub1-3" || key === "sub1-4" || key === "sub1-5")
            return <Relatorio menuOptionSelected={menuOptionSelected} />;
        else if(key === "sub2-1")
            return <RelatorioEscolasTurmas menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub4-1" || key === "sub4-2" || key === "sub4-3" || key === "sub4-6")
            return <RelatorioFrequenciaGeral menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub4-4")
            return <RelatorioFrequenciaAluno menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub4-5")
            return <RelatorioFrequenciaComparativo menuOptionSelected={menuOptionSelected} />;
        else if (key === "5")
            return <RelatorioNotas menuOptionSelected={menuOptionSelected} />;
        else if (key === "6")
            return <RelatorioConceito menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub7-1")
            return <RelatorioObservacoesCurso menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub7-2")
            return <RelatorioObservacoes menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub8-1")
            return <RelatorioPareceresCurso menuOptionSelected={menuOptionSelected} />;
        else if (key === "sub8-2")
            return <RelatorioPareceres menuOptionSelected={menuOptionSelected} />;
    }


    return (
        <div className="reports" style={{ marginTop: 80 }}>
            <div className="menu">
                <Menu
                    onClick={handleClick}
                    style={{ width: 256 }}
                    mode="inline"
                >
                    <SubMenu key="sub1" title="Usuários">
                        <Menu.Item key="sub1-1">Geral</Menu.Item>
                        <Menu.Item key="sub1-2">Por Escola</Menu.Item>
                        <Menu.Item key="sub1-3">Por Curso</Menu.Item>
                        <Menu.Item key="sub1-4">Por Fases</Menu.Item>
                        <Menu.Item key="sub1-5">Por Perfil</Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub2" title="Escolas">
                        <Menu.Item key="sub2-1">Cursos</Menu.Item>
                        <Menu.Item key="sub2-2" disabled>Estatísticas <i className="soon">Brevemente</i></Menu.Item>
                        <Menu.Item key="sub2-3" disabled>Estatísticas de Atividades <i className="soon">Brevemente</i></Menu.Item>
                        <Menu.Item key="sub2-4" disabled>Estatísticas de Reuniões <i className="soon">Brevemente</i></Menu.Item>
                    </SubMenu>
                    <Menu.Item key="3" disabled>Professores <i className="soon">Brevemente</i></Menu.Item>
                    <SubMenu key="sub4" title="Frequência">
                        <Menu.Item key="sub4-1">Geral</Menu.Item>
                        <Menu.Item key="sub4-2">Por Escola</Menu.Item>
                        <Menu.Item key="sub4-3">Por Curso</Menu.Item>
                        <Menu.Item key="sub4-4">Por Aluno</Menu.Item>
                        <Menu.Item key="sub4-5">Comparativo</Menu.Item>
                        <Menu.Item key="sub4-6">Percentual Aluno</Menu.Item>
                    </SubMenu>
                    <Menu.Item key="5">
                        Notas
                    </Menu.Item>
                    <Menu.Item key="6">
                        Conceito
                    </Menu.Item>
                    <SubMenu key="sub7" title="Observações">
                        <Menu.Item key="sub7-1">Por Curso</Menu.Item>
                        <Menu.Item key="sub7-2">Por Aluno</Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub8" title="Pareceres">
                        <Menu.Item key="sub8-1">Por Curso</Menu.Item>
                        <Menu.Item key="sub8-2">Por Aluno</Menu.Item>
                    </SubMenu>
                    <Menu.Item key="9" disabled>Conselho de classe <i className="soon">Brevemente</i></Menu.Item>
                </Menu>
            </div>
            {menuOptionSelected ?
                buildReport()
                :
                <div className="report-not-selected">
                    <div className="page-container">
                        <img src={require("../images/select-option.svg")} />
                        <h1>Selecione o relatório pretendido.</h1>
                        <h3>Utilize as opções no menu da esquerda para selecionar o relatório que pretende consultar.</h3>
                    </div>
                </div>
            }
        </div>
    );
}

export default Relatorios;