import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../User/Header";
import { Select, Icon, notification, Modal } from "antd";
import ExcelTabelaNotasRegular from "./_Aux/ExcelTabelaNotasRegular";
import CardsNotasRegular from "./_Aux/CardsNotasRegular";
import noimage from "../../images/noimage.png";
import axios from "axios";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class NotasRegular extends Component {
    state = {
        utilizador: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
    };

    UNSAFE_componentWillMount() {
        this.carregarUtilizadorInfo()
    }

    carregarUtilizadorInfo = () => {
        axios({
            method: "get",
            url: "/api/consultar-notas/carregar-utilizador",
            params: {
                utilizadorId: this.props.match.params.utilizadorid
            }
        })
            .then(response => {
                this.setState({
                    utilizador: response.data
                })
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar");
            });
    }

    exportar = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-notas/notas-regular",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        utilizadorId: this.props.match.params.utilizadorid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    exportarPdf = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/consultar-notas/notas-regular-pdf",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        utilizadorId: this.props.match.params.utilizadorid
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    render() {
        const {
            utilizador,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar
        } = this.state;

        return (
            <>
                <Header titulo="CONSULTAR RESULTADOS" />
                <div className="container container-noticias conteiner-consultar container-body">
                    <div className="content">
                        <div className="bloco-cursos">
                            <div className="bloco-tabela">
                                <div className="header">
                                    <div className="utilizador-info">
                                        {utilizador ?
                                            <>
                                                <div className="container-img">
                                                    <img src={utilizador.foto ? utilizador.foto : noimage} />
                                                </div>
                                                <div className="info">
                                                    <span className="nome">
                                                        {utilizador.nome.split("_").length > 1 ? `${utilizador.nome.split("_")[0]} ${utilizador.nome.split("_")[utilizador.nome.split("_").length - 1]}` : utilizador.nome}
                                                    </span>
                                                    <span className="numero">{utilizador.numero}</span>
                                                </div>
                                            </>
                                            : null}
                                    </div>
                                    <div className="controlos">
                                        <Link to={`/${this.props.match.params.entidade}/consultar/${this.props.match.params.utilizadorid}`} className="botao-icon-voltar" title="Voltar">
                                            <Icon type="arrow-left" />
                                        </Link>
                                        <Link className="botao-icon-pdf" to="#" title="Descarregar" onClick={this.exportarPdf}>
                                            <Icon type="file-pdf" />
                                        </Link>
                                        <Link className="botao-icon-download" to="#" title="Descarregar" onClick={this.exportar}>
                                            <Icon type="download" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="bloco-notas-desktop">
                                    <ExcelTabelaNotasRegular />
                                </div>
                                <div className="bloco-notas-mobile">
                                    <CardsNotasRegular />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                <p className="texto">Dados exportados com sucesso!</p>
                                <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}
export default NotasRegular;
