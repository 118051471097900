import React, { Component } from "react";

class TabelaNotas extends Component {

    montarNomeAvaliacoes = (avaliacoes) => {
        const avaliacoesSemRecuperacao = avaliacoes.filter(x => !x.recuperacao)
        let _avaliacoes = [];
        for (let i = 0; i < this.props.total; i++) {
            if (i < avaliacoesSemRecuperacao.length) {
                _avaliacoes.push(<td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                    <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{avaliacoes[i].titulo}
                        {/*({avaliacoes[i].peso} %)*/}
                    </font></b>
                </td>)
            }
            else {
                _avaliacoes.push(<td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                    <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}></font></b>
                </td>)
            }
        }

        return [
            ..._avaliacoes,
            <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>Recuperação</font></b>
            </td>
        ];
    }

    montarNotasAvaliacoes = (avaliacoes) => {
        const avaliacoesSemRecuperacao = avaliacoes.filter(x => !x.recuperacao)
        const recuperacao = avaliacoes.find(x => x.recuperacao)
        let _avaliacoes = [];

        for (let i = 0; i < this.props.total; i++) {
            if (i < avaliacoesSemRecuperacao.length) {
                _avaliacoes.push(<td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" sdnum="1033;0;0.0;-0.0;">
                    <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{avaliacoesSemRecuperacao[i].nota}</font></b>
                </td>)
            }
            else {
                _avaliacoes.push(<td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" sdnum="1033;0;0.0;-0.0;">
                    <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}></font></b>
                </td>)
            }
        }


        return [
            ..._avaliacoes,
            <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" sdnum="1033;0;0.0;-0.0;">
                <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{recuperacao?.nota}</font></b>
            </td>
        ];
    }

    calcularTotalPeriodo = (avaliacoes) => {
        var total = 0;
        var _avaliacoes = avaliacoes.filter(x => !x.recuperacao);
        _avaliacoes.forEach(avaliacao => {
            if (avaliacao.nota.length)
                total += parseFloat(avaliacao.nota)
            //total += (avaliacao.peso / 100) * avaliacao.nota
        });

        var recuperacao = avaliacoes.find(x => x.recuperacao);
        if (recuperacao) {
            if (total < recuperacao.nota)
                total = parseFloat(recuperacao.nota)
        }

        return parseFloat(total.toFixed(2));
    }

    montarFinal = () => {
        let rows = [];
        let notas = [];
        let maxTotalAvaliacoes = 0;

        this.props.periodos.map((periodo, indexPeriodo) => {
            const nota = this.calcularTotalPeriodo(periodo.avaliacoes);
            notas.push({
                nota,
                peso: periodo.peso
            })
            const avaliacoes = periodo.avaliacoes.filter(x => !x.recuperacao);
            if (maxTotalAvaliacoes < avaliacoes.length)
                maxTotalAvaliacoes = avaliacoes.length;
        })

        let totalGeral = this.calcularNotaGeral(notas);
        let mediaAnual = this.calcularMediaAnual(notas);
        let mediaFinal = 0;
        if (this.props.formula === 1)
            mediaFinal = this.props.provaFinal ? ((parseFloat(this.props.provaFinal) + mediaAnual) / 2) : mediaAnual;
        else
            mediaFinal = this.props.provaFinal ? (((parseFloat(this.props.provaFinal) * 3) + (mediaAnual * 4)) / 7) : mediaAnual;

        rows.push(<tr>
            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxTotalAvaliacoes + 2}></td>
            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>Total Geral</font></b>
            </td>
            <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={totalGeral >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{totalGeral}</font></b>
            </td>
        </tr>)
        rows.push(<tr>
            <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxTotalAvaliacoes + 2}></td>
            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>Média Anual</font></b>
            </td>
            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={mediaAnual >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{mediaAnual}</font></b>
            </td>
        </tr>)
        rows.push(<tr>
            <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxTotalAvaliacoes + 2}></td>
            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>Prova Final</font></b>
            </td>
            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={this.props.provaFinal >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{this.props.provaFinal}</font></b>
            </td>
        </tr>)
        rows.push(<tr>
            <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxTotalAvaliacoes + 2}></td>
            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>Média Final</font></b>
            </td>
            <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={mediaFinal >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{mediaFinal.toFixed(2)}</font></b>
            </td>
        </tr>)
        if (this.props.ano === "9º" && !this.props.eja || this.props.ano === "10º" && this.props.eja) {
            rows.push(<tr>
                <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxTotalAvaliacoes + 2}></td>
                <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                    <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>2ª Chamada</font></b>
                </td>
                <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={this.props.segundaChamada >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                    <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{this.props.segundaChamada}</font></b>
                </td>
            </tr>)
            const pontuacaoFinal = this.props.segundaChamada ? ((mediaFinal + parseFloat(this.props.segundaChamada)) / 2) : mediaFinal;
            rows.push(<tr>
                <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxTotalAvaliacoes + 2}></td>
                <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                    <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}>Pontuação Final</font></b>
                </td>
                <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={pontuacaoFinal >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                    <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{pontuacaoFinal.toFixed(2)}</font></b>
                </td>
            </tr>)
            rows.push(<tr>
                <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxTotalAvaliacoes + 2}></td>
                <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                    <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}></font></b>
                </td>
                <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={pontuacaoFinal >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                    <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{pontuacaoFinal >= 6 ? "Aprovado" : "Reprovado"}</font></b>
                </td>
            </tr>)
        }
        else
            rows.push(<tr>
                <td style={{ borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} colSpan={maxTotalAvaliacoes + 2}></td>
                <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor="#D9D9D9"><a className="comment-indicator" />
                    <b><font face="Cambria" size={1} color="#C00000" style={{ fontSize: "14px" }}></font></b>
                </td>
                <td style={{ borderBottom: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={mediaFinal >= 6 ? "#c9ffdb" : "#FFC7CE"}><a className="comment-indicator" />
                    <b><font face="Cambria" size={1} color="#000000" style={{ fontSize: "14px" }}>{mediaFinal >= 6 ? "Aprovado" : "Reprovado"}</font></b>
                </td>
            </tr>)

        return rows;
    }

    calcularNotaGeral = (notas) => {
        let total = 0;
        notas.forEach(_nota => {
            total += parseFloat(_nota.nota)
            //total += (_nota.peso / 100) * _nota.nota
        })

        return parseFloat(total.toFixed(2));
    }

    calcularMediaAnual = (notas) => {
        let total = 0;
        notas.forEach(_nota => {
            total += parseFloat(_nota.nota)
        })

        return parseFloat((total / notas.length).toFixed(2));
    }

    montarNotaPeridoo = (avaliacoes) => {
        const nota = this.calcularTotalPeriodo(avaliacoes)

        return (
            <>
                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={nota >= 6 ? "#c9ffdb" : "#FFC7CE"} sdval={0} sdnum="1033;0;0.0;-0.0;"><b><font face="Cambria" color="#000000">{nota}<br /></font></b></td>
                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} align="center" valign="middle" bgcolor={nota >= 6 ? "#c9ffdb" : "#FFC7CE"} sdval={0} sdnum="1033;0;0.0;-0.0;"><b><font face="Cambria" color="#000000">{nota >= 6 ? "Aprovado" : "Reprovado"}<br /></font></b></td>
            </>
        )
    }

    render() {
        return (
            <table cellSpacing={0} border={0} style={{ width: "100%" }}>
                <tbody>
                    {this.props.periodos.map((periodo, index) => (
                        <>
                            <tr>
                                <td style={{ borderLeft: '1px solid #000000', borderTop: '1px solid #000000', borderRight: '1px solid #000000', fontSize: "14px" }} height={40} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#000000"></font></b></td>
                                {this.montarNomeAvaliacoes(periodo.avaliacoes).map(avaliacao => avaliacao)}
                                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000', fontSize: "14px" }} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#C00000">NOTA BIMESTRAL</font></b></td>
                                <td style={{ borderTop: '1px solid #000000', borderRight: '1px solid #000000', fontSize: "14px" }} align="center" valign="middle" bgcolor="#D9D9D9"><b><font face="Cambria" color="#C00000"></font></b></td>
                            </tr>
                            <tr>
                                <td style={{ borderLeft: '1px solid #000000', borderTop: '1px solid #000000', borderRight: '1px solid #000000' }} height={40} align="center" valign="middle" sdval={1} sdnum="1033;0;00"><b><font face="Cambria" color="#000000">{periodo.titulo}</font></b></td>
                                {this.montarNotasAvaliacoes(periodo.avaliacoes).map(nota => nota)}
                                {this.montarNotaPeridoo(periodo.avaliacoes)}
                            </tr>
                        </>
                    ))}
                    {this.montarFinal()}
                </tbody></table>
        );
    }
}

export default TabelaNotas;
