import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, notification, Modal } from "antd";
import ExcelTabelaNotasRegular from "./_Aux/ExcelTabelaNotasRegular";
import CardsNotasRegular from "./_Aux/CardsNotasRegular";
import noimage from "../../images/noimage.png";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ExcelNotasRegular extends Component {
    state = {
        utilizadorId: "",
        foto: "",
        nome: "",
        numero: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //TABELA
        grupos: [],
        periodos: [],
        avaliacoes: [],
        //LOADING
        iconLoading: false
    }

    componentDidMount() {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"))
        this.setState({
            utilizadorId: userInfo.id,
            foto: userInfo.foto,
            nome: userInfo.nome,
            numero: userInfo.numero
        })
        this.listar();
    }

    listar = () => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"))
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/aulas/listar-notas-pre",
                params: {
                    cursoId: localStorage.getItem("codigo_curso"),
                    utilizadorId: userInfo.id
                }
            })
                .then(response => {
                    if (response.data.periodos.length > 0) {
                        let grupos = [];
                        if (response.data.periodos.length > 1)
                            grupos = response.data.periodos[0].grupos;

                        this.setState({
                            grupos,
                            periodos: response.data.periodos,
                            avaliacoes: response.data.avaliacoes,
                            iconLoading: false
                        })
                    }
                    else {
                        this.setState({
                            iconLoading: false
                        })
                        openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a informação!");
                });
        })
    }

    exportar = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/notas-regular",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        utilizadorId: this.state.utilizadorId
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    exportarPdf = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/notas-regular-pdf",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        utilizadorId: this.state.utilizadorId
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    render() {
        const {
            foto,
            nome,
            numero,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //TABELA
            grupos,
            periodos,
            avaliacoes,
            //LOADING
            iconLoading
        } = this.state;

        return (
            <>
                <div className="container container-body">
                    <div className="bloco-curso-diario-opcoes-user">
                        <div className="info-participante">
                            <div className="container-img">
                                <img src={foto ? foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">{nome}</span>
                                <p className="numero">{numero}</p>
                            </div>
                        </div>
                        <div className="notas-pre-controlos">
                            <Link to={`/gerir-curso/${this.props.match.params.curso}/curso-diario-notas-detalhe`} className="botao-icon-voltar" title="Voltar">
                                <Icon type="arrow-left" />
                            </Link>
                            <Link className="botao-icon-pdf" to="#" title="Descarregar" onClick={this.exportarPdf}>
                                <Icon type="file-pdf" />
                            </Link>
                            <Link className="botao-icon-download" to="#" title="Descarregar" onClick={this.exportar}>
                                <Icon type="download" />
                            </Link>
                        </div>
                    </div>
                    <div className="curso-diario curso-diario-frequencia-desktop">
                        <ExcelTabelaNotasRegular
                            grupos={grupos}
                            periodos={periodos}
                            avaliacoes={avaliacoes}
                        />
                    </div>
                    <div className="curso-diario curso-diario-frequencia-mobile">
                        <CardsNotasRegular
                            grupos={grupos}
                            periodos={periodos}
                            avaliacoes={avaliacoes}
                        />
                    </div>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                <p className="texto">Dados exportados com sucesso!</p>
                                <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

export default ExcelNotasRegular;
