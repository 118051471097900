import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Rate, Skeleton, Affix, Icon } from "antd";
import { GlobalContext } from "../../GlobalState";
import noimage from "../../images/noimage.png";
import axios from "axios";
import AdicionarCarrinho from "./_Aux/AdicionarCarrinho";
import AdicionarSubscricao from "./_Aux/AdicionarSubscricao";
import RedesSociais from "../../Geral/_Aux/RedesSociais";
import HelmetMetaData from "../../Geral/_Aux/Helmet";

class Header extends Component {
    static contextType = GlobalContext;
    state = {
        //DADOS
        imagem: "",
        codigo: "",
        nome: "",
        preco: "",
        subscricao: false,
        objectivo: "",
        avaliacao: "",
        totalAvaliacao: "",
        escola: "",
        coordenador: "",
        dt_curso: "",
        linguas: [],
        linguasLegendas: [],
        formacao: "",
        progress: 0,
        classificacao: "",
        concluido: "",
        aula: "",
        //DRAWER
        loading: true,
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = this.props.match.params.cursoid;

        this.setState({
            cursoId
        });
        this.carregarInfo(cursoId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.curso !== prevProps.match.params.curso) {
            var cursoId = this.props.match.params.cursoid;

            this.setState({
                cursoId
            });
            this.carregarInfo(cursoId);
        }
    }

    //CARREGA A INFORMAÇÃO DO CURSO
    carregarInfo = cursoid => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/carregar-header-curso",
            params: {
                cursoIdEnc: cursoid
            }
        })
            .then(response => {
                this.context.atualizarState({
                    atividadesLivres: response.data.atividadesLivres,
                    disciplinasVisiveis: response.data.disciplinasVisiveis
                });

                this.setState({
                    imagem: response.data.imagem,
                    codigo: response.data.codigo,
                    nome: response.data.nome,
                    preco: response.data.preco,
                    subscricao: response.data.subscricao,
                    objectivo: response.data.objectivo,
                    avaliacao: response.data.mediaAvaliacoes,
                    totalAvaliacao: response.data.totalAvaliacoes,
                    escola: response.data.escola,
                    loading: false
                });
            })
            .catch(() => { });
    };

    render() {
        const {
            //DADOS
            cursoId,
            codigo,
            nome,
            preco,
            subscricao,
            objectivo,
            avaliacao,
            totalAvaliacao,
            escola,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        let location = window.location;
        let currentUrl = `${location.protocol}//${location.hostname}${location.pathname}`;

        return (
            <>
                <HelmetMetaData
                    title={`${codigo} - ${nome}`}
                    description={objectivo}
                    image={`${location.protocol}//${location.hostname}${this.state.imagem}`}
                />
                <div className="header-cursos header-cursos-pagina-publica">
                    <img src={require("../../images/wave_hero_user.svg")} className="img-hero" />
                    <div className="container">
                        <Skeleton active={this.state.loading} avatar loading={this.state.loading}>
                            <div className="lado-esquerdo">
                                <div className="titulo-favorito">
                                    <div className="titulo-curso">
                                        <h2 title={nome}>{codigo} - {nome}</h2>
                                    </div>
                                </div>
                                <span className="descritivo">{objectivo}</span>
                                <div className="bloco-rating-info">
                                    <div className="bloco-esquerda">
                                        {totalAvaliacao ?
                                            <span className="rating">
                                                <Rate disabled value={avaliacao} />
                                                <span className="ant-rate-text">({totalAvaliacao})</span>
                                            </span> : null}
                                        <div className="div-blocos">
                                            {(escola && this.context.escolas_entidade) &&
                                                <div className="bloco-escola">
                                                    <span className="escola">{escola}</span>
                                                </div>}
                                        </div>
                                    </div>
                                    {!this.context.carrinho.find(x => x.id === cursoId || x.tipo === "SUBSCRICAO") ?
                                        <div className="bloco-direita">
                                            {(preco && this.context.monetizacao_entidade && this.context.vendaCursos) ?
                                                <AdicionarCarrinho
                                                    produto={{
                                                        id: cursoId,
                                                        capa: this.state.imagem ? this.state.imagem : noimage,
                                                        nome: `${codigo} - ${nome}`,
                                                        descricao: objectivo,
                                                        preco: preco,
                                                        tipo: "CURSO"
                                                    }}
                                                />
                                                : null}
                                            {(this.context.monetizacao_entidade && this.context.subscricao_entidade && subscricao) ?
                                                <AdicionarSubscricao
                                                    produto={{
                                                        id: cursoId
                                                    }}
                                                />
                                                : null}
                                        </div>
                                        :
                                        <div className="bloco-direita">
                                            {(preco && this.context.monetizacao_entidade && this.context.vendaCursos) ?
                                                <span className="tag-valor-curso" dangerouslySetInnerHTML={{ __html: `${this.context.moeda_entidade} ${preco}` }}>

                                                </span>
                                                : null}
                                            {(this.context.monetizacao_entidade && this.context.subscricao_entidade && subscricao) ?
                                                <span className="tag-valor-subscricao">
                                                    Subscrição
                                                        </span>
                                                : null}
                                        </div>
                                    }
                                    {preco && !this.context.monetizacao_entidade ?
                                        <div className="bloco-direita">
                                            <span className="tag-valor-curso" dangerouslySetInnerHTML={{ __html: `${this.context.moeda_entidade} ${preco}` }}>
                                            </span>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            <div className="lado-direito">
                                <div className="bloco-imagem">
                                    <div className="container-img">
                                        <img src={this.state.imagem ? this.state.imagem : noimage} alt="Imagem do Curso" />
                                    </div>
                                </div>
                            </div>
                        </Skeleton>
                    </div>
                    {/*<RedesSociais
                        facebook
                        twitter
                        linkedin
                        url={currentUrl}
                        description={objectivo}
                        hashtag={""}
                    />*/}
                </div>
            </>
        );
    }
}

export default withRouter(Header);
