import React, { Component } from "react";
import { Link } from "react-router-dom";
import { notification, Rate } from "antd";
import ControlosSimples from "./QuestionarioSatisfacao/ControlosSimples";
import { GlobalContext } from "../GlobalState";
import axios from "axios";
import LazyLoad from "react-lazy-load";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Rating extends Component {
    static contextType = GlobalContext;
    state = {
        cursoId: 0,
        curso: "",
        rating: 0,
        avaliado: false,
        fullscreen: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);

        this.setState({
            curso: this.props.match.params.curso,
            cursoId: localStorage.getItem("codigo_curso")
        });
    }

    handleChange = rating => {
        this.setState({
            rating
        });
    };

    avaliarCurso = () => {
        axios({
            method: "put",
            url: "/api/questionario-satisfacao/avaliar-curso",
            params: {
                cursoid: this.state.cursoId,
                qualificacao: this.state.rating
            }
        })
            .then(() => {
                this.setState({
                    avaliado: true
                });
            })
            .catch(() => {});
    };

    render() {
        const { rating, avaliado } = this.state;

        const desc = ["(1)", "(2)", "(3)", "(4)", "(5)"];

        return (
            <div className="questionario">
                <div className="player-wrapper">
                    <LazyLoad offsetVertical={200}>
                        <img src={require("../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                    </LazyLoad>
                    <div className="container container-body">
                        <div className="bloco-questionario">
                            <div className="bloco-info">
                                {!avaliado ? (
                                    <div className="confirmacao">
                                        {localStorage.getItem("nome_curso_pai") ? (
                                            <div className="bloco-titulo">
                                                <span className="curso">Curso</span>
                                                <span className="nome-curso">{localStorage.getItem("nome_curso_pai")}</span>
                                                <span className="nome-subcurso">{localStorage.getItem("nome_subcurso")}</span>
                                            </div>
                                        ) : (
                                            <div className="bloco-titulo">
                                                <span className="curso">Curso</span>
                                                <span className="nome-curso">{localStorage.getItem("nome_curso")}</span>
                                            </div>
                                        )}
                                        <span className="rating">
                                            <Rate tooltips={desc} value={rating} onChange={this.handleChange} />
                                        </span>
                                        <div className="controlos-resposta">
                                            <button className="botao-principal" onClick={this.avaliarCurso}>
                                                Confirmar
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="confirmacao">
                                        <h2> Obrigado pela sua avaliação</h2>
                                        <div className="controlos-resposta">
                                            <Link to="/sistema-informacao" className="botao-principal">
                                                Concluir
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <ControlosSimples />
                </div>
            </div>
        );
    }
}

export default Rating;
