import React, { Component, useState } from "react";
import { Table, Input, Button, Popconfirm, Form, Upload, Icon, DatePicker } from "antd";
import moment from "moment";

const EditableTable = ({ dataSource, propsUpload, concluido }) => {
    const columns = [
        {
            title: "Nome",
            dataIndex: "nome",
            editable: false
        },
        {
            title: "Data",
            dataIndex: "data",
            editable: false
        },
        //{
        //    title: "Autor",
        //    dataIndex: "autor",
        //    editable: false
        //},
        {
            title: "",
            dataIndex: "opcoes"
        }
    ];

    return (
        <div className="grid-anexos">
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                locale={{ emptyText: "Não existem registos!" }}
            />
        </div>
    );
};

const GridAnexos = Form.create()(EditableTable);

export default GridAnexos;
