import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon } from "antd";
import axios from "axios";
import noimage from "../../images/noimage.png";

class ListaAlunos extends Component {
    state = {
        participantes: [],
        //LOADING
        loading: true,
    }

    componentDidMount() {
        this.listarParticipantes();
    }

    listarParticipantes = () => {
        axios({
            method: "get",
            url: "/api/aulas/participantes",
            timeout: 60 * 10 * 1000,
            params: {
                cursoid: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    render() {
        const {
            participantes,
            //LOADING
            loading,
        } = this.state;

        const montarUrl = (tipo) => {
            if (tipo === "CRECHE")
                return "curso-diario-folha-notas-creche";
            else if (tipo === "PRE")
                return "curso-diario-folha-notas-pre";
            else
                return "curso-diario-folha-notas-regular";
        }

        return (
            <div className="container container-body">
                <div className="bloco-curso-diario-opcoes-end">
                    <Link to={`/gerir-curso/${this.props.match.params.curso}/curso-diario`} className="botao-icon-voltar" title="Voltar">
                        <Icon type="arrow-left" />
                    </Link>
                </div>
                <div className="curso-diario">
                    {loading ? (
                        <div className="loading-data">
                            <div className="loading" />
                        </div>
                    ) : (
                            <ul className="lista-participantes-notas-pre">
                                {!participantes.length ? "Não existem inscrições" : ""}
                            {participantes.map((participante, index) => (
                                <li key={index} className="participante">
                                    <div className="info-participante">
                                        <div className="container-img">
                                            <img src={participante.foto ? participante.foto : noimage} />
                                        </div>
                                        <div className="info">
                                            <span className="nome">{participante.nome}</span>
                                            <p className="numero">{participante.numero}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <Link to={`/gerir-curso/${this.props.match.params.curso}/curso-diario-folha-conceito`}
                                            className="botao-icon-detalhe"
                                            title="Detalhe"
                                            onClick={() => localStorage.setItem("userInfo", JSON.stringify(participante))}
                                        >
                                            <Icon type="eye" />
                                        </Link>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        );
    }
}

export default ListaAlunos;
