import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Checkbox, Tabs, Collapse, Icon, Modal, notification, Radio, Input } from "antd";
import { GeralContext } from "../../../ContextProvider";
import axios from "axios";
import moment from 'moment';
import noimage from "../../../../images/noimage.png";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const { TabPane } = Tabs;
const { Panel } = Collapse;

class Estrutura extends Component {
    static contextType = GeralContext;
    state = {
        periodoId: "",
        avaliacaoId: "",
        eja: false,
        ano: "",
        tipo: "",
        provaFinal: false,
        segundaChamada: false,
        estrutura: [],
        avaliacoes: [],
        participantes: [],
        participantesNotas: [],
        formItemLayout: {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        },
        //DRAWER
        visible: false,
        //LOADING
        iconLoading: false,
        //LOADING
        loading: true,
    }

    componentDidMount() {
        this.listar();
    }

    handleChange = (event, avaliacaoId, utilizadorId) => {
        let notas = this.state.participantesNotas;
        let index = notas.findIndex(x => x.id_avaliacao === avaliacaoId && x.id_utilizador === utilizadorId)

        if (index >= 0) {
            notas[index].nota = event.target.value;
        }
        else {
            const nota = {
                id_avaliacao: avaliacaoId,
                id_utilizador: utilizadorId,
                nota: event.target.value
            };

            notas = [...notas, nota]
        }

        this.setState({
            participantesNotas: notas
        })
    };

    handleChangeProvaFinal = (event, index) => {
        const participantes = this.state.participantes;
        participantes[index].provaFinal = event.target.value;

        this.setState({
            participantes
        })
    };

    handleChangeSegundaChamada = (event, index) => {
        const participantes = this.state.participantes;
        participantes[index].segundaChamada = event.target.value;

        this.setState({
            participantes
        })
    };

    handleChangeNumber = (value, avaliacaoId, utilizadorId) => {
        let notas = this.state.participantesNotas;
        let index = notas.findIndex(x => x.id_avaliacao === avaliacaoId && x.id_utilizador === utilizadorId)

        if (index >= 0) {
            notas[index].nota = value;
        }
        else {
            const nota = {
                id_avaliacao: avaliacaoId,
                id_utilizador: utilizadorId,
                nota: value
            };

            notas = [...notas, nota]
        }

        this.setState({
            participantesNotas: notas
        })
    };

    handleChangeCheckbox = (event, avaliacaoId, utilizadorId) => {
        let notas = this.state.participantesNotas;
        let index = notas.findIndex(x => x.id_avaliacao === avaliacaoId && x.id_utilizador === utilizadorId)

        if (index >= 0) {
            notas[index].nota = event.target.checked;
        }
        else {
            const nota = {
                id_avaliacao: avaliacaoId,
                id_utilizador: utilizadorId,
                nota: event.target.value
            };

            notas = [...notas, nota]
        }

        this.setState({
            participantesNotas: notas
        })
    };

    listar = () => {
        axios({
            method: "get",
            url: "/api/diario/detalhe",
            params: {
                cursoId: this.props.cursoId,
                faseId: this.props.faseId
            }
        })
            .then(response => {
                this.setState({
                    eja: response.data.curso.eja,
                    ano: response.data.curso.ano,
                    tipo: response.data.fase.tipo,
                    provaFinal: response.data.prova_final,
                    segundaChamada: response.data.segunda_chamada,
                    estrutura: [...response.data.fase.periodos, ...response.data.fase.registos],
                    avaliacoes: response.data.fase.avaliacoes,
                    participantesNotas: response.data.notas,
                    loading: false
                }, () => this.listarParticipantes())
            })
            .catch(error => {
                if (error.response?.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                openNotificationWithIcon("error", "Erro", "Não foi possível carregar registos!");
            });
    }

    listarParticipantes = () => {
        axios({
            method: "get",
            url: "/api/aulas/participantes",
            timeout: 60 * 10 * 1000,
            params: {
                cursoid: this.props.cursoId
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data,
                    loading: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    carregarNota = (avaliacaoId, utilizadorId) => {
        var notas = this.state.participantesNotas;
        if (notas.length) {
            var registo = notas.find(x => x.id_avaliacao === avaliacaoId && x.id_utilizador === utilizadorId)
            if (registo)
                return registo.nota;
        }
    }

    guardar = (event, avaliacaoId) => {
        event.preventDefault();

        if (this.state.tipo === "Notas" && !this.state.formValida)
            return false;

        this.setState({ iconLoading: true })

        const participantesNotas = this.state.participantesNotas.filter(x => x.id_avaliacao === avaliacaoId);

        var item = new FormData()
        item.append("cursoId", this.props.cursoId)
        item.append("notas", JSON.stringify(participantesNotas))

        axios({
            method: "post",
            url: "/api/aulas/adicionar-fase-escolar-notas",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Notas guardadas!")
                this.setState({ iconLoading: false })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi guardar notas!")
                this.setState({ iconLoading: false })
            })
    }

    guardarProvas = () => {
        if (!this.state.formValida)
            return false;

        this.setState({ iconLoading: true })

        var item = new FormData()
        item.append("cursoId", this.props.cursoId)
        item.append("participantesProvas", JSON.stringify(this.state.participantes))

        axios({
            method: "post",
            url: "/api/aulas/adicionar-provas-finais",
            data: item,
            timeout: 60 * 30 * 1000
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Notas guardadas!")
                this.setState({ iconLoading: false })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi guardar notas!")
                this.setState({ iconLoading: false })
            })
    }

    montarPeriodos = (lista) => {
        lista = lista.filter(x => x.idPai === 0);
        return lista.map((item, index) => {
            return (
                <div key={index} className="curso-diario bloco-notas" style={{ boxShadow: "none", marginTop: 0 }}>
                    <div className="bloco-informacoes">
                        <div className="info-aula">
                            <span className="bloco-info-aula">
                                <span className="titulo-aula">{item.nome}</span>
                                <span className="datas-aula">{moment(item.inicio).format("DD/MM/YYYY")} - {moment(item.termino).format("DD/MM/YYYY")}</span>
                            </span>
                        </div>
                    </div>
                    <div className="listagem-avaliacoes">
                        {this.montarAvaliacoes(this.state.estrutura.filter(x => x.idPai === item.id), 0, true)}
                    </div>
                </div>
            )
        })
    }

    validateDecimal = (rule, value, callback) => {
        var regexp = /^\d+\.\d{0,2}$/;

        if (isNaN(value) || !isNaN(value.replace(",", ".")) && regexp.test(value.replace(",", ".")) && !value.includes(".")) {
            if (!isNaN(value.replace(",", ".")) && regexp.test(value.replace(",", ".")) && !value.includes("."))
                callback("Substitua a ',' por '.'");

            if (isNaN(value))
                callback("A nota deve ser um número.");

            this.setState({
                formValida: false
            })
        }
        else {
            callback();
            this.setState({
                formValida: true
            })
        }
    }

    montarAvaliacoes = (lista, idPai, periodo) => {
        const { getFieldDecorator } = this.props.form;
        if (lista.length) {
            let temp = lista.map((item, index) => {
                return (
                    <Panel key={index} header={<span className="collapse-panel-avaliacoes sub-avaliacao">
                        <span>{item.nome}</span>
                    </span>}
                    >
                        {this.montarAvaliacoes(this.state.estrutura.filter(x => x.idPai === item.id), item.id, false)}
                    </Panel>
                )
            })

            return <Collapse defaultActiveKey={[]} className="collapse-campos">{temp}</Collapse>
        }
        else {
            if (!periodo)
                return (<>
                    {!this.state.participantes.length ? "Sem alunos inscritos..."
                        :
                        <>
                            <Form {...this.formItemLayout} className="form-categorias">
                                <ul className="lista-participantes-notas">
                                    {this.state.participantes?.map((participante, indexParticipante) => (
                                        <li key={indexParticipante} className="participante">
                                            <div className="info-participante">
                                                <div className="container-img">
                                                    <img src={participante.foto ? participante.foto : noimage} />
                                                </div>
                                                <div className="info">
                                                    <span className="nome">{participante.nome}</span>
                                                    <p className="numero">{participante.numero}</p>
                                                </div>
                                            </div>
                                            {this.state.tipo === "Conceito" ?
                                                <div>
                                                    {this.state.avaliacoes.length ?
                                                        <Form.Item label="Nota">
                                                            {this.state.avaliacoes.length > 1 ?
                                                                <Radio.Group onChange={(event) => this.handleChange(event, idPai, participante.id)} value={this.carregarNota(idPai, participante.id)}>
                                                                    {this.state.avaliacoes.map((avaliacao, index) => (
                                                                        <Radio key={index} value={avaliacao.nome}>
                                                                            {avaliacao.nome}
                                                                        </Radio>
                                                                    ))}
                                                                </Radio.Group>
                                                                :
                                                                <Checkbox checked={this.carregarNota(idPai, participante.id)} onChange={(event) => this.handleChangeCheckbox(event, idPai, participante.id)} style={{ marginLeft: 10 }}>
                                                                    {this.state.avaliacoes[0].nome}
                                                                </Checkbox>
                                                            }
                                                        </Form.Item>
                                                        : null}
                                                </div>
                                                :
                                                <div>
                                                    <Form.Item label="Nota">
                                                        {getFieldDecorator(`Nota${idPai}${indexParticipante}`, {
                                                            rules: [
                                                                {
                                                                    validator: this.validateDecimal,
                                                                }
                                                            ],
                                                            initialValue: this.carregarNota(idPai, participante.id)
                                                        })(<Input name={`Nota${idPai}${indexParticipante}`} onChange={(event) => this.handleChange(event, idPai, participante.id)} />)}
                                                    </Form.Item>
                                                </div>
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </Form>
                            <Link className="botao-principal" to="#" onClick={(event) => this.guardar(event, idPai)}>
                                Guardar
                            </Link>
                        </>
                    }
                </>)
            else
                return "Sem registos...";
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            periodoId,
            avaliacaoId,
            estrutura,
            avaliacoes,
            participantes,
            //DRAWER
            visible,
            //LOADING
            iconLoading,
            //LOADING
            loading,
            //FORMULA
            ano,
            eja,
            formula
        } = this.state;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        return (
            <>
                <div className="container container-body">
                    {loading ? (
                        <div className="loading-data">
                            <div className="loading" />
                        </div>
                    ) : (
                            <>
                                {this.montarPeriodos(estrutura)}
                                {this.state.tipo === "Notas" && (
                                    <>
                                        <div className="curso-diario bloco-notas" style={{ boxShadow: "none", marginTop: 0 }}>
                                            <div className="bloco-informacoes">
                                                <div className="info-aula">
                                                    <span className="bloco-info-aula">
                                                        <span className="titulo-aula">Prova Final</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="listagem-avaliacoes">
                                                <Collapse defaultActiveKey={[]} className="collapse-campos">
                                                    <Panel header={<span className="collapse-panel-avaliacoes">
                                                        Resultados
                                                    </span>}
                                                    >
                                                        <Form {...formItemLayout} className="form-categorias">
                                                            <ul className="lista-participantes-notas">
                                                                {participantes.map((participante, index) => (
                                                                    <li key={index} className="participante">
                                                                        <div className="info-participante">
                                                                            <div className="container-img">
                                                                                <img src={participante.foto ? participante.foto : noimage} />
                                                                            </div>
                                                                            <div className="info">
                                                                                <span className="nome">{participante.nome}</span>
                                                                                <p className="numero">{participante.numero}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Form.Item label="Nota">
                                                                                {getFieldDecorator(`nota${index}`, {
                                                                                    rules: [
                                                                                        {
                                                                                            validator: this.validateDecimal,
                                                                                        }
                                                                                    ],
                                                                                    initialValue: participante.provaFinal
                                                                                })(<Input name={`nota${index}`} onChange={(event) => this.handleChangeProvaFinal(event, index)} />)}
                                                                            </Form.Item>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </Form>
                                                        <Link className="botao-principal" to="#" disabled={iconLoading} onClick={this.guardarProvas}>
                                                            {iconLoading ? <Icon type="loading" /> : null}
                                                            Guardar
                                                        </Link>
                                                    </Panel>
                                                </Collapse>
                                            </div>
                                        </div>
                                        {ano === "9º" && !eja || ano === "10º" && eja ?
                                            <div className="curso-diario bloco-notas" style={{ boxShadow: "none", marginTop: 0 }}>
                                                <div className="bloco-informacoes">
                                                    <div className="info-aula">
                                                        <span className="bloco-info-aula">
                                                            <span className="titulo-aula">Prova (2ª Chamada)</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="listagem-avaliacoes">
                                                    <Collapse defaultActiveKey={[]} className="collapse-campos">
                                                        <Panel header={<span className="collapse-panel-avaliacoes">
                                                            Resultados
                                                        </span>}
                                                        >
                                                            <Form {...formItemLayout} className="form-categorias">
                                                                <ul className="lista-participantes-notas">
                                                                    {participantes.map((participante, index) => (
                                                                        <li key={index} className="participante">
                                                                            <div className="info-participante">
                                                                                <div className="container-img">
                                                                                    <img src={participante.foto ? participante.foto : noimage} />
                                                                                </div>
                                                                                <div className="info">
                                                                                    <span className="nome">{participante.nome}</span>
                                                                                    <p className="numero">{participante.numero}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <Form.Item label="Nota">
                                                                                    {getFieldDecorator(`segundaChamada${index}`, {
                                                                                        rules: [
                                                                                            {
                                                                                                validator: this.validateDecimal,
                                                                                            }
                                                                                        ],
                                                                                        initialValue: participante.segundaChamada
                                                                                    })(<Input name={`segundaChamada${index}`} onChange={(event) => this.handleChangeSegundaChamada(event, index)} />)}
                                                                                </Form.Item>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </Form>
                                                            <Link className="botao-principal" to="#" disabled={iconLoading} onClick={this.guardarProvas}>
                                                                {iconLoading ? <Icon type="loading" /> : null}
                                                                Guardar
                                                            </Link>
                                                        </Panel>
                                                    </Collapse>
                                                </div>
                                            </div>
                                            : null}
                                    </>)}
                            </>
                    )}
                </div>
            </>
        );
    }
}

const FormEstrutura = Form.create({ name: "form-estrutura" })(Estrutura);

export default FormEstrutura;
