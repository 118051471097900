import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Collapse, Popover, Icon } from "antd";
import Header from "../../User/Header";
import { GlobalContext } from "../../GlobalState";
import Avatar2 from "../../images/Avatar2.png";
import axios from "axios";
import noimage from "../../images/noimage.png";

const { Panel } = Collapse;

export class Ranking extends Component {
    static contextType = GlobalContext;
    state = {
        ranking: [],
        curso: "",
        atividades: [],
        outras: [],
        forum: [],
        meets: [],
        atividadesAbertas: [],
        ultimaPosicao: "",
        ultimosPontos: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LOADING
        loading: false
    };

    UNSAFE_componentWillMount() {
        this.carregarRanking()
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    carregarRanking = () => {
        this.setState(
            {
                loading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/curso-ranking/carregar-geral",
                    params: {
                        cursoid: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        this.setState({
                            ranking: response.data.ranking,
                            curso: response.data.curso,
                            atividades: response.data.atividades,
                            outras: response.data.outras,
                            forum: response.data.forum,
                            meets: response.data.meets,
                            loading: false
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    render() {
        const {
            ranking,
            curso,
            atividades,
            outras,
            forum,
            meets,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const montarHeader = atividade => {
            return (<div className="lista-atividades sem-collapse">
                <div className="info-atividade">
                    <span className="titulo-atividade">{atividade.nome}</span>
                    {atividade.medalhas ?
                        <span className="icon-medalha">
                            <i className="fas fa-award icon-medalha-aula"></i>
                            <span>{atividade.medalhas}</span>
                        </span> : null}
                    <span className="pontuacao-atividade">
                        {atividade.pontos} pts (<strong>{atividade.pontos > 0 ? `${atividade.posicao}º` : "---"}</strong>)
                                                </span>
                </div>
            </div>)
        }

        const montarHeader2 = (nome, atividade) => {
            return (<div className="lista-atividades sem-collapse">
                <div className="info-atividade">
                    <span className="titulo-atividade">{nome}</span>
                    {atividade.medalhas ?
                        <span className="icon-medalha">
                            <i className="fas fa-award icon-medalha-aula"></i>
                            <span>{atividade.medalhas}</span>
                        </span> : null}
                    <span className="pontuacao-atividade">
                        {atividade.pontos} pts (<strong>{atividade.pontos > 0 ? `${atividade.posicao}º` : "---"}</strong>)
                                                </span>
                </div>
            </div>)
        }

        const montarPosicoes = (posicao, pontos) => {
            if (pontos !== 0)
                switch (posicao) {
                    case 1:
                        return <span className="posicao medalha-ouro"><i className="fas fa-medal"></i></span>
                        break;
                    case 2:
                        return <span className="posicao medalha-prata"><i className="fas fa-medal"></i></span>
                        break;
                    case 3:
                        return <span className="posicao medalha-bronze"><i className="fas fa-medal"></i></span>
                        break;
                    default:
                        return <span className="posicao">{posicao}º</span>
                        break;
                }
            else
                return <span className="posicao">---</span>
        }

        return (
            <>
                <div className="container container-body">
                    <div className="bloco-cursos">
                        <div className="curso-resultados bloco-tabela bloco-info-ranking">
                            <div className="bloco-flex" style={{ justifyContent: "flex-end"}}>
                                <Link
                                    to={`/curso/${localStorage.getItem("nome_curso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-resultados`}
                                    className="botao-principal"
                                >
                                    Voltar
                                </Link>
                            </div>
                            <div className="bloco" style={{ paddingTop: 0 }}>
                                {loading ?
                                    <div className="loading-data">
                                        <div className="loading" />
                                    </div>
                                    :
                                    <>
                                        <div className="bloco-ranking">
                                            <h3 className="titulo-separador" style={{ fontSize: 17 }}>
                                                Ranking
                                                <Popover
                                                    content={
                                                        <>
                                                            <span><strong style={{ marginRight: 5 }}>Conclusão do curso/disciplina:</strong> 25 </span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Medalha em Curso:</strong> 25</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Medalha em Tarefa:</strong> 10</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Avaliação:</strong> 10</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Trabalho:</strong> 5</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Questionário:</strong> 3</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Presença em Stepmeet:</strong> 3</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Presença:</strong> 3</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Publicação em fórum:</strong> 1</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Outras tarefas:</strong> 1</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Classificação:</strong> O seu valor em pontos</span><br />
                                                        </>
                                                    }
                                                    title="Legenda"
                                                    className="info-icon">
                                                    <Icon type="question-circle-o" />
                                                </Popover>
                                            </h3>
                                            <div className="lista-ranking">
                                                {ranking.map((rank, index) => (
                                                    <div key={index} className={`posicao-ranking ${(rank.nome ? "minha-posicao-ranking" : "")}`}>
                                                        {montarPosicoes(rank.posicao, rank.pontos)}
                                                        <div className="info-participante">
                                                            <div className="container-img">
                                                                <img src={rank.avatar ? rank.avatar : noimage} />
                                                            </div>
                                                            <div className="info info-desktop">
                                                                {rank.nome ?
                                                                    <span className="nome">
                                                                        {rank.nome}
                                                                    </span>
                                                                    :
                                                                    <span className="nome sem-nome">
                                                                        ***
                                                                            </span>
                                                                }
                                                                <span className="icon-medalha-curso">
                                                                    {rank.medalha &&
                                                                        <i className="fas fa-award"></i>}
                                                                </span>
                                                                <span className="pontos">
                                                                    {`${rank.pontos} pts`}
                                                                </span>
                                                            </div>
                                                            <div className="info info-mobile">
                                                                <div className="info-nome-medalha">
                                                                    {rank.nome ?
                                                                        <span className="nome">
                                                                            {rank.nome}
                                                                        </span>
                                                                        :
                                                                        <span className="nome sem-nome">
                                                                            ***
                                                                            </span>
                                                                    }
                                                                    <span className="icon-medalha-curso">
                                                                        {rank.medalha &&
                                                                            <i className="fas fa-award"></i>}
                                                                    </span>
                                                                </div>
                                                                <div className="info-pontos">
                                                                    <span className="pontos">
                                                                        {`${rank.pontos} pts`}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="bloco-atividades">
                                            <div className="bloco-atividade">
                                                <h3 className="titulo-separador">Curso <span>(Conclusão do curso/disciplina + Medalha em Curso + Classificação)</span></h3>
                                                <div className="lista-atividades sem-collapse">
                                                    <div className="info-atividade">
                                                        <span className="titulo-atividade">{curso.nome}</span>
                                                        {curso.medalha &&
                                                            <span className="icon-medalha">
                                                                <i className="fas fa-award icon-medalha-curso"></i>
                                                                <span>{curso.medalha}</span>
                                                            </span>}
                                                        <span className="pontuacao-atividade">
                                                            {curso.soma} = {curso.pontos} pts (<strong>{curso.pontos > 0 ? `${curso.posicao}º` : "---"}</strong>)
                                                </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bloco-atividade">
                                                <h3 className="titulo-separador">Outras Avaliações</h3>
                                                {!outras.length ?
                                                    <span>Sem registos</span>
                                                    : null}
                                                {outras.map((outra, index) => (
                                                    montarHeader(outra)
                                                ))}
                                            </div>
                                            <div className="bloco-atividade">
                                                <h3 className="titulo-separador">Atividades</h3>
                                                {atividades.map((atividade, index) => (
                                                    montarHeader(atividade)
                                                ))}
                                                {montarHeader2("Fórum", forum)}
                                                {montarHeader2("Stepmeets", meets)}
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Ranking;
