import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Form, Input, Tooltip, Icon, notification } from "antd";
import Navbar from "../User/Navbar";
import Header from "../User/Header";
import axios from "axios";
import { GlobalContext } from "../GlobalState";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class AlterarPassword extends Component {
    static contextType = GlobalContext;
    state = {
        old_password: "",
        new_password: "",
        iconLoading: false
    };

    componentDidMount() {
        this.registarOperacao();
        //if (!this.context.is_authenticated)
        //    this.setState({
        //        redirect: true,
        //        redirectLink: "/login"
        //    });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue("nova-password")) {
            callback("Senhas não coincidentes");
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(["confirm"], { force: true });
        }
        callback();
    };

    registarOperacao = () => {
        axios({
            method: "post",
            url: "api/minha-conta/registar-operacao",
            params: {
                pagina: "Página Alterar Senha"
            }
        })
            .then(() => {

            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    alterarPassword = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                axios({
                    method: "post",
                    url: "/api/alterar-password/alterar",
                    params: {
                        oldPassword: this.state.old_password,
                        newPassword: this.state.new_password
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Senha alterada!");
                        this.setState({ iconLoading: false });
                        this.props.form.resetFields();
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //log = (erro, pedido) => {
    //    axios({
    //        method: "post",
    //        url: "/api/logs/registar-erros",
    //        params: {
    //            erro,
    //            pedido
    //        }
    //    });
    //};

    //logOperacao = operacao => {
    //    axios({
    //        method: "post",
    //        url: "/api/logs/registar-operacoes",
    //        params: {
    //            operacao
    //        }
    //    });
    //};

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                {/* <Navbar /> */}
                <Header titulo="Alterar senha" />
                <div className="container container-body">
                    <div className="bloco-alterar-password">
                        <div className="bloco-info">
                            <Form className="alterar-password">
                                <Form.Item
                                    label={
                                        <span>
                                            Senha atual
                                            <Tooltip
                                                className="info-icon"
                                                title={
                                                    <>
                                                        Caso não se lembre da senha atual, termine a sessão e utilize o{" "}
                                                        <strong>Não me lembro da senha</strong> em iniciar a sessão.
                                                    </>
                                                }
                                            >
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </span>
                                    }
                                >
                                    {getFieldDecorator("password", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                                whitespace: true
                                            }
                                        ]
                                    })(<Input.Password type="password" name="old_password" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label={<span>Nova senha</span>}>
                                    {getFieldDecorator("nova-password", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                                whitespace: true
                                            },
                                            {
                                                pattern: "^(?=.*[A-Z])(?=.*[0-9])(?=.{6,})",
                                                message: (
                                                    <p>
                                                        A senha deve conter pelo menos uma uma letra maiúscula, um número e um mínimo de 6
                                                        caracteres
                                                    </p>
                                                )
                                            },
                                            {
                                                validator: this.validateToNextPassword
                                            }
                                        ]
                                    })(<Input.Password type="password" name="new_password" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label={<span>Confirmar senha</span>}>
                                    {getFieldDecorator("confirmar-password", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório",
                                                whitespace: true
                                            },
                                            {
                                                validator: this.compareToFirstPassword
                                            }
                                        ]
                                    })(<Input.Password type="password" />)}
                                </Form.Item>
                                <button
                                    className="botao-principal"
                                    disabled={iconLoading}
                                    onClick={e => {
                                        this.alterarPassword(e);
                                        // this.logOperacao("Guardar");
                                    }}
                                >
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Alterar
                                </button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const FormAlterarPassword = Form.create({ name: "form-minha-conta" })(AlterarPassword);

export default FormAlterarPassword;
