import React, { Component } from "react";
import { Button, Icon, notification, Modal, Table } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ExcelTabelaNotas extends Component {
    state = {
        num: 1,
        total: 0,
        semPeriodos: false,
        //PERIODO
        titulo: "",
        periodos: [],
        colunas: [],
        dados: [],
        avaliacoes: [],
        participantes: [],
        //FINAL
        participantesFinal: [],
        finalVisivel: false,
        //LOADING
        iconLoading: false
    }

    componentDidMount() {
        this.total();
    }

    total = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/aulas/total-periodos",
                params: {
                    faseId: localStorage.getItem("faseEscolar")
                }
            })
                .then(response => {
                    this.setState({
                        total: response.data
                    }, () => {
                        if (response.data > 0) {
                            this.listar();
                        }
                        else {
                            this.setState({
                                semPeriodos: true,
                                iconLoading: false
                            })
                            openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                        }
                    })
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                });
        })
    }

    montarColunasAvaliacoes = (avaliacoes, idPai) => {
        let lista = avaliacoes.filter(x => x.idPai === idPai);
        const estrutura = lista.map((item, index) => {
            const registosFilho = avaliacoes.filter(x => x.idPai === item.id);
            if (registosFilho.length)
                return {
                    key: item.id,
                    title: item.titulo,
                    dataIndex: item.titulo + index,
                    align: 'center',
                    render: text => text ? text : "-",
                    children: this.montarColunasAvaliacoes(avaliacoes, item.id)
                }
            else
                return {
                    key: item.id,
                    title: item.titulo,
                    dataIndex: item.titulo + index,
                    align: 'center',
                    render: text => text ? text : "-"
                }
        })

        return estrutura;
    }

    montarLinhasAvaliacoes = (avaliacoes, idPai, utilizadorId) => {
        var notas = {};
        let lista = avaliacoes.filter(x => x.idPai === idPai);
        lista.map((item, index) => {
            const registosFilho = avaliacoes.filter(x => x.idPai === item.id);
            if (registosFilho.length)
                notas = { ...notas, ...this.montarLinhasAvaliacoes(avaliacoes, item.id, utilizadorId)}
            else {
                const participante = item.participantes.find(x => x.id_utilizador === utilizadorId);
                notas[item.titulo + index] = participante?.nota
            }
        })

        return { ...notas };
    }

    montarListaNotasAvaliacoes = (avaliacoes, idPai, utilizadorId) => {
        var notas = [];
        let lista = avaliacoes.filter(x => x.idPai === idPai);
        lista.map((item, index) => {
            const registosFilho = avaliacoes.filter(x => x.idPai === item.id);
            if (registosFilho.length)
                notas = [...notas, ...this.montarListaNotasAvaliacoes(avaliacoes, item.id, utilizadorId)];
            else {
                const participante = item.participantes.find(x => x.id_utilizador === utilizadorId);
                notas.push({
                    nota: participante?.nota ? participante?.nota : 0,
                    recuperacao: item.recuperacao
                });
            }
        })

        return notas;
    }

    listar = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-notas",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                faseId: localStorage.getItem("faseEscolar"),
                periodoSelecionado: this.state.num
            }
        })
            .then(response => {
                const periodo = response.data.periodo;
                const participantes = response.data.participantes;
                let colunasAvaliacoes = this.montarColunasAvaliacoes(periodo.avaliacoes, periodo.id);
                let colunas = [
                    {
                        title: 'Ordem numérica',
                        dataIndex: 'ordem',
                        key: 'ordem',
                        align: 'center'
                    },
                    {
                        title: 'Nomes dos alunos',
                        dataIndex: 'nome',
                        key: 'nome',
                        align: 'center'
                    },
                    {
                        title: <>
                            <Button className='botao-periodo botao-periodo-anterior' disabled={this.state.num === 1} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={this.anterior}>
                                <Icon type="left" />
                            </Button>
                            <span>{periodo.titulo}</span>
                            <Button className='botao-periodo botao-periodo-proximo' disabled={this.state.finalVisivel} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={this.proximo}>
                                <Icon type="right" />
                            </Button>
                        </>,
                        children: colunasAvaliacoes
                    },
                    {
                        title: '',
                        children: [
                            {
                                title: 'Nota bimestral',
                                dataIndex: 'nota',
                                key: 'nota',
                                align: 'center',
                                render: (text, record) => <div className={record.nota >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div>,
                            },
                            {
                                title: '',
                                dataIndex: 'estado',
                                key: 'estado',
                                align: 'center',
                                className: 'td-no-padding',
                                render: (text, record) => <div className={record.nota >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div>,
                            },
                        ],
                    }
                ];

                let participantesFinal = this.state.participantesFinal;
                let dados = participantes.map((participante, index) => {
                    const avaliacoes = this.montarLinhasAvaliacoes(periodo.avaliacoes, periodo.id, participante.id)
                    const listaNotas = this.montarListaNotasAvaliacoes(periodo.avaliacoes, periodo.id, participante.id)
                    const notasNormais = listaNotas.filter(x => !x.recuperacao);
                    const notasRecuperacao = listaNotas.filter(x => x.recuperacao);
                    let totalNotaNormal = 0;
                    let totalNotaRecuperacao= 0;
                    notasNormais.forEach(item => {
                        totalNotaNormal += Number(item.nota);
                    })
                    notasRecuperacao.forEach(item => {
                        totalNotaRecuperacao += Number(item.nota);
                    })

                    const existeUtilizador = participantesFinal.find(x => x.id === participante.id);
                    if (!existeUtilizador) {
                        participantesFinal.push({
                            id: participante.id,
                            nome: participante.nome,
                            periodos: [{
                                id: periodo.id,
                                nome: periodo.titulo,
                                nota: Math.max(totalNotaNormal, totalNotaRecuperacao)
                            }],
                            provaFinal: participante.provaFinal,
                            segundaChamada: participante.segundaChamada,
                        })
                    }
                    else {
                        const utilizadorIndex = participantesFinal.findIndex(x => x.id === participante.id);
                        let periodos = existeUtilizador.periodos;
                        const periodoIndex = periodos.findIndex(x => x.id === periodo.id);

                        if (periodoIndex >= 0) {
                            periodos[periodoIndex] = {
                                id: periodo.id,
                                nome: periodo.titulo,
                                nota: Math.max(totalNotaNormal, totalNotaRecuperacao)
                            }
                        }
                        else {
                            periodos.push({
                                id: periodo.id,
                                nome: periodo.titulo,
                                nota: Math.max(totalNotaNormal, totalNotaRecuperacao)
                            })
                        }

                        participantesFinal[utilizadorIndex].periodos = periodos;
                    }

                    return {
                        key: participante.id,
                        ordem: (('0' + (index + 1)).slice(-2)),
                        nome: participante.nome,
                        ...avaliacoes,
                        nota: Math.max(totalNotaNormal, totalNotaRecuperacao),
                        estado: Math.max(totalNotaNormal, totalNotaRecuperacao) >= 6 ? "Aprovado" : "Reprovado"
                    }
                })

                let periodos = this.state.periodos;
                if (!periodos.find(x => x.id === periodo.id)) {
                    periodos.push(periodo)
                }

                this.setState({
                    titulo: periodo.titulo,
                    periodos,
                    avaliacoes: periodo.avaliacoes,
                    colunas,
                    dados,
                    participantesFinal,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    listarFinal = () => {
        const colunasPeriodos = this.state.periodos.map((periodo, index) => {
            return {
                title: periodo.titulo,
                dataIndex: periodo.id,
                key: periodo.id,
                align: 'center'
            }
        })

        let colunas = [];
        let dados = [];

        if (this.props.ano === "9º" && !this.props.eja || this.props.ano === "10º" && this.props.eja) {
            colunas = [
                {
                    title: 'Ordem numérica',
                    dataIndex: 'ordem',
                    key: 'ordem',
                    align: 'center'
                },
                {
                    title: 'Nomes dos alunos',
                    dataIndex: 'nome',
                    key: 'nome',
                    align: 'center'
                },
                {
                    title: <>
                        <Button className='botao-periodo botao-periodo-anterior' disabled={this.state.num === 1} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={this.anterior}>
                            <Icon type="left" />
                        </Button>
                        <span>Geral</span>
                        <Button className='botao-periodo botao-periodo-proximo' disabled={true} style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                            <Icon type="right" />
                        </Button>
                    </>,
                    children: colunasPeriodos
                },
                {
                    title: '',
                    children: [
                        {
                            title: 'Total Geral',
                            dataIndex: 'totalGeral',
                            key: 'totalGeral',
                            align: 'center'
                        },
                        {
                            title: 'Média Anual',
                            dataIndex: 'mediaAnual',
                            key: 'mediaAnual',
                            align: 'center',
                            render: text => <div className={text >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div>,
                        },
                        {
                            title: 'Prova Final',
                            dataIndex: 'provaFinal',
                            key: 'provaFinal',
                            align: 'center',
                            render: text => text ? <div className={text >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div> : "-",
                        },
                        {
                            title: 'Média Final',
                            dataIndex: 'mediaFinal',
                            key: 'mediaFinal',
                            align: 'center',
                            render: text => <div className={text >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div>,
                        },
                        {
                            title: '2ª Chamada',
                            dataIndex: 'segundaChamada',
                            key: 'segundaChamada',
                            align: 'center',
                            render: text => text ? <div className={text >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div> : "-",
                        },
                        {
                            title: 'Pontuação Final',
                            dataIndex: 'pontuacaoFinal',
                            key: 'pontuacaoFinal',
                            align: 'center',
                            render: text => text ? <div className={text >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div> : "-",
                        },
                        {
                            title: '',
                            dataIndex: 'estado',
                            key: 'estado',
                            align: 'center',
                            className: 'td-no-padding',
                            render: (text, record) => <div className={record.pontuacaoFinal >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div>,
                        },
                    ],
                }
            ];
            dados = this.state.participantesFinal.map((participante, index) => {
                let periodos = [];
                participante.periodos.map(periodo => {
                    periodos[periodo.id] = periodo?.nota;
                });

                var notas = participante.periodos.map(x => x.nota);
                const totalGeral = this.calcularNotaGeral(notas);
                const mediaAnual = this.calcularMediaAnual(notas);
                let mediaFinal = 0;
                let pontuacaoFinal = 0;

                if (this.props.formula === 1) {
                    mediaFinal = participante.provaFinal ? ((parseFloat(participante.provaFinal) + mediaAnual) / 2) : mediaAnual;
                }
                else {
                    mediaFinal = participante.provaFinal ? (((parseFloat(participante.provaFinal) * 3) + (mediaAnual * 4)) / 7) : mediaAnual;
                    pontuacaoFinal = participante.segundaChamada ? ((mediaFinal + parseFloat(participante.segundaChamada)) / 2) : mediaFinal;
                }

                return {
                    key: participante.id,
                    ordem: (('0' + (index + 1)).slice(-2)),
                    nome: participante.nome,
                    ...periodos,
                    totalGeral: totalGeral,
                    mediaAnual: parseFloat(mediaAnual.toFixed(2)),
                    provaFinal: participante.provaFinal,
                    mediaFinal: parseFloat(mediaFinal.toFixed(2)),
                    segundaChamada: participante.segundaChamada,
                    pontuacaoFinal: parseFloat(pontuacaoFinal.toFixed(2)),
                    estado: pontuacaoFinal >= 6 ? "Aprovado" : "Reprovado"
                }
            });
        }
        else {
            colunas = [
                {
                    title: 'Ordem numérica11',
                    dataIndex: 'ordem',
                    key: 'ordem',
                    align: 'center'
                },
                {
                    title: 'Nomes dos alunos',
                    dataIndex: 'nome',
                    key: 'nome',
                    align: 'center'
                },
                {
                    title: <>
                        <Button className='botao-periodo botao-periodo-anterior' disabled={this.state.num === 1} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={this.anterior}>
                            <Icon type="left" />
                        </Button>
                        <span>Geral</span>
                        <Button className='botao-periodo botao-periodo-proximo' disabled={true} style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                            <Icon type="right" />
                        </Button>
                    </>,
                    children: colunasPeriodos
                },
                {
                    title: '',
                    children: [
                        {
                            title: 'Total Geral',
                            dataIndex: 'totalGeral',
                            key: 'totalGeral',
                            align: 'center'
                        },
                        {
                            title: 'Média Anual',
                            dataIndex: 'mediaAnual',
                            key: 'mediaAnual',
                            align: 'center',
                            render: text => <div className={text >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div>,
                        },
                        {
                            title: 'Prova Final',
                            dataIndex: 'provaFinal',
                            key: 'provaFinal',
                            align: 'center',
                            render: text => text ? <div className={text >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div> : "-",
                        },
                        {
                            title: 'Média Final',
                            dataIndex: 'mediaFinal',
                            key: 'mediaFinal',
                            align: 'center',
                            render: text => <div className={text >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div>,
                        },
                        {
                            title: '',
                            dataIndex: 'estado',
                            key: 'estado',
                            align: 'center',
                            className: 'td-no-padding',
                            render: (text, record) => <div className={record.mediaFinal >= 6 ? "marca-aluno-aprovado" : "marca-aluno-reprovado"}>{text}</div>,
                        },
                    ],
                }
            ];

            dados = this.state.participantesFinal.map((participante, index) => {
                let periodos = [];
                participante.periodos.map(periodo => {
                    periodos[periodo.id] = periodo?.nota;
                });

                var notas = participante.periodos.map(x => x.nota);
                const totalGeral = this.calcularNotaGeral(notas);
                const mediaAnual = this.calcularMediaAnual(notas);
                let mediaFinal = 0;
                
                if (this.props.formula === 1) {
                    mediaFinal = participante.provaFinal ? ((parseFloat(participante.provaFinal) + mediaAnual) / 2) : mediaAnual;
                }
                else {
                    mediaFinal = participante.provaFinal ? (((parseFloat(participante.provaFinal) * 3) + (mediaAnual * 4)) / 7) : mediaAnual;
                }

                return {
                    key: participante.id,
                    ordem: (('0' + (index + 1)).slice(-2)),
                    nome: participante.nome,
                    ...periodos,
                    totalGeral: totalGeral,
                    mediaAnual: parseFloat(mediaAnual.toFixed(2)),
                    provaFinal: participante.provaFinal,
                    mediaFinal: parseFloat(mediaFinal.toFixed(2)),
                    estado: mediaFinal >= 6 ? "Aprovado" : "Reprovado"
                }
            });
        }

        this.setState({
            colunas,
            dados
        })
    }

    anterior = () => {
        if (!this.state.finalVisivel)
            this.setState({
                num: this.state.num - 1,
                iconLoading: true
            }, () => this.listar())
        else
            this.setState({
                finalVisivel: false
            }, () => this.listar())
    }

    proximo = () => {
        if (this.state.num < this.state.total)
            this.setState({
                num: this.state.num + 1,
                iconLoading: true
            }, () => this.listar())
        else {
            this.setState({
                finalVisivel: true
            }, () => this.listarFinal())
        }
    }

    calcularNotaGeral = (notas) => {
        let total = 0;
        notas.forEach(nota => {
            total += parseFloat(nota)
        })

        return parseFloat(total.toFixed(2));
    }

    calcularMediaAnual = (notas) => {
        let total = 0;
        notas.forEach(nota => {
            total += parseFloat(nota)
        })

        return parseFloat((total / notas.length).toFixed(2));
    }

    render() {
        const {
            //LOADING
            iconLoading
        } = this.state;

        return (
            <>
                <div>
                    <Table
                        className="tabela-notas-diario"
                        columns={this.state.colunas}
                        dataSource={this.state.dados}
                        bordered
                        pagination={false}
                    />
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default ExcelTabelaNotas;