import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Drawer,
    Icon,
    Form,
    Input,
    Select,
    Collapse,
    notification,
    Checkbox,
    Tooltip
} from "antd";
import axios from "axios";

const { Panel } = Collapse;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerAvalicao = ({ visibleDrawerAvaliacao, atualizarAdicionado, atualizarEditado, estado, registoPaiId, registo, onClose, tipo, recuperacaoBloqueada, form }) => {
    const [titulo, setTitulo] = useState("")
    const [ordem, setOrdem] = useState(1)
    const [recuperacao, setRecuperacao] = useState(false)
    const [iconLoading, setIconLoading] = useState(false)
    const { getFieldDecorator } = form;

    const handleChangeTitulo = (event) => {
        setTitulo(event.target.value);
    };

    const handleChangeOrdem = (event) => {
        setOrdem(event.target.value);
    };

    const handleChangeRecuperacao = (event) => {
        setRecuperacao(event.target.checked);
    };

    const detalhe = () => {
        if (registo) {
            setTitulo(registo.nome);
            setOrdem(registo.ordem);
            setRecuperacao(registo.recuperacao);
        }
    }

    const adicionar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                try {
                    atualizarAdicionado({
                        id: Date.now(),
                        idPai: registoPaiId,
                        nome: titulo,
                        ordem: ordem,
                        recuperacao: recuperacao,
                        ativo: true
                    });
                }
                catch {
                    openNotificationWithIcon("error", "Erro", "Não foi possível adicionar o período.")
                }
            }
        })
    }

    const guardar = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                atualizarEditado({
                    id: registo.id,
                    idPai: registo.idPai,
                    nome: titulo,
                    ordem: ordem,
                    recuperacao: recuperacao,
                    ativo: registo.ativo
                });
            }
        })
    }

    useEffect(() => {
        form.resetFields();
        if (estado === "EDITAR")
            detalhe();
        else {
            setTitulo("");
            setOrdem(1);
            setRecuperacao(false);
            setIconLoading(false);
        }
    }, [estado]);

    return (
        <Drawer
            className="drawer-add-cursos"
            title="Período"
            width={720}
            onClose={onClose}
            visible={visibleDrawerAvaliacao}
            style={{
                overflow: "auto",
                height: "calc(100% - 108px)",
                paddingBottom: "108px"
            }}
            maskClosable={false}
        >
            <div className="bloco-info">
                <Form className="form-categorias listagem-avaliacoes" layout="horizontal">
                    <div className="bloco">
                        <Form.Item label="Titulo">
                            {getFieldDecorator("titulo", {
                                initialValue: titulo,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="titulo" onChange={handleChangeTitulo} />)}
                        </Form.Item>
                        <Form.Item label="Ordem">
                            {getFieldDecorator("ordem", {
                                initialValue: ordem,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="ordem" onChange={handleChangeOrdem} />)}
                        </Form.Item>
                        <Form.Item label="Ordem">
                            {getFieldDecorator("ordem", {
                                initialValue: ordem,
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="ordem" onChange={handleChangeOrdem} />)}
                        </Form.Item>
                        {tipo === "Notas" &&
                            <Form.Item>
                                <Checkbox name="recuperacao" disabled={recuperacaoBloqueada && !recuperacao} checked={recuperacao} onChange={handleChangeRecuperacao}>
                                    Recuperação
                                    <Tooltip className="info-icon" title="Marcar como avaliação de recuperação">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>}
                    </div>
                </Form>
            </div>
            <div className="ant-drawer-footer">
                <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                    Voltar
                </button>
                {estado === "EDITAR" ?
                    <button className="botao-principal" onClick={guardar} disabled={iconLoading} type="primary">
                        {iconLoading ? <Icon type="loading" /> : null}
                        Guardar
                    </button>
                    :
                    <button className="botao-principal" onClick={adicionar} disabled={iconLoading} type="primary">
                        {iconLoading ? <Icon type="loading" /> : null}
                        Adicionar
                    </button>
                }
            </div>
        </Drawer>
    );
}

const FormDrawerAvalicao = Form.create({ name: "drawer-avaliacao" })(DrawerAvalicao);

export default FormDrawerAvalicao;