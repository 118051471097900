import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { notification } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import axios from "axios";
import moment from "moment";
import Vimeo from "@vimeo/player";
import { GlobalContext } from "../GlobalState";
import Oops from "../Geral/Oops.jsx";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Video extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        modulo: "",
        moduloIndex: 0,
        aulaid: 0,
        aula: "",
        nome: "",
        objetivos: "",
        aulaIndex: 0,
        fullscreen: false,
        vimeo: "",
        current: "",
        updateModulos: false,
        dataHora: "",
        loading: true,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        sameUrl: true,
        player: "",
        //NAO DISPONIVEL
        nao_disponivel: false,
        //VALIDAR 
        conclusao: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: localStorage.getItem("codigo_aula")
        }, () => {
            this.CarregarConteudo();
            this.registarEntrada();
            this.validarConclusao();
        });
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState(
                {
                    sameUrl: false,
                    aulaid: localStorage.getItem("codigo_aula")
                },
                () => {
                    this.CarregarConteudo();
                    this.registarEntrada();
                    this.validarConclusao();
                    this.setState({
                        sameUrl: true
                    })
                }
            );
        }
    }

    registarEntrada = () => {
        axios({
            method: "post",
            url: "/api/logs/registar-entrada",
            params: {
                aulaid: this.state.aulaid
            }
        })
            .then(response => {
                localStorage.setItem("codigo_entrada", response.data);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    validarConclusao = () => {
        axios({
            method: "get",
            url: "/api/player-trabalho/validar-conclusao",
            params: {
                aulaid: this.state.aulaid
            }
        })
            .then(response => {
                this.setState({
                    conclusao: response.data
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    marcarPosicao = seconds => {
        axios({
            method: "put",
            url: "/api/player/marcar-video",
            params: {
                aulaid: this.state.aulaid,
                periodo: seconds
            }
        }).catch(() => {
            openNotificationWithIcon("error", "Erro", "Ocorreu um erro!");
        });
    };

    //MARCA A AULA COMO CONCLUIDA PELO ALUNO
    onEnded = () => {
        axios({
            method: "put",
            url: "/api/player/concluir-video",
            params: {
                aulaid: this.state.aulaid
            }
        }).then(() => {
            this.setState({
                conclusao: true
            })
            this.context.atualizarListaModulos();
        }).catch(() => {
            openNotificationWithIcon("error", "Erro", "Não foi possível marcar o vídeo como concluído.");
        });
    };

    //AVANÇAR VIA TEMPO ANOTAÇÃO
    anotacaoSeek = current => {
        var iframe = document.querySelector("iframe");
        var player = new Vimeo(iframe);
        player.setCurrentTime(current);
    };

    //APANHA OS ATRIBUTOS PASSADOS PELO ENDEREÇO
    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    //CARREGA A AULA
    CarregarConteudo = () => {
        axios({
            method: "get",
            url: "/api/player/conteudo1",
            params: {
                aulaid: this.state.aulaid
            }
        })
            .then(response => {
                this.setState(
                    {
                        nome: response.data.nome,
                        objetivos: response.data.objetivos,
                        vimeo: "https://player.vimeo.com/video/" + response.data.recurso,
                        dataHora: moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"),
                        loading: false
                    },
                    () => {
                        var iframe = document.querySelector("iframe");
                        var player = new Vimeo(iframe);

                        this.setState({
                            player
                        });

                        player.on("seeked", data => {
                            this.setState({ current: data.seconds });
                        });

                        player.on("ended", this.onEnded);

                        player.ready().then(() => {
                            if (this.getParameterByName("start")) player.setCurrentTime(this.getParameterByName("start"));
                        });
                    }
                );
            })
            .catch(error => {
                if (error.response.data === "SEM_PERMISSAO")
                    this.setState({
                        redirect: true,
                        redirectLink: "/user"
                    });
                else if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    nao_disponivel: true
                });
            });
    };

    //MARCA A POSSIÇÃO DO VIDEO EM QUE O PLAYER VAI PARA SER FEITA A RETOMA DA AULA PELO ALUNO
    marcar = periodo => {
        axios({
            method: "put",
            url: "/api/player/marcar-video",
            params: {
                aulaid: this.state.aulaid,
                periodo: periodo
            }
        }).catch(() => {
            openNotificationWithIcon("error", "Erro", "Ocorreu um erro!");
        });
    };

    opcaoMarcar = () => {
        var player = this.state.player;
        player.getCurrentTime().then(seconds => {
            this.marcarPosicao(seconds);
        });
    };

    opcaoMarcarVoltar = () => {
        var player = this.state.player;
        player.getCurrentTime().then(seconds => {
            this.marcarPosicao(seconds);
            this.setState({
                redirect: true,
                redirectLink: `/curso/${this.state.curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`
            });
        });
    };

    render() {
        const {
            curso,
            nome,
            objetivos,
            vimeo,
            current,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //NAO DISPONIVEL
            nao_disponivel,
            //VALIDAR 
            conclusao
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return this.state.loading ? (
            <div className="loading-data pagina-inteira">
                <div className="loading" />
            </div>
        ) : (
                <div className="player">
                    <div className="player-wrapper">
                        {!nao_disponivel ? (
                            <>
                                {sameUrl && <iframe src={vimeo} id="player"></iframe>}
                                <Controlos
                                    backUrl={`/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                    sameUrl={sameUrl}
                                    temRating
                                    temAnotacoes
                                    temMarcador
                                    temMensagens
                                    nome={nome}
                                    objetivos={objetivos}
                                    icon={require("../images/icons/video.png")}
                                    current={current}
                                    anotacaoSeek={this.anotacaoSeek}
                                    player
                                    opcaoMarcar={this.opcaoMarcar}
                                    opcaoMarcarVoltar={this.opcaoMarcarVoltar}
                                    dataHora={dataHora}
                                    conclusao={conclusao}
                                />
                            </>
                        ) : (
                                <>
                                    <div className="container">
                                        <div className="bloco-nao-disponivel">
                                            <div className="bloco-info nao-disponivel">
                                                <Oops tem_texto texto={"Conteúdo não disponível"} />
                                            </div>
                                        </div>
                                    </div>
                                    <ControlosSimples />
                                </>
                            )}
                    </div>
                </div>
            );
    }
}

export default Video;
