import React from "react";

const Video = props => {
    return (
        <div className="bloco-video">
            {/*<video className="video" autoPlay={props.autoPlay} muted={props.muted} loop={props.loop}>
                <source src={require("../images/video_example.mp4")} type="video/mp4" />O browser não suporta o video!
            </video>*/}
            <iframe className="video" src={`https://player.vimeo.com/video/${props.capavideo}`} id="player" frameBorder="0"></iframe>
        </div>
    );
};

export default Video;
