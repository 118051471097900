import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Input, InputNumber, Checkbox, Select, notification, DatePicker, TimePicker, Tooltip, Icon, Radio, Row, Col, Alert } from "antd";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../../../GlobalState"

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class FormVideoConf extends Component {
    static contextType = GlobalContext;
    state = {
        btnIniciar: false,
        assunto: "",
        dia: null,
        hora: null,
        duracao: moment("01:00", "HH:mm"),
        moderadorVideoSom: false,
        gravarSessao: false,
        habilitarChat: false,
        audioVideoAtivo: false,
        recorrente: false,
        recorrenciaIntervalo: 1,
        recorrencia: "SEMANA",
        recorrenciaFim: null,
        verificacao: 2,
        codigo: Math.floor(100000 + Math.random() * 900000),
        totalContratado: "",
        totalParticipantes: "",
        //EXTERNA
        meetExterna: false,
        meetExternaConvite: "",
        //LOADING
        iconLoading: false
    };

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handlerChangeNumber = value => {
        this.setState({
            totalParticipantes: value
        });
    };

    handlerChangeRecorrenciaIntervalo = value => {
        this.setState({
            recorrenciaIntervalo: value
        });
    };

    handlerChangeCheckbox = event => {
            this.setState({
                [event.target.name]: event.target.checked
            });
    };

    handlerChangeRadioGeral = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handlerChangeRadio = event => {
        this.setState({
            verificacao: event.target.value,
            codigo: event.target.value === 2 ? Math.floor(100000 + Math.random() * 900000) : ""
        });
    };

    criar = iniciar => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (iniciar)
                    this.props.prepararMeet(true);

                this.setState({
                    iconLoading: true
                });

                var item = new FormData()
                item.append("assunto", this.state.assunto);
                item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
                item.append("hora", this.state.hora.format("HH:mm"));
                item.append("duracao", this.state.duracao.format("HH:mm"));
                item.append("moderadorVideoSom", this.state.moderadorVideoSom);
                item.append("gravarSessao", this.state.gravarSessao);
                item.append("habilitarChat", this.state.habilitarChat);
                item.append("audioVideoAtivo", this.state.audioVideoAtivo);
                item.append("Verificacao", this.state.verificacao);
                item.append("Codigo", this.state.codigo);
                item.append("iniciar", iniciar);
                if (this.state.meetExterna) {
                    item.append("meetExterna", this.state.meetExterna);
                    item.append("meetExternaConvite", this.state.meetExternaConvite);
                }
                if (this.state.recorrente) {
                    item.append("recorrente", this.state.recorrente);
                    item.append("recorrenciaIntervalo", this.state.recorrenciaIntervalo);
                    item.append("recorrencia", this.state.recorrencia);
                    item.append("recorrenciaFim", moment(this.state.recorrenciaFim).format("YYYY/MM/DD"));
                }
                item.append("totalParticipantes", this.state.totalParticipantes);

                axios({
                    method: "post",
                    url: !this.state.recorrente ? "/api/gestao-videoconferencia/criar-nova" : "/api/gestao-videoconferencia/criar-nova-recorrencia",
                    data: item
                })
                    .then(response => {
                        if (iniciar) {
                            if (this.state.meetExterna)
                                this.props.IniciarMeet(this.state.meetExternaLink, this.state.meetExterna, response.data);
                            else
                                this.props.IniciarMeet(`/gestao-videoconferencia/${response.data}/player-videoconferencia`, false, response.data);
                        }
                        else
                            openNotificationWithIcon("success", "Sucesso", "Criada nova Stepmeet");

                        this.setState({
                            iconLoading: false
                        });
                        this.context.atualizarEventosHoje();
                        this.props.atualizarListagem();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.props.prepararMeet(false);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        })
    };

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({
                    iconLoading: true
                });

                var item = new FormData()
                item.append("id", this.props.videoConfId);
                item.append("assunto", this.state.assunto);
                item.append("meetingDate", moment(this.state.dia).format("YYYY/MM/DD"));
                item.append("hora", this.state.hora.format("HH:mm"));
                item.append("duracao", this.state.duracao.format("HH:mm"));
                item.append("moderadorVideoSom", this.state.moderadorVideoSom);
                item.append("gravarSessao", this.state.gravarSessao);
                item.append("habilitarChat", this.state.habilitarChat);
                item.append("audioVideoAtivo", this.state.audioVideoAtivo);
                item.append("Verificacao", this.state.verificacao);
                item.append("Codigo", this.state.codigo);
                if (this.state.meetExterna) {
                    item.append("meetExternaConvite", this.state.meetExternaConvite);
                }
                item.append("totalParticipantes", this.state.totalParticipantes);

                axios({
                    method: "post",
                    url: "/api/gestao-videoconferencia/alterar",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Stepmeet alterada");
                        this.setState({
                            iconLoading: false
                        });
                        this.context.atualizarEventosHoje();
                        this.props.atualizarListagem();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    carregarDetalhe = id => {
        axios({
            method: "get",
            url: "/api/gestao-videoconferencia/detalhe",
            params: {
                id
            }
        })
            .then(response => {
                this.setState({
                    assunto: response.data.assunto,
                    dia: moment(response.data.meetingDate),
                    hora: response.data.hora ? moment(response.data.hora, "HH:mm") : null,
                    duracao: moment(response.data.duracao, "HH:mm"),
                    moderadorVideoSom: response.data.moderadorVideoSom,
                    gravarSessao: response.data.gravarSessao,
                    habilitarChat: response.data.habilitarChat,
                    audioVideoAtivo: response.data.audioVideoAtivo,
                    verificacao: response.data.verificacao,
                    codigo: response.data.codigo,
                    totalParticipantes: response.data.totalParticipantes,
                    //EXTERNA
                    meetExterna: response.data.meetExterna,
                    meetExternaConvite: response.data.meetExternaConvite,
                }, () => this.carregarTotalContratado());
            })
            .catch(() => { });
    }

    carregarTotalContratado = () => {
        axios({
            method: "get",
            url: "/api/gerir-videoconferencia/total-contratado",
            timeout: 60 * 10 * 1000
        })
            .then(response => {
                this.setState({
                    totalContratado: response.data
                });
            })
            .catch(() => {

            });
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.videoConfId) {
                this.carregarDetalhe(this.props.videoConfId);
            }
            else {
                const inicio = moment();
                const minutos = 15 - (inicio.minute() % 15);

                const hora = moment(inicio).add(minutos, "minutes");

                this.carregarTotalContratado();
                this.setState({
                    assunto: "",
                    dia: moment(),
                    hora: hora,
                    duracao: moment("01:00", "HH:mm"),
                    moderadorVideoSom: false,
                    gravarSessao: false,
                    habilitarChat: true,
                    audioVideoAtivo: false,
                    recorrente: false,
                    recorrenciaIntervalo: 1,
                    recorrencia: "SEMANA",
                    recorrenciaFim: null,
                    verificacao: 2,
                    codigo: Math.floor(100000 + Math.random() * 900000),
                    totalContratado: "",
                    totalParticipantes: "",
                    //EXTERNA
                    meetExterna: false,
                    meetExternaConvite: "",
                }, () => this.validarVerBotao())
            }
        }
    };

    validarVerBotao = () => {
        var dataHora = `${moment(this.state.dia).format("MM/DD/YYYY")} ${moment(this.state.hora).format("HH:mm")}`
        const agora = moment();
        const inicio = moment(dataHora);

        const diff = inicio.diff(agora);

        const diffDuration = moment.duration(diff);

        this.setState({
            btnIniciar: Math.round(diffDuration.asHours()) <= 0 && Math.round(diffDuration.asMinutes()) <= 15
        });
    }

    render() {
        const { TextArea } = Input;
        const { getFieldDecorator } = this.props.form;
        const {
            btnIniciar,
            assunto,
            dia,
            hora,
            duracao,
            moderadorVideoSom,
            gravarSessao,
            habilitarChat,
            audioVideoAtivo,
            totalContratado,
            totalParticipantes,
            recorrente,
            recorrenciaIntervalo,
            recorrencia,
            recorrenciaFim,
            verificacao,
            codigo,
            //EXTERNA
            meetExterna,
            meetExternaConvite,
            //LOADING
            iconLoading
        } = this.state;

        const { videoConfId } = this.props;

        return (
            <Drawer
                className="drawer-add-cursos drawer-videoconferencias"
                title={videoConfId ? "Alterar Stepmeet" : "Adicionar nova Stepmeet"}
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Assunto">
                                {getFieldDecorator("assunto", {
                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                    initialValue: assunto
                                })(<Input name="assunto" onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label="Dia">
                                {getFieldDecorator("dia", {
                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                    initialValue: dia
                                })(<DatePicker
                                    className="input-25"
                                    placeholder="Selecionar"
                                    format="DD-MM-YYYY"
                                    name="dia"
                                    onChange={dia => this.setState({ dia }, () => this.validarVerBotao())}
                                />)}
                            </Form.Item>
                            <Form.Item label="Hora">
                                {getFieldDecorator("hora", {
                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                    initialValue: hora
                                })(<TimePicker
                                    className="input-25"
                                    name="hora"
                                    defaultOpenValue={moment("00:00", "HH:mm")}
                                    format="HH:mm"
                                    onChange={hora => this.setState({ hora }, () => this.validarVerBotao())}
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                    minuteStep={15}
                                />)}
                            </Form.Item>
                            <Form.Item label="Duração">
                                {getFieldDecorator("duracao", {
                                    rules: [{ required: true, message: "Campo obrigatório" }],
                                    initialValue: duracao
                                })(<TimePicker
                                    className="input-25"
                                    name="duracao"
                                    defaultOpenValue={moment("01:00", "HH:mm")}
                                    format="HH:mm"
                                    onChange={duracao => this.setState({ duracao })}
                                    placeholder="Selecionar"
                                    suffixIcon={false}
                                    clearIcon={false}
                                />)}
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador">Configurações</h3>
                            <Form.Item className="form-item-checkbox">
                                <Checkbox checked={meetExterna} name="meetExterna" disabled={videoConfId || moderadorVideoSom || gravarSessao || habilitarChat || audioVideoAtivo} onChange={this.handlerChangeCheckbox}>
                                    Videoconferência noutra plataforma
                                    </Checkbox>
                            </Form.Item>
                            {meetExterna &&
                                <>
                                    <Form.Item label="Convite" className="form-item-checkbox">
                                        {getFieldDecorator("meetExternaConvite", {
                                            rules: [{ required: true, message: "Campo obrigatório" }],
                                            initialValue: meetExternaConvite
                                        })(<TextArea
                                            name="meetExternaConvite"
                                            onChange={this.handlerChange}
                                            rows={3}
                                            style={{ height: 100 }}
                                        />)}
                                    </Form.Item>
                                </>}
                            <Form.Item className="form-item-checkbox">
                                {getFieldDecorator("moderadorVideoSom")(
                                    <Checkbox name="moderadorVideoSom" checked={moderadorVideoSom} disabled={meetExterna} onChange={this.handlerChangeCheckbox}>Apenas moderador com áudio e vídeo</Checkbox>
                                )}
                            </Form.Item>
                            {this.context.conf_total_recorders > 0 &&
                                <Form.Item className="form-item-checkbox">
                                    {getFieldDecorator("gravarSessao")(
                                        <Checkbox name="gravarSessao" checked={gravarSessao} disabled={meetExterna} onChange={this.handlerChangeCheckbox}>Gravar sessão</Checkbox>
                                )}
                                </Form.Item>}
                            <Form.Item className="form-item-checkbox">
                                {getFieldDecorator("habilitarChat")(
                                    <Checkbox name="habilitarChat" checked={habilitarChat} disabled={meetExterna} onChange={this.handlerChangeCheckbox}>Habilitar Chat</Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item className="form-item-checkbox">
                                {getFieldDecorator("audioVideoAtivo")(
                                    <Checkbox name="audioVideoAtivo" checked={audioVideoAtivo} disabled={meetExterna} onChange={this.handlerChangeCheckbox}>Iniciar com audio e video para todos</Checkbox>
                                )}
                            </Form.Item>
                        </div>
                        {!videoConfId &&
                            <div className="bloco">
                                <h3 className="titulo-separador">recorrência</h3>
                                <Form.Item className="form-item-checkbox">
                                    <Radio.Group name="recorrente" value={recorrente} onChange={this.handlerChangeRadioGeral}>
                                        <Radio value={false}>Evento único</Radio>
                                        <Radio value={true}>Recorrente</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {recorrente &&
                                    <>
                                        <Form.Item>
                                            <Row gutter={8}>
                                                <Col span={3}>
                                                    <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>A cada</label>
                                                </Col>
                                                <Col span={3}>
                                                    <InputNumber value={recorrenciaIntervalo} onChange={this.handlerChangeRecorrenciaIntervalo} />
                                                </Col>
                                                <Col span={6}>
                                                    <Select
                                                        className="input-100"
                                                        placeholder="Selecionar"
                                                        defaultValue={recorrencia}
                                                        onChange={this.handlerChangeSelect}
                                                    >
                                                        <Option value="SEMANA">Semana</Option>
                                                        <Option value="MES">Mês</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Form.Item>
                                            <Row gutter={8}>
                                                <Col span={4}>
                                                    <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>Repetir até</label>
                                                </Col>
                                                <Col span={8}>
                                                    {getFieldDecorator("recorrenciaFim", {
                                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                                        initialValue: recorrenciaFim
                                                    })(<DatePicker
                                                        placeholder="Selecionar"
                                                        format="DD-MM-YYYY"
                                                        name="dia"
                                                        onChange={recorrenciaFim => this.setState({ recorrenciaFim })}
                                                    />)}
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </>}
                            </div>}
                        <div className="bloco">
                            <h3 className="titulo-separador">Entrada na sessão pelos participantes a convidar</h3>
                            <Form.Item className="form-item-checkbox">
                                <Radio.Group onChange={this.handlerChangeRadio} value={verificacao} disabled={meetExterna}>
                                    <Radio value={1}>Por verificação do e-mail e introdução do código recebido</Radio>
                                    <Radio value={2}>Por introdução do código enviado com o convite</Radio>
                                </Radio.Group>
                                {verificacao === 2 &&
                                    <span style={{ display: "block" }}><strong>Código: </strong>{codigo}</span>}
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            <Form.Item>
                                Nº máx. de participantes em simulâneo: {totalContratado}
                                <Tooltip className="info-icon" title="Nº máximo de participantes contratualizado envolvido numa ou em mais Stepmeets  simultâneas">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </Form.Item>
                            <Form.Item>
                                <div className="bloco-numero-participantes">
                                    <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>Assinale o nº esperado de participantes</label>
                                    {getFieldDecorator("Assinale o nº esperado de participantes", {
                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                        initialValue: totalParticipantes
                                    })(<InputNumber name="totalParticipantes" min={1} onChange={totalParticipantes => this.setState({ totalParticipantes })} />)}
                                </div>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <Link
                        to="#"
                        className="botao-secundario"
                        onClick={this.props.onClose}
                        style={{ marginRight: 20, display: "inline-block" }}
                    >
                        Voltar
                                </Link>
                    {btnIniciar && !meetExterna && !videoConfId ?
                        <>
                            <button className="botao-principal botao-principal-border" disabled={iconLoading} onClick={() => this.criar(false)} style={{ marginRight: 20, display: "inline-block" }}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                            </button>
                            <button className="botao-principal" disabled={iconLoading} onClick={() => this.criar(true)}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                    Iniciar
                                </button>
                        </>
                        :
                        videoConfId ? (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.alterar}>
                                {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                            </button>
                        )
                            :
                            (
                                <button className="botao-principal" disabled={iconLoading} onClick={() => this.criar(false)}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                                </button>
                            )}
                </div>
            </Drawer>
        );
    }
}
const FormFormVideoConf = Form.create({ name: "form-video-conf" })(FormVideoConf);

export default FormFormVideoConf;
