import React from "react";
import { Dropdown } from "antd";

const BotaoDefinicoes = props => {
    return (
        <div className="botao-definicoes">
            <Dropdown
                className="botao-icon-configs"
                overlay={props.menu}
                placement="topCenter"
                onClick={event => {
                    event.stopPropagation();
                }}>
                <i className={props.pendente ? "fas fa-cog pendente" : "fas fa-cog"} />
            </Dropdown>
        </div>
    );
};

export default BotaoDefinicoes;
