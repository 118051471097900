import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { notification } from "antd";
import ConteudoTrabalho from "./ConteudoTrabalho";
import Controlos from "../_Aux/Controlos";
import ControlosSimples from "../_Aux/ControlosSimples";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import Oops from "../../Geral/Oops.jsx";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Trabalho extends Component {
    static contextType = GlobalContext;
    state = {
        nome: "",
        objetivos: "",
        icon: "",
        curso: "",
        cursoid: 0,
        modulo: "",
        moduloIndex: 0,
        aulaid: 0,
        aula: "",
        aulaIndex: 0,
        fullscreen: false,
        dataHora: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        sameUrl: true,
        //NAO DISPONIVEL
        nao_disponivel: false,
        //VALIDAR
        conclusao: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: aulaId
        });

        this.registarEntrada(aulaId);
        this.validarConclusao(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.registarEntrada(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    registarEntrada = aulaid => {
        axios({
            method: "post",
            url: "/api/logs/registar-entrada",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                localStorage.setItem("codigo_entrada", response.data)
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            })
    };

    validarConclusao = aulaid => {
        axios({
            method: "get",
            url: "/api/player-trabalho/validar-conclusao",
            params: {
                aulaid
            }
        })
            .then(response => {
                this.setState({
                    conclusao: response.data
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    marcarComoNaoDisponivel = () => {
        this.setState({
            nao_disponivel: true
        })
    }

    render() {
        const {
            nome,
            objetivos,
            icon,
            curso,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //NAO DISPONIVEL
            nao_disponivel,
            //VALIDAR
            conclusao
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div className="trabalho">
                <div className="player-wrapper">
                    <LazyLoad offsetVertical={200}>
                        <img src={require("../../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                    </LazyLoad>
                    {!nao_disponivel ?
                        <>
                            {sameUrl && <ConteudoTrabalho marcarComoNaoDisponivel={this.marcarComoNaoDisponivel} desbloquearRating={() => !this.stateconclusao ? this.setState({ conclusao: true }) : null} carregarInfo={(nome, objetivos, icon, dataHora) => this.setState({ nome, objetivos, icon, dataHora })}/>}
                            <Controlos
                                backUrl={`/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                sameUrl={sameUrl}
                                temRating
                                temAnotacoes
                                temMarcador
                                temMensagens
                                nome={nome}
                                objetivos={objetivos}
                                icon={icon}
                                conclusao={conclusao}
                                dataHora={dataHora}
                            />
                        </>
                        :
                        <>
                            <div className="container">
                                <div className="bloco-nao-disponivel">
                                    <div className="bloco-info nao-disponivel">
                                        <Oops tem_texto texto={"Conteúdo não disponível"} />
                                    </div>
                                </div>
                            </div>
                            <ControlosSimples />
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default Trabalho;
