import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Navbar from "./Navbar";
import Header from "./Header";
import Cursos from "../Cursos/Cursos";
import WhatsAppButton from "../Geral/WhatsAppButton";
import axios from "axios";
import { GlobalContext } from "../GlobalState";
import BlocoNovidades from "../Geral/BlocoNovidades";

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        loadingNovidades: true,
        loadingContinuar: true,
        loadingInstrutor: true,
        loadingCursos: true,
        novidades: [],
        continuar: [],
        cursos: [],
        instrutor: [],
        chave: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL INFORMACOES
        visivelModalInformacoes: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        /*localStorage.removeItem("opcao_voltar");*/
        //this.listarContinuar();
        //this.listarNovidades();
        this.entidadeChave();
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    entidadeChave = () => {
        axios({
            method: "get",
            url: "/api/home-utilizador/entidade-chave"
        })
            .then(response => {
                if (response.data) {
                    this.listarCursos();
                }
                else {
                    this.listarContinuar();
                    this.listarNovidades();
                    this.listarCursosInstrutor();
                }

                this.setState({
                    chave: response.data
                })
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    //LISTA CURSO A RETOMAR
    listarContinuar = () => {
        axios({
            method: "get",
            url: "/api/home-utilizador/utilizador-continuar-a-ver"
        })
            .then(response => {
                this.setState({
                    continuar: response.data,
                    loadingContinuar: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarNovidades = () => {
        axios({
            method: "get",
            url: "/api/home-utilizador/utilizador-novidades"
        })
            .then(response => {
                this.setState({
                    novidades: response.data,
                    loadingNovidades: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarCursosInstrutor = () => {
        axios({
            method: "get",
            url: "/api/home-utilizador/listar-cursos-instrutor"
        })
            .then(response => {
                this.setState({
                    instrutor: response.data,
                    loadingInstrutor: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    listarCursos = () => {
        axios({
            method: "get",
            url: "/api/home-utilizador/utilizador-cursos"
        })
            .then(response => {
                this.setState({
                    cursos: response.data,
                    loadingCursos: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    confirmar = () => {
        axios({
            method: "post",
            url: "/api/utilizadores-informacoes/confirmar"
        })
            .then(response => {
                this.setState({
                    visivelModalInformacoes: false
                })

                this.context.atualizarState({
                    informacoes: false
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    render() {
        const {
            loadingNovidades,
            loadingContinuar,
            loadingInstrutor,
            loadingCursos,
            novidades,
            continuar,
            instrutor,
            cursos,
            chave,
            redirect,
            redirectLink,
            //MODAL INFORMACOES
            visivelModalInformacoes
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header
                    titulo={
                        (this.context.sexo_utilizador === "FEMININO" ? "Bem-vinda " : "Bem-vindo ") +
                        (this.context.nome_utilizador.split(" ").length > 1
                            ? this.context.nome_utilizador.split(" ")[0] +
                              " " +
                              this.context.nome_utilizador.split(" ")[this.context.nome_utilizador.split(" ").length - 1]
                            : this.context.nome_utilizador)
                    }
                    negativeTop
                />
                {!chave ?
                    <div className="container container-body lista-cursos">
                        {loadingNovidades || loadingContinuar ? (
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                        ) : (
                                <>
                                    {novidades.length > 0 ? (
                                        <div className="user-bloco-cursos" key="novidades">
                                            <div className="info-titulos">
                                                <h2>Novidades</h2>
                                            </div>
                                            <Cursos loading={loadingNovidades} cursos={novidades} keytext="novidades" link="/user" />
                                        </div>
                                    ) : null}
                                    {continuar.length > 0 ? (
                                        <div className="user-bloco-cursos" key="continuar">
                                            <div className="info-titulos">
                                                <h2>Assistir</h2>
                                            </div>
                                            <Cursos loading={loadingContinuar} cursos={continuar} keytext="continuar" link="/user" />
                                        </div>
                                    ) : null}
                                    {instrutor.length > 0 ? (
                                        <div className="user-bloco-cursos" key="instrutor">
                                            <div className="info-titulos">
                                                <h2>Cursos como professor</h2>
                                            </div>
                                            <Cursos loading={loadingInstrutor} cursos={instrutor} keytext="instrutor" link="/user" />
                                        </div>
                                    ) : null}
                                </>
                            )}
                    </div>
                    :
                    <div className="container container-body">
                        {loadingCursos ? (
                            <div className="loading-data">
                                <div className="loading" />
                            </div>
                        ) : (
                                <>
                                    {cursos.length > 0 ? (
                                        <div className="user-bloco-cursos">
                                            <Cursos loading={loadingCursos} cursos={cursos} keytext="novidades" link="/user" />
                                        </div>
                                    ) : null}
                                </>
                            )}
                    </div>
                }
                {/*{this.context.whatsapp_entidade ?
                    <WhatsAppButton
                        whatsapp={this.context.whatsapp_entidade}
                        mensagem={`Tenho a seguinte questão a colocar &agrave; equipa da ${this.context.nome_entidade}`}
                    />
                    : null}*/}
                {this.context.informacoes ?
                    <BlocoNovidades
                        visivelModalInformacoes={visivelModalInformacoes}
                        confirmar={this.confirmar}
                        //cancelar={() => this.setState({visivelModalInformacoes: false})}
                    />
                    : null}
            </>
        );
    }
}

export default Main;
