import React, { Component } from "react";
import { Button, Icon, notification, Modal } from "antd";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ExcelTabelaConteudos extends Component {
    state = {
        num: 1,
        total: 0,
        semPeriodos: false,
        periodos: [],
        //PESQUISA
        dt_inicio: "",
        dt_fim: "",
        //PERIODO
        mes: "",
        ano: "",
        titulo: "",
        aulas: [],
        //LOADING
        iconLoading: false,
    }

    componentDidMount() {
        this.total();
    }

    total = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/aulas/total-periodos-conteudos",
                params: {
                    cursoId: localStorage.getItem("codigo_curso")
                }
            })
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            total: response.data.length,
                            periodos: response.data,
                            titulo: response.data[0].titulo,
                            dt_inicio: response.data[0].dt_inicio,
                            dt_fim: response.data[0].dt_fim,
                        }, () => {
                            this.listar();
                        })
                    }
                    else {
                        this.setState({
                            semPeriodos: true,
                            iconLoading: false
                        })
                        openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                });
        })
    }

    listar = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-conteudos",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                dt_inicio: this.state.dt_inicio,
                dt_fim: this.state.dt_fim
            }
        })
            .then(response => {
                this.setState({
                    aulas: response.data,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    anteriorProximo = (num) => {
        var periodo = this.state.periodos[num - 1];
        this.setState({
            num,
            titulo: periodo.titulo,
            dt_inicio: periodo.dt_inicio,
            dt_fim: periodo.dt_fim,
            iconLoading: true
        }, () => this.listar())
    }

    render() {
        const {
            num,
            semPeriodos,
            periodos,
            //PERIODO
            titulo,
            aulas,
            //LOADING
            iconLoading,
        } = this.state;

        return (
            <>
                <div>
                    <div className="bloco-controlos-frequencia">
                        {!semPeriodos ?
                            <div>
                                <Button className='botao-periodo botao-periodo-anterior' disabled={num === 1} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.anteriorProximo(num - 1)}>
                                    <Icon type="left" />
                                </Button>
                                <span>{titulo}</span>
                                <Button className='botao-periodo botao-periodo-proximo' disabled={num === periodos.length} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.anteriorProximo(num + 1)}>
                                    <Icon type="right" />
                                </Button>
                            </div>
                            : null}
                    </div>
                    <table cellSpacing={0} border={0} style={{ width: "100%" }}>
                        <tbody><tr>
                            <td style={{ border: '1px solid #000000' }} height={27} align="center" valign="middle"><b><font face="Cambria" color="#000000"></font></b></td>
                            <td style={{ border: '1px solid #000000' }} align="center" valign="middle"><b><font face="Cambria" color="#000000">OBJETO DE CONHECIMENTO/CONTEÚDO</font></b></td>
                            <td style={{ border: '1px solid #000000' }} align="center" valign="middle"><b><font face="Cambria" color="#000000">DESDP/METODOLOGIA</font></b></td>
                        </tr>
                            {aulas.map((aula, index) => (
                                <tr>
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} height={38} align="center" valign="middle" bgcolor={index % 2 === 0 ? "#D9D9D9" : "#FFFFFF"} sdval={44228} sdnum="1033;1033;M/D/YYYY">{aula.curso ? <label face="Cambria" color="#000000" style={{ padding: 5, display: "block", fontWeight: "bold" }}>{aula.curso}</label> : null}<label face="Cambria" color="#000000" style={{ padding: 5 }}>{moment(aula.dt_inicio).format("DD/MM/YYYY")}</label></td>
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="left" valign="middle" bgcolor={index % 2 === 0 ? "#D9D9D9" : "#FFFFFF"}><p face="Cambria" color="#000000" style={{ padding: 5 }}>{aula.conteudo}</p></td>
                                    <td style={{ borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000' }} align="left" valign="middle" bgcolor={index % 2 === 0 ? "#D9D9D9" : "#FFFFFF"}><p face="Cambria" color="#000000" style={{ padding: 5 }}>{aula.metodologia}</p></td>
                                </tr>
                            ))}
                        </tbody></table>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default ExcelTabelaConteudos;