import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { notification, Form, Icon } from "antd";
import Controlos from "./_Aux/Controlos";
import ControlosSimples from "./_Aux/ControlosSimples";
import axios from "axios";
import moment from "moment";
import { GlobalContext } from "../GlobalState";
import LazyLoad from "react-lazy-load";
import Oops from "../Geral/Oops.jsx";

class SessaoPresencial extends Component {
    static contextType = GlobalContext;
    state = {
        curso: "",
        modulo: "",
        moduloIndex: 0,
        aulaid: 0,
        aula: "",
        aulaIndex: 0,
        fullscreen: false,
        nome: "",
        objetivos: "",
        dt_inicio: null,
        hora: null,
        local: "",
        presente: false,
        current: "",
        updateModulos: false,
        dataHora: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        sameUrl: true,
        //NAO DISPONIVEL
        nao_disponivel: false,
        //LOADING
        loading: true
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        this.context.montarNavbar(false);
        var aulaId = localStorage.getItem("codigo_aula");

        this.setState({
            curso: this.props.match.params.curso,
            aulaid: aulaId
        });

        this.CarregarConteudo(aulaId);
        this.registarEntrada(aulaId);
    }

    UNSAFE_componentWillReceiveProps(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            var aulaId = localStorage.getItem("codigo_aula");

            this.CarregarConteudo(aulaId);
            this.registarEntrada(aulaId);
            this.setState(
                {
                    sameUrl: false
                },
                () =>
                    this.setState({
                        sameUrl: true
                    })
            );
        }
    }

    registarEntrada = aulaid => {
        axios({
            method: "post",
            url: "/api/logs/registar-entrada",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                localStorage.setItem("codigo_entrada", response.data);
            })
            .catch(error => {
                if (error.response.data === "SEM_PERMISSAO")
                    this.setState({
                        redirect: true,
                        redirectLink: "/user"
                    });
                else if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    //APANHA OS ATRIBUTOS PASSADOS PELO ENDEREÇO
    getParameterByName = name => {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    //CARREGA A AULA
    CarregarConteudo = aulaid => {
        axios({
            method: "get",
            url: "/api/player/conteudo4",
            params: {
                aulaid: aulaid
            }
        })
            .then(response => {
                this.setState({
                    nome: response.data.nome,
                    objetivos: response.data.objetivos,
                    dt_inicio: response.data.dt_inicio ? moment(response.data.dt_inicio).format("DD/MM/YYYY") : "",
                    hora: response.data.hora,
                    local: response.data.recurso,
                    presente: response.data.presente,
                    dataHora: moment(response.data.dataHora).format("DD/MM/YYYY HH:mm"),
                    loading: false
                });
                this.concluirAula(aulaid);
            })
            .catch(error => {
                if (error.response.data === "SEM_PERMISSAO")
                    this.setState({
                        redirect: true,
                        redirectLink: "/user"
                    });
                else if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });

                this.setState({
                    nao_disponivel: true
                });
            });
    };

    concluirAula = aulaid => {
        axios({
            method: "put",
            url: "/api/player/concluir",
            params: {
                aulaid: aulaid
            }
        }).then(() => {
            this.context.atualizarListaModulos();
        });
    };

    render() {
        const {
            curso,
            nome,
            objetivos,
            dt_inicio,
            hora,
            local,
            presente,
            dataHora,
            //REDIRECT
            redirect,
            redirectLink,
            sameUrl,
            //NAO DISPONIVEL
            nao_disponivel,
            //LOADING
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return loading ? (
            <div className="loading-data pagina-inteira">
                <div className="loading" />
            </div>
        ) : (
                <div className="questionario">
                    <div className="player-wrapper">
                        <LazyLoad offsetVertical={200}>
                            <img src={require("../images/wave_hero_user.svg")} style={{ position: "absolute", zIndex: "-1" }} />
                        </LazyLoad>
                        {!nao_disponivel ? (
                            <>
                                <div className="container">
                                    <div className="bloco-presencial">
                                        <div className="bloco-info sessao-presencial bloco-player-template">
                                            <div className="bloco-info-tarefa">
                                                <span className="icon-tarefa">
                                                    <img src={require("../images/icons/sessao-presencial.png")} />
                                                </span>
                                                <span className="titulo-tarefa">{nome}</span>
                                            </div>
                                            <p className="descricao-tarefa" dangerouslySetInnerHTML={{ __html: objetivos?.replace(/(?:\r\n|\r|\n)/g, "<br>") }}></p>
                                            <Form className="form-aula" layout="horizontal" style={{ margin: 0 }}>
                                                <div className="info">
                                                    <Form.Item>
                                                        <span className="desc">
                                                            <Icon type="calendar" /> {dt_inicio}
                                                        </span>
                                                    </Form.Item>
                                                    {hora && (
                                                        <Form.Item>
                                                            <span className="desc">
                                                                <Icon type="clock-circle" /> {hora}
                                                            </span>
                                                        </Form.Item>
                                                    )}
                                                    <Form.Item>
                                                        <span className="desc">
                                                            <Icon type="home" /> <span dangerouslySetInnerHTML={{ __html: local.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></span>
                                                        </span>
                                                    </Form.Item>
                                                    {presente !== "SEM_REGISTO" ?
                                                        <Form.Item>
                                                            {presente === "PRESENCA" ?
                                                                <span className="desc">
                                                                    <span className="participacao">
                                                                        <i className="fas fa-check presente" title="Presença"></i> Presença
                                                                        </span>
                                                                </span>
                                                                :
                                                                <span className="desc">
                                                                    <span className="participacao">
                                                                        <i className="fas fa-times faltou" title="Falta"></i> Falta
                                                                        </span>
                                                                </span>
                                                            }
                                                        </Form.Item>
                                                        : null}
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <Controlos
                                    backUrl={`/curso/${curso.replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-modulos`}
                                    sameUrl={sameUrl}
                                    temRating
                                    temAnotacoes
                                    temMarcador
                                    temMensagens
                                    nome={nome}
                                    objetivos={objetivos}
                                    icon={require("../images/icons/sessao-presencial.png")}
                                    dataHora={dataHora}
                                    conclusao
                                />
                            </>
                        ) : (
                                <>
                                    <div className="container">
                                        <div className="bloco-nao-disponivel">
                                            <div className="bloco-info nao-disponivel">
                                                <Oops tem_texto texto={"Conteúdo não disponível"} />
                                            </div>
                                        </div>
                                    </div>
                                    <ControlosSimples />
                                </>
                            )}
                    </div>
                </div>
            );
    }
}

export default SessaoPresencial;
