import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Sidebar from "./Menu";
import { Form, Input, notification, Modal, Select, Row, Col, Tooltip, Icon, Dropdown, Menu, Carousel, Drawer } from "antd";
import TabelaProximas from "./TabelaProximas";
import TabelaProximasMobile from "./TabelaProximasMobile";
import TabelaAnteriores from "./TabelaAnteriores";
import TabelaAnterioresMobile from "./TabelaAnterioresMobile";
import noimage from "../../images/noimage.png";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";
import chrome from "../../images/browsers/chrome.png";
import edgeChromium from "../../images/browsers/edge-chromium.png";
import firefox from "../../images/browsers/firefox.png";
import safari from "../../images/browsers/safari.png";
import encerrarStepmeet from "../../images/browsers/encerrar-stepmeet4.png";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import CarouselAluno from "../../Geral/CarouselAluno";

const accessToken = "c25f3d22c7bcd79d2044ad6eb33ea514"

const headerPost = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessToken}`,
    "Content-Type": "application/json"
}

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Main extends Component {
    static contextType = GlobalContext;
    state = {
        cursoid: 0,
        pesquisa: "",
        videoconferencias: [],
        videoconferenciasMobile: [],
        total_recebidos: 0,
        proximas_ativo_on: "ativo",
        total_enviados: 0,
        anteriores_ativo_on: "",
        nr_proximas_videoconferencias: 0,
        nr_anteriores_videoconferencias: 0,
        //TABELA
        pagination: {
            pageSize: 10,
            current: 1,
            total: 0
        },
        loading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //DRAWER
        visible: false,
        alterarVideoconferencia: null,
        //BROWSERS INFO
        visibleBrowsers: false,
        meetID: 0,
        //MODAL LISTA PARTICIPANTES
        visibleParticipantes: false,
        carregarLista: false,
        participantes: [],
        //MODAL MEET EXTERNA
        meetExternaConvite: "",
        visibleMeetExterna: false,
        //DRAWER PROXIMAS
        visibleProximas: false,
        proximasAgendada: "",
        proximasParticipantes: "",
        //DRAWER ANTERIORES
        visibleAnteriores: false,
        anterioresRealizada: "",
        anterioresParticipantes: "",
        //VALIDAR INFORMACOES
        naoMostrarMensagem: false,
        visivelMensagem1: false,
        //CURSO
        fechado: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        this.carregarEstadoCurso(localStorage.getItem("codigo_curso"));
        if (localStorage.getItem("menu_mensagens"))
            if (localStorage.getItem("menu_mensagens") === "proximas") {
                this.setState(
                    {
                        cursoid: localStorage.getItem("codigo_curso"),
                        proximas_ativo_on: "ativo",
                        anteriores_ativo_on: ""
                    },
                    () => {
                        this.totalProximas();
                        this.listarProximas();
                    }
                );
            } else {
                this.setState(
                    {
                        cursoid: localStorage.getItem("codigo_curso"),
                        proximas_ativo_on: "",
                        anteriores_ativo_on: "ativo"
                    },
                    () => {
                        this.totalAnteriores();
                        this.listarAnteriores();
                    }
                );
            }
        else {
            this.setState(
                {
                    cursoid: localStorage.getItem("codigo_curso")
                },
                () => {
                    this.totalProximas();
                    this.listarProximas();
                }
            );
        }
    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    handleChangeSelect = value => {
        const pagination = { ...this.state.pagination };
        pagination.current = 1;
        pagination.pageSize = value;

        this.setState(
            {
                pagination
            },
            () => {
                if (this.state.anteriores_ativo_on === "ativo") {
                    this.totalAnteriores();
                    this.listarAnteriores();
                } else {
                    this.totalProximas();
                    this.listarProximas();
                }
            }
        );
    };

    handleTableChange = (pagination, filters, sorter) => {
        const _pagination = { ...this.state.pagination };
        _pagination.current = pagination.current;

        this.setState(
            {
                pagination: _pagination
            },
            () => {
                if (this.state.anteriores_ativo_on === "ativo") {
                    this.totalAnteriores();
                    this.listarAnteriores();
                } else {
                    this.totalProximas();
                    this.listarProximas();
                }
            }
        );
    };

    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value,
                    pagination: {
                        pageSize: 10,
                        current: 1,
                        total: 0
                    }
                },
                () => {
                        this.totalAnteriores();
                        this.listarAnteriores();
                }
            );
        }
    };

    //LIMPA A CAIXA DE PESQUISA E LISTA NOVAMENTE O TOTAL DE TICKETS
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                }
            },
            () => {
                if (this.state.anteriores_ativo_on === "ativo") {
                    this.totalAnteriores(false);
                    this.listarAnteriores(this.state.current);
                } else {
                    this.totalProximas();
                    this.listarProximas(this.state.current);
                }
            }
        );
    };

    filtro_proximas = () => {
        localStorage.setItem("menu_mensagens", "proximas");

        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                pesquisa: "",
                anteriores_ativo_on: "",
                proximas_ativo_on: "ativo",
                loading: true
            },
            () => {
                this.totalProximas();
                this.listarProximas();
            }
        );
    };

    filtro_anteriores = () => {
        localStorage.setItem("menu_mensagens", "anteriores");

        this.setState(
            {
                pagination: {
                    pageSize: 10,
                    current: 1,
                    total: 0
                },
                pesquisa: "",
                anteriores_ativo_on: "ativo",
                proximas_ativo_on: "",
                loading: true
            },
            () => {
                this.totalAnteriores();
                this.listarAnteriores();
            }
        );
    };

    totalProximas = () => {
        axios({
            method: "get",
            url: "/api/videoconferencia/total-proximas",
            params: {
                cursoId: this.state.cursoid,
                pesquisa: this.state.pesquisa
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(() => { });
    };

    carregarEstadoCurso = (cursoId) => {
        axios({
            method: "get",
            url: "/api/curso/carregar-estado-curso",
            params: {
                cursoId
            }
        })
            .then(response => {
                this.setState({
                    fechado: response.data
                })
            })
            .catch(() => { });
    }

    listarProximas = () => {
        this.setState({ loading: true });
        axios({
            method: "get",
            url: "/api/videoconferencia/listar-proximas",
            params: {
                cursoId: this.state.cursoid,
                pesquisa: this.state.pesquisa,
                pageSize: this.state.pagination.pageSize,
                page: this.state.pagination.current
            }
        })
            .then(response => {
                var videoconferencias = [];
                var videoconferenciasMobile = [];
                var visivelMensagem1 = false;

                response.data.map((videoConf, index) => {
                    visivelMensagem1 = videoConf.mensagem1;

                    videoconferencias.push({
                        key: index,
                        id: videoConf.id,
                        inicio: (
                            <div className="info-data">
                                <span className="data">{moment(videoConf.meetingDate).format("ddd, DD MMM")}</span>
                                <p className="hora">{videoConf.hora}</p>
                            </div>
                        ),
                        assunto: videoConf.assunto,
                        agendada: (<div className="info-participante">
                            <div className="bloco-img">
                                <img src={videoConf.criador.foto ? videoConf.criador.foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">
                                    {videoConf.criador.nome_completo.length > 1 ? `${videoConf.criador.nome_completo.split(" ")[0]} ${videoConf.criador.nome_completo.split(" ")[videoConf.criador.nome_completo.split(" ").length - 1]}` : videoConf.criador.nome_completo}
                                </span>
                                <p className="numero">{videoConf.criador.numero}</p>
                            </div>
                        </div>),
                        participantes: (!videoConf.todos || videoConf.listaParticipantes > 0) && this.montarTipoPartilha(videoConf),
                        controlos: (
                            <div className="controlos">
                                {videoConf.sessaoVideo ?
                                    <Dropdown
                                        overlay={this.montarMenuVideosRealizadas(videoConf.sessaoVimeoID)}
                                        placement="bottomLeft"
                                    >
                                        <button className="botao-icon-camera" tilte="Vídeos">
                                            <Icon type="video-camera" />
                                        </button>
                                    </Dropdown> : null}
                                {videoConf.ficheiro ?
                                    <Dropdown
                                        overlay={this.montarMenuAnexos(videoConf.ficheiro)}
                                        placement="bottomLeft"
                                    >
                                        <button className="botao-icon-clip" title="Anexos">
                                            <Icon type="paper-clip" />
                                        </button>
                                    </Dropdown> : null}
                                {videoConf.meetExterna ?
                                    <button className="botao-icon-convite" onClick={() => this.iniciarExterna(videoConf.meetExternaConvite)} title="Ver convite">
                                        <Icon type="play-circle" />
                                    </button>
                                    :
                                    videoConf.userConnected ?
                                        <button className="botao-icon-iniciar" onClick={() => this.iniciar(videoConf.id, videoConf.gravarSessao, videoConf.mensagem1, videoConf.participanteNaMeet)} title="Iniciar">
                                            <Icon type="play-circle" />
                                        </button>
                                        :
                                        !videoConf.participanteNaMeet ?
                                            <Link to={`/curso/${this.props.match.params.curso
                                                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                                .toLowerCase()}/vconf/${videoConf.id}/player-videoconferencia-aguardar`} className="botao-icon-iniciar" title="Iniciar" style={{ marginLeft: 10 }}>
                                                <Icon type="play-circle" />
                                            </Link>
                                            :
                                            <Link to="#" className="botao-icon-iniciar" title="Iniciar" onClick={() => openNotificationWithIcon("warning", "Atenção", "Já está a participar numa Stepmeet. Para participar nesta, deve reentrar na Stepmeet onde já está a participar e clicar no botão SAIR para poder encerrar a sua participação nessa mesma sessão.")} style={{ marginLeft: 10 }}>
                                                <Icon type="play-circle" />
                                            </Link>
                                }
                        </div>
                        )
                    });

                    const agendada = (<div className="info-participante">
                        <div className="container-img">
                            <img src={videoConf.criador.foto ? videoConf.criador.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">
                                {videoConf.criador.nome_completo.length > 1 ? `${videoConf.criador.nome_completo.split(" ")[0]} ${videoConf.criador.nome_completo.split(" ")[videoConf.criador.nome_completo.split(" ").length - 1]}` : videoConf.criador.nome_completo}
                            </span>
                            <p className="numero">{videoConf.criador.numero}</p>
                        </div>
                    </div>);

                    const participantes = (!videoConf.todos || videoConf.listaParticipantes > 0) ? this.montarTipoPartilha(videoConf) : "Todos";

                    videoconferenciasMobile.push({
                        key: index,
                        id: videoConf.id,
                        assunto: (
                            <div className="info-videoconferencias">
                                <span className="assunto">{videoConf.assunto}</span>
                                <p className="data-hora">
                                    {moment(videoConf.meetingDate).format("ddd, DD MMM")} {videoConf.hora}
                                    <Dropdown
                                        overlay={this.montarMenuProximas(agendada, participantes)}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="btn-opcoes">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                </p>
                            </div>
                        ),
                        controlos: (
                            <div className="controlos controlos-vertical">
                                <div>
                                    {videoConf.meetExterna ?
                                        <button className="botao-icon-convite" onClick={() => this.iniciarExterna(videoConf.meetExternaConvite)} title="Ver convite">
                                            <Icon type="play-circle" />
                                        </button>
                                        :
                                        videoConf.userConnected ?
                                            <button className="botao-icon-iniciar" onClick={() => this.iniciar(videoConf.id, videoConf.gravarSessao, videoConf.mensagem1, videoConf.participanteNaMeet)} title="Iniciar">
                                                <Icon type="play-circle" />
                                            </button>
                                            :
                                            !videoConf.participanteNaMeet ?
                                                <Link to={`/curso/${this.props.match.params.curso
                                                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                                    .toLowerCase()}/vconf/${videoConf.id}/player-videoconferencia-aguardar`} className="botao-icon-iniciar" title="Iniciar">
                                                    <Icon type="play-circle" />
                                                </Link>
                                                :
                                                <Link to="#" className="botao-icon-iniciar" title="Iniciar" onClick={() => openNotificationWithIcon("warning", "Atenção", "Já está a participar numa Stepmeet. Para participar nesta, deve reentrar na Stepmeet onde já está a participar e clicar no botão SAIR para poder encerrar a sua participação nessa mesma sessão.")}>
                                                    <Icon type="play-circle" />
                                                </Link>
                                    }
                                    {videoConf.permissaoDescarregar && videoConf.sessaoVideo ?
                                        <Dropdown
                                            overlay={this.montarMenuVideosRealizadas(videoConf.sessaoVimeoID)}
                                            placement="bottomLeft"
                                        >
                                            <button className="botao-icon-camera" tilte="Vídeos">
                                                <Icon type="video-camera" />
                                            </button>
                                        </Dropdown> : null}
                                    {videoConf.ficheiro ?
                                        <Dropdown
                                            overlay={this.montarMenuAnexos(videoConf.ficheiro)}
                                            placement="bottomLeft"
                                        >
                                            <button className="botao-icon-clip" title="Anexos">
                                                <Icon type="paper-clip" />
                                            </button>
                                        </Dropdown> : null}
                                </div>
                            </div>
                        )
                    })
                });

                this.setState({
                    loading: false,
                    videoconferencias,
                    videoconferenciasMobile,
                    visivelMensagem1
                });
            })
            .catch(() => { });
    };

    montarMenuProximas = (agendada, participantes) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleProximas: true, proximasAgendada: agendada, proximasParticipantes: participantes })}>
                        Detalhe
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    totalAnteriores = () => {
        axios({
            method: "get",
            url: "/api/videoconferencia/total-anteriores",
            params: {
                cursoId: this.state.cursoid,
                pesquisa: this.state.pesquisa
            }
        })
            .then(response => {
                var _pagination = this.state.pagination;
                _pagination.total = response.data;

                this.setState({
                    pagination: _pagination
                });
            })
            .catch(() => { });
    };

    listarAnteriores = () => {
        this.setState({ loading: true });
        axios({
            method: "get",
            url: "/api/videoconferencia/listar-anteriores",
            params: {
                cursoId: this.state.cursoid,
                pesquisa: this.state.pesquisa,
                pageSize: this.state.pagination.pageSize,
                page: this.state.pagination.current
            }
        })
            .then(response => {
                var videoconferencias = [];
                var videoconferenciasMobile = [];

                response.data.map((videoConf, index) => {
                    videoconferencias.push({
                        key: index,
                        id: videoConf.id,
                        assunto: videoConf.assunto,
                        inicio: (
                            <div className="info-data">
                                <span className="data">{moment(videoConf.meetingDate).format("ddd, DD MMM")}</span>
                                <p className="hora">{videoConf.hora} <span className="participacao">{videoConf.participacao ? <i className="fas fa-check presente" title="Participei"></i> : <i className="fas fa-times faltou" title="Não participei"></i>}</span></p>
                            </div>
                        ),
                        realizada: videoConf.iniciada ? (<div className="info-participante">
                            <div className="bloco-img">
                                <img src={videoConf.iniciada.foto ? videoConf.iniciada.foto : noimage} />
                            </div>
                            <div className="info">
                                <span className="nome">
                                    {videoConf.iniciada.nome_completo.length > 1 ? `${videoConf.iniciada.nome_completo.split(" ")[0]} ${videoConf.iniciada.nome_completo.split(" ")[videoConf.iniciada.nome_completo.split(" ").length - 1]}` : videoConf.iniciada.nome_completo}
                                </span>
                                <p className="numero">{videoConf.iniciada.numero}</p>
                            </div>
                        </div>) : "---",
                        participantes: (!videoConf.todos || videoConf.listaParticipantes > 0) && this.montarTipoPartilha(videoConf),
                        controlos: <div className="controlos">
                            {videoConf.permissaoDescarregar && videoConf.sessaoVideo ?
                                <Dropdown
                                    overlay={this.montarMenuVideosRealizadas(videoConf.sessaoVimeoID)}
                                    placement="bottomLeft"
                                >
                                    <button className="botao-icon-camera" tilte="Vídeos">
                                        <Icon type="video-camera" />
                                    </button>
                                </Dropdown> : null}
                            {videoConf.ficheiro ?
                                <Dropdown
                                    overlay={this.montarMenuAnexos(videoConf.ficheiro)}
                                    placement="bottomLeft"
                                >
                                    <button className="botao-icon-clip" title="Anexos">
                                        <Icon type="paper-clip" />
                                    </button>
                                </Dropdown> : null}
                        </div>
                    });

                    const realizada = videoConf.iniciada ? (<div className="info-participante">
                        <div className="bloco-img">
                            <img src={videoConf.iniciada.foto ? videoConf.iniciada.foto : noimage} />
                        </div>
                        <div className="info">
                            <span className="nome">
                                {videoConf.iniciada.nome_completo.length > 1 ? `${videoConf.iniciada.nome_completo.split(" ")[0]} ${videoConf.iniciada.nome_completo.split(" ")[videoConf.iniciada.nome_completo.split(" ").length - 1]}` : videoConf.iniciada.nome_completo}
                            </span>
                            <p className="numero">{videoConf.iniciada.numero}</p>
                        </div>
                    </div>) : "---";

                    const participantes = (!videoConf.todos || videoConf.listaParticipantes > 0) ? this.montarTipoPartilha(videoConf) : "Todos";

                    videoconferenciasMobile.push({
                        key: index,
                        id: videoConf.id,
                        assunto: (
                            <div className="info-videoconferencias">
                                <span className="assunto">{videoConf.assunto}</span>
                                <p className="data-hora">
                                    {moment(videoConf.meetingDate).format("ddd, DD MMM")} {videoConf.hora}
                                    <Dropdown
                                        overlay={this.montarMenuRealizadasMobile(realizada, participantes)}
                                        placement="bottomLeft"
                                    >
                                        <Link to="#" className="btn-opcoes">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                </p>
                            </div>
                        ),
                        controlos: <div className="controlos controlos-vertical">
                            <div>
                                {videoConf.permissaoDescarregar && videoConf.sessaoVideo ?
                                    <Dropdown
                                        overlay={this.montarMenuVideosRealizadas(videoConf.sessaoVimeoID)}
                                        placement="bottomLeft"
                                    >
                                        <button className="botao-icon-camera" tilte="Vídeos">
                                            <Icon type="video-camera" />
                                        </button>
                                    </Dropdown> : null}
                                {videoConf.ficheiro ?
                                    <Dropdown
                                        overlay={this.montarMenuAnexos(videoConf.ficheiro)}
                                        placement="bottomLeft"
                                    >
                                        <button className="botao-icon-clip" title="Anexos">
                                            <Icon type="paper-clip" />
                                        </button>
                                    </Dropdown> : null}
                            </div>
                        </div>
                    })
                });

                this.setState({
                    loading: false,
                    videoconferencias,
                    videoconferenciasMobile
                }, () => {
                    if (response.data.find(x => !x.sessaoVideo && x.gravarSessao))
                        response.data.map((videoConf, index) => {
                            if (!videoConf.sessaoVideo && videoConf.gravarSessao)
                                this.carregarVideo(videoConf.meetingName, videoConf.id)
                        })
                });
            })
            .catch(() => { });
    };

    montarTipoPartilha = (videoConf) => {
        if (videoConf.todos && videoConf.listaParticipantes > 0 || !videoConf.todos && videoConf.listaParticipantes > 1) {
            return (<div className="info-participantes">
                <Link to="#" className="link-model-participantes" onClick={() => this.listarParticipantes(videoConf.id)}>
                    <i className="fas fa-users icon-tipo-partilha partilhado"></i>
                </Link>
            </div>)
        }
        else {
            return (<div className="info-participante">
                <div className="container-img">
                    <img src={videoConf.participante.foto ? videoConf.participante.foto : noimage} />
                </div>
                <div className="info">
                    <span className="nome">
                        {videoConf.participante.nome_completo.length > 1 ? `${videoConf.participante.nome_completo.split(" ")[0]} ${videoConf.participante.nome_completo.split(" ")[videoConf.participante.nome_completo.split(" ").length - 1]}` : videoConf.participante.nome_completo}
                    </span>
                    <p className="numero">{videoConf.participante.numero}</p>
                </div>
            </div>)
        }
    };

    carregarVideo = (meetingName, videoConfId) => {
        axios({
            method: "get",
            url: `https://api.vimeo.com/me/videos`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost,
            params: {
                query: meetingName
            }
        }).then(response => {
            if (response.data.data.length) {
                var videos = [];

                response.data.data.forEach(video => {
                    videos = [...videos, video.uri.split("/").pop()]
                })

                var videoconferencias = this.state.videoconferencias;
                var confIndex = videoconferencias.findIndex(x => x.id === videoConfId)
                videoconferencias[confIndex].controlos = (
                    <div className="controlos">
                        <Dropdown
                            overlay={this.montarMenuVideosRealizadas(JSON.stringify(videos))}
                            placement="bottomLeft"
                        >
                            <button className="btn-start">Ver</button>
                        </Dropdown>
                    </div>
                )
                this.setState({
                    videoconferencias
                }, () => {
                    response.data.data.forEach(video => {
                        this.moverParaPasta(video.uri.split("/").pop());
                        this.carregarDominio(video.uri.split("/").pop());
                    })
                    this.guardarVideoID(videoConfId, JSON.stringify(videos));
                    //this.guardarVideoID(videoConfId, response.data.data[0].uri.split("/").pop())
                    //this.moverParaPasta(response.data.data[0].uri.split("/").pop())
                    //this.carregarDominio(response.data.data[0].uri.split("/").pop())
                });
            }
        })
    }

    guardarVideoID = (videoConfId, videoId) => {
        axios({
            method: "put",
            url: "/api/videoconferencia/guardar-video-id",
            params: {
                videoConfId,
                videoId
            }
        })
            .catch(() => { });
    };

    moverParaPasta = videoId => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/me/projects/${this.context.albumId_entidade}/videos/${videoId}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        })
    }

    carregarDominio = videoId => {
        axios({
            method: "get",
            url: "/api/curso/carregar-vimeo-configs",
        }).then(response => {
            this.adicionarDominio(videoId, response.data.dominio);
        });
    };

    adicionarDominio = (videoId, dominio) => {
        axios({
            method: "put",
            url: `https://api.vimeo.com/videos/${videoId}/privacy/domains/${dominio}`,
            transformRequest: [function (data, headers) {
                delete headers.common['apikey'];

                return JSON.stringify(data);
            }],
            headers: headerPost
        });
    };

    montarMenuVideosRealizadas = sessaoVimeoID => {
        var montar = JSON.parse(sessaoVimeoID).map((video, index) => (
            <Menu.Item>
                <Link to="#" onClick={() => this.iniciarVideo(video)}>
                    Video {index + 1}
                </Link>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    montarMenuAnexos = ficheiros => {
        var montar = JSON.parse(ficheiros).map((ficheiro) => (
            <Menu.Item>
                <Link to="#" onClick={() => this.downloadFicheiro(ficheiro.Nome, ficheiro.Caminho)}>
                    {ficheiro.Nome}
                </Link>
            </Menu.Item>
        ));

        return (
            <Menu>
                {montar}
            </Menu>
        );
    };

    downloadFicheiro = (nome, caminho) => {
        const link = document.createElement('a');
        link.href = caminho;
        link.setAttribute(
            'download',
            nome,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
    }

    montarMenuRealizadasMobile = (realizada, participantes) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ visibleAnteriores: true, anterioresRealizada: realizada, anterioresParticipantes: participantes })}>
                        Detalhe
                        </Link>
                </Menu.Item>
            </Menu>
        );
    };

    iniciar = (id, gravarSessao, Mensagem1, ParticipanteNaMeet) => {
        if (!ParticipanteNaMeet)
            if (gravarSessao) {
                confirm({
                    title: "Esta sessão está a ser gravada!",
                    okText: "Continuar",
                    okType: "Cancelar",
                    onOk: () => {
                        if (Mensagem1)
                            this.setState({
                                meetID: id,
                                redirectLink: `/curso/${this.props.match.params.curso
                                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                    .toLowerCase()}/vconf/${id}/player-videoconferencia`
                            }, () => this.registarEntrada());
                        else
                            this.setState({
                                visibleBrowsers: true,
                                meetID: id,
                                redirectLink: `/curso/${this.props.match.params.curso
                                    .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                                    .toLowerCase()}/vconf/${id}/player-videoconferencia`
                            });
                    }
                });
            }
            else {
                if (Mensagem1)
                    this.setState({
                        meetID: id,
                        redirectLink: `/curso/${this.props.match.params.curso
                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                            .toLowerCase()}/vconf/${id}/player-videoconferencia`
                    }, () => this.registarEntrada());
                else
                    this.setState({
                        visibleBrowsers: true,
                        meetID: id,
                        redirectLink: `/curso/${this.props.match.params.curso
                            .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                            .toLowerCase()}/vconf/${id}/player-videoconferencia`
                    });
            }
        else
            openNotificationWithIcon("warning", "Atenção", "Já está a participar numa Stepmeet. Para participar nesta, deve reentrar na Stepmeet onde já está a participar e clicar no botão SAIR para poder encerrar a sua participação nessa mesma sessão.")
    };

    confirmarEntrada = () => {
        if (this.state.naoMostrarMensagem)
            this.marcarNaoMostrar();

        this.registarEntrada();
    }

    registarEntrada = () => {
        axios({
            method: "post",
            url: "/api/videoconferencia/add-participant-entry",
            params: {
                meetingID: this.state.meetID,
                TempId: "",
                tipoEvento: 0
            }
        })
            .then(response => {
                localStorage.setItem("participanteNaMeet", true)
                this.setState({
                    redirect: true
                })
            })
            .catch(() => { });
    }

    marcarNaoMostrar = () => {
        axios({
            method: "post",
            url: "/api/utilizadores-informacoes/marcar-não-mostrar",
            params: {
                //cursoId: this.state.cursoid,
                mensagemId: 1,
            }
        })
            //.then(() => {

            //})
            //.catch(() => { });
    }

    iniciarExterna = meetExternaConvite => {
        this.setState({
            visibleMeetExterna: true,
            meetExternaConvite
        })
    };

    iniciarVideo = id => {
        this.setState({
            redirect: true,
            redirectLink: `/curso/${this.props.match.params.curso
                .replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-")
                .toLowerCase()}/vconf/${id}/player-video`
        });
    };

    atualizarListagem = () => {
        if (this.state.anteriores_ativo_on === "ativo") {
            this.totalAnteriores();
            this.listarAnteriores();
        } else {
            this.totalProximas();
            this.listarProximas();
        }
    }

    listarParticipantes = meetingId => {
        this.setState({
            visibleParticipantes: true,
            carregarLista: true
        })
        axios({
            method: "get",
            url: "/api/videoconferencia/listar-participantes",
            params: {
                meetingId
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data,
                    carregarLista: false
                })
            })
            .catch(() => { });
    };

    montarBotaoVoltar = () => {
        if (localStorage.getItem("opcao_voltar") != null) {
            const opcaoInfo = JSON.parse(localStorage.getItem("opcao_voltar"));
            return (<Link to={opcaoInfo.link_voltar_curso ? opcaoInfo.link_voltar_curso : opcaoInfo.link_voltar} className="botao-principal" onClick={() => opcaoInfo.id_curso && localStorage.setItem("codigo_curso", opcaoInfo.id_curso)}>
                Voltar
            </Link>);
        }
    }

    render() {
        const {
            anteriores_ativo_on,
            videoconferencias,
            videoconferenciasMobile,
            pagination,
            loading,
            //REDIRECT
            redirect,
            redirectLink,
            //BROWSERS INFO
            visibleBrowsers,
            //MODAL LISTA PARTICIPANTES
            visibleParticipantes,
            carregarLista,
            participantes,
            //MODAL MEET EXTERNA
            meetExternaConvite,
            visibleMeetExterna,
            //DRAWER PROXIMAS
            visibleProximas,
            proximasAgendada,
            proximasParticipantes,
            //DRAWER ANTERIORES
            visibleAnteriores,
            anterioresRealizada,
            anterioresParticipantes,
            //CURSO
            fechado
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <div className="container container-body">
                    <div className="curso-mensagens curso-videoconferencias">
                        <div className="controlos controlos-fundo">
                            <div className="controlos-opcoes" style={{display: "flex"}}>
                                {this.montarBotaoVoltar()}
                                {fechado ?
                                    <button className="botao-principal botao-disabled" style={{ fontSize: 25, padding: "0 10px" }} title="Atualizar Stepmeets">
                                        <Icon type="reload" />
                                    </button>
                                    :
                                    <button className="botao-principal" onClick={this.atualizarListagem} style={{ fontSize: 25, padding: "0 10px" }} title="Atualizar Stepmeets">
                                        <Icon type="reload" spin={loading} />
                                    </button>
                                }
                            </div>
                            {anteriores_ativo_on ?
                                <div className="controlos-opcoes controlo-search">
                                    <BotaoProcurar
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                        tem_placeholder
                                        placeholder="Procurar no Assunto"
                                    //disabled={!anteriores_ativo_on}
                                    />
                                </div>
                                : null}
                        </div>
                        <div className="tickets stepmeets min-height">
                            <Sidebar
                                filtro_proximas={this.filtro_proximas}
                                filtro_anteriores={this.filtro_anteriores}
                                proximas_ativo={this.state.proximas_ativo_on}
                                anteriores_ativo={this.state.anteriores_ativo_on}
                            />
                            <div className="bloco-formadores bloco-videoconferencias-desktop">
                                {anteriores_ativo_on === "ativo" ? (
                                    <TabelaAnteriores
                                        videoconferencias={videoconferencias}
                                        pagination={pagination}
                                        loading={loading}
                                        handleTableChange={this.handleTableChange}
                                        handleChangeSelect={this.handleChangeSelect}
                                    />
                                ) : (
                                        <TabelaProximas
                                            videoconferencias={videoconferencias}
                                            pagination={pagination}
                                            loading={loading}
                                            handleTableChange={this.handleTableChange}
                                            handleChangeSelect={this.handleChangeSelect}
                                        />
                                    )}
                            </div>
                            <div className="bloco-formadores bloco-videoconferencias-mobile">
                                {anteriores_ativo_on === "ativo" ? (
                                    <TabelaAnterioresMobile
                                        videoconferencias={videoconferenciasMobile}
                                        pagination={pagination}
                                        loading={loading}
                                        handleTableChange={this.handleTableChange}
                                        handleChangeSelect={this.handleChangeSelect}
                                    />
                                ) : (
                                        <TabelaProximasMobile
                                            videoconferencias={videoconferenciasMobile}
                                            pagination={pagination}
                                            loading={loading}
                                            handleTableChange={this.handleTableChange}
                                            handleChangeSelect={this.handleChangeSelect}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <CarouselAluno
                    visibleBrowsers={visibleBrowsers}
                    confirmarEntrada={this.confirmarEntrada}
                    cancelarEntrada={() => this.setState({ visibleBrowsers: false })}
                    selecionarNaoMostrar={naoMostrarMensagem => this.setState({ naoMostrarMensagem })}
                />
                <Modal
                    title={<>Participantes<br /><span style={{ fontSize: 13, color: "#8e9aa0" }}>Total: {participantes.length}</span></>}
                    visible={visibleParticipantes}
                    onCancel={() => this.setState({ visibleParticipantes: false })}
                    maskClosable={false}
                    className="modal-lista-participantes"
                    footer={null}
                >
                    <div className="bloco-lista-participantes">
                        {carregarLista ? (
                            <div className="bloco-loading">
                                <p>
                                    <Icon className="icon-loading" type="loading" />
                                </p>
                                <p className="texto">A carregar...</p>
                            </div>
                        ) : (
                                <>
                                    {participantes.map((participante, index) => (
                                        <div key={index} className="info-participante">
                                            <div className="bloco-img">
                                                <img src={participante.foto ? participante.foto : noimage} />
                                            </div>
                                            <div className="info">
                                                <span className="nome">
                                                    {participante.nome}
                                                </span>
                                                <p className="numero">{participante.numero}</p>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                    </div>
                </Modal>
                <Modal
                    title="Convite"
                    visible={visibleMeetExterna}
                    onCancel={() => this.setState({ visibleMeetExterna: false })}
                    maskClosable={false}
                    className="modal-lista-participantes"
                    footer={null}
                >
                    <div className="bloco-lista-participantes" dangerouslySetInnerHTML={{ __html: meetExternaConvite?.replace(/(?:\r\n|\r|\n)/g, '<br>') }}>
                    </div>
                </Modal>
                <Drawer
                    className="drawer-detalhe-proximas"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleProximas: false })}
                    visible={visibleProximas}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Agendada por">
                                    {proximasAgendada}
                                </Form.Item>
                                <Form.Item label="Participantes">
                                    {proximasParticipantes}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleProximas: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
                <Drawer
                    className="drawer-detalhe-anteriores"
                    title="Detalhe"
                    width={720}
                    onClose={() => this.setState({ visibleAnteriores: false })}
                    visible={visibleAnteriores}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Iniciada por">
                                    {anterioresRealizada}
                                </Form.Item>
                                <Form.Item label="Participantes">
                                    {anterioresParticipantes}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={() => this.setState({ visibleAnteriores: false })}>
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

const MainForm = Form.create({ name: "form-categorias" })(Main);

export default MainForm;
