import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, notification, Modal } from "antd";
import ExcelTabelaNotas from "./_Aux/ExcelTabelaNotas";
import CardsNotas from "./_Aux/CardsNotas";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ListaNotas extends Component {
    state = {
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //FORMULA
        ano: "",
        eja: false,
        formula: 0
    }

    componentDidMount() {
        this.carregarFormulaAno();
    }

    carregarFormulaAno = () => {
        axios({
            method: "get",
            url: "/api/aulas/carregar-formula-ano",
            params: {
                cursoId: localStorage.getItem("codigo_curso"),
                faseId: localStorage.getItem("faseEscolar")
            }
        })
            .then(response => {
                this.setState({
                    ano: response.data.ano,
                    eja: response.data.eja,
                    formula: response.data.formula
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a formula!");
            });
    }

    exportar = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/notas",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    exportarPdf = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas-exportar/notas-pdf",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    render() {
        const {
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //FORMULA
            ano,
            eja,
            formula
        } = this.state;

        return (
            <>
                <div className="container container-body">
                    <div className="bloco-curso-diario-opcoes-end">
                        <Link to={`/gerir-curso/${this.props.match.params.curso}/${localStorage.getItem("faseEscolar")}`} className="botao-icon-voltar" title="Voltar">
                            <Icon type="arrow-left" />
                        </Link>
                        <Link className="botao-icon-pdf" to="#" title="Descarregar" onClick={this.exportarPdf}>
                            <Icon type="file-pdf" />
                        </Link>
                        <Link className="botao-icon-download" to="#" title="Descarregar" onClick={this.exportar}>
                            <Icon type="download" />
                        </Link>
                    </div>
                    <div className="curso-diario curso-diario-frequencia-desktop">
                        <ExcelTabelaNotas
                            ano={ano}
                            eja={eja}
                            formula={formula}
                        />
                    </div>
                    <div className="curso-diario curso-diario-frequencia-mobile">
                        <CardsNotas
                            ano={ano}
                            eja={eja}
                            formula={formula}
                        />
                    </div>
                </div>
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                <p className="texto">Dados exportados com sucesso!</p>
                                <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

export default ListaNotas;
