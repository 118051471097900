import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Select, Icon, Checkbox, Drawer, Tooltip } from "antd";

class DrawerChat extends Component {
    state = {
        iconLoading: false
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;
        const { TextArea } = Input;

        return (
            <Drawer
                className="drawer-adicionar-conversa"
                title="Chat"
                width={720}
                onClose={this.props.fecharChat}
                visible={this.props.state.mostrarChat}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                // afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias">
                        <Form.Item label="Assunto">
                            {getFieldDecorator("assunto", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<Input name="assunto" onChange={this.handleChange} />)}
                        </Form.Item>
                        <Form.Item label="Estado">
                            {getFieldDecorator("estado", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: this.state.estado
                            })(
                                <Select
                                    className="input-25"
                                    style={{ width: "100%" }}
                                    placeholder="Selecionar"
                                    allowClear={true}
                                    onChange={value => this.setState({ estado: value })}
                                >
                                    <Option key="0" value="Normal">
                                        Normal
                                    </Option>
                                    <Option key="1" value="Prioritário">
                                        Prioritário
                                    </Option>
                                    <Option key="2" value="Critico">
                                        Critico
                                    </Option>
                                    <Option key="3" value="Fechado">
                                        Fechado
                                    </Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Texto">
                            {getFieldDecorator("mensagem", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ]
                            })(<TextArea name="mensagem" onChange={this.handleChange} style={{ height: "180px" }} />)}
                        </Form.Item>
                        <Form.Item className="form-item-checkbox">
                            <Checkbox name="notificar" onChange={this.handlerChangeCheck}>
                                Notificar por e-mail
                                <Tooltip className="info-icon" title="Notificar os membros por e-mail">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </Checkbox>
                        </Form.Item>
                    </Form>
                </div>
            </Drawer>
        );
    }
}
const FormDrawerChat = Form.create({ name: "main" })(DrawerChat);

export default withRouter(FormDrawerChat);
