import React, { useState, useEffect } from "react";
import { Table } from 'antd';
import ReactDragListView from "react-drag-listview";
import moment from 'moment';
import axios from "axios";
import Controlos from "./Controlos/Controlos";
import TabelaDetalhe from "./TabelaDetalhe/TabelaDetalhe";

const Relatorio = ({ menuOptionSelected }) => {
    const [search, setSearch] = useState("");
    const [filterSchool, setFilterSchool] = useState(undefined);
    const [filterEdition, setFilterEdition] = useState(undefined);
    const [filterCourse, setFilterCourse] = useState(undefined);
    const [activeFilters, setActiveFilters] = useState([]);
    const [cols, setCols] = useState([]);
    const [data, setData] = useState([]);
    const [dataBackUp, setDataBackUp] = useState([]);
    const [sorter, setSorter] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const columns = [...cols];
            const item = columns.splice(fromIndex, 1)[0];
            columns.splice(toIndex, 0, item);
            setCols(columns)
        },
        nodeSelector: "th"
    };

    const loadList = () => {
        if (search || filterSchool || filterEdition || filterCourse) {
            setLoading(true);
            axios({
                method: "get",
                url: "/api/relatorios/lista-notas",
                params: {
                    search,
                    columns: JSON.stringify(cols.map(col => col.dataIndex)),
                    filterSchool,
                    filterEdition,
                    filterCourse
                }
            })
                .then(response => {
                    setData(response.data);
                    setDataBackUp(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });

                    setLoading(false);
                });
        }
        else {
            setData([]);
        }
    }

    useEffect(() => {
        setFilterSchool(undefined);
        setFilterEdition(undefined);
        setFilterCourse(undefined);
        setCols([
            {
                title: "Número",
                dataIndex: "number",
                sorter: (a, b) => a.number.localeCompare(b.number),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Nome",
                dataIndex: "name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Escola",
                dataIndex: "school",
                sorter: (a, b) => a.school.localeCompare(b.school),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Curso",
                dataIndex: "course",
                sorter: (a, b) => a.course.localeCompare(b.course),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Nota",
                dataIndex: "grade",
                sorter: (a, b) => a.course.localeCompare(b.course),
                sortDirections: ['descend', 'ascend'],
                render: grade => parseFloat(grade.toFixed(2)),
            },
            {
                title: "Estado",
                dataIndex: "state",
                sorter: (a, b) => a.course.localeCompare(b.course),
                sortDirections: ['descend', 'ascend'],
                render: state => <span className={state === "Aprovado" ? "state-green-tag" : "state-red-tag"}>{state}</span>,
            }
        ])

        setData([]);

    }, [menuOptionSelected]);

    useEffect(() => {
        loadList();

    }, [activeFilters]);

    const HandleKeyPress = (event) => {
        if (event.key === "Enter") {
            setActiveFilters([...activeFilters, "SEARCH"])
            setSearch(event.target.value);
        }
    }

    const resetSearch = () => {
        setActiveFilters(activeFilters.filter(x => x !== "SEARCH"))
        setSearch("");
    }

    const handleChangeSchool = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "SCHOOL"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "SCHOOL"))
        }
        setFilterSchool(filter);
    }

    const handleChangeEdition = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "EDITION"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "EDITION"))
        }
        setFilterEdition(filter);
    }

    const handleChangeCourse = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "COURSE"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "COURSE"))
        }
        setFilterCourse(filter);
    }

    return (
        <>
            <div className="report-info">
                <Controlos
                    menuItemKey={menuOptionSelected?.menuItemKey}
                    menuItemName={menuOptionSelected?.menuItemName}
                    HandleKeyPress={(event) => HandleKeyPress(event)}
                    resetSearch={resetSearch}
                    filterSchool={filterSchool}
                    setFilterSchool={(filter) => handleChangeSchool(filter)}
                    filterEdition={filterEdition}
                    setFilterEdition={(filter) => handleChangeEdition(filter)}
                    filterCourse={filterCourse}
                    setFilterCourse={(filter) => handleChangeCourse(filter)}
                    setCols={(columns) => setCols(columns)}
                    sorter={sorter}
                    menuOptionSelected={menuOptionSelected}
                    disabledButtons={!search && !filterSchool && !filterEdition && !filterCourse}
                    columnsPositions={cols.map(col => col.dataIndex)}
                    cols={cols}
                    data={data}
                    pageTitle={menuOptionSelected?.menuItemName}
                />
                <div className="page-container">
                    <div className="page-header">
                        <h1 className="page-title">Relatório - {menuOptionSelected?.menuItemName}</h1>
                        <h3 className="page-counters">
                            <span>
                                <strong>Contar:</strong>
                                <span>{data.length}</span>
                            </span>
                        </h3>
                    </div>
                    <div className="page-body">
                        <ReactDragListView.DragColumn {...dragProps}>
                            <Table
                                columns={cols}
                                pagination={false}
                                dataSource={data}
                                expandedRowRender={record =>
                                    <TabelaDetalhe
                                        record={record}
                                    />
                                }
                                onChange={(pagination, filters, sorter) => setSorter(sorter.order ? { order: sorter.order, key: sorter.columnKey } : undefined)}
                                loading={{
                                    spinning: loading,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                            />
                        </ReactDragListView.DragColumn>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Relatorio;