import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Drawer, Icon, Form, Input, Select, Collapse, notification, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import DrawerPeriodo from "./DrawerPeriodo";
import DrawerAvaliacao from "./DrawerAvalicao";
import uuid from 'react-uuid'


const { Panel } = Collapse;
const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const DrawerDiario = ({ atualizarRegistos, onClose, visible, escolaridadeId, form }) => {
    const [titulo, setTitulo] = useState("")
    const [faseEscolar, setFaseEscolar] = useState("NORMAL")
    const [bncc, setBncc] = useState(undefined)
    const [tipo, setTipo] = useState(undefined)
    const [formula, setFormulo] = useState(undefined)
    const [estruturaBncc, setEstruturaBncc] = useState([])
    const [estrutura, setEstrutura] = useState([])
    const [avaliacoes, setAvaliacoes] = useState([])
    const [iconLoading, setIconLoading] = useState(false)
    const [visibleDrawerPeriodo, setVisibleDrawerPeriodo] = useState(false)
    const [visibleDrawerAvaliacao, setVisibleDrawerAvaliacao] = useState(false)
    const [estadoDrawer, setEstadoDrawer] = useState("")
    const [periodo, setPeriodo] = useState(null)
    const [registo, setRegisto] = useState(null)
    const [registoPaiId, setRegistoPaiId] = useState(0)
    const [html, setHtml] = useState()
    const [primeiroNivel, setPrimeiroNivel] = useState(false)
    const [existeRecuperacao, setExisteRecuperacao] = useState(false)
    const { getFieldDecorator } = form;

    const handleChangeAvaliacao = (event, index) => {
        let _avaliacoes = avaliacoes;
        _avaliacoes[index].nome = event.target.value;

        setAvaliacoes([..._avaliacoes]);
    };

    const carregarDetalheFase = escolaridadeId => {
        axios({
            method: "get",
            url: "/api/gestao-diario/detalhe",
            params: {
                id: escolaridadeId,
            }
        })
            .then(response => {
                const escolaridade = response.data;
                setTitulo(escolaridade.nome);
                setTipo(escolaridade.tipo);
                setFaseEscolar(escolaridade.bncc ? "BNCC" : "NORMAL");
                setBncc(escolaridade.codigo)
                setFormulo(escolaridade.formula)
                setEstrutura([...escolaridade.periodos, ...escolaridade.registos])
                setAvaliacoes([...escolaridade.avaliacoes])
                carregarBncc(escolaridade.codigo);
            })
            .catch(error => {

            });
    };

    const carregarBncc = codigo => {
        axios({
            method: "get",
            url: "/api/gestao-diario/carregar-bncc",
            params: {
                codigo,
            }
        })
            .then(response => {
                setEstruturaBncc(response.data);
            })
            .catch(error => {

            });
    };

    //ABRE O DRAWER COM OS DADOS DA EDIÇÃO
    //carregarDetalheBncc = escolaridade => {
    //    axios({
    //        method: "get",
    //        url: "/api/gestao-diario/detalhe-bncc",
    //        params: {
    //            id: escolaridade.id,
    //        }
    //    })
    //        .then(response => {
    //            this.setState({
    //                estado: "EDITAR",
    //                escolaridade: response.data.fase,
    //                escolaridadeBncc: response.data.bncc,
    //                visible: true
    //            });
    //        })
    //        .catch(error => {

    //        });
    //};

    const adicionar = event => {
        event.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                var item = new FormData();
                item.append("nome", titulo);
                item.append("tipo", tipo);
                if (bncc)
                    item.append("codigo", bncc);
                if (formula)
                    item.append("formula", formula);
                item.append("estrutura", JSON.stringify(estrutura));
                item.append("avaliacoes", JSON.stringify(avaliacoes));

                axios({
                    method: "post",
                    url: "/api/gestao-diario/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Registo adicionado!");
                        setIconLoading(false);
                        atualizarRegistos();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false);
                    });
            }
        })
    }

    //ATUALIZA A EDIÇÕES
    const guardar = event => {
        event.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setIconLoading(true);

                var item = new FormData();
                item.append("id", escolaridadeId);
                item.append("nome", titulo);
                item.append("tipo", tipo);
                if (formula)
                    item.append("formula", formula);
                item.append("estrutura", JSON.stringify(estrutura));
                item.append("avaliacoes", JSON.stringify(avaliacoes));

                axios({
                    method: "put",
                    url: "/api/gestao-diario/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Registo alterado!");
                        setIconLoading(false);
                        atualizarRegistos();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        setIconLoading(false);
                    });
            }
        });
    };

    const atualizarAdicionado = (registo) => {
        if (registo.idPai === 0) {
            if (faseEscolar === "BNCC") {
                const bnccEstrutura = adicionarBncc(registo.id);
                setEstrutura([...estrutura, registo, ...bnccEstrutura]);
            }
            else
                setEstrutura([...estrutura, registo]);
        }
        else
            setEstrutura([...estrutura, registo]);
        setVisibleDrawerPeriodo(false);
        setVisibleDrawerAvaliacao(false);
        setEstadoDrawer("");
    }

    const adicionarBncc = (idPai) => {
        let bncc = [];

        estruturaBncc.map((grupo, index) => {
            let registo = {
                key: parseInt(Date.now().toString() + Math.floor(Math.random() * 100).toString()),
                id: parseInt(Date.now().toString() + Math.floor(Math.random() * 100).toString()),
                idPai: idPai,
                nome: grupo.titulo,
                ordem: grupo.ordem,
                ativo: true
            }

            const avaliacoes = grupo.avaliacoes.map((avaliacao, index2) => {
                return {
                    key: parseInt(Date.now().toString() + Math.floor(Math.random() * 100).toString()),
                    id: parseInt(Date.now().toString() + Math.floor(Math.random() * 100).toString()),
                    idPai: registo.id,
                    nome: avaliacao.codigo ? `${avaliacao.codigo} - ${avaliacao.titulo}` : avaliacao.titulo,
                    ordem: avaliacao.ordem,
                    ativo: true
                }
            })

            bncc.push(registo);
            bncc.push(...avaliacoes);
        })

        return bncc;
    }

    const atualizarEditado = (registo) => {
        let tempEstrutura = estrutura;
        const index = tempEstrutura.findIndex(x => x.id === registo.id)
        tempEstrutura[index] = registo;
        setEstrutura([...tempEstrutura]);
        setVisibleDrawerPeriodo(false);
        setVisibleDrawerAvaliacao(false);
        setEstadoDrawer("");
    }

    const carregarDetalhePeriodo = (periodo) => {
        setPeriodo(periodo)
        setVisibleDrawerPeriodo(true);
        setEstadoDrawer("EDITAR");
    }

    const carregarDetalhe = (registo) => {
        setRegisto(registo)
        setVisibleDrawerAvaliacao(true);
        setEstadoDrawer("EDITAR");
    }

    const excluirRegisto = (id) => {
        confirm({
            title: "Pretende excluir este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                const lista = estrutura.filter(x => x.id !== id);
                setEstrutura(lista);
            }
        });
    }

    const ocultarRegisto = (id, ativo) => {
        confirm({
            title: !ativo ? "Pretende mostrar este registo?" : "Pretende ocultar este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                let lista = estrutura;
                const index = estrutura.findIndex(x => x.id === id);
                lista[index].ativo = !ativo
                lista = ocultarRegistosFilhos(lista, id, ativo)
                setEstrutura([...lista]);
                if (!ativo)
                    openNotificationWithIcon("success", "Sucesso", "Registo visível");
                else
                    openNotificationWithIcon("success", "Sucesso", "Registo ocultado");
            }
        });
    }

    const ocultarRegistosFilhos = (estrutura, id, ativo) => {
        let lista = estrutura;
        const filhos = lista.filter(x => x.idPai === id);

        filhos.map(filho => {
            const index = lista.findIndex(x => x.id === filho.id);
            lista[index].ativo = !ativo;
            lista = ocultarRegistosFilhos(lista, filho.id, ativo);
        })

        return lista;
    }

    const montarPeriodos = (lista) => {
        lista = lista.filter(x => x.idPai === 0);
        let temp = lista.map((item, index) => {
            return (
                <>
                    <div key={uuid()} className="item-estrutura">
                        <span className={`titulo-item-estrutura ${!item.ativo ? "oculto" : ""}`}>{item.nome} ({moment(item.inicio).format("DD/MM/YYYY")} - {moment(item.termino).format("DD/MM/YYYY")})</span>
                        {faseEscolar === "BNCC" ?
                            <span className="controlos-item-estrutura">
                                <Link className="botao-icon-editar" to="#" onClick={() => carregarDetalhePeriodo(item)} title="Editar registo">
                                    <Icon type="edit" />
                                </Link>
                                <Link className="botao-icon-excluir" to="#" onClick={() => excluirRegisto(item.id)} title="Excluir registo">
                                    <Icon type="delete" />
                                </Link>
                                {item.ativo ?
                                    <Link className="botao-icon-oculto" to="#" onClick={() => ocultarRegisto(item.id, item.ativo)} title="Tornar registo oculto">
                                        <Icon type="eye-invisible" />
                                    </Link>
                                    :
                                    <Link className="botao-icon-visivel" to="#" onClick={() => ocultarRegisto(item.id, item.ativo)} title="Tornar registo visível">
                                        <Icon type="eye" />
                                    </Link>
                                }
                            </span>
                            :
                            <span className="controlos-item-estrutura">
                                <Link className="botao-icon-adicionar" to="#" onClick={() => { setVisibleDrawerAvaliacao(true); setRegistoPaiId(item.id); setEstadoDrawer("NOVO"); setPrimeiroNivel(true); setExisteRecuperacao(estrutura.some(x => x.idPai === item.id && x.recuperacao)); }} title="Adicionar sub-registo">
                                    <Icon type="plus" />
                                </Link>
                                <Link className="botao-icon-editar" to="#" onClick={() => carregarDetalhePeriodo(item)} title="Editar registo">
                                    <Icon type="edit" />
                                </Link>
                                <Link className="botao-icon-excluir" to="#" onClick={() => excluirRegisto(item.id)} title="Excluir registo">
                                    <Icon type="delete" />
                                </Link>
                            </span>
                        }
                    </div>
                    {montarAvaliacoes(estrutura.filter(x => x.idPai === item.id), item.id)}
                </>
            );
        })
        let estruturaHtml = <div className="diario-estrutura" > {temp}</div>;
        return estruturaHtml;
    }

    const montarAvaliacoes = (lista, idPai) => {
        let temp = lista.map((item, index) => {
            return (
                <>
                    <div key={uuid()} className="item-estrutura">
                        <span className={`titulo-item-estrutura ${!item.ativo ? "oculto" : ""}`}>{item.nome}</span>
                        {faseEscolar === "BNCC" ?
                            <span className="controlos-item-estrutura">
                                {item.ativo ?
                                    <Link className="botao-icon-oculto" to="#" onClick={() => ocultarRegisto(item.id, item.ativo)} title="Tornar registo oculto">
                                        <Icon type="eye-invisible" />
                                    </Link>
                                    :
                                    <Link className="botao-icon-visivel" to="#" onClick={() => ocultarRegisto(item.id, item.ativo)} title="Tornar registo visível">
                                        <Icon type="eye" />
                                    </Link>
                                }
                            </span>
                            :
                            <span className="controlos-item-estrutura">
                                <Link className="botao-icon-adicionar" to="#" onClick={() => { setVisibleDrawerAvaliacao(true); setRegistoPaiId(item.id); setEstadoDrawer("NOVO"); setPrimeiroNivel(false); setExisteRecuperacao(false); }} title="Adicionar sub-registo">
                                    <Icon type="plus" />
                                </Link>
                                <Link className="botao-icon-editar" to="#" onClick={() => { carregarDetalhe(item); setPrimeiroNivel(false); setExisteRecuperacao(estrutura.some(x => x.idPai === idPai && x.recuperacao)); }} title="Editar registo">
                                    <Icon type="edit" />
                                </Link>
                                <Link className="botao-icon-excluir" to="#" onClick={() => excluirRegisto(item.id)} title="Excluir registo">
                                    <Icon type="delete" />
                                </Link>
                            </span>
                        }
                    </div>
                    {montarAvaliacoes(estrutura.filter(x => x.idPai === item.id), item.id)}
                </>
            );
        })
        let estruturaHtml = lista.length ? <div className="diario-estrutura" > {temp}</div> : null;
        return estruturaHtml;
    }

    useEffect(() => {
        const html = montarPeriodos(estrutura.filter(x => x.idPai === 0));
        setHtml(html);
    }, [estrutura])

    const afterVisibleChange = aberto => {
        if (aberto) {
            form.resetFields();
            if (escolaridadeId > 0)
                carregarDetalheFase(escolaridadeId)
            else {
                setTitulo("")
                setFaseEscolar("NORMAL")
                setBncc(undefined)
                setTipo(undefined)
                setEstrutura([])
                setFormulo(undefined)
                setAvaliacoes([])
                setIconLoading(false)
            }
        }
    }

    return (
        <>
            <Drawer
                className="drawer-add-cursos"
                title="Fase Escolar"
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-categorias listagem-avaliacoes" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Titulo">
                                {getFieldDecorator("titulo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: titulo
                                })(<Input name="titulo" onChange={(event) => setTitulo(event.target.value) } />)}
                            </Form.Item>
                            <Form.Item label="Tipo de Fase Escolar">
                                {getFieldDecorator("faseEscolar", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: faseEscolar
                                })(<Select placeholder="Selecionar" onChange={value => setFaseEscolar(value)}>
                                    <Option value="NORMAL">Normal</Option>
                                    <Option value="BNCC">BNCC</Option>
                                </Select>)}
                            </Form.Item>
                            {faseEscolar === "BNCC" &&
                                <Form.Item label="BNCC">
                                    {getFieldDecorator("bncc", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: bncc
                                    })(<Select placeholder="Selecionar" onChange={value => { setBncc(value); carregarBncc(value); }}>
                                        <Option value="CRECHE">Creche</Option>
                                        <Option value="PRE">Pré Infantil</Option>
                                        <Option value="REGULAR1E2">Regular (1º e 2º)</Option>
                                        <Option value="REGULAR4">Regular (4º)</Option>
                                    </Select>)}
                                </Form.Item>}
                        </div>
                        <Collapse defaultActiveKey={['1', '2']} className="collapse-campos">
                            <Panel
                                header="Tipo de avalição"
                                key="2"
                            >
                                <div className="bloco">
                                    <Form.Item label="Tipo">
                                        {getFieldDecorator("tipo", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório"
                                                }
                                            ],
                                            initialValue: tipo
                                        })(
                                            <Select placeholder="Selecionar" onChange={value => { setTipo(value); setFormulo(undefined); }}>
                                                <Option value="Notas">Notas</Option>
                                                <Option value="Conceito">Conceito</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                    {tipo === "Notas" &&
                                        <Form.Item label="Fórmula">
                                            {getFieldDecorator("formula", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Campo obrigatório"
                                                    }
                                                ],
                                                initialValue: formula
                                            })(
                                                <Select placeholder="Selecionar" onChange={value => setFormulo(value)}>
                                                    <Option value={1}>Sem peso no cálculo da Média Final</Option>
                                                    <Option value={2}>Com peso no cálculo da Média Final</Option>
                                                </Select>
                                            )}
                                        </Form.Item>}
                                </div>
                                {tipo === "Conceito" &&
                                    <div className="bloco bloco-lista-avaliacao-opcoes">
                                        <h3 className="titulo-separador">
                                            Avaliações
                                            <button className="botao-principal" onClick={(event) => { event.stopPropagation(); setAvaliacoes([...avaliacoes, { id: -(avaliacoes.length + 1), nome: "" }]) }}>
                                                <Icon type="plus" />
                                            </button>
                                        </h3>
                                        <div className="lista-avaliacao-opcoes">
                                            {avaliacoes.map((avaliacao, index) => (
                                                <Form.Item key={uuid()}>
                                                    <div className="avaliacao-opcao">
                                                        <Input name="nome" onChange={(event) => handleChangeAvaliacao(event, index)} value={avaliacao.nome} />
                                                        <button className="botao-excluir" onClick={() => setAvaliacoes([...avaliacoes.filter(x => x.id !== avaliacao.id)])}>
                                                            <Icon type="delete" />
                                                        </button>
                                                    </div>
                                                </Form.Item>
                                            ))}
                                            {!avaliacoes.length && "Sem registos..."}
                                        </div>
                                    </div>}
                            </Panel>
                            <Panel header={<>
                                Estrutura
                                <button className="botao-principal" disabled={faseEscolar === "BNCC" && !bncc} onClick={(event) => { event.stopPropagation(); setVisibleDrawerPeriodo(true); }}>
                                    <Icon type="plus" />
                                </button>
                            </>}
                                key="1"
                            >
                                <div className="bloco">
                                    {estrutura.length ?
                                        html
                                        :
                                        <div>Sem registos...</div>
                                    }
                                </div>
                            </Panel>
                        </Collapse>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    {escolaridadeId > 0 ?
                        <button className="botao-principal" onClick={guardar} disabled={iconLoading} type="primary">
                            {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>
                        :
                        <button className="botao-principal" onClick={adicionar} disabled={iconLoading} type="primary">
                            {iconLoading ? <Icon type="loading" /> : null}
                            Criar
                        </button>
                    }
                </div>
            </Drawer>
            <DrawerPeriodo
                onClose={() => { setVisibleDrawerPeriodo(false); setEstadoDrawer(""); }}
                atualizarAdicionado={atualizarAdicionado}
                atualizarEditado={atualizarEditado}
                visibleDrawerPeriodo={visibleDrawerPeriodo}
                estado={estadoDrawer}
                periodo={periodo}
            />
            <DrawerAvaliacao
                onClose={() => { setVisibleDrawerAvaliacao(false); setEstadoDrawer(""); }}
                atualizarAdicionado={atualizarAdicionado}
                atualizarEditado={atualizarEditado}
                visibleDrawerAvaliacao={visibleDrawerAvaliacao}
                estado={estadoDrawer}
                registoPaiId={registoPaiId}
                registo={registo}
                tipo={tipo}
                recuperacaoBloqueada={primeiroNivel && existeRecuperacao || !primeiroNivel && existeRecuperacao && estadoDrawer === "EDITAR" || !primeiroNivel && estadoDrawer !== "EDITAR"}
            />
        </>
    );
}

const FormDrawerDiario = Form.create({ name: "drawer-diario" })(DrawerDiario);

export default FormDrawerDiario;