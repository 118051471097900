import React, { Component } from "react";
import { Form, Input, Icon, notification } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Conteudos extends Component {
    state = {
        conteudo: "",
        metodologia: "",
        //LOADING
        iconLoading: false,
    }

    componentDidMount() {
        this.detalhe();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    detalhe = () => {
        axios({
            method: "get",
            url: "/api/aulas/detalhe-conteudo",
            params: {
                aulaId: localStorage.getItem("aula_diario")
            }
        })
            .then(response => {
                this.setState({
                    conteudo: response.data.conteudo,
                    metodologia: response.data.metodologia,
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", localStorage.getItem("aula_diario"));
                item.append("conteudo", this.state.conteudo);
                item.append("metodologia", this.state.metodologia);

                axios({
                    method: "put",
                    url: "/api/aulas/alterar-conteudo",
                    data: item
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Conteúdos da aula alterados!");
                        this.setState({ iconLoading: false });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível alterar conteúdos da aula!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        const {
            conteudo,
            metodologia,
            //LOADING
            iconLoading,
        } = this.state;

        const {
            drawerConteudosVisible,
            eventoId
        } = this.props;

        return (
            <>
                <Form className="form-categorias" layout="horizontal">
                    <div className="bloco">
                        <Form.Item label="Objeto de Conhecimento/Conteúdo">
                            {getFieldDecorator("conteudo", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: conteudo
                            })(
                                <TextArea
                                    name="conteudo"
                                    rows={4}
                                    style={{ height: 100 }}
                                    onChange={this.handleChange}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="DESDP/Metodologia">
                            {getFieldDecorator("metodologia", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Campo obrigatório"
                                    }
                                ],
                                initialValue: metodologia
                            })(
                                <TextArea
                                    name="metodologia"
                                    rows={4}
                                    style={{ height: 100 }}
                                    onChange={this.handleChange}
                                />
                            )}
                        </Form.Item>
                    </div>
                    <div >
                        <button className="botao-principal" onClick={this.alterar} disabled={iconLoading} type="primary">
                            {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                                </button>
                    </div>
                </Form>
            </>
        );
    }
}

const FormConteudos = Form.create({ name: "form-conteudos" })(Conteudos);

export default FormConteudos;