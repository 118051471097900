import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, Upload, notification, Icon } from "antd";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Contacto extends Component {
    static contextType = GlobalContext;
    state = {
        nome: "",
        email: "",
        assunto: "",
        mensagem: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    //ENVIA O CONTACTO
    Enviar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome", this.state.nome);
                item.append("email", this.state.email);
                item.append("assunto", this.state.assunto);
                item.append("mensagem", this.state.mensagem);
                item.append("entidadeId", localStorage.getItem("entidadeId"));

                axios({
                    method: "post",
                    url: "/api/pagina-entidade/form-footer-publico",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Contato enviado!");
                        this.setState({
                            iconLoading: false,
                            redirect: true
                        });
                        this.reset();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível enviar a mensagem de contato!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    //LIMPA AS CAIXAS DE TEXTO APOS O ENVIO DO CONTACTO
    reset = () => {
        this.props.form.resetFields();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        if (this.state.redirect)
            return <Redirect to={`/${this.props.match.params.entidade}`} />;

        const {
            iconLoading,
        } = this.state;

        return (
            <>
                <Header titulo="Contato" />
                <div className="container">
                    <div className="content">
                        <Form className="suporte-contacto">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="nome" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Email">
                                {getFieldDecorator("email", {
                                    rules: [
                                        {
                                            type: "email",
                                            message: "Introduza um endereço de e-mail válido"
                                        },
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="email" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Assunto">
                                {getFieldDecorator("assunto", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="assunto" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Mensagem">
                                {getFieldDecorator("mensagem", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<TextArea name="mensagem" onChange={this.handleChange} style={{ height: "180px" }} resize />)}
                            </Form.Item>
                            <div className="btn_plano">
                                <Link to="#" className="botao-principal" disabled={iconLoading} onClick={this.Enviar}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Enviar
                                </Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}
const FormContacto = Form.create({ name: "contactos" })(Contacto);

export default FormContacto;
