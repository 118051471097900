import React, { Component } from "react";
import { notification } from "antd";
import Header from "../../User/Header";
import { GlobalContext } from "../../GlobalState";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};


class Lista extends Component {
    static contextType = GlobalContext;
    state = {
        noticias: [],
        //LOADING
        iconLoading: false
    };

    UNSAFE_componentWillMount() {
        this.listar()
    }

    listar = () => {
        this.setState({
            iconLoading: true
        });
        axios({
            method: "get",
            url: "/api/pagina-entidade/listar-todas-informacoes",
            params: {
                entidadeId: localStorage.getItem("entidadeId"),
                entidadeUrl: this.props.match.params.entidade
            }
        })
            .then(response => {
                this.setState({
                    noticias: response.data,
                    iconLoading: false
                });
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar as informações");
            });
    }

    render() {
        const { noticias } = this.state;

        return (
            <>
                <Header titulo="Informações" />
                <div className="container container-noticias container-body">
                    <div className="content">
                        <div className="bloco-noticias" style={{ width: "700px", marginLeft: "auto", marginRight: "auto" }}>
                            {noticias.map((noticia, index) => (
                                <div id={`informacao${index}`} key={index} className="noticia">
                                    {noticia.capa &&
                                        <div className="container-img">
                                            <img src={noticia.capa} />
                                        </div>}
                                    <h5 className="titulo">{noticia.titulo}</h5>
                                    {noticia.link &&
                                        <a href={noticia.link} target="_blank" rel="noopener noreferrer" className="link">
                                            <i className="fa fa-link"></i> Link
                                        </a>}
                                    <p className="texto" dangerouslySetInnerHTML={{ __html: noticia.texto.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                    <div className="tempo">
                                        {noticia.data && (
                                            <span className="data">
                                                <i className="fa fa-calendar" />
                                                {moment(noticia.data).format("DD/MM/YYYY")}
                                            </span>
                                        )}
                                        {noticia.hora && (
                                            <span className="hora">
                                                <i className="fa fa-clock" />
                                                {noticia.hora}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Lista;
