import React from "react";
import moment from "moment";
import { HashLink as HashLink } from 'react-router-hash-link';

const BlocoNoticias = ({ noticias, entidade }) => {

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset });
    }

    return (
        <div className="bloco-noticias">
            {noticias.map((noticia, index) => (
                <div key={index} className="noticia">
                    <HashLink scroll={el => scrollWidthOffset(el)} to={`/${entidade}/informacoes#informacao${index}`}>
                        <h5 className="titulo">{noticia.titulo}</h5>
                        {noticia.link &&
                            <a href={noticia.link} target="_blank" rel="noopener noreferrer" className="link">
                                <i className="fa fa-link"></i> Link
                            </a>}
                        <p className="texto">
                            {noticia.texto}
                        </p>
                        <div className="tempo">
                            {noticia.data && (
                                <span className="data">
                                    <i className="fa fa-calendar" />
                                    {moment(noticia.data).format("DD/MM/YYYY")}
                                </span>
                            )}
                            {noticia.hora && (
                                <span className="hora">
                                    <i className="fa fa-clock" />
                                    {noticia.hora}
                                </span>
                            )}
                        </div>
                    </HashLink>
                </div>
            ))}
        </div>
    );
};

export default BlocoNoticias;
