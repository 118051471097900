import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";

class ListaColaboradores extends Component {
    static contextType = GlobalContext;

    render() {
        return (
            <div className="lista-colaboradores">
                <ul className="opcoes">
                    <li className={`opcao ${this.props.todosAtivo}`}>
                        <Link to="/gestao-utilizadores-todos">Todos</Link>
                    </li>
                    <li className={`opcao ${this.props.pendentesAtivo}`}>
                        <Link to="/gestao-utilizadores-pendentes">Pendentes</Link>
                    </li>
                </ul>
                <ul className="info">
                    <li className="descritivo">
                        Usadas {this.props.licencasUtilizadas} de {this.props.totalLicencas} licenças
                    </li>
                    {this.props.licencasUtilizadas < this.props.totalLicencas ? (
                        <>
                            <li className="botao-associar">
                                <Link to="/gestao-utilizadores-associar">Associar</Link>
                            </li>
                            {this.context.login2_entidade && (
                                <li className="botao-associar-alunos">
                                    <Link to="/gestao-associar-alunos">Associar (simplificado)</Link>
                                </li>
                            )}
                        </>
                    ) : null}
                </ul>
            </div>
        );
    }
}

export default ListaColaboradores;
