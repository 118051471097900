import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Collapse, Input, Icon, Popover } from "antd";
import Header from "../../User/Header";
import { GlobalContext } from "../../GlobalState";
import Esquilo from "../../images/Esquilo_3.jpg";
import axios from "axios";
import noimage from "../../images/noimage.png";

const { Panel } = Collapse;

export class Ranking extends Component {
    static contextType = GlobalContext;
    state = {
        ranking: [],
        cursoRanking: [],
        atividades: [],
        outras: [],
        forum: [],
        meets: [],
        atividadesAbertas: [],
        ultimaPosicao: "",
        ultimosPontos: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LOADING
        loading: false
    };

    UNSAFE_componentWillMount() {
        this.carregarRanking()
    }

    carregarRanking = () => {
        this.setState(
            {
                loading: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gerir-curso-ranking/carregar-geral",
                    params: {
                        cursoid: localStorage.getItem("codigo_curso")
                    }
                })
                    .then(response => {
                        this.setState({
                            ranking: response.data.ranking,
                            cursoRanking: response.data.cursoRanking,
                            atividades: response.data.atividades,
                            outras: response.data.outras,
                            forum: response.data.forum,
                            meets: response.data.meets,
                            loading: false
                        })
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    render() {
        const { Search } = Input;

        const {
            ranking,
            cursoRanking,
            atividades,
            outras,
            forum,
            meets,
            atividadesAbertas,
            //REDIRECT
            redirect,
            redirectLink,
            //LOADING
            loading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const montarHeader = atividade => {
            return (<div className="lista-atividades">
                <div className="info-atividade">
                    <span className="titulo-atividade">{atividade}</span>
                </div>
            </div>)
        }

        const montarHeader2 = nome => {
            return (<div className="lista-atividades">
                <div className="info-atividade">
                    <span className="titulo-atividade">{nome}</span>
                </div>
            </div>)
        }

        const montarPosicoes = (posicao, pontos) => {
            if (pontos !== 0)
                switch (posicao) {
                    case 1:
                        return <span className="posicao medalha-ouro"><i className="fas fa-medal"></i></span>
                        break;
                    case 2:
                        return <span className="posicao medalha-prata"><i className="fas fa-medal"></i></span>
                        break;
                    case 3:
                        return <span className="posicao medalha-bronze"><i className="fas fa-medal"></i></span>
                        break;
                    default:
                        return <span className="posicao">{posicao}º</span>
                        break;
                }
            else
                return <span className="posicao">---</span>
        }

        return (
            <div className="container container-body">
                <div className="bloco-cursos">
                    <div className="curso-resultados bloco-tabela bloco-info-ranking">
                        <div className="bloco-flex" style={{ justifyContent: "flex-end" }}>
                            <Link
                                to={`/gerir-curso/${localStorage.getItem("nome_curso").replace(/[&\/\\#,+()$~%.'":*?!<>{}\s]/g, "-").toLowerCase()}/curso-resultados`}
                                className="botao-principal"
                            >
                                Voltar
                                </Link>
                        </div>
                        <div className="bloco" style={{ paddingTop: 0 }}>
                            {loading ?
                                <div className="loading-data">
                                    <div className="loading" />
                                </div>
                                :
                                <>
                                    <div className="bloco-ranking">
                                        <Collapse className="lista-ranking-collapse" defaultActiveKey={['0']}>
                                            <Panel header={montarHeader(<h3 className="titulo-separador" style={{ margin: 0, border: 'none', fontSize: 17 }}>
                                                Ranking
                                                <Popover
                                                    content={
                                                        <>
                                                            <span><strong style={{ marginRight: 5 }}>Conclusão do curso/disciplina:</strong> 25 </span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Medalha em Curso:</strong> 25</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Medalha em Tarefa:</strong> 10</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Avaliação:</strong> 10</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Trabalho:</strong> 5</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Questionário:</strong> 3</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Presença em Stepmeet:</strong> 3</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Presença:</strong> 3</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Publicação em fórum:</strong> 1</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Outras tarefas:</strong> 1</span><br />
                                                            <span><strong style={{ marginRight: 5 }}>Classificação:</strong> O seu valor em pontos</span><br />
                                                        </>
                                                    }
                                                    title="Legenda"
                                                    className="info-icon">
                                                    <Icon type="question-circle-o" />
                                                </Popover>
                                            </h3>)}>
                                                <div className="lista-ranking">
                                                    {ranking.map((rank, index) => (
                                                        <div key={index} className="posicao-ranking">
                                                            {montarPosicoes(rank.posicao, rank.pontos)}
                                                            <div className="info-participante">
                                                                <div className="container-img">
                                                                    <img src={rank.foto ? rank.foto : noimage} />
                                                                </div>
                                                                <div className="info info-desktop">
                                                                    <div>
                                                                        <span className="nome">
                                                                            {rank.nome}
                                                                        </span>
                                                                        <p className="numero">{rank.codigo}</p>
                                                                    </div>
                                                                    <span className="icon-medalha-curso">
                                                                        {rank.medalha &&
                                                                            <i className="fas fa-award"></i>}
                                                                    </span>
                                                                    <span className="pontos">
                                                                        {`${rank.pontos} pts`}
                                                                    </span>
                                                                </div>
                                                                <div className="info info-mobile">
                                                                    <div className="info-nome-medalha">
                                                                        <span className="nome">
                                                                            {rank.nome}
                                                                        </span>
                                                                        <span className="icon-medalha-curso">
                                                                            {rank.medalha &&
                                                                                <i className="fas fa-award"></i>}
                                                                        </span>
                                                                    </div>
                                                                    <p className="numero">{rank.codigo}</p>
                                                                    <div className="info-pontos">
                                                                        <span className="pontos">
                                                                            {`${rank.pontos} pts`}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                    <div className="bloco-atividades">
                                        <div className="bloco-atividade">
                                            <Collapse className="lista-atividades">
                                                <Panel header={montarHeader(<h3 className="titulo-separador" style={{ margin: 0, border: 'none' }}>Curso <span>(Conclusão do curso/disciplina + Medalha em Curso + Classificação)</span></h3>)}>
                                                    <div className="bloco-ranking">
                                                        <div className="lista-ranking">
                                                            {cursoRanking.map((rank, index) => (
                                                                <div key={index} className="posicao-ranking">
                                                                    {montarPosicoes(rank.posicao, rank.pontos)}
                                                                    <div className="info-participante">
                                                                        <div className="container-img">
                                                                            <img src={rank.foto ? rank.foto : noimage} />
                                                                        </div>
                                                                        <div className="info info-desktop">
                                                                            <div>
                                                                                <span className="nome">
                                                                                    {rank.nome}
                                                                                </span>
                                                                                <p className="numero">{rank.codigo}</p>
                                                                            </div>
                                                                            <span className="icon-medalha-curso">
                                                                                {rank.medalha &&
                                                                                    <i className="fas fa-award"></i>}
                                                                            </span>
                                                                            <span className="pontos">
                                                                                {rank.soma} = {`${rank.pontos} pts`}
                                                                            </span>
                                                                        </div>
                                                                        <div className="info info-mobile">
                                                                            <div className="info-nome-medalha">
                                                                                <span className="nome">
                                                                                    {rank.nome}
                                                                                </span>
                                                                                <span className="icon-medalha-curso">
                                                                                    {rank.medalha &&
                                                                                        <i className="fas fa-award"></i>}
                                                                                </span>
                                                                            </div>
                                                                            <p className="numero">{rank.codigo}</p>
                                                                            <div className="info-pontos">
                                                                                <span className="pontos">
                                                                                    {`${rank.pontos} pts`}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </div>
                                        <div className="bloco-atividade">
                                            <h3 className="titulo-separador">Outras Avaliações</h3>
                                            <Collapse className="lista-atividades" onChange={this.handleChange}>
                                                {!outras.length ?
                                                    <span>Sem registos</span>
                                                    : null}
                                                {outras.map((outra, index) => (
                                                    <Panel header={montarHeader(outra.nome)} key={outra.id}>
                                                        {outra.ranking &&
                                                            <div className="bloco-ranking">
                                                                <div className="lista-ranking">
                                                                    {outra.ranking.map((rank, index) => (
                                                                        <div key={index} className="posicao-ranking">
                                                                            {montarPosicoes(rank.posicao, rank.pontos)}
                                                                            <div className="info-participante">
                                                                                <div className="container-img">
                                                                                    <img src={rank.foto ? rank.foto : noimage} />
                                                                                </div>
                                                                                <div className="info info-desktop">
                                                                                    <div>
                                                                                        <span className="nome">
                                                                                            {rank.nome}
                                                                                        </span>
                                                                                        <p className="numero">{rank.codigo}</p>
                                                                                    </div>
                                                                                    <span className="icon-medalha-tarefa">
                                                                                        {rank.medalhas > 0 &&
                                                                                            <><i className="fas fa-award"></i><span className="total-medalhas-tarefa">{rank.medalhas}</span></>}
                                                                                    </span>
                                                                                    <span className="pontos">
                                                                                        {`${rank.pontos} pts`}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="info info-mobile">
                                                                                    <div className="info-nome-medalha">
                                                                                        <span className="nome">
                                                                                            {rank.nome}
                                                                                        </span>
                                                                                        <span className="icon-medalha-curso">
                                                                                            {rank.medalha &&
                                                                                                <i className="fas fa-award"></i>}
                                                                                        </span>
                                                                                    </div>
                                                                                    <p className="numero">{rank.codigo}</p>
                                                                                    <div className="info-pontos">
                                                                                        <span className="pontos">
                                                                                            {`${rank.pontos} pts`}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>}
                                                    </Panel>
                                                ))}
                                            </Collapse>
                                        </div>
                                        <div className="bloco-atividade">
                                            <h3 className="titulo-separador">Atividades</h3>
                                            <Collapse className="lista-atividades" onChange={this.handleChange}>
                                                {atividades.map((atividade, index) => (
                                                    <Panel header={montarHeader(atividade.nome)} key={atividade.id}>
                                                        {atividade.ranking &&
                                                            <div className="bloco-ranking">
                                                                <div className="lista-ranking">
                                                                    {atividade.ranking.map((rank, index) => (
                                                                        <div key={index} className="posicao-ranking">
                                                                            {montarPosicoes(rank.posicao, rank.pontos)}
                                                                            <div className="info-participante">
                                                                                <div className="container-img">
                                                                                    <img src={rank.foto ? rank.foto : noimage} />
                                                                                </div>
                                                                                <div className="info info-desktop">
                                                                                    <div>
                                                                                        <span className="nome">
                                                                                            {rank.nome}
                                                                                        </span>
                                                                                        <p className="numero">{rank.codigo}</p>
                                                                                    </div>
                                                                                    <span className="icon-medalha-tarefa">
                                                                                        {rank.medalhas > 0 &&
                                                                                            <><i className="fas fa-award"></i><span className="total-medalhas-tarefa">{rank.medalhas}</span></>}
                                                                                    </span>
                                                                                    <span className="pontos">
                                                                                        {`${rank.pontos} pts`}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="info info-mobile">
                                                                                    <div className="info-nome-medalha">
                                                                                        <span className="nome">
                                                                                            {rank.nome}
                                                                                        </span>
                                                                                        <span className="icon-medalha-curso">
                                                                                            {rank.medalha &&
                                                                                                <i className="fas fa-award"></i>}
                                                                                        </span>
                                                                                    </div>
                                                                                    <p className="numero">{rank.codigo}</p>
                                                                                    <div className="info-pontos">
                                                                                        <span className="pontos">
                                                                                            {`${rank.pontos} pts`}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>}
                                                    </Panel>
                                                ))}
                                                <Panel header={montarHeader2("Fórum")}>
                                                    <div className="bloco-ranking">
                                                        <div className="lista-ranking">
                                                            {forum.map((rank, index) => (
                                                                <div key={index} className="posicao-ranking">
                                                                    {montarPosicoes(rank.posicao, rank.pontos)}
                                                                    <div className="info-participante">
                                                                        <div className="container-img">
                                                                            <img src={rank.foto ? rank.foto : noimage} />
                                                                        </div>
                                                                        <div className="info info-desktop">
                                                                            <div>
                                                                                <span className="nome">
                                                                                    {rank.nome}
                                                                                </span>
                                                                                <p className="numero">{rank.codigo}</p>
                                                                            </div>
                                                                            <span className="icon-medalha-curso">
                                                                                {rank.medalha &&
                                                                                    <i className="fas fa-award"></i>}
                                                                            </span>
                                                                            <span className="pontos">
                                                                                {`${rank.pontos} pts`}
                                                                            </span>
                                                                        </div>
                                                                        <div className="info info-mobile">
                                                                            <div className="info-nome-medalha">
                                                                                <span className="nome">
                                                                                    {rank.nome}
                                                                                </span>
                                                                                <span className="icon-medalha-curso">
                                                                                    {rank.medalha &&
                                                                                        <i className="fas fa-award"></i>}
                                                                                </span>
                                                                            </div>
                                                                            <p className="numero">{rank.codigo}</p>
                                                                            <div className="info-pontos">
                                                                                <span className="pontos">
                                                                                    {`${rank.pontos} pts`}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Panel>
                                                <Panel header={montarHeader2("Stepmeets")}>
                                                    <div className="bloco-ranking">
                                                        <div className="lista-ranking">
                                                            {meets.map((rank, index) => (
                                                                <div key={index} className="posicao-ranking">
                                                                    {montarPosicoes(rank.posicao, rank.pontos)}
                                                                    <div className="info-participante">
                                                                        <div className="container-img">
                                                                            <img src={rank.foto ? rank.foto : noimage} />
                                                                        </div>
                                                                        <div className="info info-desktop">
                                                                            <div>
                                                                                <span className="nome">
                                                                                    {rank.nome}
                                                                                </span>
                                                                                <p className="numero">{rank.codigo}</p>
                                                                            </div>
                                                                            <span className="icon-medalha-curso">
                                                                                {rank.medalha &&
                                                                                    <i className="fas fa-award"></i>}
                                                                            </span>
                                                                            <span className="pontos">
                                                                                {`${rank.pontos} pts`}
                                                                            </span>
                                                                        </div>
                                                                        <div className="info info-mobile">
                                                                            <div className="info-nome-medalha">
                                                                                <span className="nome">
                                                                                    {rank.nome}
                                                                                </span>
                                                                                <span className="icon-medalha-curso">
                                                                                    {rank.medalha &&
                                                                                        <i className="fas fa-award"></i>}
                                                                                </span>
                                                                            </div>
                                                                            <p className="numero">{rank.codigo}</p>
                                                                            <div className="info-pontos">
                                                                                <span className="pontos">
                                                                                    {`${rank.pontos} pts`}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Ranking;
