import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Table, Form, Select, notification, Modal, Dropdown, Menu, Drawer, Input, Card } from "antd";
import Header from "../../User/Header";
import noimage from "../../images/noimage.png";
import axios from "axios";
import BotaoProcurar from "../../Geral/_Aux/BotaoProcurar";
import DrawerImportar from "./_Aux/DrawerImportar";
import DrawerDetalhe from "./_Aux/DrawerDetalheInscricao";
//import DrawerEnviarMsg from "./_Aux/DrawerEnviarMsg";
import moment from "moment";
import { GlobalContext } from "../../GlobalState";

const confirm = Modal.confirm;
const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class GestaoCursosInscricoes extends Component {
    static contextType = GlobalContext;
    state = {
        //TABELA
        loading_table: true,
        alunos: [],
        ativo: true,
        inscritos: false,
        //PAGINATION
        //pagination: {
        //    pageSize: 10,
        //    current: 1,
        //    total: 0
        //},
        sorter: {
            order: "ascend",
            field: "nome"
        },
        //INFO PRINCIPAL
        cursoid: 0,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //CHECKBOX'S DA TABELA
        selectedRowKeys: [],
        loading: false,
        //FILTRO PROCURAR
        pesquisa: "",
        //DRAWER IMPORTAR
        importar_visible: false,
        //DRAWER DETALHE INSCRIÇÃO
        inscricaoId: 0,
        drawer_detalhe_visible: false,
        //DRAWER INSCREVER
        loading_pesquisa: false,
        visibleDrawerInscrever: false,
        pesquisaUtilizadores: "",
        pesquisar_utilizadores: [],
        ////DRAWER ENVIAR MENSAGENS
        //utilizador: "",
        //visibleDrawerEnviarMsg: false
    };

    UNSAFE_componentWillMount() {
        //this.total();
        this.listar();
    }

    componentDidUpdate() {
        if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
            this.setState({
                redirect: true,
                redirectLink: "/user"
            });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeSelectSexo = value => {
        this.setState({ sexo: value });
    };

    handleChangeSelectEstado = value => {
        this.setState({ estado_civil: value });
    };

    handleChangeSelectPais = value => {
        this.setState({ pais: value });
    };

    handleChangeDataNascimento = value => {
        this.setState({ dt_nascimento: value });
    };

    handleChangeDataCc = value => {
        this.setState({ dt_ccvalidade: value });
    };

    handleChangeDataCcID = value => {
        this.setState({ dt_ccvalidadeID: value });
    };

    //INSCREVER ALUNOS
    inscrever = () => {
        this.setState({
            loading_pesquisa: false,
            visibleDrawerInscrever: true,
            pesquisaUtilizadores: "",
            pesquisar_utilizadores: []
        });
    };

    //INSCREVER ALUNOS
    remover = () => {
        confirm({
            title: "Pretende remover as inscrições?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                this.setState({ loading: true });
                axios({
                    method: "post",
                    url: "/api/curso-inscricoes/remover",
                    params: {
                        inscricoes: JSON.stringify(this.state.selectedRowKeys)
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Inscrições removidas");
                        if (this.state.ativo) {
                            this.setState({
                                selectedRowKeys: [],
                                loading: false,
                                ativo: true
                            });
                            //this.total();
                        } else {
                            this.setState({
                                selectedRowKeys: [],
                                loading: false
                            });
                            this.totalAlunos();
                        }
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível remover as inscrições");
                    });
            }
        });
    };

    //LIMPA A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: ""
            },
            () => {
                //this.total();
                this.listar();
            }
        );
    };

    //CHAMADA APÓS INTERASSÃO COM A CAIXA DE PROCURA
    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value,
                    //pagination: {
                    //    pageSize: 10,
                    //    current: 1,
                    //    total: 0
                    //}
                },
                () => {
                    //this.total();
                    this.listar();
                }
            );
        }
    };

    //LISTA TODOS OS ALUNOS DA ENTIDADE
    listar = () => {
        this.setState(
            {
                loading_table: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/gestao-curso-inscricoes/listar",
                    params: {
                        cursoid: localStorage.getItem("codigo_curso"),
                        pesquisa: this.state.pesquisa,
                        //pageSize: this.state.pagination.pageSize,
                        //page: this.state.pagination.current
                    }
                })
                    .then(response => {
                        var alunos = [];

                        response.data.map((aluno, index) => {
                            alunos.push({
                                key: aluno.inscricaoId,
                                nome: (
                                    <>
                                        <div className="imagem-curso">
                                            <img src={aluno.foto ? aluno.foto : noimage} />
                                        </div>
                                        <div className="nome-colaborador">
                                            <span className="titulo-curso">{aluno.nome_completo}</span>
                                        </div>
                                    </>
                                ),
                                email: aluno.email,
                                nr_aluno: <div className="info-numero-estado">
                                    <span className="info-numero">{aluno.nr_aluno}</span>
                                    {aluno.pendente ?
                                        <span className="info-estado">Registo Pendente</span>
                                        : null}
                                </div>,
                                opcoes: (
                                    <Dropdown overlay={this.montarMenu(aluno.inscricaoId, aluno)} placement="bottomLeft">
                                        <Link to="#" className="botao-icon-configs">
                                            <i className="fas fa-cog" />
                                        </Link>
                                    </Dropdown>
                                )
                            });
                        });

                        this.setState({
                            alunos,
                            loading_table: false
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    };

    // MONTA O MENU DE OPÇÕES POR CADA CURSO/LINHA
    montarMenu = (inscricaoId, aluno) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={() => this.setState({ inscricaoId, drawer_detalhe_visible: true })}>
                        Editar
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={() => this.removerInscricaoUtilizador(inscricaoId)}>
                        Anular
                    </Link>
                </Menu.Item>
                {/*<Menu.Item>
                    <Link to="#" onClick={() => {
                        this.setState({ visibleDrawerEnviarMsg: true, utilizador: JSON.stringify({ id_inscricao: inscricaoId, foto: aluno.foto, nome: aluno.nome_completo }) })
                    }}>
                        Mensagem
                    </Link>
                </Menu.Item>*/}
            </Menu>
        );
    };

    fecharDrawer = importar_visible => {
        this.setState({
            importar_visible,
            ativo: true
        });
    };

    atualizarListagem = () => {
        //this.total();
        this.listar();
    };

    fecharDrawerInscricao = () => {
        this.setState({
            inscricao_visible: false
        });
        //this.total();
        this.listar();
    };

    fecharDrawerInscrever = () => {
        this.setState({
            visibleDrawerInscrever: false
        });
        //this.total();
        this.listar();
    };

    pesquisarUtilizador = value => {
        if (value) {
            this.setState(
                {
                    loading_pesquisa: true
                },
                () => {
                    axios({
                        method: "get",
                        url: "/api/gestao-curso-inscricoes/pesquisar-utilizador-curso",
                        params: {
                            pesquisa: value,
                            cursoid: localStorage.getItem("codigo_curso"),
                            organismoId: localStorage.getItem("escola_id")
                        }
                    })
                        .then(response => {
                            this.setState({
                                pesquisar_utilizadores: response.data,
                                loading_pesquisa: false
                            });
                        })
                        .catch(() => { });
                }
            );
        }
        else
            this.setState({
                pesquisar_utilizadores: []
            });
    };

    inscreverUtilizador = (event, utilizadorId) => {
        event.preventDefault();

            axios({
                method: "post",
                url: "/api/gestao-curso-inscricoes/inscrever-utilizador",
                params: {
                    cursoId: localStorage.getItem("codigo_curso"),
                    utilizadorId
                }
            })
                .then(() => {
                    var utilizadores = this.state.pesquisar_utilizadores;
                    const utilizadorIndex = utilizadores.findIndex(x => x.id === utilizadorId);
                    const pesquisar_utilizadores = utilizadores;
                    pesquisar_utilizadores[utilizadorIndex].inscrito = true;

                    this.setState(
                        {
                            pesquisar_utilizadores
                        },
                        () => {
                            openNotificationWithIcon("success", "Sucesso", "Utilizador inscrito!");
                        }
                    );
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", error.response.data);
                });
    };

    removerInscricaoUtilizador = id => {
        confirm({
            title: "Pretende anular esta inscrição?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                //if (localStorage.getItem("nome_curso_pai"))
                //    axios({
                //        method: "delete",
                //        url: "/api/gestao-curso-inscricoes/remover-disciplina-inscricao",
                //        params: {
                //            id
                //        }
                //    })
                //        .then(() => {
                //            var alunos = this.state.alunos.filter(x => x.key !== id);

                //            this.setState({
                //                alunos
                //            });

                //            openNotificationWithIcon("success", "Sucesso", "Inscrição anulada!");
                //        })
                //        .catch(error => {
                //            openNotificationWithIcon("error", "Erro", "Não foi possível anular a inscrição");
                //        });
                //else
                    axios({
                        method: "delete",
                        url: "/api/gestao-curso-inscricoes/remover-inscricao",
                        params: {
                            id
                        }
                    })
                        .then(() => {
                            var alunos = this.state.alunos.filter(x => x.key !== id);

                            this.setState({
                                alunos
                            });

                            openNotificationWithIcon("success", "Sucesso", "Inscrição anulada!");
                        })
                        .catch(error => {
                            openNotificationWithIcon("error", "Erro", "Não foi possível anular a inscrição");
                        });
            }
        });
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoFicheiro = formato => {
        var formatos = ["pdf"];
        var valido = false;

        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) valido = true;
        }

        return valido;
    };

    render() {
        const { Search } = Input;

        const {
            //TABELA
            loading_table,
            alunos,
            //PAGINATION
            //pagination,
            //REDIRECT
            redirect,
            redirectLink,
            //CHECKBOX'S DA TABELA
            loading,
            //DRAWER IMPORTAR
            importar_visible,
            //DRAWER DETALHE INSCRIÇÃO
            inscricaoId,
            drawer_detalhe_visible,
            //DRAWER INSCREVER
            loading_pesquisa,
            pesquisar_utilizadores,
            ////DRAWER ENVIAR MENSAGENS
            //utilizador,
            //visibleDrawerEnviarMsg
        } = this.state;

        const columns = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-nome"
            },
            {
                title: "Código",
                dataIndex: "nr_aluno"
            },
            {
                title: "E-mail",
                dataIndex: "email"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-50"
            }
        ];

        const columnsMobile = [
            {
                title: "Nome",
                dataIndex: "nome",
                className: "td-nome"
            },
            {
                title: "",
                dataIndex: "opcoes",
                className: "td-opcoes"
            }
        ];

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Inscrições" />
                <div className="container container-body">
                    <div className="bloco-cursos bloco-cursos-inscricoes">
                        <div className="bloco-tabela">
                            <div className="bloco-flex" style={{ marginBottom: 20 }}>
                                {localStorage.getItem("nome_curso_pai") ? (
                                    <div>
                                        <h3>
                                            {localStorage.getItem("cod_curso_pai")} - {localStorage.getItem("nome_curso_pai")}
                                        </h3>
                                        <span>
                                            {localStorage.getItem("cod_subcurso")} - {localStorage.getItem("nome_subcurso")}
                                        </span>
                                        <span className="escola-nome">
                                            {localStorage.getItem("escola_nome")}
                                        </span>
                                    </div>
                                ) : (
                                        <div>
                                            <h3 className="tabela_titulo">
                                                {localStorage.getItem("cod_curso")} - {localStorage.getItem("nome_curso")}
                                            </h3>
                                            <span className="escola-nome">
                                                {localStorage.getItem("escola_nome")}
                                            </span>
                                        </div>
                                )}
                                <Link
                                    to={this.context.escolas_entidade ? "/gestao-cursos-organismos" : "/gestao-cursos"}
                                    className="botao-principal"
                                    onClick={() => { localStorage.removeItem("escola_nome"); localStorage.removeItem("escola_id"); }}
                                >
                                    Voltar
                                </Link>
                            </div>
                            <div className="filtros filtros-inscricoes">
                                <div className="bloco-esquerda">
                                    <BotaoProcurar
                                        placeholder="Coloque o nome a pesquisar..."
                                        tem_placeholder
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                    />
                                </div>
                                <div className="bloco-direita">
                                    <button className="botao-principal" onClick={this.inscrever}>
                                        Inscrever
                                    </button>
                                    <button
                                        className="botao-secundario"
                                        onClick={() => this.setState({ importar_visible: true })}
                                        style={{ marginLeft: 20 }}
                                    >
                                        Importar
                                    </button>
                                </div>
                            </div>
                            <Table
                                id="tabela-inscricoes"
                                className="tabela-inscricoes-desktop"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columns}
                                dataSource={alunos}
                                pagination={false}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                            <Table
                                id="tabela-inscricoes"
                                className="tabela-inscricoes-mobile"
                                loading={{
                                    spinning: loading_table,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                                columns={columnsMobile}
                                dataSource={alunos}
                                pagination={false}
                                locale={{ emptyText: "Não existem registos!" }}
                            />
                        </div>
                    </div>
                </div>
                <DrawerImportar
                    visible={importar_visible}
                    cursoid={localStorage.getItem("codigo_curso")}
                    onClose={() => this.setState({ importar_visible: false })}
                    fecharDrawer={this.fecharDrawer}
                    atualizarListagem={this.atualizarListagem}
                />
                <DrawerDetalhe
                    visible={drawer_detalhe_visible}
                    onClose={() => this.setState({ drawer_detalhe_visible: false })}
                    onCloseUpdate={() => { this.setState({ drawer_detalhe_visible: false }); this.listar(); }}
                    inscricaoId={inscricaoId}
                />
                <Drawer
                    className="drawer-inscrever"
                    title="Inscrições"
                    width={720}
                    onClose={this.fecharDrawerInscrever}
                    visible={this.state.visibleDrawerInscrever}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-drawer-associar">
                        <div className="pesquisar">
                            <Search
                                placeholder="Coloque o nome, código ou e-mail a pesquisar..."
                                onChange={e => this.setState({ pesquisaUtilizadores: e.target.value })}
                                onSearch={this.pesquisarUtilizador}
                                style={{ width: 400 }}
                                allowClear={true}
                                value={this.state.pesquisaUtilizadores}
                            />
                        </div>
                        <div className="listagem" style={{ marginTop: 30 }}>
                            {loading_pesquisa ? (
                                <div className="loading-data">
                                    <div className="loading" />
                                </div>
                            ) : (
                                pesquisar_utilizadores.map((utilizador, index) => (
                                    <Card key={index}>
                                        <div className="bloco-info-card-head">
                                            <div className="container-img">
                                                <img src={utilizador.foto ? utilizador.foto : noimage} alt="Foto" />
                                            </div>
                                            <span className="nome">{utilizador.nome_completo}</span>
                                        </div>
                                        <p>
                                            <strong>Código:</strong> {utilizador.nr_aluno} {utilizador.pendente ? <span className="info-estado-pendente">Registo Pendente</span> : ""}
                                        </p>
                                        <p>
                                            <strong>Email:</strong> {utilizador.email}
                                        </p>
                                        {utilizador.contacto && (
                                            <p>
                                                <strong>Contacto:</strong> {utilizador.contacto}
                                            </p>
                                        )}
                                        {utilizador.organismo && this.context.escolas_entidade && (
                                            <p>
                                                <strong>Organismo:</strong> {utilizador.organismo}
                                            </p>
                                        )}
                                        <button className="botao-principal" disabled={utilizador.inscrito} onClick={e => this.inscreverUtilizador(e, utilizador.id)}>
                                            Inscrever
                                        </button>
                                    </Card>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="ant-drawer-footer">
                        <button
                            className="botao-secundario"
                            onClick={this.fecharDrawerInscrever}
                            style={{ marginRight: 20, display: "inline-block" }}
                        >
                            Voltar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}
const FormGestaoCursosInscricoes = Form.create({ name: "cursos" })(GestaoCursosInscricoes);

export default FormGestaoCursosInscricoes;
