import React, { Component } from "react";
import { notification, Drawer, Form, Input, Icon, DatePicker, Select, Tooltip } from "antd";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerAlterarSenha extends Component {
    state = {
        new_password: "",
        //LOADING
        iconLoading: false
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });
                axios({
                    method: "post",
                    url: "/api/gestao-alterar-password/alterar",
                    params: {
                        newPassword: this.state.new_password,
                        utilizadorId: this.props.utilizadorid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Senha alterada!");
                        this.setState({ iconLoading: false });
                        this.props.onClose();
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });

                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue("nova-password")) {
            callback("Senhas não coincidentes");
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(["confirm"], { force: true });
        }
        callback();
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            this.setState({
                new_password: "",
                //LOADING
                iconLoading: false
            })
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { iconLoading } = this.state;
        const { visible, nome_completo, onClose } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos"
                    title={"Alterar Senha de " + nome_completo}
                    width={720}
                    onClose={onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-add-curso" layout="horizontal">
                            <Form.Item label={<span>Nova senha</span>}>
                                {getFieldDecorator("nova-password", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                            whitespace: true
                                        },
                                        {
                                            pattern: "^(?=.*[A-Z])(?=.*[0-9])(?=.{6,})",
                                            message: (
                                                <p>
                                                    A senha deve conter pelo menos uma uma letra maiúscula, um número e um mínimo de 6
                                                    caracteres
                                                </p>
                                            )
                                        },
                                        {
                                            validator: this.validateToNextPassword
                                        }
                                    ]
                                })(<Input.Password type="password" name="new_password" onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label={<span>Confirmar senha</span>}>
                                {getFieldDecorator("confirmar-password", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                            whitespace: true
                                        },
                                        {
                                            validator: this.compareToFirstPassword
                                        }
                                    ]
                                })(<Input.Password type="password" />)}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.alterar}>
                            {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerAlterarSenha = Form.create({ name: "drawer-alterar-senha" })(DrawerAlterarSenha);

export default FormDrawerAlterarSenha;
