import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Rate, Skeleton, notification } from "antd";
import { GeralContext } from "./ContextProvider";
import { GlobalContext } from "../GlobalState";
import noimage from "../images/noimage.png";
import axios from "axios";
import LazyLoad from "react-lazy-load";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Header extends Component {
    static contextType = GeralContext;
    state = {
        //DADOS
        cursoId: 0,
        imagem: "",
        codigo: "",
        nome: "",
        edicao: "",
        ano: "",
        turma: "",
        objectivo: "",
        avaliacao: "",
        totalAvaliacao: "",
        coordenador: "",
        dt_curso: "",
        linguas: [],
        linguasLegendas: [],
        formacao: "",
        progress: 0,
        classificacao: "",
        concluido: "",
        inscricao: false,
        aula: "",
        retomar: "",
        fechado: false,
        escola: "",
        favorito: false,
        //DRAWER
        visible: false,
        loading: true,
        //INSCRICAO
        inscrito: false,
        preco: "",
        subscricao: false,
        tipologia: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        loaded: false
    };

    //CHAMADA ANTES DO RENDER
    UNSAFE_componentWillMount() {
        axios.defaults.timeout = 60 * 10 * 1000;
        var cursoId = localStorage.getItem("codigo_curso");

        this.setState({
            cursoId
        });
        this.carregarInfo(cursoId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.curso !== prevProps.match.params.curso) {
            var cursoId = localStorage.getItem("codigo_curso");

            this.setState({
                cursoId
            });
            this.carregarInfo(cursoId);
        }
    }

    //CARREGA A INFORMAÇÃO DO CURSO
    carregarInfo = cursoid => {
        axios({
            method: "get",
            url: "/api/gerir-curso/carregar-header-gerir-curso",
            params: {
                cursoid: cursoid
            }
        })
            .then(response => {
                this.context.atualizar({
                    fechado: response.data.fechado,
                    inscricao: response.data.inscricao,
                    subcursos: response.data.subcursos,
                    dt_fechado: response.data.dt_fechado,
                    dt_inicio: response.data.dt_inicio,
                    dt_fim: response.data.dt_fim,
                    habilitar_competencias: response.data.habilitar_competencias,
                    habilitar_gamificacao: response.data.habilitar_gamificacao,
                    diarioCurso: response.data.diario,
                    tipoDiarioCurso: response.data.tipo_diario,
                    professorPolivalente: response.data.professor_polivalente
                });

                if (response.data.inscricao)
                    this.context.atualizarInscricao(response.data.inscrito);

                this.setState({
                    imagem: response.data.imagem,
                    codigo: response.data.codigo,
                    nome: response.data.nome,
                    edicao: response.data.edicao,
                    ano: response.data.ano,
                    turma: response.data.turma,
                    objectivo: response.data.objectivo,
                    avaliacao: response.data.mediaAvaliacoes,
                    totalAvaliacao: response.data.totalAvaliacoes,
                    favorito: response.data.favorito,
                    fechado: response.data.fechado,
                    escola: response.data.escola,
                    preco: response.data.preco,
                    subscricao: response.data.subscricao,
                    tipologia: response.data.tipologia,
                    loading: false
                });

                localStorage.setItem("curso_fechado", response.data.fechado);
                localStorage.setItem("habilitar_competencias", response.data.habilitar_competencias);
                localStorage.setItem("habilitar_gamificacao", response.data.habilitar_gamificacao);
                localStorage.setItem("nome_curso", response.data.nome);
                localStorage.setItem("nome_curso_pai", "");
                localStorage.setItem("tipoDiario", response.data.tipo_diario);
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    render() {
        const {
            //DADOS
            codigo,
            nome,
            edicao,
            ano,
            turma,
            objectivo,
            avaliacao,
            totalAvaliacao,
            fechado,
            escola,
            preco,
            subscricao,
            tipologia,
            //REDIRECT
            redirect,
            redirectLink,
            loaded
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <GlobalContext.Consumer>
                {GlobalContext => {
                    if (!loaded && fechado) {
                        GlobalContext.atualizarState({ fechado });
                        this.setState({
                            loaded: true
                        })
                    }

                    return (<div className="header-cursos">
                        <LazyLoad offsetVertical={335}>
                            <img src={require("../images/wave_hero_user.svg")} className="img-hero" />
                        </LazyLoad>
                        <div className="container">
                            <Skeleton active={this.state.loading} avatar loading={this.state.loading}>
                                <div className="lado-esquerdo">
                                    <div className="titulo-favorito">
                                        <div className="titulo-curso">
                                            <h2 title={nome}>{codigo} - {nome}</h2>
                                        </div>
                                        <div className="marcar-favorito">
                                            <Link to="#" >
                                                <i className="far fa-heart" />
                                            </Link>
                                        </div>
                                    </div>
                                    <span className="descritivo">{objectivo}</span>
                                    <div className="bloco-rating-info bloco-rating-info-desktop">
                                        <div className="bloco-esquerda">
                                            {totalAvaliacao ?
                                                <div className="rating esquerda-item">
                                                    <Rate disabled value={avaliacao} />
                                                    <span className="ant-rate-text">({totalAvaliacao})</span>
                                                </div> : null}
                                            {fechado &&
                                                <div className="bloco-fechado esquerda-item">
                                                <span className="curso-fechado" onClick={() => openNotificationWithIcon("warning", "Atenção", "Uma vez que curso está fechado, não é possível realizar avaliações, questionários e trabalhos")}>fechado</span>
                                                </div>}
                                            <div className="bloco-ano-turma esquerda-item" title={ano && turma ? "Edição Ano e Turma" : ano ? "Edição e Ano" : turma ? "Edição e Turma" : "Edição"}>
                                                <span className="ano-turma">{edicao} {ano ? ano : ""} {turma ? turma : ""}</span>
                                            </div>
                                            {(escola && GlobalContext.escolas_entidade) &&
                                                <div className="bloco-escola esquerda-item">
                                                    <span className="escola">{escola}</span>
                                                </div>}
                                        </div>

                                        {((preco && GlobalContext.vendaCursos) || (GlobalContext.subscricao_entidade && subscricao)) && GlobalContext.monetizacao_entidade || preco ?
                                            <div className="bloco-direita">
                                                {(preco && GlobalContext.vendaCursos) || (preco && tipologia === "MISTO") ?
                                                    <span className="tag-valor-curso">
                                                        {GlobalContext.moeda_entidade} {preco}
                                                    </span>
                                                    : null}
                                                {(GlobalContext.subscricao_entidade && subscricao) ?
                                                    <span className="tag-valor-subscricao">
                                                        Subscrição
                                                        </span>
                                                    : null}
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="bloco-rating-info bloco-rating-info-mobile">
                                        {totalAvaliacao ?
                                        <div className="bloco-info-1">
                                            <div className="rating esquerda-item">
                                                <Rate disabled value={avaliacao} />
                                                <span className="ant-rate-text">({totalAvaliacao})</span>
                                            </div>
                                            </div> : null}
                                        <div className="bloco-info-2">
                                            {fechado &&
                                                <div className="bloco-fechado">
                                                <span className="curso-fechado" onClick={() => openNotificationWithIcon("warning", "Atenção", "Uma vez que curso está fechado, não é possível realizar avaliações, questionários e trabalhos")}>fechado</span>
                                                </div>}
                                            <div className="bloco-ano-turma" title={ano && turma ? "Edição Ano e Turma" : ano ? "Edição e Ano" : turma ? "Edição e Turma" : "Edição"}>
                                                <span className="ano-turma">{edicao} {ano ? ano : ""} {turma ? turma : ""}</span>
                                            </div>
                                            {(escola && GlobalContext.escolas_entidade) &&
                                                <div className="bloco-escola">
                                                    <span className="escola">{escola}</span>
                                                </div>}
                                        </div>
                                        {((preco && GlobalContext.vendaCursos) || (GlobalContext.subscricao_entidade && subscricao)) && GlobalContext.monetizacao_entidade || preco ?
                                            <div className="bloco-info-3">
                                                {(preco && GlobalContext.vendaCursos) || (preco && tipologia === "MISTO") ?
                                                    <span className="tag-valor-curso">
                                                        {GlobalContext.moeda_entidade} {preco}
                                                    </span>
                                                    : null}
                                                {(GlobalContext.subscricao_entidade && subscricao) ?
                                                    <span className="tag-valor-subscricao">
                                                        Subscrição
                                                        </span>
                                                    : null}
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="lado-direito">
                                    <div className="bloco-imagem">
                                        <div className="container-img">
                                            <img src={this.state.imagem ? this.state.imagem : noimage} alt="Imagem do Curso" />
                                        </div>
                                    </div>
                                </div>
                            </Skeleton>
                        </div>
                    </div>)
                }}
            </GlobalContext.Consumer>
        );
    }
}

export default withRouter(Header);
