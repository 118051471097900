import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../User/Header";
import TabelaFormador from "./Tabelas/Formador";
import TabelaAluno from "./Tabelas/Aluno";
import TabelaSessoes from "./Tabelas/Sessoes";
import TabelaHistorico from "./Tabelas/Historico";
import axios from "axios";
import { GlobalContext } from "../../GlobalState";
import { DatePicker, Icon, Modal, notification, Tooltip, Alert } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

export class Main extends Component {
    static contextType = GlobalContext;
    state = {
        dt_inicio: moment().subtract(1, "month"),
        dt_termino: moment(),
        filtroAtivo: "",
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //ATUALIZAR
        atualizar: false
    };

    UNSAFE_componentWillMount() {

    }

    componentDidUpdate() {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });
    }

    onChangeDataInicio = dt_inicio => {
        this.setState({
            dt_inicio
        });
    };

    onChangeDataTermino = dt_termino => {
        this.setState({
            dt_termino
        });
    };

    ativarFiltro(filtroAtivo) {
        this.setState({ filtroAtivo });
    }

    exportarFormadorCSV = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/exportar/formador",
                    params: {
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarFormadorDet = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/exportar/formador-det",
                    params: {
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarAlunoCSV = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/exportar/aluno",
                    params: {
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarAlunoDet = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/exportar/aluno-det",
                    params: {
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarSessoesCSV = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/exportar/aluno-sessoes",
                    params: {
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarSessoesDet = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/exportar/aluno-sessoes-det",
                    params: {
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    exportarHistoricoDet = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/exportar/aluno-historico",
                    params: {
                        dtini: this.state.dt_inicio.format("YYYY/MM/DD"),
                        dtfim: this.state.dt_termino.format("YYYY/MM/DD")
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    };

    paraAtualizar = () => {
        this.setState({
            atualizar: false
        });
    };

    pesquisar = () => {
        var diff = this.state.dt_termino.diff(this.state.dt_inicio, "months");
        var diffDays = this.state.dt_termino.diff(this.state.dt_inicio, "days");

        if (diff >= 0 && diff <= 5 && diffDays >= 0) this.setState({ atualizar: true });
        else if (diff < 0 || diffDays < 0)
            openNotificationWithIcon("warning", "Atenção", "A data de Inicio deve ser inferior a data de Término");
        else openNotificationWithIcon("warning", "Atenção", "O intervalo das duas datas não deve ser superior a 6 meses");
    };

    render() {
        const {
            dt_inicio,
            dt_termino,
            filtroAtivo,
            //REDIRECT
            redirect,
            redirectLink,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //ATUALIZAR
            atualizar
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const opcoesMenu = () => {
            if (this.context.role === "FORMADOR") {
                return ["Professor", "Aluno", "Sessões", "Histórico"]
            }
            else {
                return ["Aluno", "Sessões", "Histórico"]
            }
        }

        const montarTitulo = () => {
            if (filtroAtivo === "Professor" || !filtroAtivo && opcoesMenu().length === 4)
                return "Estatisticas enquanto professor";
            else if (filtroAtivo === "Aluno" || !filtroAtivo && opcoesMenu().length === 3)
                return "Estatisticas enquanto aluno";
            else if (filtroAtivo === "Sessões")
                return "Sessões na plataforma";
            else
                return "Histórico de navegação";
        }

        return (
            <>
                <Header titulo={montarTitulo()} />
                <div className="administracao-estatisticas">
                    <div className="container container-body">
                        <div className="bloco-grid" style={{ marginTop: 0 }}>
                            <div className="filtros">
                                <ul>
                                    {opcoesMenu().map((item, index) => (
                                        <li
                                            key={index}
                                            className={filtroAtivo === item || !filtroAtivo && index === 0 ? "ativo" : ""}
                                            onClick={() => this.ativarFiltro(item)}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="bloco-cursos-estatisticas" style={{ maxWidth: 920 }}>
                                <div className="combo-datas combo-datas-desktop" style={{ padding: "30px 30px 15px" }}>
                                    <div className="inicio">
                                        <label>Inicio:</label>
                                        <DatePicker
                                            value={dt_inicio}
                                            placeholder="Data Inicio"
                                            format="DD/MM/YYYY"
                                            onChange={this.onChangeDataInicio}
                                        />
                                    </div>
                                    <div className="termino">
                                        <label>Término:</label>
                                        <DatePicker
                                            value={dt_termino}
                                            placeholder="Data Término"
                                            format="DD/MM/YYYY"
                                            onChange={this.onChangeDataTermino}
                                        />
                                    </div>
                                    <div className="icon-informacao">
                                        <Tooltip className="info-icon" title="Intervalo máximo de 6 meses">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </div>
                                    <button className="procurar" onClick={this.pesquisar}>
                                        <Icon type="search" />
                                    </button>
                                </div>
                                <div className="combo-datas combo-datas-mobile" style={{ padding: "30px 30px 15px" }}>
                                    <Alert message="Intervalo máximo de 6 meses" type="info" showIcon />
                                    <div className="bloco-filtro-datas">
                                        <div className="inicio">
                                            <label>Inicio:</label>
                                            <DatePicker
                                                value={dt_inicio}
                                                placeholder="Data Inicio"
                                                format="DD/MM/YYYY"
                                                onChange={this.onChangeDataInicio}
                                            />
                                        </div>
                                        <div className="termino">
                                            <label>Término:</label>
                                            <DatePicker
                                                value={dt_termino}
                                                placeholder="Data Término"
                                                format="DD/MM/YYYY"
                                                onChange={this.onChangeDataTermino}
                                            />
                                        </div>
                                        <button className="procurar" onClick={this.pesquisar}>
                                            <Icon type="search" />
                                        </button>
                                    </div>
                                </div>
                                {(filtroAtivo === "Instrutor" || !filtroAtivo && opcoesMenu().length === 4) && ( // QUANDO FORMADOR
                                        <>
                                            <TabelaFormador
                                                dt_inicio={dt_inicio}
                                                dt_termino={dt_termino}
                                                atualizar={atualizar}
                                                paraAtualizar={this.paraAtualizar}
                                            />
                                            <div className="botoes-exportar">
                                                <Link to="#" onClick={this.exportarFormadorCSV}>
                                                    <Icon type="export" />
                                                    Exportar resumo para csv
                                                </Link>
                                                <Link to="#" onClick={this.exportarFormadorDet}>
                                                    <Icon type="export" />
                                                    Exportar detalhe para csv
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                {(filtroAtivo === "Aluno" || !filtroAtivo && opcoesMenu().length === 3) && ( // QUANDO ALUNO
                                    <>
                                        <TabelaAluno
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                        <div className="botoes-exportar">
                                            <Link to="#" onClick={this.exportarAlunoCSV}>
                                                <Icon type="export" />
                                                Exportar resumo para csv
                                            </Link>
                                            <Link to="#" onClick={this.exportarAlunoDet}>
                                                <Icon type="export" />
                                                Exportar detalhe para csv
                                            </Link>
                                        </div>
                                    </>
                                )}
                                {(filtroAtivo === "Sessões") && ( // QUANDO SESSÕES
                                    <>
                                        <TabelaSessoes
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                        <div className="botoes-exportar">
                                            <Link to="#" onClick={this.exportarSessoesCSV}>
                                                <Icon type="export" />
                                                Exportar resumo para csv
                                            </Link>
                                            <Link to="#" onClick={this.exportarSessoesDet}>
                                                <Icon type="export" />
                                                Exportar detalhe para csv
                                            </Link>
                                        </div>
                                    </>
                                )}
                                {(filtroAtivo === "Histórico") && ( // QUANDO HISTORICO
                                    <>
                                        <TabelaHistorico
                                            dt_inicio={dt_inicio}
                                            dt_termino={dt_termino}
                                            atualizar={atualizar}
                                            paraAtualizar={this.paraAtualizar}
                                        />
                                        <div className="botoes-exportar">
                                            <Link to="#" onClick={this.exportarHistoricoDet}>
                                                <Icon type="export" />
                                                Exportar detalhe para csv
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                    <p className="texto">Dados exportados com sucesso!</p>
                                    <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}

export default Main;
