import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, Select } from "antd";

const Option = Select.Option;

class TabelaProximas extends Component {
    state = {
        filteredInfo: null,
        sortedInfo: null
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter
        });
    };

    render() {
        let { sortedInfo, filteredInfo } = this.state;
        const { videoconferencias } = this.props;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: "Inicio",
                dataIndex: "inicio",
                key: "inicio"
            },
            {
                title: "Assunto",
                dataIndex: "assunto",
                key: "assunto"
            },
            {
                title: "Agendada por",
                dataIndex: "agendada",
                key: "agendada"
            },
            {
                title: "",
                dataIndex: "controlos",
                key: "controlos"
            }
        ];

        return (
            <>
                <Table
                    columns={columns}
                    // dataSource={this.props.tickets}
                    dataSource={videoconferencias}
                    //pagination={this.props.pagination}
                    pagination={false}
                    loading={this.props.loading}
                    onChange={this.props.handleTableChange}
                    locale={{ emptyText: "Não existem registos!" }}
                />
                {/*{videoconferencias.length > 0 && (
                    <div className="num_elementos">
                        Mostrar{" "}
                        <Select defaultValue="10" onChange={this.props.handleChangeSelect}>
                            <Option value="10">10</Option>
                            <Option value="25">25</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                        </Select>
                    </div>
                )}*/}
            </>
        );
    }
}

export default withRouter(TabelaProximas);
