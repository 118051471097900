import React, {useState, useEffect} from "react";
import { Table } from 'antd';
import ReactDragListView from "react-drag-listview";
import moment from 'moment';
import axios from "axios";
import Controlos from "./Controlos/Controlos";

const Relatorio = ({ menuOptionSelected }) => {
    const [search, setSearch] = useState("");
    const [filterSchool, setFilterSchool] = useState(undefined);
    const [filterEdition, setFilterEdition] = useState(undefined);
    const [filterCourse, setFilterCourse] = useState(undefined);
    const [filterPercentage, setFilterPercentage] = useState(undefined);
    const [activeFilters, setActiveFilters] = useState([]);
    const [cols, setCols] = useState([]);
    const [data, setData] = useState([]);
    const [dataBackUp, setDataBackUp] = useState([]);
    const [sorter, setSorter] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const columns = [...cols];
            const item = columns.splice(fromIndex, 1)[0];
            columns.splice(toIndex, 0, item);
            setCols(columns)
        },
        nodeSelector: "th"
    };

    const loadList = () => {
        if (search || filterSchool || filterEdition || filterCourse || filterPercentage) {
            setLoading(true);
            axios({
                method: "get",
                url: "/api/relatorios/lista-frequencia-geral",
                params: {
                    search,
                    columns: JSON.stringify(cols.map(col => col.dataIndex)),
                    filterSchool,
                    filterEdition,
                    filterCourse,
                    filterPercentage
                }
            })
                .then(response => {
                    setData(response.data);
                    setDataBackUp(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error.response.status === 401)
                        this.setState({
                            redirect: true,
                            redirectLink: "/login"
                        });

                    setLoading(false);
                });
        }
        else {
            setData([]);
        }
    }

    useEffect(() => {
        setFilterSchool(undefined);
        setFilterEdition(undefined);
        setFilterCourse(undefined);
        setFilterPercentage(undefined);
        setCols([
            {
                title: "Número",
                dataIndex: "number",
                sorter: (a, b) => a.number.localeCompare(b.number),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Nome",
                dataIndex: "name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Presenças",
                dataIndex: "presences",
                sorter: (a, b) => a.presences - b.presences,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Faltas",
                dataIndex: "absence",
                sorter: (a, b) => a.absence - b.absence,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Média",
                dataIndex: "average",
                sorter: (a, b) => a.average - b.average,
                sortDirections: ['descend', 'ascend'],
                render: average => average ? <span className={average >= 75 ? "percentage-above" : "percentage-below"}>{average.toFixed(2)}%</span> : "",
            },
        ])

        setData([]);

    }, [menuOptionSelected]);

    useEffect(() => {
        loadList();

    }, [activeFilters]);

    //const HandleKeyPress = (event) => {
        //var pesquisa = event.target.value;
        //if (event.key === "Enter") {
        //    var data = dataBackUp.filter(x =>
        //        cols.find(x => x.dataIndex === "number") && x.number.toLowerCase().includes(pesquisa.toLowerCase())
        //        || cols.find(x => x.dataIndex === "name") && x.name.toLowerCase().includes(pesquisa.toLowerCase())
        //        || (cols.find(x => x.dataIndex === "school") && x.school) && x.school.toLowerCase().includes(pesquisa.toLowerCase())
        //        /*|| cols.find(x => x.dataIndex === "presences") && x.presences.toLowerCase().includes(pesquisa.toLowerCase())*/
        //        || cols.find(x => x.dataIndex === "absence") && x.absence.toLowerCase().includes(pesquisa.toLowerCase())
        //        || cols.find(x => x.dataIndex === "average") && x.average.toLowerCase().includes(pesquisa.toLowerCase())
        //    )
        //    setData(data)
        //}
    //}

    const HandleKeyPress = (event) => {
        if (event.key === "Enter") {
            setActiveFilters([...activeFilters, "SEARCH"])
            setSearch(event.target.value);
        }
    }

    const resetSearch = () => {
        setActiveFilters(activeFilters.filter(x => x !== "SEARCH"))
        setSearch("");
    }

    const handleChangeSchool = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "SCHOOL"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "SCHOOL"))
        }
        setFilterSchool(filter);
    }

    const handleChangeEdition = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "EDITION"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "EDITION"))
        }
        setFilterEdition(filter);
    }

    const handleChangeCourse = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "COURSE"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "COURSE"))
        }
        setFilterCourse(filter);
    }

    const handleChangePercentage = (filter) => {
        if (filter)
            setActiveFilters([...activeFilters, "PERCENTAGE"])
        else {
            setActiveFilters(activeFilters.filter(x => x !== "PERCENTAGE"))
        }
        setFilterPercentage(filter);
    }

    const calculateAverage = (data) => {
        if (!data.length)
            return 0;

        /*var allAverages = data.map(item => item.average);*/

        var newData = data.filter(item => item.presences > 0 || item.absence > 0);
        var allAverages = newData.map(item => item.average);

        return (allAverages.reduce((a, b) => a + b, 0) / newData.length).toFixed(2);
    }

    const globalPercentage = calculateAverage(data);

    return (
        <>
            <div className="report-info">
                <Controlos
                    menuItemKey={menuOptionSelected?.menuItemKey}
                    menuItemName={menuOptionSelected?.menuItemName}
                    HandleKeyPress={(event) => HandleKeyPress(event)}
                    resetSearch={resetSearch}
                    filterSchool={filterSchool}
                    setFilterSchool={(filter) => handleChangeSchool(filter)}
                    filterEdition={filterEdition}
                    setFilterEdition={(filter) => handleChangeEdition(filter)}
                    filterCourse={filterCourse}
                    setFilterCourse={(filter) => handleChangeCourse(filter)}
                    filterPercentage={filterPercentage}
                    setFilterPercentage={(filter) => handleChangePercentage(filter)}
                    setCols={(columns) => setCols(columns)}
                    sorter={sorter}
                    menuOptionSelected={menuOptionSelected}
                    disabledButtons={!search && !filterSchool && !filterEdition && !filterCourse && !filterPercentage}
                    columnsPositions={cols.map(col => col.dataIndex)}
                    cols={cols}
                    data={data}
                    pageTitle={menuOptionSelected?.menuItemName}
                />
                <div className="page-container">
                    <div className="page-header">
                        <h1 className="page-title">Relatório - Frequência {menuOptionSelected?.menuItemName}</h1>
                        <h3 className="page-counters">
                            <span>
                                <strong>Contar:</strong>
                                <span>{data.length}</span>
                            </span>
                            <span>
                                <strong>Média:</strong>
                                <span className={globalPercentage >= 75 ? "percentage-above" : "percentage-below"}>{globalPercentage}%</span>
                            </span>
                        </h3>
                    </div>
                    <div className="page-body">
                        <ReactDragListView.DragColumn {...dragProps}>
                            <Table
                                columns={cols}
                                pagination={false}
                                dataSource={data}
                                onChange={(pagination, filters, sorter) => setSorter(sorter.order ? { order: sorter.order, key: sorter.columnKey } : undefined)}
                                loading={{
                                    spinning: loading,
                                    indicator: (
                                        <div className="loading-data-table">
                                            <div className="loading" />
                                        </div>
                                    )
                                }}
                            />
                        </ReactDragListView.DragColumn>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Relatorio;