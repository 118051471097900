import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../User/Header";
import { Select, Icon, notification, Modal } from "antd";
import ExcelTabelaNotasCrechePre from "../../GerirCursos/Diario/_Aux/ExcelTabelaNotasCreche";
import CardsNotasCrechePre from "../../GerirCursos/Diario/_Aux/CardsNotasCreche";
import noimage from "../../images/noimage.png";
import axios from "axios";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class NotasCreche extends Component {
    state = {
        utilizador: "",
        //EXPORTAR
        visibleExportar: false,
        btnDescarregar: false,
        ficheiroExportar: "",
        //TABELA
        periodos: [],
        avaliacoes: [],
        //LOADING
        iconLoading: false
    };

    UNSAFE_componentWillMount() {
        this.carregarUtilizadorInfo();
        this.listar();
    }

    carregarUtilizadorInfo = () => {
        axios({
            method: "get",
            url: "/api/colaboradores-consultar-notas/carregar-utilizador",
            params: {
                utilizadorId: JSON.parse(localStorage.getItem("sis_utilizador")).id
            }
        })
            .then(response => {
                this.setState({
                    utilizador: response.data
                })
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar");
            });
    }

    listar = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/colaboradores-consultar-notas/listar-notas-pre",
                params: {
                    cursoId: localStorage.getItem("codigo_curso"),
                    utilizadorId: JSON.parse(localStorage.getItem("sis_utilizador")).id
                }
            })
                .then(response => {
                    if (response.data.periodos.length > 0) {
                        this.setState({
                            periodos: response.data.periodos,
                            avaliacoes: response.data.avaliacoes,
                            iconLoading: false
                        })
                    }
                    else {
                        this.setState({
                            iconLoading: false
                        })
                        openNotificationWithIcon("warning", "Atenção", "Curso sem periodos registados!");
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a informação!");
                });
        })
    }

    exportar = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/colaboradores-consultar-notas/notas-pre-creche",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        utilizadorId: JSON.parse(localStorage.getItem("sis_utilizador")).id
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    exportarPdf = () => {
        this.setState(
            {
                visibleExportar: true,
                btnDescarregar: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/colaboradores-consultar-notas/notas-pre-creche-pdf",
                    params: {
                        cursoId: localStorage.getItem("codigo_curso"),
                        utilizadorId: JSON.parse(localStorage.getItem("sis_utilizador")).id
                    }
                })
                    .then(response => {
                        this.setState({
                            btnDescarregar: false,
                            ficheiroExportar: response.data
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                        this.setState({
                            visibleExportar: false
                        });
                    });
            }
        );
    }

    render() {
        const {
            utilizador,
            //EXPORTAR
            visibleExportar,
            btnDescarregar,
            ficheiroExportar,
            //TABELA
            periodos,
            avaliacoes,
            //LOADING
            iconLoading
        } = this.state;

        return (
            <>
                <Header titulo="Histórico" />
                <div className="container container-noticias conteiner-consultar container-body">
                    <div className="content">
                        <div className="bloco-cursos">
                            <div className="bloco-tabela">
                                <div className="header">
                                    <div className="utilizador-info">
                                        {utilizador ?
                                            <>
                                                <div className="container-img">
                                                    <img src={utilizador.foto ? utilizador.foto : noimage} />
                                                </div>
                                                <div className="info">
                                                    <span className="nome">
                                                        {utilizador.nome.split("_").length > 1 ? `${utilizador.nome.split("_")[0]} ${utilizador.nome.split("_")[utilizador.nome.split("_").length - 1]}` : utilizador.nome}
                                                    </span>
                                                    <span className="numero">{utilizador.numero}</span>
                                                </div>
                                            </>
                                            : null}
                                    </div>
                                    <div className="controlos">
                                        <Link to={`/gestao-utilizadores-sistema-informacao/consultar/${this.props.match.params.utilizador}`} className="botao-icon-voltar" title="Voltar">
                                            <Icon type="arrow-left" />
                                        </Link>
                                        <Link className="botao-icon-pdf" to="#" title="Descarregar" onClick={this.exportarPdf}>
                                            <Icon type="file-pdf" />
                                        </Link>
                                        <Link className="botao-icon-download" to="#" title="Descarregar" onClick={this.exportar}>
                                            <Icon type="download" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="bloco-notas-desktop">
                                    <ExcelTabelaNotasCrechePre
                                        periodos={periodos}
                                        avaliacoes={avaliacoes}
                                    />
                                </div>
                                <div className="bloco-notas-mobile">
                                    <CardsNotasCrechePre
                                        periodos={periodos}
                                        avaliacoes={avaliacoes}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
                <Modal
                    visible={visibleExportar}
                    okText={
                        <a href={ficheiroExportar} download onClick={() => this.setState({ visibleExportar: false })}>
                            Descarregar
                        </a>
                    }
                    onCancel={() => this.setState({ visibleExportar: false })}
                    cancelText="Cancelar"
                    okButtonProps={{ disabled: btnDescarregar }}
                    maskClosable={false}
                    className="exportar-csv"
                >
                    <div className="exportar-csv-bloco">
                        {btnDescarregar ? (
                            <>
                                <p>
                                    <Icon type="loading" />
                                </p>
                                <p className="texto">A gerar documento...</p>
                            </>
                        ) : (
                            <>
                                <p>
                                    <Icon type="check-circle" style={{ color: "#84bd00" }} />
                                </p>
                                <p className="texto">Dados exportados com sucesso!</p>
                                <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}
export default NotasCreche;
