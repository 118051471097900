import React from "react";
import noimage from "../../images/noimage.png";

const Info = props => {
    return (
        <div className="info-topo">
            <div className="info-principal">
                <span className="assunto">{props.assunto}</span>
                <div className="info-autor">
                    <div className="container-img">
                        <img src={props.autor.foto ? props.autor.foto : noimage} />
                    </div>
                    <div className="dados">
                        <div className="nome">
                            {props.autor.nome_completo}
                            <span className="codigo">{props.autor.numero}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Info;
