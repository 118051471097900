import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../GlobalState";

class ControlosTopo extends Component {
    static contextType = GlobalContext;
    render() {
        return (
            <div className="controlos">
                {this.props.controlos_responder_topo &&
                    <>
                        <div className="bloco1">
                            {!this.props.sem_resposta &&
                                <button
                                    className="botao-principal"
                                    onClick={this.props.responder_topo}
                                >
                                    Responder
                            </button>}
                        {this.context.role == "ADMIN" && (
                            <Link to="/administracao-suporte" className="botao-secundario" onClick={() => localStorage.setItem("voltou", true)}>
                                Voltar
                                </Link>
                            )}
                            {this.context.role == "ENTIDADE" && (
                            <Link to="/gestao-suporte" onClick={() => localStorage.setItem("voltou", true)}>
                                    <button className="botao-secundario">
                                        Voltar
                                </button>
                                </Link>
                            )}
                            {this.context.role !== "ADMIN" && this.context.role !== "ENTIDADE" && (
                            <Link to="/user-suporte" onClick={() => localStorage.setItem("voltou", true)}>
                                    <button className="botao-secundario">
                                        Voltar
                                </button>
                                </Link>
                            )}
                            <button
                                className="botao-excluir"
                                onClick={() => this.props.remover()}
                            >
                            Remover
                            </button>
                            {this.props.estado !== "Fechado" ? (
                                <button
                                    className="botao-secundario"
                                    onClick={this.props.fechar_topo}
                                >
                                    Fechar
                                </button>
                            ) : null}
                        </div>
                        <div className="bloco2">
                            <span className="codigo">#{this.props.codigo}</span>
                            <span className={"estado " + this.props.estado_cor}>{this.props.estado}</span>
                        </div>
                    </>}
            </div>
        );
    }
}

export default ControlosTopo;
