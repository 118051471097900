import React, { Component } from "react";
import { Drawer, Form, Input, DatePicker, TimePicker, Icon, notification, Radio, Row, Col, InputNumber, Select, Checkbox } from "antd";
import { CirclePicker } from 'react-color';
import DrawerPesquisar from "./DrawerPesquisar"
import noimage from "../../../images/noimage.png";
import { GeralContext } from "../../ContextProvider";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerAdicionar extends Component {
    static contextType = GeralContext;

    state = {
        titulo: "",
        dt_inicio: "",
        hr_inicio: "",
        hr_termino: "",
        cor_fundo: "",
        opcao: 1,
        //RECORRENCIA
        recorrente: false,
        recorrenciaIntervalo: 1,
        recorrencia: "SEMANA",
        recorrenciaFim: null,
        //LOADING
        iconLoading: false,
        //REPLICAR
        listaCursos: [],
        selecionados: [],
        indeterminate: false,
        todos: false,
        //VALIDAR CRIAR AULAS
        validateStatus: "",
        validateHelp: ""
    }

    componentDidMount() {
        this.listarCursos();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeDataInicio = dt_inicio => {
        //if (!this.state.dt_termino)
        //    this.setState({
        //        dt_inicio,
        //        dt_termino: dt_inicio
        //    });
        //else
        if (this.props.aulaId)
            this.setState({
                dt_inicio
            });
        else
            this.setState({
                dt_inicio
            }, () => dt_inicio ? this.props.atualizarPosicaoCalendário(dt_inicio.toDate()) : {});
    };

    handleChangeHoraInicio = hr_inicio => {
        this.setState({ hr_inicio });
    };

    handleChangeHoraTermino = hr_termino => {
        this.setState({ hr_termino });
    };

    handleChangeColorPickerFundo = (color, event) => {
        this.setState({
            cor_fundo: color.hex
        })
    }

    selecionarOpcaoHandler = event => {
        this.setState({
            opcao: event.target.value
        });
    };

    handlerChangeRadio = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handlerChangeRecorrenciaIntervalo = value => {
        this.setState({
            recorrenciaIntervalo: value
        });
    };

    criar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (this.context.professorPolivalente && !this.state.aulaId && this.state.listaCursos.length > 0 && this.state.selecionados.length === 0) {
                this.setState({
                    validateStatus: "error",
                    validateHelp: "Campo obrigatório"
                })
            }

            if (!err && (!this.context.professorPolivalente || this.context.professorPolivalente && !this.state.aulaId && this.state.listaCursos.length > 0 && this.state.selecionados.length > 0)) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_curso", localStorage.getItem("codigo_curso"));
                item.append("titulo", this.state.titulo);
                item.append("dt_inicio", this.state.dt_inicio.format("YYYY/MM/DD"));
                item.append("hr_inicio", this.state.hr_inicio.format("HH:mm"));
                item.append("hr_termino", this.state.hr_termino.format("HH:mm"));
                item.append("cor_fundo", this.state.cor_fundo);
                if (this.state.recorrente) {
                    item.append("recorrente", this.state.recorrente);
                    item.append("recorrenciaIntervalo", this.state.recorrenciaIntervalo);
                    item.append("recorrencia", this.state.recorrencia);
                    item.append("recorrenciaFim", moment(this.state.recorrenciaFim).format("YYYY/MM/DD"));
                }
                item.append("cursosSelecionados", JSON.stringify(this.state.selecionados));

                axios({
                    method: "post",
                    url: !this.state.recorrente ? "/api/aulas/adicionar" : "/api/aulas/adicionar-recorrencia",
                    data: item
                })
                    .then(response => {
                        this.setState({ iconLoading: false });
                        const lista = response.data.map(aula => {
                            return {
                                id: aula.id,
                                title: aula.titulo,
                                start: new Date(`${moment(aula.dt_inicio).format("YYYY/MM/DD")} ${aula.hr_inicio}`),
                                end: new Date(`${moment(aula.dt_inicio).format("YYYY/MM/DD")} ${aula.hr_termino}`),
                                cor: aula.cor_fundo,
                                recorrencia: this.state.recorrente ? aula.codigo_recorrencia !== "" : false
                            }
                        });
                        this.props.adicionarAulas(lista)
                        //if (!this.state.recorrente)
                        //    this.props.adicionarAula({
                        //        id: response.data,
                        //        title: this.state.titulo,
                        //        start: new Date(`${this.state.dt_inicio.format("YYYY/MM/DD")} ${this.state.hr_inicio.format("HH:mm")}`),
                        //        end: new Date(`${this.state.dt_inicio.format("YYYY/MM/DD")} ${this.state.hr_termino.format("HH:mm")}`),
                        //        cor: this.state.cor_fundo,
                        //        recorrencia: false
                        //    })
                        //else {
                        //    const lista = response.data.map(aula => {
                        //        return {
                        //            id: aula.id,
                        //            title: aula.titulo,
                        //            start: new Date(`${moment(aula.dt_inicio).format("YYYY/MM/DD")} ${aula.hr_inicio}`),
                        //            end: new Date(`${moment(aula.dt_inicio).format("YYYY/MM/DD")} ${aula.hr_termino}`),
                        //            cor: aula.cor_fundo,
                        //            recorrencia: aula.codigo_recorrencia !== ""
                        //        }
                        //    });
                        //    this.props.adicionarAulas(lista)
                        //}
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível adicionar a aula!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    }

    detalhe = aulaId => {
        axios({
            method: "get",
            url: "/api/aulas/detalhe",
            params: {
                aulaId
            }
        })
            .then(response => {
                let evento = response.data;
                this.setState({
                    titulo: evento.titulo,
                    dt_inicio: moment(evento.dt_inicio),
                    hr_inicio: moment(evento.hr_inicio, "HH:mm"),
                    hr_termino: moment(evento.hr_termino, "HH:mm"),
                    cor_fundo: evento.cor_fundo
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    alterar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.aulaId);
                item.append("titulo", this.state.titulo);
                item.append("dt_inicio", this.state.dt_inicio.format("YYYY/MM/DD"));
                item.append("hr_inicio", this.state.hr_inicio.format("HH:mm"));
                item.append("hr_termino", this.state.hr_termino.format("HH:mm"));
                item.append("cor_fundo", this.state.cor_fundo);
                item.append("opcao", this.state.opcao);
                //if (this.state.recorrente) {
                //    item.append("recorrente", this.state.recorrente);
                //    item.append("recorrenciaIntervalo", this.state.recorrenciaIntervalo);
                //    item.append("recorrencia", this.state.recorrencia);
                //    item.append("recorrenciaFim", moment(this.state.recorrenciaFim).format("YYYY/MM/DD"));
                //}

                axios({
                    method: "put",
                    url: "/api/aulas/alterar",
                    data: item
                })
                    .then(response => {
                        this.setState({ iconLoading: false });
                        this.props.atualizarAula({
                            titulo: this.state.titulo,
                            dt_inicio: this.state.dt_inicio,
                            hr_inicio: this.state.hr_inicio,
                            hr_termino: this.state.hr_termino
                        })
                        localStorage.setItem("posicao_calendario", this.state.dt_inicio.toDate())
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível alterar a aula!");
                        this.setState({ iconLoading: false });
                    });
            }
        });
    }

    afterVisibleChange = aberto => {
        if (aberto) {
            this.props.form.resetFields();
            if (this.props.aulaId) {
                this.detalhe(this.props.aulaId)
            }
            else {
                if (this.props.dataHoraInicio && this.props.dataHoraFim) {
                    var dataIni = moment(this.props.dataHoraInicio);
                    var dataFim = moment(this.props.dataHoraFim);

                    this.setState({
                        titulo: "",
                        dt_inicio: dataIni,
                        hr_inicio: dataIni,
                        hr_termino: dataFim,
                        cor_fundo: "",
                        //LOADING
                        iconLoading: false,
                        //REPLICAR
                        selecionados: [],
                        indeterminate: false,
                        todos: false
                    })
                }
                else {
                    const inicio = moment();
                    const minutos = 15 - (inicio.minute() % 15);

                    const hora = moment(inicio).add(minutos, "minutes");

                    this.setState({
                        titulo: "",
                        dt_inicio: moment(),
                        hr_inicio: hora,
                        hr_termino: moment(hora).add(1, "hours"),
                        cor_fundo: "",
                        //LOADING
                        iconLoading: false,
                        //REPLICAR
                        selecionados: [],
                        indeterminate: false,
                        todos: false
                    })
                }
            }
        }
    };

    listarCursos = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-subcursos",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                this.setState({
                    listaCursos: response.data
                });
            })
            .catch(() => { });
    }

    onChange = selecionados => {
        this.setState({
            selecionados: selecionados,
            indeterminate: !!selecionados.length && selecionados.length < this.state.listaCursos.length,
            todos: selecionados.length === this.state.listaCursos.length,
            validateStatus: "",
            validateHelp: "",
        })
    };

    selecionarTodos = (event) => {
        this.setState({
            selecionados: event.target.checked ? this.state.listaCursos.map(x => x.id) : [],
            indeterminate: false,
            todos: event.target.checked,
            validateStatus: "",
            validateHelp: "",
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            titulo,
            dt_inicio,
            hr_termino,
            hr_inicio,
            cor_fundo,
            opcao,
            //RECORRENCIA
            recorrente,
            recorrenciaIntervalo,
            recorrencia,
            recorrenciaFim,
            //LOADING
            iconLoading,
            //REPLICAR
            listaCursos,
            selecionados,
            indeterminate,
            todos,
            //VALIDAR CRIAR AULAS
            validateStatus,
            validateHelp
        } = this.state;

        const {
            visible,
            aulaId,
            temRecorrencia
        } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-adicionar-evento"
                    title={aulaId ? "Alterar" : "Adicionar"}
                    width={720}
                    onClose={this.props.onClose}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <div className="bloco">
                                <Form.Item label="Aula">
                                    {getFieldDecorator("titulo", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: titulo
                                    })(<Input name="titulo" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label="Inicio">
                                    {getFieldDecorator("dt_inicio", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: dt_inicio
                                    })(
                                        <DatePicker
                                            className="input-25"
                                            placeholder="Selecionar"
                                            format="DD-MM-YYYY"
                                            name="dt_fimAula"
                                            onChange={this.handleChangeDataInicio}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Hora Inicio">
                                    {getFieldDecorator("hr_inicio", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: hr_inicio
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="duracaoAula"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={this.handleChangeHoraInicio}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Hora Término">
                                    {getFieldDecorator("hr_termino", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ],
                                        initialValue: hr_termino
                                    })(
                                        <TimePicker
                                            className="input-25"
                                            name="duracaoAula"
                                            defaultOpenValue={moment("00:00", "HH:mm")}
                                            format="HH:mm"
                                            onChange={this.handleChangeHoraTermino}
                                            placeholder="Selecionar"
                                            suffixIcon={false}
                                            clearIcon={false}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Cor de fundo">
                                    {getFieldDecorator("cor_fundo", {
                                        rules: [{
                                            required: true,
                                            message: "Campo obrigatório"
                                        }],
                                        initialValue: cor_fundo
                                    })(
                                        <CirclePicker
                                            color={cor_fundo}
                                            colors={['#29313E', '#696969', '#778899', '#C0C0C0', '#3A75AF', '#6495ED', '#87CEFA', '#5F9EA0', '#D0E5DE', '#2F4F4F', '#006400', '#3CB371', '#9FCD46', '#8B4513', '#A0522D', '#CD853F', '#800000', '#FF6347', '#D2691E', '#FFD700', '#8A2BE2', '#EE82EE', '#FAEBD7']}
                                            onChange={this.handleChangeColorPickerFundo}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                            <div className="bloco">
                                <h3 className="titulo-separador">Recorrência</h3>
                                <Form.Item className="form-item-checkbox">
                                    <Radio.Group name="recorrente" value={recorrente} onChange={this.handlerChangeRadio}>
                                        <Radio value={false}>Aula única</Radio>
                                        <Radio value={true}>Recorrente</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {recorrente &&
                                    <>
                                        <Form.Item>
                                            <Row gutter={8}>
                                                <Col span={3}>
                                                    <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>A cada</label>
                                                </Col>
                                                <Col span={3}>
                                                    <InputNumber value={recorrenciaIntervalo} onChange={this.handlerChangeRecorrenciaIntervalo} />
                                                </Col>
                                                <Col span={6}>
                                                    <Select
                                                        className="input-100"
                                                        placeholder="Selecionar"
                                                        defaultValue={recorrencia}
                                                        onChange={this.handlerChangeSelect}
                                                    >
                                                        <Option value="SEMANA">Semana</Option>
                                                        <Option value="MES">Mês</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Form.Item>
                                            <Row gutter={8}>
                                                <Col span={4}>
                                                    <label style={{ height: 45, marginTop: 5, color: "#3d464d", fontSize: 14 }}>Repetir até</label>
                                                </Col>
                                                <Col span={8}>
                                                    {getFieldDecorator("recorrenciaFim", {
                                                        rules: [{ required: true, message: "Campo obrigatório" }],
                                                        initialValue: recorrenciaFim
                                                    })(<DatePicker
                                                        placeholder="Selecionar"
                                                        format="DD-MM-YYYY"
                                                        name="dia"
                                                        onChange={recorrenciaFim => this.setState({ recorrenciaFim })}
                                                    />)}
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </>}
                            </div>
                            {(this.context.professorPolivalente && !aulaId && listaCursos.length > 0) &&
                                <div className="bloco">
                                    <h3 className="titulo-separador">Criar aula ao nível da(s) disciplina(s)</h3>
                                    <Form.Item
                                        validateStatus={validateStatus}
                                        help={validateHelp}
                                    >
                                        <div style={{ marginBottom: "20px" }}>
                                            <Checkbox
                                                indeterminate={indeterminate}
                                                onChange={this.selecionarTodos}
                                                checked={todos}
                                            >
                                                Todos
                                            </Checkbox>
                                        </div>
                                        <Checkbox.Group style={{ width: '100%' }} value={selecionados} onChange={this.onChange}>
                                            <Row>
                                                {listaCursos.map((curso, index) => (
                                                    <Col key={index} span={16}>
                                                        <Checkbox value={curso.id}>
                                                            {curso.nome}
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </div>}
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </button>
                        {aulaId ? (
                            <button className="botao-principal" onClick={this.alterar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                            <button className="botao-principal" onClick={this.criar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                            </button>
                        )}
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerAdicionar = Form.create({ name: "form-drawer-adicionar" })(DrawerAdicionar);

export default FormDrawerAdicionar;