import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Menu, notification, Modal, Pagination } from "antd";
import Header from "../User/Header";
import HeaderColaboradores from "./_Aux/HeaderColaboradores";
import ListaColaboradores from "./_Aux/ListaColaboradores";
import Pendente from "./_Aux/Pendente";
import axios from "axios";
import moment from "moment";
import Oops from "../Geral/Oops.jsx";
import { GlobalContext } from "../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class ColaboradoresPendentes extends Component {
    static contextType = GlobalContext;
    state = {
        colaboradores: [],
        id: 2,
        existeHistorico: false,
        totalLicencas: 0,
        licencasUtilizadas: 0,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //LOADING PENDENTES
        loadingLista: false,
        //PAGINATION
        current: 1,
        pageSize: 10,
        total: 0,
        //PESQUISA
        pesquisa: "",
        filtroEscolaId: 0,
    };

    UNSAFE_componentWillMount() {
        this.total();
        this.listar();
        //CM  
        this.carregarTotalLicencas();
    }

    componentDidUpdate() {
        if (this.context.role === "UTILIZADOR" || this.context.role === "FORMADOR")
            this.setState({
                redirect: true,
                redirectLink: "/user"
            });
    }

    //CHAMADA APÓS INTERASSÃO COM A CAIXA DE PROCURA
    HandleKeyPress = e => {
        if (e.key === "Enter") {
            this.setState(
                {
                    pesquisa: e.target.value,
                    //PAGINATION
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                () => {
                    this.total();
                    this.listar();
                }
            );
        }
    };

    //LIMPA A CAIXA DE PROCURA
    resetCaixaProcura = () => {
        this.setState(
            {
                pesquisa: "",
                //PAGINATION
                current: 1,
                pageSize: 10,
                total: 0,
            },
            () => {
                this.total();
                this.listar();
            }
        );
    };

    filtrarOrganismo = value => {
        this.setState(
            {
                filtroEscolaId: value,
                //PAGINATION
                current: 1,
                pageSize: 10,
                total: 0,
            },
            () => {
                this.total();
                this.listar();
            }
        );
    };

    listar = () => {
        this.setState({
            loadingLista: true
        }, () => {
                axios({
                    method: "get",
                    url: "/api/colaboradores/listar-pendentes",
                    params: {
                        pesquisa: this.state.pesquisa,
                        filtrarOrganismo: this.state.filtroEscolaId,
                        pageSize: this.state.pageSize,
                        page: this.state.current,
                    }
                })
                    .then(response => {
                        this.setState({
                            loadingLista: false,
                            colaboradores: response.data
                        });
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
        });
    };

    total = () => {
        axios({
            method: "get",
            url: "/api/colaboradores/total-pendentes",
            params: {
                pesquisa: this.state.pesquisa,
                filtrarOrganismo: this.state.filtroEscolaId,
            }
        })
            .then(response => {
                this.setState(
                    {
                        total: response.data
                    });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    paginar = pag => {
        this.setState(
            {
                current: pag
            },
            () => {
                this.listar();
            }
        );
    };

    reenviarMensagem = (event, utilizadorid) => {
        event.preventDefault();

        let item = new FormData();
        item.append("ativo", this.state.ativo);

        axios({
            method: "put",
            url: "/api/colaboradores/reenviar",
            data: item,
            params: {
                utilizadorid: utilizadorid
            }
        })
            .then(() => {
                this.total();
                this.listar();
                openNotificationWithIcon("success", "Sucesso", "Pedido de registo reenviado!");
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível reenviar pedido de registo!");
            });
    };

    excluirPendente = (event, utilizadorid) => {
        event.preventDefault();

        confirm({
            title: "Pretende excluir o convite?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/colaboradores/excluir-pendente",
                    params: {
                        utilizadorid: utilizadorid
                    }
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Registo pendente excluido!");
                        this.total();
                        this.listar();
                        //CM 
                        this.carregarTotalLicencas();
                    })
                    .catch(() => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível excluir o registo!");
                    });
            }
        });
    };

    existeHistorico = () => {
        this.setState({
            existeHistorico: true
        });
    };

    carregarTotalLicencas = () => {
        axios({
            method: "get",
            url: "/api/colaboradores/total-licencas"
        })
            .then(response => {
                this.setState({
                    totalLicencas: response.data.total,
                    licencasUtilizadas: response.data.utilizadas
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    menu = utilizadorid => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={e => this.reenviarMensagem(e, utilizadorid)}>
                        Reenviar email de registo
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="#" onClick={e => this.excluirPendente(e, utilizadorid)}>
                        Excluir
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    render() {
        const {
            existeHistorico,
            //REDIRECT
            redirect,
            redirectLink,
            //PAGINATION
            current,
            pageSize,
            total,
            //LOADING PENDENTES
            loadingLista,
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <div>
                <Header titulo="Utilizadores" />
                <div className="container">
                    <div className="bloco-colaboradores">
                        <div className="bloco-grid">
                            <div className="bloco-esquerda">
                                <div className="info-colaboradores">
                                    <HeaderColaboradores
                                        headerPendentes
                                        nr_colaboradores={this.state.colaboradores.length}
                                        obterDados={this.listar}
                                        HandleKeyPress={this.HandleKeyPress}
                                        resetCaixaProcura={this.resetCaixaProcura}
                                        filtrarOrganismo={this.filtrarOrganismo}
                                        filtros
                                    />
                                </div>
                                <ListaColaboradores
                                    pendentesAtivo="ativo"
                                    existeHistorico={existeHistorico}
                                    totalLicencas={this.state.totalLicencas}
                                    licencasUtilizadas={this.state.licencasUtilizadas}
                                />
                            </div>
                            <div className="bloco-direita">
                                <div className="info-colaboradores">
                                    <HeaderColaboradores headerPendentes nr_colaboradores={this.state.colaboradores.length} existeHistorico={this.existeHistorico} botoesMensagens obterDados={this.listar} />
                                </div>
                                <div className="conteudo-colaboradores">
                                    {loadingLista ? (
                                        <div className="loading-data">
                                            <div className="loading" />
                                        </div>
                                    ) : this.state.colaboradores.length > 0 ? (
                                        this.state.colaboradores.map((colaborador, index) => (
                                            <Pendente
                                                key={index}
                                                colaborador={colaborador}
                                                dt_convite={moment(colaborador.dt_convite).format("DD/MM/YYYY")}
                                                tem_definicoes
                                                menu={this.menu(colaborador.id)}
                                            />
                                        ))
                                    ) : (
                                        <Oops />
                                    )}
                                </div>
                                {!loadingLista && (
                                    <Pagination
                                        current={current}
                                        total={total}
                                        pageSize={pageSize}
                                        onChange={this.paginar}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} registos`}
                                        hideOnSinglePage={true}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ColaboradoresPendentes;
