import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, notification, Icon, Drawer, Menu, Modal, Dropdown, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import noimage from "../../images/noimage.png";
import Oops from "../../Geral/Oops.jsx";
import { GlobalContext } from "../../GlobalState";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Escolas extends Component {
    static contextType = GlobalContext;
    state = {
        escolas: [],
        preview: null,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //UPLOAD VALIDATE
        totalBytes: 0,
        formatoValido: true,
        validateStatus: "",
        help: "",
        //DRAWER
        visible: false,
        organismoid: 0,
        icon: "",
        nome: "",
        descricao: "",
        //LOADING
        iconLoading: false
    };

    UNSAFE_componentWillMount() {
        this.listarEscolas();
    }

    handlerChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    listarEscolas = () => {
        axios({
            method: "get",
            url: "/api/gestao-organismos"
        })
            .then(response => {
                this.setState({
                    escolas: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    };

    adicionarEscola = () => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            organismoid: 0,
            icon: "",
            preview: null,
            nome: "",
            descricao: "",
        });
    };

    guardarEscola = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("icon", this.state.icon);
                item.append("nome", this.state.nome);
                item.append("descricao", this.state.descricao);

                axios({
                    method: "post",
                    url: "/api/gestao-organismos/add-organismo",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Registo adicionado!");
                        this.listarEscolas();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    editarEscola = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.state.organismoid);
                item.append("icon", this.state.icon);
                item.append("nome", this.state.nome);
                item.append("descricao", this.state.descricao);

                axios({
                    method: "put",
                    url: "/api/gestao-organismos/alterar-organismo",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Registo alterado!");
                        this.listarEscolas();
                        this.setState({
                            iconLoading: false,
                            visible: false
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    excluir = organismoid => {
        confirm({
            title: "Pretende remover este registo?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                axios({
                    method: "delete",
                    url: "/api/gestao-organismos/excluir-organismo",
                    params: {
                        organismoid
                    }
                })
                    .then(response => {
                        openNotificationWithIcon("success", "Sucesso", "Registo removido!");
                        var escolas = this.state.escolas.filter(x => x.id !== organismoid);
                        this.setState({
                            escolas
                        });
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível remover este registo!");
                    });
            }
        });
    };

    //ABRE O DRAWER COM OS DADOS DA CATEGORIA
    carregar = organismoid => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-organismos/carregar-organismo",
            params: {
                organismoid
            }
        })
            .then((response) => {
                this.setState(
                    {
                        organismoid: response.data.id,
                        icon: response.data.icon,
                        preview: response.data.icon,
                        nome: response.data.nome,
                        descricao: response.data.descricao ? response.data.descricao : "",
                        ativo: response.data.ativo,
                        visible: true
                    });
            })
            .catch(() => {

            });
    };

    //ABRE O FILE BROWSER
    ativarUploadFoto = () => {
        document.getElementById("input-foto").click();
    };

    //ATUALIZA A IMAGEM
    uploadFoto = event => {
        if (event.target.files[0].size < 100 * 1024)
            if (this.validarFormatoImagem(event.target.files[0].name.split(".").pop())) {
                this.setState({
                    totalBytes: event.target.files[0].size,
                    formatoValido: true,
                    validateStatus: "",
                    help: "",
                    icon: event.target.files[0],
                    preview: URL.createObjectURL(event.target.files[0])
                });
            } else {
                document.getElementById("input-foto").value = "";
                openNotificationWithIcon("error", "Erro", "Imagem com formato inválido");
                //this.setState({
                //    preview: "",
                //    foto: "",
                //    totalBytes: 0,
                //    formatoValido: false,
                //    validateStatus: "error",
                //    help: "Imagem com formato inválido"
                //});
            }
        else {
            document.getElementById("input-foto").value = "";
            openNotificationWithIcon("error", "Erro", "Limite de 100 KB para a imagem.");
            //this.setState({
            //    totalBytes: event.target.files[0].size,
            //    validateStatus: "error",
            //    help: "Limite de 100 KB para a imagem."
            //});
        }
    };

    //VALIDA O TIPO DE FICHEIRO COM OS FORMATOS ACEITES
    validarFormatoImagem = formato => {
        var formatos = ["jpg", "jpeg", "png", "gif"];
        var valido = false;
        for (var i = 0; i < formatos.length; i++) {
            if (formato.toLowerCase() === formatos[i]) {
                valido = true;
                break;
            }
        }
        return valido;
    };

    //REMOVE A IMAGEM
    removerImagem = () => {
        document.getElementById("input-foto").value = "";
        this.setState({
            icon: "",
            preview: ""
        });
    };

    afterVisibleChange = aberto => {
        if (aberto) {
            if (document.getElementById("input-foto"))
                document.getElementById("input-foto").value = "";
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            escolas,
            //UPLOAD VALIDATE
            validateStatus,
            help,
            //REDIRECT
            redirect,
            redirectLink,
            //DRAWER
            visible,
            organismoid,
            preview,
            nome,
            descricao,
            //LOADING
            iconLoading
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        // const opcoes = escola => {
        //     return (
        //         <Menu>
        //             <Menu.Item>
        //                 <Link to="#" onClick={() => this.setState({ escolaId: escola.id, nome: escola.nome.replace("." + escola.nome.split(".").pop(), ""), visible: true })}>
        //                     Editar
        //                 </Link>
        //             </Menu.Item>
        //             <Menu.Item>
        //                 <Link to="#" onClick={() => this.excluir(escola.id)}>
        //                     Excluir
        //                 </Link>
        //             </Menu.Item>
        //         </Menu>
        //     );
        // };
        const opcoes = organismoid => {
            return (
                <Menu>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.carregar(organismoid)}>
                            Editar
                        </Link>
                    </Menu.Item>
                    <Menu.Item>
                        <Link to="#" onClick={() => this.excluir(organismoid)}>Excluir</Link>
                    </Menu.Item>
                </Menu>
            );
        };

        return (
            <div className="bloco-minha-conta">
                <div className="escolas">
                    <div className="controlos">
                        <button className="botao-principal" onClick={this.adicionarEscola}>
                            Adicionar
                        </button>
                    </div>
                    <div className="box-ficheiros box-ficheiros-desktop">
                        <div className="header">
                            <span className="desc">Unidade Organizacional (Escola, Instituto, Academia, Faculdade, ...)</span>
                            <span className="desc"></span>
                        </div>
                        {escolas.length > 0 ?
                            escolas.map((escola, index) => (
                                <div key={index} className="content">
                                    <span className="desc">
                                        <div className="container-img">
                                            <img src={escola.icon ? escola.icon : noimage} />
                                        </div>
                                        {escola.nome}
                                    </span>
                                    <span className="desc">
                                        <Dropdown overlay={opcoes(escola.id)} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    </span>
                                </div>
                            ))
                            :
                            <Oops />
                        }
                    </div>
                    <div className="box-ficheiros box-ficheiros-mobile">
                        <div className="header">
                            <span className="desc">Unidade Organizacional</span>
                            <span className="desc"></span>
                        </div>
                        {escolas.length > 0 ?
                            escolas.map((escola, index) => (
                                <div key={index} className="content">
                                    <span className="desc">
                                        <div className="container-img">
                                            <img src={escola.icon ? escola.icon : noimage} />
                                        </div>
                                        {escola.nome}
                                    </span>
                                    <span className="desc">
                                        <Dropdown overlay={opcoes(escola.id)} placement="bottomLeft">
                                            <Link to="#" className="botao-icon-configs">
                                                <i className="fas fa-cog" />
                                            </Link>
                                        </Dropdown>
                                    </span>
                                </div>
                            ))
                            :
                            <Oops />
                        }
                    </div>
                </div>
                <Drawer
                    className="drawer-add-cursos drawer-videoconferencias"
                    title="Adicionar Unidade Organizacional"
                    width={720}
                    onClose={() => this.setState({ visible: false })}
                    visible={visible}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                    afterVisibleChange={this.afterVisibleChange}
                >
                    <div className="bloco-img">
                        <Form>
                            <Form.Item validateStatus={validateStatus} help={help}>
                                <div className="container-img">
                                    <img src={preview ? preview : noimage} alt="Foto da Escola" />
                                </div>
                                <input type="file" id="input-foto" accept="image/*" onChange={this.uploadFoto} style={{ display: "none" }} />
                                {!preview ? (
                                    <>
                                        <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto}>
                                            <span>Adicionar</span>
                                        </Link>{" "}
                                        <Tooltip
                                            className="info-icon"
                                            title={
                                                <div>
                                                    <p>Tamanho recomendado: 200x200px</p>
                                                    <p>Formatos válidos: .gif, .jpg, .jpeg, .png</p>
                                                </div>
                                            }
                                        >
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                            <Link to="#" className="link-principal-border" onClick={this.ativarUploadFoto} style={{ marginRight: 20 }}>
                                            <span>Alterar</span>
                                        </Link>
                                            <Link to="#" className="link-principal-border" onClick={this.removerImagem}>
                                            <span>Remover</span>
                                        </Link>
                                    </>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="bloco-info">
                        <Form className="form-categorias" layout="horizontal">
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" onChange={this.handlerChange} />)}
                            </Form.Item>
                            <Form.Item label={<span>Descrição</span>}>
                                {getFieldDecorator("descricao", {
                                    initialValue: descricao
                                })(<TextArea name="descricao" onChange={this.handlerChange} rows={10} style={{ height: 100 }} />)}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="ant-drawer-footer">
                        <Link to="#" className="botao-secundario" onClick={() => this.setState({ visible: false })} style={{ marginRight: 20, display: "inline-block" }}>
                            Voltar
                        </Link>
                        {organismoid > 0 ? (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.editarEscola}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        ) : (
                            <button className="botao-principal" disabled={iconLoading} onClick={this.guardarEscola}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                            </button>
                        )}
                    </div>
                </Drawer>
            </div>
        );
    }
}

const FormEscolas = Form.create({ name: "form-minha-conta" })(Escolas);

export default FormEscolas;
