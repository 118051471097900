import React, { Component } from "react";
import { Button, Icon, notification, Tooltip, Modal } from "antd";
import axios from "axios";
import moment from "moment";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class CardsFrequencia extends Component {
    state = {
        num: 1,
        total: 0,
        semPeriodos: false,
        mesesPeriodo: [],
        numDias: 0,
        //PESQUISA
        dt_inicio: "",
        dt_fim: "",
        //PERIODO
        periodoId: 0,
        mes: "",
        ano: "",
        periodoTitulo: "",
        titulo: "",
        aulas: [],
        /*aulasTotais: [],*/
        participantes: [],
        //LOADING
        iconLoading: false,
    }

    componentDidMount() {
        this.total();
    }

    total = () => {
        this.setState({
            iconLoading: true
        }, () => {
            axios({
                method: "get",
                url: "/api/aulas/total-periodos-frequencia",
                params: {
                    cursoId: this.props.cursoId
                }
            })
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            total: response.data.length,
                            mesesPeriodo: response.data,
                            numDias: response.data[0].dias,
                            //periodoId: response.data[0].periodoId,
                            mes: response.data[0].mes,
                            ano: response.data[0].ano,
                            periodoTitulo: response.data[0].titulo,
                            titulo: `${response.data[0].mes.charAt(0).toUpperCase() + response.data[0].mes.slice(1)} ${response.data[0].ano} / ${response.data[0].titulo}`,
                            dt_inicio: response.data[0].dt_inicio,
                            dt_fim: response.data[0].dt_fim,
                        }, () => {
                            this.listar();
                            //this.listarTotais();
                            this.listarParticipantes();
                        })
                    }
                    else {
                        this.setState({
                            semPeriodos: true,
                            iconLoading: false
                        })
                        openNotificationWithIcon("warning", "Atenção", "Não foram adicionados períodos ao curso!");
                    }
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
                });
        });
    }

    listar = () => {
        axios({
            method: "get",
            url: "/api/aulas/listar-frequencia",
            params: {
                cursoId: this.props.cursoId,
                dt_inicio: this.state.dt_inicio,
                dt_fim: this.state.dt_fim
            }
        })
            .then(response => {
                this.setState({
                    aulas: response.data,
                    iconLoading: false
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    listarParticipantes = () => {
        axios({
            method: "get",
            url: "/api/aulas/participantes",
            timeout: 60 * 10 * 1000,
            params: {
                cursoid: this.props.cursoId
            }
        })
            .then(response => {
                this.setState({
                    participantes: response.data
                });
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    anteriorProximo = (num) => {
        var periodo = this.state.mesesPeriodo[num - 1];
        this.setState({
            num,
            numDias: periodo.dias,
            mes: periodo.mes,
            ano: periodo.ano,
            periodoTitulo: periodo.titulo,
            titulo: `${periodo.mes.charAt(0).toUpperCase() + periodo.mes.slice(1)} / ${periodo.titulo}`,
            dt_inicio: periodo.dt_inicio,
            dt_fim: periodo.dt_fim,
            iconLoading: true
        }, () => this.listar())
    }

    montarColunasDias = (participanteId, color) => {
        let dias = [];
        for (let i = 0; i < this.state.numDias; i++) {
            const aulas = this.state.aulas.filter(x => moment(x.dt_inicio).isSame(`${this.state.ano}-${(('0' + (moment().month(this.state.mes).format("M"))).slice(-2))}-${(('0' + (i + 1)).slice(-2))}`));
            if (aulas.length > 0) {
                let presencas = 0;
                let faltas = 0;
                let faltasJustificadas = 0;

                aulas.map(aula => {
                    const presencasAlunos = aula.presencas ? JSON.parse(aula.presencas) : [];
                    const participante = presencasAlunos.find(x => x.id === participanteId);
                    if ((aula.presenca || !aula.presenca && presencasAlunos.length && presencasAlunos.find(x => x.id === participanteId && ((x.presente && !x.estado) || x.estado === "P"))) && participante != undefined && !participante?.bloqueado) {
                        presencas++;
                    }
                    if ((aula.falta || !aula.falta && presencasAlunos.length && presencasAlunos.find(x => x.id === participanteId && ((!x.presente && !x.estado) || x.estado === "F"))) && participante != undefined && !participante?.bloqueado) {
                        faltas++;
                    }
                    if (!aula.presenca && !aula.falta && presencasAlunos.length && presencasAlunos.find(x => x.id === participanteId && x.estado === "FJ")) {
                        faltasJustificadas++;
                    }
                });

                dias.push(<span className="dia">
                    <span className="titulo">{(('0' + (i + 1)).slice(-2))}</span>
                    {presencas > 0 ?
                        <span className="texto presenca">{presencas > 1 ? `P (${presencas})` : "P"}</span>
                        : null}
                    {faltas > 0 ?
                        <span className="texto falta">{faltas > 1 ? `F (${faltas})` : "F"}</span>
                        : null}
                    {faltasJustificadas > 0 ?
                        <span className="texto falta-justificada">{faltasJustificadas > 1 ? `FJ (${faltasJustificadas})` : "FJ"}</span>
                        : null}
                </span>)
            }
            else
                dias.push(<span className="dia">
                    <span className="titulo">{(('0' + (i + 1)).slice(-2))}</span>
                    <span className="texto"></span>
                </span>)
        }

        return dias;
    }

    calcularTotalPresencas = (participanteId) => {
        let total = 0;

        this.state.aulas.map(aula => {
            const presencas = aula.presencas ? JSON.parse(aula.presencas) : [];
            const participante = presencas.find(x => x.id === participanteId);
            if ((aula.presenca || !aula.presenca && presencas.length && presencas.find(x => x.id === participanteId && (x.presente || x.estado === "P"))) && participante != undefined && !participante?.bloqueado)
                total++;
        });

        return total;
    }

    calcularTotalFaltas = (participanteId) => {
        let total = 0;

        this.state.aulas.map(aula => {
            const presencas = aula.presencas ? JSON.parse(aula.presencas) : [];
            const participante = presencas.find(x => x.id === participanteId);
            if (participante?.presente) {
                if ((aula.falta || !aula.falta && presencas.length && presencas.find(x => x.id === participanteId && (!x.presente || x.estado === "F"))) && participante != undefined && !participante?.bloqueado)
                    total++;
            }
            else {
                if ((aula.falta || !aula.falta && presencas.length && presencas.find(x => x.id === participanteId && x.estado === "F")) && participante != undefined && !participante?.bloqueado)
                    total++;
            }
        });

        return total;
    }

    render() {
        const {
            num,
            semPeriodos,
            mesesPeriodo,
            //PERIODO
            periodoTitulo,
            titulo,
            aulas,
            participantes,
            //LOADING
            iconLoading,
        } = this.state;

        return (
            <>
                <div>
                    <div className="bloco-controlos-frequencia">
                        {!semPeriodos ?
                            <div>
                                <Button className='botao-periodo botao-periodo-anterior' disabled={num === 1} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.anteriorProximo(num - 1)}>
                                    <Icon type="left" />
                                </Button>
                                <span>{titulo}</span>
                                <Button className='botao-periodo botao-periodo-proximo' disabled={num === mesesPeriodo.length} style={{ color: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.anteriorProximo(num + 1)}>
                                    <Icon type="right" />
                                </Button>
                            </div>
                            : null}
                    </div>
                    <div className="bloco-cards">
                        {participantes.map((participante, index) => (
                            <div key={index} className="card">
                                <div className="header">
                                    <span className="titulo">{(('0' + (index + 1)).slice(-2))} - {participante.nome.split(" ").length > 1 ? `${participante.nome.split(" ")[0]} ${participante.nome.split(" ")[participante.nome.split(" ").length - 1]}` : participante.nome}</span>
                                </div>
                                <div className="body">
                                    <div className="estrutura-calendario">
                                        {this.montarColunasDias(participante.id, (index % 2 === 0 ? "#FFFFFF" : "#D9D9D9")).map(dia => dia)}
                                    </div>
                                </div>
                                <div className="footer">
                                    <span className="total-presencas">TOTAL PRESENÇAS ({periodoTitulo}): <span>{this.calcularTotalPresencas(participante.id)}</span></span>
                                    <span className="total-faltas">TOTAL FALTAS ({periodoTitulo}): <span>{this.calcularTotalFaltas(participante.id)}</span></span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Modal
                    visible={iconLoading}
                    maskClosable={false}
                    className="modal-loading"
                    footer={null}
                    closable={false}
                >
                    <div className="modal-loading-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A carregar...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default CardsFrequencia;