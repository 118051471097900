import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Icon, Collapse, Dropdown, Menu, Form, Drawer } from "antd";
import noimage from "../../../images/noimage.png";
import Estrutura from "./Estrutura/Estrutura";
import DrawerDetalhe from "../Drawer/DrawerDetalheMobile";
import axios from "axios";
import moment from "moment";

const FasesEscolares = ({ match }) => {
    const [cursos, setCursos] = useState([]);
    const [cursosMobile, setCursosMobile] = useState([]);
    const [cursosDetalhe, setCursosDetalhe] = useState("");
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const { Panel } = Collapse;
    const columns = [
        {
            title: "Edição",
            dataIndex: "edicao",
            className: "td-150"
        },
        {
            title: "Curso",
            dataIndex: "curso",
            className: "td-curso",
        },
        {
            title: "Categoria",
            dataIndex: "categoria",
            className: "td-155"
        },
        {
            title: "Periodo",
            dataIndex: "periodo",
            className: "td-300"
        }
    ];

    const columnsMobile = [
        {
            title: "Curso",
            dataIndex: "curso",
            className: "td-curso-info",
        },
        {
            title: "",
            dataIndex: "opcoes",
            className: "td-50"
        }
    ];

    const listar = () => {
        axios({
            method: "get",
            url: "/api/diario/listar-sub-cursos-fases-escolares",
            params: {
                cursoId: localStorage.getItem("codigo_curso")
            }
        })
            .then(response => {
                const cursos = response.data.map((curso, index) => {
                    return {
                        key: index,
                        id: curso.id,
                        edicao: (
                            <div>
                                <span>{curso.edicao}</span>
                                {curso.ano || curso.turma ?
                                    <span style={{ display: "block" }}>{`${(curso.ano ? curso.ano : "")} ${(curso.turma ? curso.turma : "")}`}</span>
                                    : null}
                            </div>
                        ),
                        curso: (
                            <>
                                <div className="imagem-curso">
                                    <img src={curso.imagem ? curso.imagem : noimage} />
                                </div>
                                <div className="nome-curso">
                                    <span className="titulo-curso" title={curso.nome}>
                                        {curso.codigo} - {curso.nome}
                                    </span>
                                </div>
                            </>
                        ),
                        categoria: curso.categoria,
                        periodo: (
                            <div>
                                <span>{
                                    curso.dt_fim ?
                                        `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                                        :
                                        moment(curso.dt_inicio).format("DD/MM/YYYY")
                                }</span>
                                {curso.fechado ?
                                    <div className="tag-fechado">Fechado ({curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")})</span>}</div>
                                    : ""}
                            </div>
                        ),
                        fases: curso.fases
                    }
                });

                const cursosMobile = response.data.map((curso, index) => {
                    return {
                        key: index,
                        id: curso.id,
                        curso: (
                            <div className="curso-info">
                                <div className="imagem-curso">
                                    <img src={curso.imagem ? curso.imagem : noimage} />
                                </div>
                                <div className="nome-curso">
                                    {curso.edicao ?
                                        <span className="texto-edicao">
                                            {`${curso.edicao}${(curso.ano && curso.turma ? ` / ${curso.ano} ${curso.turma}` : `${(curso.ano || curso.turma ? " / " : "")} ${(curso.ano ? curso.ano : "")} ${(curso.turma ? curso.turma : "")}`)}`}
                                        </span> : null}
                                    <span className="titulo-curso">
                                        {curso.codigo} - {curso.nome}
                                    </span>
                                </div>
                            </div>
                        ),
                        opcoes: (
                            <Dropdown
                                overlay={montarMenu(curso)}
                                placement="bottomLeft"
                                onClick={event => event.stopPropagation()}
                                overlayStyle={{
                                    zIndex: "9"
                                }}
                                trigger={['click']}
                            >
                                <Link to="#" className="botao-icon-configs">
                                    <i className="fas fa-cog" />
                                </Link>
                            </Dropdown>
                        ),
                        fases: curso.fases
                    }
                });

                setCursos(cursos);
                setCursosMobile(cursosMobile);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    };

    const abrirDetalhe = (curso) => {
        const periodo = <div>
            <span>{
                curso.dt_fim ?
                    `${moment(curso.dt_inicio).format("DD/MM/YYYY")} - ${moment(curso.dt_fim).format("DD/MM/YYYY")}`
                    :
                    moment(curso.dt_inicio).format("DD/MM/YYYY")
            }</span>
            {curso.fechado ?
                <div className="tag-fechado">Fechado ({curso.dt_fechado && <span style={{ fontSize: "12px" }}>{moment(curso.dt_fechado).format("DD/MM/YYYY")})</span>}</div>
                : ""}
        </div>;

        setCursosDetalhe({
            categoria: curso.categoria,
            periodo
        });
        setVisible(true);
    }

    const montarMenu = (curso) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to="#" onClick={event => { event.stopPropagation(); abrirDetalhe(curso); }}>
                        Detalhe
                    </Link>
                </Menu.Item>
            </Menu>
        );
    };

    useEffect(() => {
        listar();
    }, [])

    return (
        <div className="container container-body">
            <div className="bloco-curso-diario-opcoes">
                <div>
                    <h3 style={{ marginBottom: 0, fontSize: 18, textTransform: "uppercase" }}>
                        Fases Escolares
                    </h3>
                </div>
                <Link to={`/gerir-curso/${match.params.curso}/curso-diario`} className="botao-icon-voltar" title="Voltar">
                    <Icon type="arrow-left" />
                </Link>
            </div>
            <div className="curso-resultados bloco-formadores bloco-registos-frequencia bloco-diario-expand">
                <div className="bloco">
                    <Table
                        id="cursos-disciplinas"
                        className="curso-disciplinas-desktop"
                        loading={{
                            spinning: loading,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                        columns={columns}
                        dataSource={cursos}
                        pagination={false}
                        locale={{ emptyText: "Não existem registos!" }}
                        expandedRowRender={(record, index) => (
                            <div className="collapse-info-diario collapse-frequencia">
                                <Collapse defaultActiveKey={[]} className="collapse-campos">
                                    {record.fases.map(fase => (
                                        <Panel header={fase.nome} key={fase.id} >
                                            <Estrutura
                                                cursoId={record.id}
                                                faseId={fase.id}
                                            /> 
                                        </Panel>
                                    ))}
                                </Collapse>
                            </div>
                        )}
                    />
                    <Table
                        id="cursos-disciplinas"
                        className="curso-disciplinas-mobile"
                        loading={{
                            spinning: loading,
                            indicator: (
                                <div className="loading-data-table">
                                    <div className="loading" />
                                </div>
                            )
                        }}
                        columns={columnsMobile}
                        dataSource={cursosMobile}
                        pagination={false}
                        locale={{ emptyText: "Não existem registos!" }}
                        expandedRowRender={(record, index) => (
                            <div className="collapse-info-diario collapse-frequencia">
                                <Collapse defaultActiveKey={[]} className="collapse-campos">
                                    {record.fases.map(fase => (
                                        <Panel header={fase.nome} key={fase.id} >
                                            <Estrutura
                                                cursoId={record.id}
                                                faseId={fase.id}
                                            />
                                        </Panel>
                                    ))}
                                </Collapse>
                            </div>
                        )}
                    />
                </div>
            </div>
            <DrawerDetalhe
                visible={visible}
                categoria={cursosDetalhe.categoria}
                periodo={cursosDetalhe.periodo}
                onClose={() => setVisible(false)}
            />
        </div>
    );
}

export default FasesEscolares;
