import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Icon, Modal, Checkbox, Row, Col, Select, notification } from 'antd';
import BotaoProcurar from "../../../Geral/_Aux/BotaoProcurar";
import ModalExportar from "../../../Modals/ModalExportar"
import moment from 'moment';
import axios from "axios";
import { useReactToPrint } from 'react-to-print';

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Controlos = ({
    menuItemKey,
    menuItemName,
    HandleKeyPress,
    resetSearch,
    filterSchool,
    setFilterSchool,
    filterEdition,
    setFilterEdition,
    filterCourse,
    setFilterCourse,
    filterPercentage,
    setFilterPercentage,
    setCols,
    menuOptionSelected,
    disabledButtons,
    columnsPositions,
    sorter,
    cols,
    data,
    pageTitle
    }) => {
    const { Option } = Select;
    const [visible, setVisible] = useState(false);
    const [columns, setColumns] = useState([
        {
            title: "Número",
            key: "number"
        },
        {
            title: "Nome",
            key: "name"
        },
        {
            title: "Escola",
            key: "school"
        },
        {
            title: "Presenças",
            key: "presences"
        },
        {
            title: "Faltas",
            key: "absence"
        },
        {
            title: "Média",
            key: "average"
        }
    ]);
    const [search, setSearch] = useState("");
    const [selectedColumns, setSelectedColumns] = useState(["number", "name", "presences", "absence", "average"]);
    const [schools, setSchools] = useState([]);
    const [editions, setEditions] = useState([]);
    const [courses, setCourses] = useState([]);
    const [percentage, setPercentage] = useState([
        {
            id: 1,
            name: "0 a 25%"
        },
        {
            id: 2,
            name: "26 a 50%"
        },
        {
            id: 3,
            name: "51 a 75%"
        },
        {
            id: 4,
            name: "76 a 100%"
        }
    ]);
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onChangeCheckbox = (checkedValues) => {
        setSelectedColumns(checkedValues)
    }

    const confirmColumns = () => {
        var columns = selectedColumns.map(value => {
            return returnColumn(value);
        })

        setCols(columns);
        setVisible(false);
    }

    const returnColumn = (key) => {
        if (key === "number") {
            return {
                title: "Número",
                dataIndex: "number",
                sorter: (a, b) => a.number.localeCompare(b.number),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "name") {
            return {
                title: "Nome",
                dataIndex: "name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "school") {
            return {
                title: "Escola",
                dataIndex: "school",
                sorter: (a, b) => a.school.localeCompare(b.school),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "presences") {
            return {
                title: "Presenças",
                dataIndex: "presences",
                sorter: (a, b) => a.presences - b.presences,
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "absence") {
            return {
                title: "Faltas",
                dataIndex: "absence",
                sorter: (a, b) => a.absence - b.absence,
                sortDirections: ['descend', 'ascend'],
            };
        }
        else {
            return {
                title: "Média",
                dataIndex: "average",
                sorter: (a, b) => a.average - b.average,
                sortDirections: ['descend', 'ascend'],
                render: average => average ? <span className={average >= 75 ? "percentage-above" : "percentage-below"}>{average.toFixed(2)}%</span> : "",
            };
        }
    }

    const loadSchoolsList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-escolas"
        })
            .then(response => {
                setSchools(response.data)
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const loadEditionsList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-edicoes"
        })
            .then(response => {
                setEditions(response.data)
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const loadCoursesList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-cursos"
        })
            .then(response => {
                setCourses(response.data)
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const exportPdf = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/relatorios/lista-frequencia-exportar-pdf",
            params: {
                menuItemName,
                search,
                sorter: JSON.stringify(sorter),
                selectedColumns: JSON.stringify(columnsPositions),
                filterSchool,
                filterEdition,
                filterCourse,
                filterPercentage
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    }

    const exportCsv = () => {
        setVisibleExport(true);
        setButtonDownload(true);

        axios({
            method: "get",
            url: "/api/relatorios/lista-frequencia-exportar-csv",
            params: {
                menuItemName,
                search,
                sorter: JSON.stringify(sorter),
                selectedColumns: JSON.stringify(columnsPositions),
                filterSchool,
                filterEdition,
                filterCourse,
                filterPercentage
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    }

    useEffect(() => {
        loadSchoolsList();
        loadEditionsList();
        loadCoursesList();

    }, [menuOptionSelected]);

    return (
        <>
            <div className="controls">
                <div className="controls-block controls-block-space-between">
                    <div className="controls-block">
                        <span className="search">
                            <BotaoProcurar
                                tem_placeholder
                                placeholder="Procurar"
                                HandleKeyPress={(e) => { HandleKeyPress(e); setSearch(e.target.value) }}
                                resetCaixaProcura={() => { resetSearch(); setSearch("") }}
                            />
                        </span>
                        <span className="options">
                            <Button onClick={() => setVisible(true)}>
                                <Icon type="table" />
                                Colunas
                            </Button>
                        </span>
                    </div>
                    <div className="controls-block">
                        <span className="options">
                            <Link to="#" className={`button-print ${disabledButtons ? "button-disabled" : ""}`} disabled={disabledButtons} onClick={handlePrint}>
                                <Icon type="printer" />
                            </Link>
                            <Link to="#" className={`button-export-pdf ${disabledButtons ? "button-disabled" : ""}`} disabled={disabledButtons} onClick={exportPdf}>
                                <Icon type="file-pdf" />
                            </Link>
                            <Link to="#" className={`button-export ${disabledButtons ? "button-disabled" : ""}`} disabled={disabledButtons} onClick={exportCsv}>
                                <Icon type="download" />
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="controls-block">
                    <span className="filters">
                        {(menuItemKey === "sub4-1" || menuItemKey === "sub4-2") &&
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Escola"
                                allowClear={true}
                                value={filterSchool}
                                onChange={filter => setFilterSchool(filter)}
                            >
                                {schools.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>}
                        {menuItemKey === "sub4-1" &&
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Edição"
                                allowClear={true}
                                value={filterEdition}
                                onChange={filter => setFilterEdition(filter)}
                            >
                                {editions.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>}
                        {(menuItemKey === "sub4-1" || menuItemKey === "sub4-3") &&
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Curso"
                                allowClear={true}
                                value={filterCourse}
                                onChange={filter => setFilterCourse(filter)}
                            >
                                {courses.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>}
                        {(menuItemKey === "sub4-1" || menuItemKey === "sub4-6") &&
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Percentual"
                                allowClear={true}
                                value={filterPercentage}
                                onChange={filter => setFilterPercentage(filter)}
                            >
                                {percentage.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>}
                    </span>
                </div>
            </div>
            <div ref={componentRef} className="printArea">
                <div className="print-header">
                    <h1>Relatórios - {pageTitle}</h1>
                    <div className="filters-block">
                        <span>Filtros</span>
                        <div className="filters-tags">
                            {search &&
                                <span>Pesquisa: {search}</span>}
                            {filterSchool &&
                                <span>Escola: {schools.find(x => x.id === filterSchool)?.name}</span>}
                            {filterEdition &&
                                <span>Edição: {editions.find(x => x.id === filterEdition)?.name}</span>}
                            {filterCourse &&
                                <span>Curso: {courses.find(x => x.id === filterCourse)?.name}</span>}
                            {filterPercentage &&
                                <span>Intervalo Percentual: {percentage.find(x => x.id === filterPercentage)?.name}</span>}
                        </div>
                    </div>
                </div>
                <div className="print-body">
                    <Table
                        columns={cols}
                        pagination={false}
                        dataSource={data}
                    />
                </div>
            </div>
            <Modal
                title="Selecionar colunas"
                visible={visible}
                onOk={confirmColumns}
                okText="Confirmar"
                onCancel={() => setVisible(false)}
            >
                <Checkbox.Group defaultValue={selectedColumns} style={{ width: '100%' }} onChange={onChangeCheckbox}>
                    <Row>
                        {columns.map((column, index) => (
                            <Col key={index} span={8}>
                                <Checkbox value={column.key}>{column.title}</Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </Modal>
            <ModalExportar
                visibleExportar={visibleExport}
                btnDescarregar={buttonDownload}
                ficheiroExportar={exportFile}
                onCancel={() => setVisibleExport(false)}
            />
        </>
    );
}

export default Controlos;