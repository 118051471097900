import React from "react";
import { Icon, Modal } from 'antd';

const ModalExportar = ({ visibleExportar, btnDescarregar, ficheiroExportar, onCancel }) => {
    return (
        <Modal
            visible={visibleExportar}
            okText={
                <a href={ficheiroExportar} download onClick={onCancel}>
                    Descarregar
                </a>
            }
            onCancel={onCancel}
            cancelText="Cancelar"
            okButtonProps={{ disabled: btnDescarregar }}
            maskClosable={false}
            className="exportar-csv"
        >
            <div className="exportar-csv-bloco">
                {btnDescarregar ? (
                    <>
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A gerar documento...</p>
                    </>
                ) : (
                    <>
                        <p>
                            <Icon type="check-circle" style={{ color: "#84bd00" }} />
                        </p>
                        <p className="texto">Dados exportados com sucesso!</p>
                        <p className="ficheiro-extensao">.{ficheiroExportar.split(".")[ficheiroExportar.split(".").length - 1]}</p>
                    </>
                )}
            </div>
        </Modal>
    );
}

export default ModalExportar;