import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, Alert, notification, Icon, Tooltip } from "antd";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";
import axios from "axios";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class VideoConfCodigo extends Component {
    static contextType = GlobalContext;
    state = {
        codigo: "",
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    //ENVIA CODIGO PARA VALIDAR SE PODE OU NAO ACEDER A VIDEOCONFERENCIA
    Enviar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("entidadeId", localStorage.getItem("entidadeId"));
                item.append("userId", localStorage.getItem("videoconf_userid"));
                item.append("codigo", this.state.codigo);
                item.append("token", this.props.match.params.token);

                axios({
                    method: "post",
                    url: "/api/pagina-entidade/videoconferencia-codigo",
                    data: item
                })
                    .then(response => {
                        if (response.data[0] === "NORMAL") {
                            this.setState({
                                redirect: true,
                                redirectLink: `/${this.props.match.params.entidade}/videoconferencia/${response.data[1]}/player-videoconferencia`
                            });
                        }
                        else {
                            this.setState({
                                redirect: true,
                                redirectLink: `/${this.props.match.params.entidade}/videoconferencia-aguardar/${this.props.match.params.token}`
                            });
                        }
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            iconLoading,
            redirect,
            redirectLink,
        } = this.state;

        if (redirect)
            return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo="Validar inscrição" />
                <div className="container container-body">
                    <div className="content">
                        <Form className="suporte-contacto">
                            <Form.Item>
                                <Alert message="Foi enviado um email com o código para poder participar no Stepmeet" type="info" showIcon />
                            </Form.Item>
                            <Form.Item label={
                                <>
                                    Código{" "}
                                    <Tooltip className="info-icon" className="info-icon info-icon2" title="Introduza o código que recebeu no e-mail">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </>
                            }>
                                {getFieldDecorator("codigo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ]
                                })(<Input name="codigo" onChange={this.handleChange} />)}
                            </Form.Item>
                            <div className="btn_plano">
                                <Link to="#" className="botao-principal" disabled={iconLoading} onClick={this.Enviar}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                                    Enviar
                                </Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}
const FormVideoConfCodigo = Form.create({ name: "video-conf-codigo" })(VideoConfCodigo);

export default FormVideoConfCodigo;
