import React, { Component } from "react";
import { notification, Drawer, Form, Input, Icon, DatePicker, Select, Tooltip, Upload } from "antd";
import axios from "axios";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;
const Dragger = Upload.Dragger;

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerRelatorio extends Component {
    state = {
        //LOADING
        iconLoading: false,
        inscricao: ""
    };

    guardar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.inscricaoId);
                item.append("ficheiro_inscricao", this.props.ficheiro[0]);

                axios({
                    method: "put",
                    url: "/api/sistema-informacao/guardar-inscricao",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Dados da inscrição atualizados!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.onClose();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", "Não foi possível atualizar a inscrição");
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            //LOADING
            iconLoading,
            visibleDrawerRelatorio,
            anexo,
            nome,
            email,
            nr_aluno,
            contacto,
            dt_nascimento,
            naturalidade,
            sexo,
            cc,
            dt_ccvalidade,
            nif,
            morada,
            habilitacao,
            descricao,
            responsavel_1,
            responsavel_2,
            estado_civil,
            motivo,
            ficheiro,
            ficheiro_inscricao,
            ficheiro_inscricao_nome,
            dt_ficheiro_inscricao,
            curso_ficha_inscricao,
            curso_ficha_inscricao_nome,
            propsInscricao
        } = this.props;

        return (
            <>
                <Drawer
                    className="drawer-add-cursos"
                    title="Relatório 01"
                    width={720}
                    onClose={this.props.onClose}
                    visible={visibleDrawerRelatorio}
                    style={{
                        overflow: "auto",
                        height: "calc(100% - 108px)",
                        paddingBottom: "108px"
                    }}
                    maskClosable={false}
                >
                    <div className="bloco-info">
                        <div className="bloco">
                            <div className="campo">
                                <label className="titulo">Texto</label>
                                <span>O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500, quando uma misturou os caracteres de um texto para criar um espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o salto para a tipografia electrónica, mantendo-se essencialmente inalterada. Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset, que continham passagens com Lorem Ipsum, e mais recentemente com os programas de publicação como o Aldus PageMaker que incluem versões do Lorem Ipsum.</span>
                            </div>
                            <div className="bloco">
                                <div className="campo">
                                    <label className="titulo">
                                        Anexo para descarregar:
                                            <a href="#" className="botao-icon-download margin-left" download="Ficha de inscrição.pdf">
                                            <Icon type="download" />
                                        </a>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="ant-drawer-footer">
                            <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                                Voltar
                        </button>
                            {!dt_ficheiro_inscricao &&
                                <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.guardar}>
                                    {iconLoading ? <Icon type="loading" /> : null}
                            Guardar
                        </button>}
                        </div>
                    </div>
                </Drawer>
            </>
        );
    }
}

const FormDrawerRelatorio = Form.create({ name: "drawer-relatorio" })(DrawerRelatorio);

export default FormDrawerRelatorio;
