import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Header from "./Header";
import Menu from "./Menu";
import VisaoGeral from "./VisaoGeral/VisaoGeral";
import Modulos from "./Modulos/Modulos";
import Matriz from "./Matriz";
import Forum from "./Foruns/Forum";
import ForumDetalhe from "./Foruns/ForumDetalhe";
import Anotacoes from "./Anotacoes/Anotacoes";
import AnotacoesDetalhe from "./Anotacoes/AnotacoesDetalhe";
import Ficheiros from "./Ficheiros/Ficheiros";
import Participantes from "./Participantes/Participantes";
import Videoconferencias from "./Videoconferencias/Main";
import Resultados from "./Resultados/Main";
import Ranking from "./Resultados/Ranking";
import { GlobalContext } from "../GlobalState";
import ContextProvider from "./ContextProvider";
import ModalInscricaoPart1 from "./_Aux/ModalInscricaoPart1";
import ModalInscricaoPart2 from "./_Aux/ModalInscricaoPart2";

class CursoLayout extends Component {
    static contextType = GlobalContext;
    state = {
        //REDIRECT
        redirect: false,
        redirectLink: ""
    };

    // componentDidUpdate() {
    //     if (!this.context.is_authenticated)
    //         this.setState({
    //             redirect: true,
    //             redirectLink: "/login"
    //         })
    // }

    componentDidUpdate(prevProps) {
        if (!this.context.is_authenticated)
            this.setState({
                redirect: true,
                redirectLink: "/login"
            });

        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.location.pathname.split("/").pop() !== "curso-modulos") localStorage.setItem("modulo_aberto", "0");
        }
    }

    render() {
        const {
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <>
                <ContextProvider>
                    <Header/>
                    <Menu />
                    <Switch>
                        <Route path="/curso/:curso/curso-visao-geral" component={VisaoGeral} />
                        <Route path="/curso/:curso/curso-modulos" component={Modulos} />
                        <Route path="/curso/:curso/curso-cursos" component={Matriz} />
                        <Route path="/curso/:curso/curso-forum" component={Forum} />
                        <Route path="/curso/:curso/curso-forum-detalhe" component={ForumDetalhe} />
                        <Route path="/curso/:curso/curso-anotacoes" component={Anotacoes} />
                        <Route path="/curso/:curso/curso-anotacoes-detalhe" component={AnotacoesDetalhe} />
                        <Route path="/curso/:curso/curso-ficheiros" component={Ficheiros} />
                        <Route path="/curso/:curso/curso-videoconferencias" component={Videoconferencias} />
                        <Route path="/curso/:curso/curso-resultados" component={Resultados} />
                        <Route path="/curso/:curso/curso-ranking" component={Ranking} />
                        <Route exact path="/curso/:curso/curso-participantes" component={Participantes} />
                    </Switch>
                </ContextProvider>
                {/*<ModalInscricaoPart1 />
                <ModalInscricaoPart2 />*/}
            </>
        );
    }
}

export default CursoLayout;
