import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Icon, Modal, Checkbox, Row, Col, Select, notification } from 'antd';
import BotaoProcurar from "../../../Geral/_Aux/BotaoProcurar";
import ModalExportar from "../../../Modals/ModalExportar"
import moment from 'moment';
import axios from "axios";
import { useReactToPrint } from 'react-to-print';

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const Controlos = ({
    menuItemKey,
    menuItemName,
    HandleKeyPress,
    resetSearch,
    filterSchool,
    setFilterSchool,
    filterEdition,
    setFilterEdition,
    filterCourse,
    setFilterCourse,
    filterPhase,
    setFilterPhase,
    filterRole,
    setFilterRole,
    filterState,
    setFilterState,
    setCols,
    menuOptionSelected,
    disabledButtons,
    columnsPositions,
    sorter,
    cols,
    data,
    pageTitle
    }) => {
    const { Option } = Select;
    const [visible, setVisible] = useState(false);
    const [columns, setColumns] = useState([
        {
            title: "Número",
            key: "number"
        },
        {
            title: "Nome",
            key: "name"
        },
        {
            title: "Último Acesso",
            key: "lastAccess"
        },
        {
            title: "Ausência",
            key: "absence"
        },
        {
            title: "Localidade",
            key: "locale"
        },
        {
            title: "Idade",
            key: "age"
        },
        {
            title: "Escola",
            key: "school"
        },
        {
            title: "Sexo",
            key: "gender"
        },
        {
            title: "Perfil",
            key: "role"
        },
        {
            title: "Última Alteração",
            key: "lastChange"
        }
    ]);
    const [search, setSearch] = useState("");
    const [selectedColumns, setSelectedColumns] = useState(["number", "name", "lastAccess"]);
    const [schools, setSchools] = useState([]);
    const [editions, setEditions] = useState([]);
    const [courses, setCourses] = useState([]);
    const [phases, setPhases] = useState([]);
    const [roles, setRoles] = useState([
        {
            id: 1,
            name: "Gestor"
        },
        {
            id: 2,
            name: "Instrutor"
        },
        {
            id: 3,
            name: "Aluno"
        }
    ]);
    const [states, setStates] = useState([
        {
            id: 1,
            name: "Ativo"
        },
        {
            id: 2,
            name: "Inativo"
        },
        {
            id: 3,
            name: "Excludío"
        }
    ]);
    const [visibleExport, setVisibleExport] = useState(false);
    const [buttonDownload, setButtonDownload] = useState(false);
    const [exportFile, setExportFile] = useState("");

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onChangeCheckbox = (checkedValues) => {
        setSelectedColumns(checkedValues)
    }

    const confirmColumns = () => {
        var columns = selectedColumns.map(value => {
            return returnColumn(value);
        })

        setCols(columns);
        setVisible(false);
    }

    const returnColumn = (key) => {
        if (key === "number") {
            return {
                title: "Número",
                dataIndex: "number",
                sorter: (a, b) => a.number.localeCompare(b.number),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "name") {
            return {
                title: "Nome",
                dataIndex: "name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "lastAccess") {
            return {
                title: "Último Acesso",
                dataIndex: "lastAccess",
                sorter: (a, b) => moment(a.lastAccess).unix() - moment(b.lastAccess).unix(),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "absence") {
            return {
                title: "Ausência",
                dataIndex: "absence",
                sorter: (a, b) => a.absence - b.absence,
                sortDirections: ['descend', 'ascend'],
                render: absence => absence ? <span className={`total-absence ${absence > 7 ? "above" : "normal"}`} title="Número de dias ausente">{absence} {absence > 1 ? "dias" : "dia"}</span> : "",
            };
        }
        else if (key === "locale") {
            return {
                title: "Localidade",
                dataIndex: "locale",
                sorter: (a, b) => a.locale.localeCompare(b.locale),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "age") {
            return {
                title: "Idade",
                dataIndex: "age",
                sorter: (a, b) => a.age.localeCompare(b.age),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "school") {
            return {
                title: "Escola",
                dataIndex: "school",
                sorter: (a, b) => a.school.localeCompare(b.school),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "gender") {
            return {
                title: "Sexo",
                dataIndex: "gender",
                sorter: (a, b) => a.gender.localeCompare(b.gender),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else if (key === "role") {
            return {
                title: "Perfil",
                dataIndex: "role",
                sorter: (a, b) => a.role.localeCompare(b.role),
                sortDirections: ['descend', 'ascend'],
            };
        }
        else {
            return {
                title: "Última Alteração",
                dataIndex: "lastChange",
                sorter: (a, b) => moment(a.lastChange).unix() - moment(b.lastChange).unix(),
                sortDirections: ['descend', 'ascend'],
            };
        }
    }

    const loadSchoolsList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-escolas"
        })
            .then(response => {
                setSchools(response.data)
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const loadEditionsList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-edicoes"
        })
            .then(response => {
                setEditions(response.data)
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const loadCoursesList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-cursos"
        })
            .then(response => {
                setCourses(response.data)
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const loadPhasesList = () => {
        axios({
            method: "get",
            url: "/api/relatorios/lista-fases"
        })
            .then(response => {
                setPhases(response.data)
            })
            .catch(error => {
                if (error.response.status === 401)
                    this.setState({
                        redirect: true,
                        redirectLink: "/login"
                    });
            });
    }

    const exportPdf = () => {
        setVisibleExport(true);
        setButtonDownload(true);
        axios({
            method: "get",
            url: "/api/relatorios/lista-utilizadores-exportar-pdf",
            params: {
                menuItemName,
                search,
                sorter: JSON.stringify(sorter),
                selectedColumns: JSON.stringify(columnsPositions),
                filterSchool,
                filterEdition,
                filterCourse,
                filterPhase,
                filterRole,
                filterState
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    }

    const exportCsv = () => {
        setVisibleExport(true);
        setButtonDownload(true);

        axios({
            method: "get",
            url: "/api/relatorios/lista-utilizadores-exportar-csv",
            params: {
                menuItemName,
                search,
                sorter: JSON.stringify(sorter),
                selectedColumns: JSON.stringify(columnsPositions),
                filterSchool,
                filterEdition,
                filterCourse,
                filterPhase,
                filterRole,
                filterState
            }
        })
            .then(response => {
                setButtonDownload(false);
                setExportFile(response.data)
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Não foi possível exportar!");
                setVisibleExport(false);
            });
    }

    useEffect(() => {
        loadSchoolsList();
        loadEditionsList();
        loadCoursesList();
        loadPhasesList();

    }, [menuOptionSelected]);

    return (
        <>
            <div className="controls">
                <div className="controls-block controls-block-space-between">
                    <div className="controls-block">
                        <span className="search">
                            <BotaoProcurar
                                tem_placeholder
                                placeholder="Procurar"
                                HandleKeyPress={(e) => { HandleKeyPress(e); setSearch(e.target.value) }}
                                resetCaixaProcura={() => { resetSearch(); setSearch("") }}
                            />
                        </span>
                        <span className="options">
                            <Button onClick={() => setVisible(true)}>
                                <Icon type="table" />
                                Colunas
                            </Button>
                        </span>
                    </div>
                    <div className="controls-block">
                        <span className="options">
                            <Link to="#" className={`button-print ${disabledButtons ? "button-disabled" : ""}`} disabled={disabledButtons} onClick={handlePrint}>
                                <Icon type="printer" />
                            </Link>
                            <Link to="#" className={`button-export-pdf ${disabledButtons ? "button-disabled" : ""}`} disabled={disabledButtons} onClick={exportPdf}>
                                <Icon type="file-pdf" />
                            </Link>
                            <Link to="#" className={`button-export ${disabledButtons ? "button-disabled" : ""}`} disabled={disabledButtons} onClick={exportCsv}>
                                <Icon type="download" />
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="controls-block">
                    <span className="filters">
                        {(menuItemKey === "sub1-1" || menuItemKey === "sub1-2") &&
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Escola"
                                allowClear={true}
                                value={filterSchool}
                                onChange={filter => setFilterSchool(filter)}
                            >
                                {schools.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>}
                        <Select
                            className="filter"
                            showSearch
                            placeholder="Selecionar Edição"
                            allowClear={true}
                            value={filterEdition}
                            onChange={filter => setFilterEdition(filter)}
                        >
                            {editions.map((item, index) => (
                                <Option key={index} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                        {(menuItemKey === "sub1-1" || menuItemKey === "sub1-3") &&
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Curso"
                                allowClear={true}
                                value={filterCourse}
                                onChange={filter => setFilterCourse(filter)}
                            >
                                {courses.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>}
                        {(menuItemKey === "sub1-1" || menuItemKey === "sub1-4") &&
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Fase"
                                allowClear={true}
                                value={filterPhase}
                                onChange={filter => setFilterPhase(filter)}
                            >
                                {phases.map((item, index) => (
                                    <Option key={index} value={item.code}>{item.name}</Option>
                                ))}
                            </Select>}
                        {(menuItemKey === "sub1-1" || menuItemKey === "sub1-5") &&
                            <Select
                                className="filter"
                                showSearch
                                placeholder="Selecionar Perfil"
                                allowClear={true}
                                value={filterRole}
                                onChange={filter => setFilterRole(filter)}
                            >
                                {roles.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>}
                        <Select
                            className="filter"
                            showSearch
                            placeholder="Selecionar Estado"
                            allowClear={true}
                            value={filterState}
                            onChange={filter => setFilterState(filter)}
                        >
                            {states.map((item, index) => (
                                <Option key={index} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                    </span>
                </div>
            </div>
            <div ref={componentRef} className="printArea">
                <div className="print-header">
                    <h1><span>Relatórios - {pageTitle}</span><span>{moment().format("DD/MM/YYYY HH:mm")}</span></h1>
                    <div className="filters-block">
                        <span>Filtros</span>
                        <div className="filters-tags">
                            {search &&
                                <span>Pesquisa: {search}</span>}
                            {filterSchool &&
                                <span>Escola: {schools.find(x => x.id === filterSchool)?.name}</span>}
                            {filterEdition &&
                                <span>Edição: {editions.find(x => x.id === filterEdition)?.name}</span>}
                            {filterCourse &&
                                <span>Curso: {courses.find(x => x.id === filterCourse)?.name}</span>}
                            {filterPhase &&
                                <span>Fase: {phases.find(x => x.code === filterPhase)?.name}</span>}
                            {filterRole &&
                                <span>Perfil: {roles.find(x => x.id === filterRole)?.name}</span>}
                            {filterState &&
                                <span>Estado: {states.find(x => x.id === filterState)?.name}</span>}
                        </div>
                    </div>
                </div>
                <div className="print-body">
                    <Table
                        columns={cols}
                        pagination={false}
                        dataSource={data}
                    />
                </div>
            </div>
            <Modal
                title="Selecionar colunas"
                visible={visible}
                onOk={confirmColumns}
                okText="Confirmar"
                onCancel={() => setVisible(false)}
            >
                <Checkbox.Group defaultValue={selectedColumns} style={{ width: '100%' }} onChange={onChangeCheckbox}>
                    <Row>
                        {columns.map((column, index) => (
                            <Col key={index} span={8}>
                                <Checkbox value={column.key}>{column.title}</Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </Modal>
            <ModalExportar
                visibleExportar={visibleExport}
                btnDescarregar={buttonDownload}
                ficheiroExportar={exportFile}
                onCancel={() => setVisibleExport(false)}
            />
        </>
    );
}

export default Controlos;