import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Form, Checkbox, notification, Icon, Input } from "antd";
import axios from "axios";
import noimage from "../../../images/noimage.png";

const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class Parecer extends Component {
    state = {
        pareceres: [],
        parecerGeral: "",
        participantes: [],
        aplicar: false,
        loadingParticipantes: false,
        //LOADING
        iconLoading: false
    };

    componentDidMount() {
        this.listar();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeParticipante = (event, participanteId) => {
        var pareceres = this.state.pareceres;
        var index = pareceres.findIndex(x => x.id === participanteId)
        pareceres[index].texto = event.target.value;

        this.setState({ pareceres });
    };

    listar = () => {
        this.setState(
            {
                loadingParticipantes: true
            },
            () => {
                axios({
                    method: "get",
                    url: "/api/aulas/detalhe-pareceres",
                    timeout: 60 * 10 * 1000,
                    params: {
                        aulaId: localStorage.getItem("aula_diario")
                    }
                })
                    .then(response => {
                        if (!response.data.pareceres) {
                            const pareceres = response.data.participantes.map(participante => {
                                return {
                                    id: participante.id,
                                    texto: ""
                                }
                            })

                            this.setState({
                                pareceres,
                                participantes: response.data.participantes,
                                loadingParticipantes: false
                            });
                        }
                        else {
                            let pareceres = JSON.parse(response.data.pareceres);
                            if (pareceres.length === 0)
                                pareceres = response.data.participantes.map(participante => {
                                    return {
                                        id: participante.id,
                                        texto: ""
                                    }
                                })
                            else
                                pareceres = response.data.participantes.map(participante => {
                                    var registo = pareceres.find(x => x.id === participante.id);
                                    if (registo)
                                        return registo;
                                    else
                                        return {
                                            id: participante.id,
                                            texto: ""
                                        }
                                })

                            this.setState({
                                pareceres,
                                parecerGeral: response.data.parecer_geral ? response.data.parecer_geral : "",
                                participantes: response.data.participantes,
                                loadingParticipantes: false
                            });
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 401)
                            this.setState({
                                redirect: true,
                                redirectLink: "/login"
                            });
                    });
            }
        );
    }

    guardarPareceres = () => {
        if (this.state.parecerGeral || this.state.pareceres.filter(x => x.parecer).length) {
            this.setState({ iconLoading: true })

            var item = new FormData()
            item.append("id", localStorage.getItem("aula_diario"))
            item.append("cursoId", localStorage.getItem("codigo_curso"))
            item.append("pareceres", JSON.stringify(this.state.pareceres))
            item.append("parecer_geral", this.state.parecerGeral)
            item.append("aplicar", this.state.aplicar)

            axios({
                method: "put",
                url: "/api/aulas/alterar-pareceres",
                data: item,
                timeout: 60 * 30 * 1000
            })
                .then(() => {
                    openNotificationWithIcon("success", "Sucesso", "Pareceres guardados!")
                    this.setState({ iconLoading: false, aplicar: false })
                })
                .catch(error => {
                    openNotificationWithIcon("error", "Erro", "Não foi guardar Pareceres!")
                    this.setState({ iconLoading: false, aplicar: false })
                })
        }
        else {
            openNotificationWithIcon("info", "Informação", "Não existem registos a serem guardados")
        }
    }

    montarParticipante = (participanteId) => {
        var participante = this.state.participantes.find(x => x.id === participanteId);

        return <div className="participante">
            <div className="info-participante">
                <div className="container-img">
                    <img src={participante.foto ? participante.foto : noimage} />
                </div>
                <div className="info">
                    <span className="nome">{participante.nome}</span>
                    <p className="numero">{participante.numero}</p>
                </div>
            </div>
        </div>
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;
        const {
            pareceres,
            parecerGeral,
            aplicar,
            //LOADING
            iconLoading
        } = this.state;

        return (
            <>
                <div className="bloco-presencial">
                    <div className="bloco-parecer-diario" style={{ boxShadow: "none" }}>
                        <div className="listagem-participantes" style={{ marginTop: 0 }}>
                            <Form className="form-presencas" layout="horizontal">
                                <div className="bloco">
                                    <h3 className="titulo-separador">Turma</h3>
                                    <Form.Item>
                                        <div className="lista-presencas">
                                            {pareceres.map((parecer, index) => (
                                                <div key={parecer.id} className="bloco-participante">
                                                    <div className="bloco-esquerda">
                                                        {this.montarParticipante(parecer.id)}
                                                    </div>
                                                    <div className="bloco-direita">
                                                        <Form.Item label="Parecer">
                                                            <TextArea
                                                                name="observacoes"
                                                                rows={4}
                                                                style={{ height: 100 }}
                                                                value={parecer.texto}
                                                                onChange={(e) => this.handleChangeParticipante(e, parecer.id)}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label="Parecer Geral">
                                        <TextArea
                                            name="parecerGeral"
                                            rows={4}
                                            style={{ height: 100 }}
                                            value={parecerGeral}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Checkbox name="aplicar" checked={aplicar} onChange={this.handleChangeCheckbox}>
                                            Aplicar as alterações a todas as aulas posteriores a ocorrerem neste dia.
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Link className="botao-principal" to="#" disabled={iconLoading} onClick={this.guardarPareceres}>
                                {iconLoading ? <Icon type="loading" /> : null}
                                            Guardar
                                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const FormParecer = Form.create({ name: "form-parecer" })(Parecer);

export default FormParecer;
