import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Drawer, notification, Icon, Form, Input, Checkbox, Modal, Select } from "antd";
import axios from "axios";
import { GlobalContext } from "../../../GlobalState";
import imgCompetenciasGerais from "../../../images/competencias-gerais.png";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerCompetencia extends Component {
    static contextType = GlobalContext;
    state = {
        categoria: undefined,
        nome: "",
        descricao: "",
        tipos: [],
        associar_bncc: false,
        competenciasGerais: [],
        ativo: true,
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //MODAL
        visibleModalImagem: false
    };

    componentDidUpdate() {
        if (!this.context.is_authenticated || this.context.role === "UTILIZADOR") {
            if (!this.context.is_authenticated)
                this.setState({
                    redirect: true,
                    redirectLink: "/login"
                });
            else if (this.context.role === "UTILIZADOR")
                this.setState({
                    redirect: true,
                    redirectLink: "/user"
                });
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleChangeSwitch = ativo => {
        this.setState(
            {
                ativo
            },
            () => {
                this.listar();
            }
        );
    };

    //ABRE O DRAWER COM OS DADOS DA CATEGORIA
    carregarCompetencia = competenciaId => {
        this.props.form.resetFields();
        axios({
            method: "get",
            url: "/api/gestao-competencias/carregar-detalhe",
            params: {
                competenciaId
            }
        })
            .then(response => {
                this.setState({
                    categoria: response.data.id_categoria,
                    nome: response.data.nome,
                    descricao: response.data.descricao ? response.data.descricao : "",
                    tipos: JSON.parse(response.data.tipos),
                    competenciasGerais: JSON.parse(response.data.competencias_gerais),
                    associar_bncc: JSON.parse(response.data.competencias_gerais).length > 0,
                    ativo: response.data.ativo
                });
            })
            .catch(() => { });
    };

    //CRIA UMA NOVA CATEGORIA
    criar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id_categoria", this.state.categoria);
                item.append("nome", this.state.nome);
                item.append("descricao", this.state.descricao);
                item.append("tipos", JSON.stringify(this.state.tipos));
                item.append("competencias_gerais", JSON.stringify(this.state.competenciasGerais));

                axios({
                    method: "post",
                    url: "/api/gestao-competencias/adicionar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Adicionada nova competência!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    //ATUALIZA A COMPETENCIA
    alterar = event => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("id", this.props.competenciaId);
                item.append("id_categoria", this.state.categoria);
                item.append("nome", this.state.nome);
                item.append("descricao", this.state.descricao);
                item.append("tipos", JSON.stringify(this.state.tipos));
                item.append("competencias_gerais", JSON.stringify(this.state.competenciasGerais));

                axios({
                    method: "put",
                    url: "/api/gestao-competencias/alterar",
                    data: item
                })
                    .then(() => {
                        openNotificationWithIcon("success", "Sucesso", "Competência alterada!");
                        this.setState({
                            iconLoading: false
                        });
                        this.props.atualizar();
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({
                            iconLoading: false
                        });
                    });
            }
        });
    };

    afterVisibleChange = aberto => {
        if (aberto) {

            if (this.props.competenciaId) {
                this.carregarCompetencia(this.props.competenciaId);
            }
            else {
                this.props.form.resetFields();
                this.setState({
                    categoria: undefined,
                    nome: "",
                    descricao: "",
                    tipos: [],
                    associar_bncc: false,
                    competenciasGerais: [],
                    ativo: true,
                    //LOADING
                    iconLoading: false,
                });
            }
        }
    };

    render() {
        const {
            categoria,
            nome,
            descricao,
            tipos,
            associar_bncc,
            competenciasGerais,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink,
            //MODAL
            visibleModalImagem
        } = this.state;
        const {
            competenciaId,
            categorias,
            visible,
            onClose
        } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { TextArea } = Input;

        if (redirect) return <Redirect to={redirectLink} />;

        return (
            <Drawer
                className="drawer-add-cursos"
                title={competenciaId ? "Alterar competência" : "Adicionar nova competência"}
                width={720}
                onClose={onClose}
                visible={visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-competencias" layout="horizontal">
                        <div className="bloco">
                            <Form.Item label="Categoria">
                                {getFieldDecorator("categoria", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: categoria
                                })(<Select
                                    className="filtro-categorias"
                                    onChange={categoria => this.setState({ categoria })}
                                    placeholder="Selecionar"
                                    allowClear={true}
                                >
                                    {categorias.map(categoria => (
                                        <Option value={categoria.id} key={categoria.id}>{categoria.nome}</Option>
                                    ))}
                                </Select>)}
                            </Form.Item>
                            <Form.Item label="Nome">
                                {getFieldDecorator("nome", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: nome
                                })(<Input name="nome" maxLength={75} onChange={this.handleChange} />)}
                            </Form.Item>
                            <Form.Item label="Descrição">
                                {getFieldDecorator("descricao", {
                                    initialValue: descricao
                                })(<TextArea name="descricao" maxLength={350} onChange={this.handleChange} rows={4} style={{ height: 100 }} />)}
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            <h3 className="titulo-separador titulo-separado-obrigatorio">Tipos</h3>
                            <Form.Item>
                                {getFieldDecorator("tipos", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório"
                                        }
                                    ],
                                    initialValue: tipos
                                })(<Checkbox.Group name="tipos" className="lista-tipos-competencia" onChange={tipos => this.setState({tipos})}>
                                    <Checkbox value="Conceito">
                                        Conceito
                                            <span className="span-sub-titulo">(Conhecer o conceito)</span>
                                    </Checkbox>
                                    <Checkbox value="Domínio">
                                        Domínio
                                            <span className="span-sub-titulo">(Saber aplicar o conceito)</span>
                                    </Checkbox>
                                    <Checkbox value="Indicador">
                                        Indicador
                                            <span className="span-sub-titulo">(Sinaliza que conhece o objetivo da competência)</span>
                                    </Checkbox>
                                    <Checkbox value="Proficiência">
                                        Proficiência
                                            <span className="span-sub-titulo">(Habilidade básica)</span>
                                    </Checkbox>
                                    <Checkbox value="Nível">
                                        Nível
                                            <span className="span-sub-titulo">(Nível de aprendizagem da competência)</span>
                                    </Checkbox>
                                    <Checkbox value="Prática">
                                        Prática
                                            <span className="span-sub-titulo">(Sabe fazer satisfatoriamente)</span>
                                    </Checkbox>
                                    <Checkbox value="Habilidade">
                                        Habilidade
                                            <span className="span-sub-titulo">(Sabe fazer e é capaz de orientar os outros)</span>
                                    </Checkbox>
                                    <Checkbox value="Comportamento">
                                        Comportamento
                                            <span className="span-sub-titulo">(Mudança de comportamento com a aprendizagem adquirida)</span>
                                    </Checkbox>
                                </Checkbox.Group>)}
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            <Checkbox checked={associar_bncc} name="associar_bncc" onChange={this.handleChangeCheckbox}>
                                Associar Competências BNCC
                            </Checkbox>
                            {associar_bncc &&
                                <>
                                    <h3 className="titulo-separador">
                                        Competências Gerais
                                        <Link to="#" className="link-help" onClick={() => this.setState({ visibleModalImagem: true })}>
                                            <Icon type="question-circle-o" />
                                        </Link>
                                    </h3>
                                    <Form.Item>
                                        {getFieldDecorator("competenciasGerais", {
                                            initialValue: competenciasGerais
                                        })(<Checkbox.Group name="competenciasGerais" className="lista-competencias-gerais" onChange={competenciasGerais => this.setState({ competenciasGerais })}>
                                            <Checkbox value="1. Conhecimento">
                                                <span className="competencia competencia-1">
                                                    <span className="lista-numero">1.</span><span>Conhecimento</span>
                                                </span>
                                            </Checkbox>
                                            <Checkbox value="2. Pensamento cientifico, crítico e criativo">
                                                <span className="competencia competencia-2">
                                                    <span className="lista-numero">2.</span><span>Pensamento cientifico, crítico e criativo</span>
                                                </span>
                                            </Checkbox>
                                            <Checkbox value="3. Repertório cultural">
                                                <span className="competencia competencia-3">
                                                    <span className="lista-numero">3.</span><span>Repertório cultural</span>
                                                </span>
                                            </Checkbox>
                                            <Checkbox value="4. Comunicação">
                                                <span className="competencia competencia-4">
                                                    <span className="lista-numero">4.</span><span>Comunicação</span>
                                                </span>
                                            </Checkbox>
                                            <Checkbox value="5. Cultura digital">
                                                <span className="competencia competencia-5">
                                                    <span className="lista-numero">5.</span><span>Cultura digital</span>
                                                </span>
                                            </Checkbox>
                                            <Checkbox value="6. Trabalho e projeto de vida">
                                                <span className="competencia competencia-6">
                                                    <span className="lista-numero">6.</span><span>Trabalho e projeto de vida</span>
                                                </span>
                                            </Checkbox>
                                            <Checkbox value="7. Argumentação">
                                                <span className="competencia competencia-7">
                                                    <span className="lista-numero">7.</span><span>Argumentação</span>
                                                </span>
                                            </Checkbox>
                                            <Checkbox value="8. Autoconhecimento e autocuidado">
                                                <span className="competencia competencia-8">
                                                    <span className="lista-numero">8.</span><span>Autoconhecimento e autocuidado</span>
                                                </span>
                                            </Checkbox>
                                            <Checkbox value="9. Empatia e cooperação">
                                                <span className="competencia competencia-9">
                                                    <span className="lista-numero">9.</span><span>Empatia e cooperação</span>
                                                </span>
                                            </Checkbox>
                                            <Checkbox value="10. Responsabilidade e cidadania">
                                                <span className="competencia competencia-10">
                                                    <span className="lista-numero">10.</span><span>Responsabilidade e cidadania</span>
                                                </span>
                                            </Checkbox>
                                        </Checkbox.Group>)}
                                    </Form.Item>
                                </>}
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                        </button>
                    {competenciaId ? (
                        <button className="botao-principal" onClick={this.alterar} disabled={iconLoading} type="primary">
                            {iconLoading ? <Icon type="loading" /> : null}
                                Guardar
                        </button>
                    ) : (
                            <button className="botao-principal" onClick={this.criar} disabled={iconLoading} type="primary">
                                {iconLoading ? <Icon type="loading" /> : null}
                                Criar
                            </button>
                        )}
                </div>
                <Modal
                    title=""
                    visible={visibleModalImagem}
                    footer={false}
                    onCancel={() => this.setState({ visibleModalImagem: false })}
                    className="modal-imagem"
                >
                    <div className="modal-imagem-conteudo">
                        <img className="imagem-conteudo" src={imgCompetenciasGerais} />
                        </div>
                </Modal>
            </Drawer>
        );
    }
}
const FormDrawerCompetencia = Form.create({ name: "form-drawer-competencia" })(DrawerCompetencia);

export default FormDrawerCompetencia;
