import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Drawer, Form, Input, Modal, Icon, notification, Checkbox } from "antd";
import axios from "axios";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class DrawerPartilhar extends Component {
    state = {
        email: "",
        partilharComAluno: false,
        partilharComInstrutores: false,
        emails: [],
        iconLoading: false
    };

    UNSAFE_componentWillUpdate() {
        this.props.form.resetFields();
    }

    handlerChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handlerChangeCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }

    //CRIA NOVO MODULO
    partilhar = event => {
        event.preventDefault();

        if (!this.state.partilharComAluno && !this.state.partilharComInstrutores && !this.state.emails.length) {
            openNotificationWithIcon("warning", "Atenção", "Não existem opções selecionadas ou e-mails adicionados.");
            return false;
        }

        this.setState({ iconLoading: true });
        var item = new FormData();
        item.append("cursoId", this.props.cursoId);
        item.append("utilizadorId", this.props.partilharUtilizadorId);
        item.append("partilharComAluno", this.state.partilharComAluno);
        item.append("partilharComInstrutores", this.state.partilharComInstrutores);
        if (this.state.emails.length)
            item.append("emails", JSON.stringify(this.state.emails));

        axios({
            method: "post",
            url: "/api/gerir-curso-partilha/partilhar-resultados",
            data: item
        })
            .then(() => {
                openNotificationWithIcon("success", "Sucesso", "Resultados partilhados");
                this.setState({ iconLoading: false });
                this.props.onClose();
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", error.response.data);
                this.setState({ iconLoading: false });
            });
    };

    adicionar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const email = this.state.emails.find(x => x === this.state.email)

                if (email) {
                    openNotificationWithIcon("warning", "Atenção", "Esse e-mail já existe na lista.");
                    return false;
                }
                const emails = [...this.state.emails, this.state.email]
                this.setState({
                    email: "",
                    emails: emails.sort()
                })
            }
        })
    }

    excluir = (index) => {
        confirm({
            title: "Pretende excluir este e-mail?",
            okText: "Confirmar",
            okType: "Cancelar",
            onOk: () => {
                const emails = this.state.emails;
                emails.splice(index, 1)
                this.setState({
                    emails
                })
            }
        });
    }

    afterVisibleChange = aberto => {
        //if (aberto) {
            this.props.form.resetFields();
            this.setState({
                email: "",
                partilharComAluno: false,
                partilharComInstrutores: false,
                emails: [],
                iconLoading: false
            })
        //}
    }

    render() {
        const {
            email,
            partilharComAluno,
            partilharComInstrutores,
            emails,
            iconLoading
        } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                className="drawer-from-partilhar"
                title="Partilhar Resultados"
                width={720}
                onClose={this.props.onClose}
                visible={this.props.visible}
                style={{
                    overflow: "auto",
                    height: "calc(100% - 108px)",
                    paddingBottom: "108px"
                }}
                maskClosable={false}
                afterVisibleChange={this.afterVisibleChange}
            >
                <div className="bloco-info">
                    <Form className="form-modulo" layout="horizontal">
                        <div className="bloco">
                            <Form.Item className="form-item-checkbox">
                                <Checkbox name="partilharComAluno" checked={partilharComAluno} onChange={this.handlerChangeCheckbox}>
                                    Partilhar com o aluno
                                </Checkbox>
                                <Checkbox name="partilharComInstrutores" checked={partilharComInstrutores} onChange={this.handlerChangeCheckbox}>
                                    Partilhas com os professores
                                </Checkbox>
                            </Form.Item>
                        </div>
                        <div className="bloco">
                            <Form.Item>
                                <h3 className="titulo-separador">Outros emails</h3>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
                                    {getFieldDecorator("email", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            },
                                            {
                                                type: "email",
                                                message: "Introduza um endereço de e-mail válido"
                                            }
                                        ],
                                        initialValue: email
                                    })(<Input name="email" style={{ width: "90%" }} onChange={this.handlerChange} />)}
                                    <Link to="#" className="botao-icon-adicionar" title="Adicionar" onClick={this.adicionar}>
                                        <Icon type="plus" />
                                    </Link>
                                </div>
                                <div className="lista-emails">
                                    {emails.map((email, index) => (
                                        <div key={index} className="item-email">
                                            <span className="email-titulo">{email}</span>
                                            <div className="email-controlos">
                                                <Link to="#" className="botao-icon-excluir" title="Excluir" onClick={() => this.excluir(index)}>
                                                    <Icon type="delete" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
                <div className="ant-drawer-footer">
                    <button className="botao-secundario" onClick={this.props.onClose} style={{ marginRight: 20, display: "inline-block" }}>
                        Voltar
                    </button>
                    <button className="botao-principal" type="primary" disabled={iconLoading} onClick={this.partilhar} style={{ padding: "0 10px" }}>
                        {iconLoading ? <Icon type="loading" /> : null}
                            Partilhar
                        </button>
                </div>
            </Drawer>
        );
    }
}
const FormDrawerPartilhar = Form.create({ name: "form-drawer-partilhar" })(DrawerPartilhar);

export default FormDrawerPartilhar;
