import React, { Component } from "react";
import { GlobalContext } from "../GlobalState";
import { Badge } from 'antd';
import noimage from "../images/noimage.png";
import esquilo from "../images/Esquilo_3.jpg";
import moment from "moment";

class ConversasMobile extends Component {
    static contextType = GlobalContext;

    emitEmpty = () => {
        this.setState({ pesquisa: "" });
        this.props.resetCaixaProcura();
    };

    handleChange = e => {
        this.setState({ pesquisa: e.target.value });
        if (e.target.value.length === 0) this.props.resetCaixaProcura();
    };

    validarData = data => {
        let diff = moment().diff(data, 'weeks')
        if (diff > 0)
            return moment(data).format("DD/MM/YYYY")

        diff = moment().diff(data, 'days')
        if (diff === 0)
            return moment(data).format("HH:mm")
        else {
            let _data = moment(data).startOf('day')
            diff = moment().startOf('day').diff(_data, 'days')
            if (diff === 1)
                return "Ontem"
            else
                return moment(data).format('dddd')
        }
    }

    render() {
        const {
            iconLoadingGrupos,
            grupos,
            carregarMensagens,
            selecionarConversa,
            selecionada
        } = this.props;

        return (
            <div className={`menu ${this.props.menuVisivel ? "menu-visivel" : ""}`}>
                <div className={`menu-filtros${(grupos.length === 0 || iconLoadingGrupos ? " sem-registos" : "")}`}>
                    {iconLoadingGrupos ?
                        <div className="loading-data" style={{ height: "100%" }}>
                            <div className="loading" />
                        </div>
                        :
                        <>
                            {grupos.length > 0 ?
                                grupos.map((grupo, index) => (
                                    grupo.curso ?
                                        <div key={index} className="menu-item conversa" onClick={() => { carregarMensagens(grupo); selecionarConversa(index); }}>
                                            <div className="info-conversa">
                                                <div className="container-img">
                                                    <Badge dot={grupo.porLer} style={{ background: "#ff9933", marginLeft: 10, width: 10, height: 10 }}>
                                                        <img src={grupo.curso.foto ? grupo.curso.foto : noimage} />
                                                    </Badge>
                                                </div>
                                                <div className="info">
                                                    <span className="nome" style={!grupo.existeMensagens ? { marginTop: 0 } : {}}>
                                                        {grupo.curso.nome}
                                                    </span>
                                                    <div className="info-complementar">
                                                        <span className="codigo">{grupo.curso.codigo}</span>
                                                        {grupo.existeMensagens &&
                                                            <span className="data-hora">{this.validarData(grupo.ultimaMensagem)}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        (grupo.notificacao || grupo.entidade) ?
                                            <div key={index} className="menu-item conversa" onClick={() => { carregarMensagens(grupo); selecionarConversa(index); }}>
                                                <div className="info-conversa">
                                                    <div className="container-img">
                                                        <Badge dot={grupo.porLer} style={{ background: "#ff9933", marginLeft: 10, width: 10, height: 10 }}>
                                                            <img src={grupo.notificacao ? esquilo : grupo.capa ? grupo.capa : noimage} />
                                                        </Badge>
                                                    </div>
                                                    <div className="info">
                                                        <span className="nome" style={!grupo.existeMensagens ? { marginTop: 0 } : {}}>
                                                            <span>
                                                                {grupo.nome}
                                                            </span>
                                                        </span>
                                                        <div className="info-complementar" style={{ justifyContent: "flex-end" }}>
                                                            {grupo.existeMensagens &&
                                                                <span className="data-hora">{this.validarData(grupo.ultimaMensagem)}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div key={index} className="menu-item conversa" onClick={() => { carregarMensagens(grupo); selecionarConversa(index); }}>
                                                <div className="info-conversa">
                                                    <div className="container-img">
                                                        <Badge dot={grupo.porLer} style={{ background: "#ff9933", marginLeft: 10, width: 10, height: 10 }}>
                                                            <img src={grupo.capa ? grupo.capa : grupo.utilizadores[0]?.foto ? grupo.utilizadores[0]?.foto : noimage} />
                                                        </Badge>
                                                    </div>
                                                    <div className="info">
                                                        {grupo.utilizadores.length === 1 ?
                                                            <>
                                                                <span className="nome" style={{ marginTop: 0 }}>
                                                                    {(grupo.nome && grupo.utilizadores.length > 1) ? grupo.nome : grupo.utilizadores[0]?.nome.split(" ")[0] + (grupo.utilizadores[0]?.nome.split(" ").length > 1 ? " " + grupo.utilizadores[0]?.nome.split(" ")[grupo.utilizadores[0]?.nome.split(" ").length - 1] : "")}
                                                                </span>
                                                                <div className="info-complementar">
                                                                    {grupo.utilizadores[0]?.responsavel ?
                                                                        <span className="codigo">{grupo.utilizadores[0]?.responsavel}</span>
                                                                        :
                                                                        <span className="codigo">{grupo.utilizadores[0]?.numero}</span>
                                                                    }
                                                                    {grupo.existeMensagens &&
                                                                        <span className="data-hora">{this.validarData(grupo.ultimaMensagem)}</span>}
                                                                </div>
                                                            </>
                                                            :
                                                            grupo.utilizadores.length > 1 ?
                                                                <span className="nome" style={{ marginTop: 0 }}>
                                                                    {(grupo.nome && grupo.utilizadores.length > 1) ? grupo.nome : grupo.utilizadores[0]?.nome.split(" ")[0] + (grupo.utilizadores[0]?.nome.split(" ").length > 1 ? " " + grupo.utilizadores[0]?.nome.split(" ")[grupo.utilizadores[0]?.nome.split(" ").length - 1] : "")}
                                                                </span>
                                                                :
                                                                <>
                                                                    <span className="nome" style={{ marginTop: 0 }}>
                                                                        {grupo.criador.nome.split(" ")[0] + (grupo.criador.nome.split(" ").length > 1 ? " " + grupo.criador.nome.split(" ")[grupo.criador.nome.split(" ").length - 1] : "")}
                                                                    </span>
                                                                    <div className="info-complementar">
                                                                        <span className="codigo">{grupo.criador.numero}</span>
                                                                        {grupo.existeMensagens &&
                                                                            <span className="data-hora">{this.validarData(grupo.ultimaMensagem)}</span>}
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                ))
                                :
                                <div className="bloco-sem-registos">
                                    <i className="fas fa-comment-dots icon-sem-registo"></i>
                                    <span className="mensagem-sem-registo">Sem registos</span>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default ConversasMobile;
