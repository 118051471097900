import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Icon, Tabs, Collapse, Modal, notification, Button, Form, Select, Radio, Tooltip } from "antd";
import Conteudos from "./_Aux/Conteudos";
import Frequencia from "./_Aux/Frequencia";
import Parecer from "./_Aux/Parecer";
import DrawerAdicionar from "./_Aux/DrawerAdicionar";
import axios from "axios";
import moment from "moment";

const { Option } = Select;
const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

const { TabPane } = Tabs;
const { Panel } = Collapse;

class Detalhe extends Component {
    state = {
        aulaId: "",
        visible: false,
        titulo: "",
        dt_inicio: "",
        hr_inicio: "",
        hr_termino: "",
        recorrencia: false,
        //MODAL EXCLUIR
        visibleExcluir: false,
        visibleExcluirWaiting: false,
        opcao: 1,
        //REDIRECT
        redirect: false,
        redirectLink: ""
    }

    componentDidMount() {
        this.detalhe();
    }

    selecionarOpcaoHandler = (event) => {
        this.setState({
            opcao: event.target.value
        })
    }

    detalhe = () => {
        axios({
            method: "get",
            url: "/api/aulas/detalhe",
            params: {
                aulaId: localStorage.getItem("aula_diario")
            }
        })
            .then(response => {
                this.setState({
                    titulo: response.data.titulo,
                    dt_inicio: moment(response.data.dt_inicio).format("DD/MM/YYYY"),
                    hr_inicio: response.data.hr_inicio,
                    hr_termino: response.data.hr_termino,
                    recorrencia: response.data.recorrencia
                })
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível carregar a aula!");
            });
    }

    selecionarExcluir = () => {
        if (!this.state.recorrencia)
            confirm({
                title: "Pretende excluir esta aula?",
                okText: "Confirmar",
                okType: "Cancelar",
                onOk: () => {
                    this.excluir(false);
                }
            });
        else {
            this.setState({
                visibleExcluir: true
            })
        }
    }

    excluir = () => {
        this.setState({
            visibleExcluir: false,
            visibleExcluirWaiting: true
        })
        axios({
            method: "delete",
            url: "/api/aulas/excluir",
            params: {
                aulaId: localStorage.getItem("aula_diario"),
                opcao: this.state.opcao
            }
        })
            .then(response => {
                openNotificationWithIcon("success", "Sucesso", "Aula excluida!");
                this.setState({
                    visibleExcluirWaiting: false,
                    redirect: true,
                    redirectLink: `/gerir-curso/${this.props.match.params.curso}/curso-diario`
                });
            })
            .catch(error => {
                openNotificationWithIcon("error", "Erro", "Não foi possível excluir esta aula!");
            });
    }

    render() {
        const {
            aulaId,
            visible,
            titulo,
            dt_inicio,
            hr_inicio,
            hr_termino,
            //MODAL EXCLUIR
            visibleExcluir,
            visibleExcluirWaiting,
            opcao,
            //REDIRECT
            redirect,
            redirectLink
        } = this.state;

        if (redirect) return <Redirect to={redirectLink} />;

        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginBottom: 0,
        };

        return (
            <>
                <div className="container container-body">
                    <div className="curso-diario curso-diario-desktop">
                        <div className="bloco-informacoes">
                            <div className="info-aula">
                                <span>
                                    <span className="titulo-aula">{titulo}</span>
                                    <span className="data-aula">{dt_inicio}<i className="fas fa-circle"></i>{hr_inicio} - {hr_termino}</span>
                                </span>
                                <span className="bloco-opcoes">
                                    <Link className="botao-icon-editar" to="#" title="Editar" onClick={() => this.setState({ visible: true })}>
                                        <Icon type="edit" />
                                    </Link>
                                    <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={this.selecionarExcluir}>
                                        <Icon type="delete" />
                                    </Link>
                                    <Link to={`/gerir-curso/${this.props.match.params.curso}/curso-diario`} className="botao-icon-voltar" title="Voltar">
                                        <Icon type="arrow-left" />
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="collapse-info-diario">
                            <Collapse defaultActiveKey={[]} className="collapse-campos">
                                <Panel header="Conteúdos" key="1">
                                    <Conteudos />
                                </Panel>
                                <Panel header="Frequência" key="2">
                                    <Frequencia />
                                </Panel>
                                <Panel header="Pareceres" key="3">
                                    <Parecer />
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                    <div className="curso-diario curso-diario-mobile">
                        <div className="bloco-informacoes">
                            <div className="info-aula">
                                <span>
                                    <span className="titulo-aula">{titulo}</span>
                                    <span className="data-aula">{dt_inicio}</span>
                                    <span className="data-aula">{hr_inicio} - {hr_termino}</span>
                                </span>
                                <span className="bloco-opcoes">
                                    <Link className="botao-icon-editar" to="#" title="Editar" onClick={() => this.setState({ visible: true })}>
                                        <Icon type="edit" />
                                    </Link>
                                    <Link className="botao-icon-excluir" to="#" title="Excluir" onClick={this.selecionarExcluir}>
                                        <Icon type="delete" />
                                    </Link>
                                    <Link to={`/gerir-curso/${this.props.match.params.curso}/curso-diario`} className="botao-icon-voltar" title="Voltar">
                                        <Icon type="arrow-left" />
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <div className="collapse-info-diario">
                            <Collapse defaultActiveKey={[]} className="collapse-campos">
                                <Panel header="Conteúdos" key="1">
                                    <Conteudos />
                                </Panel>
                                <Panel header="Frequência" key="2">
                                    <Frequencia />
                                </Panel>
                                <Panel header="Pareceres" key="3">
                                    <Parecer />
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                </div>
                <DrawerAdicionar
                    aulaId={localStorage.getItem("aula_diario")}
                    visible={visible}
                    onClose={() => this.setState({
                        visible: false
                    })}
                    atualizarAula={(aula) => this.setState({
                        titulo: aula.titulo,
                        dt_inicio: moment(aula.dt_inicio).format("DD/MM/YYYY"),
                        hr_inicio: moment(aula.hr_inicio).format("HH:mm"),
                        hr_termino: moment(aula.hr_termino).format("HH:mm"),
                        visible: false
                    })}
                    temRecorrencia={this.state.recorrencia}
                />
                <Modal
                    visible={visibleExcluir}
                    width="450px"
                    className="modal-encerrar-stepmeet"
                    cancelText="Cancelar"
                    onCancel={() => this.setState({ visibleExcluir: false })}
                    okText="Confirmar"
                    okButtonProps={{ disabled: !opcao }}
                    onOk={this.excluir}
                >
                    <Icon type="question-circle" className="icon-modal-encerrer-stepmeet" />
                    <p className="texto-modal-encerrar-stepmeet">Pretende excluir?</p>
                    <Form>
                        <Form.Item>
                            <Radio.Group onChange={this.selecionarOpcaoHandler} value={opcao}>
                                <Radio style={radioStyle} value={1}>
                                    Esta aula
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                    Esta aula e as seguintes
                                    <Tooltip className="info-icon info-icon2" title="Esta opção não excluí aulas que contenham registos de conteúdos, frequências e pareceres.">
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </Radio>
                                <Radio style={radioStyle} value={3}>
                                    Todas as aulas
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal visible={visibleExcluirWaiting} maskClosable={false} className="exportar-csv" closable={false} footer={null}>
                    <div className="exportar-csv-bloco">
                        <p>
                            <Icon type="loading" />
                        </p>
                        <p className="texto">A excluir...</p>
                    </div>
                </Modal>
            </>
        );
    }
}

export default Detalhe;
