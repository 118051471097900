import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, Alert, notification, Icon, Modal, Tooltip } from "antd";
import Header from "../User/Header";
import { GlobalContext } from "../GlobalState";
import axios from "axios";
import moment from "moment";
import CarouselAlunoConvite from "../Geral/CarouselAlunoConvite";

const confirm = Modal.confirm;
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    });
};

class VideoConfComCodigo extends Component {
    static contextType = GlobalContext;
    state = {
        nome: "",
        codigo: "",
        confirmacao: false,
        //METTING
        meeting_assunto: "",
        meeting_data_hora: "",
        meeting_estado: false,
        gravarSessao: false,
        //LOADING
        iconLoading: false,
        //REDIRECT
        redirect: false,
        redirectLink: "",
        //BROWSERS INFO
        visibleBrowsers: false
    };

    componentDidMount() {
        this.validarVideoconf();
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    validarVideoconf = () => {
        axios({
            method: "get",
            url: "/api/pagina-entidade/validar-videoconferencia",
            params: {
                token: this.props.match.params.token,
                entidadeId: localStorage.getItem("entidadeId")
            }
        })
            .then(response => {
                this.setState({
                    meeting_assunto: response.data.assunto,
                    meeting_data_hora: `${moment(response.data.MeetingDate).format("DD/MM/YYYY")} ${response.data.hora}`,
                    meeting_estado: response.data.estado,
                    gravarSessao: response.data.gravarSessao
                }, () => this.mostrarMensagens())
            })
            .catch(() => {
                openNotificationWithIcon("error", "Erro", "Ocorreu um erro!");
            });
    }

    mostrarMensagens = () => {
        if (this.state.gravarSessao)
            confirm({
                title: "Esta sessão está a ser gravada!",
                okText: "Continuar",
                okType: "Cancelar",
                onOk: () => {
                    this.setState({
                        visibleBrowsers: true
                    });
                },
                onCancel: () => {
                    this.setState({
                        redirect: true,
                        redirectLink: `/${this.props.match.params.entidade}`
                    });
                }
            })
        else
            this.setState({
                visibleBrowsers: true
            })
    }

    //ENVIA CODIGO PARA VALIDAR SE PODE OU NAO ACEDER A VIDEOCONFERENCIA
    Enviar = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ iconLoading: true });

                var item = new FormData();
                item.append("nome", this.state.nome);
                item.append("codigo", this.state.codigo);
                item.append("token", this.props.match.params.token);
                if (this.state.confirmacao)
                    item.append("confirmacao", this.state.confirmacao);

                axios({
                    method: "post",
                    url: "/api/pagina-entidade/videoconferencia-com-codigo",
                    data: item
                })
                    .then(response => {
                        if (response.data !== "EXISTE") {
                            localStorage.setItem("videoconf_userid", response.data.utilizadorId);
                            localStorage.setItem("videoconf_token", this.props.match.params.token);
                            if (response.data.estado === "ENTRAR") {
                                this.setState({
                                    redirect: true,
                                    redirectLink: `/${this.props.match.params.entidade}/videoconferencia/${response.data.videoCodigo}/player-videoconferencia`
                                });
                            }
                            else {
                                this.setState({
                                    redirect: true,
                                    redirectLink: `/${this.props.match.params.entidade}/videoconferencia-aguardar/${this.props.match.params.token}`
                                });
                            }
                        }
                        else {
                            this.setState({ iconLoading: false });
                            confirm({
                                title: "Já existe um utilizador registado com esse nome nesta Stepmeet. É você?",
                                okText: "Confirmar",
                                okType: "Cancelar",
                                onOk: () => {
                                    this.setState({
                                        confirmacao: true
                                    }, () => this.Enviar())
                                }
                            });
                        }
                    })
                    .catch(error => {
                        openNotificationWithIcon("error", "Erro", error.response.data);
                        this.setState({ iconLoading: false });
                    });
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            //METTING
            meeting_assunto,
            meeting_data_hora,
            meeting_estado,
            gravarSessao,
            //LOADING
            iconLoading,
            //REDIRECT
            redirect,
            redirectLink,
            //BROWSERS INFO
            visibleBrowsers
        } = this.state;

        if (redirect)
            return <Redirect to={redirectLink} />;

        return (
            <>
                <Header titulo={meeting_assunto} tem_subtitulo subtitulo={meeting_data_hora} />
                <div className="container container-body">
                    <div className="content">
                        {!meeting_estado ?
                            <Form className="suporte-contacto">
                                <Form.Item>
                                    <Alert message="Introduza o Nome e código para poder participar no Stepmeet" type="info" showIcon />
                                </Form.Item>
                                <Form.Item label="Introduza o seu nome">
                                    {getFieldDecorator("nome", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ]
                                    })(<Input name="nome" onChange={this.handleChange} />)}
                                </Form.Item>
                                <Form.Item label={
                                    <>
                                        Código{" "}
                                        <Tooltip className="info-icon" className="info-icon info-icon2" title="Introduza o código que recebeu no convite">
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </>
                                }>
                                    {getFieldDecorator("codigo", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Campo obrigatório"
                                            }
                                        ]
                                    })(<Input name="codigo" onChange={this.handleChange} />)}
                                </Form.Item>
                                <div className="btn_plano">
                                    <Link to="#" className="botao-principal" disabled={iconLoading} onClick={this.Enviar}>
                                        {iconLoading ? <Icon type="loading" /> : null}
                                    Enviar
                                </Link>
                                </div>
                            </Form>
                            :
                            <Form className="suporte-contacto" style={{ textAlign: "center" }}>
                                <h2 style={{ marginBottom: 0 }}>Esta Stepmeet já terminou!</h2>
                            </Form>
                        }
                    </div>
                </div>
                <CarouselAlunoConvite
                    visibleBrowsers={visibleBrowsers}
                    confirmarEntrada={() => this.setState({ visibleBrowsers: false })}
                    cancelarEntrada={() => this.setState({ visibleBrowsers: false })}
                />
            </>
        );
    }
}
const FormVideoConfComCodigo = Form.create({ name: "video-conf-com-codigo" })(VideoConfComCodigo);

export default FormVideoConfComCodigo;
